import React from 'react'
import { Box, Paper, Grid } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import GET_BACCARAT_ROOMS from 'graphql/application/queries/getBaccaratRooms'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100vw',
    // height: '100vh',
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 150,
    fontSize: theme.typography.pxToRem(32),
  },
}))

const Portal = ({}) => {
  let history = useHistory()
  const classes = useStyles()
  const { data, loading } = useQuery(GET_BACCARAT_ROOMS)

  const goTo = (path) => history.push(path)

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={6}>
          <Paper
            className={classes.button}
            onClick={() => goTo('/leaderboard/normal')}
          >
            初賽即時排名
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            className={classes.button}
            onClick={() => goTo('/leaderboard/super')}
          >
            決賽即時排名
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.button} onClick={() => goTo('/pool')}>
            累積總彩池
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            className={classes.button}
            onClick={() => goTo('/update_pool')}
          >
            更新彩池
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.button} onClick={() => goTo('/allRooms')}>
            六桌展示
          </Paper>
        </Grid>
        {data &&
          data.activeBaccaratRooms.map((room) => (
            <Grid item xs={6} key={room.id}>
              <Paper
                className={classes.button}
                onClick={() => goTo(`/show_room/${room.id}`)}
              >
                {room.name}
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default React.memo(Portal)
