import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LOGOUT from 'images/icons/header/logout.png'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
  icon: {
    width: 18,
    height: 18,
  },
}))

const LogoutButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [, { logout }] = useAuth()

  const logOut = () => {
    logout && logout('/')
  }

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  return (
    <Button
      color="primary"
      fullWidth
      className={buttonClass}
      startIcon={<img src={LOGOUT} className={classes.icon} />}
      onClick={logOut}
    >
      {t('components.header.logout')}
    </Button>
  )
}

export default React.memo(LogoutButton)
