import React, { useState } from 'react'
import { Box, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useGameStatus } from '../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    borderRadius: 999,
    position: 'absolute',
    top: '5%',
    right: '5%',
  },
  time: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
}))

const Time = ({ remainingTime }) => {
  const classes = useStyles()
  return <Box className={classes.time}>{remainingTime}</Box>
}

const Counting = ({ left, waitingBetSeconds }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [remain, setRemain] = useState(left)
  const { shuffle } = useGameStatus()

  const onComplete = () => setRemain(null)

  if (remain > 0 && !shuffle) {
    return (
      <Box className={classes.root}>
        <CountdownCircleTimer
          isPlaying
          duration={waitingBetSeconds}
          initialRemainingTime={remain}
          trailColor="black"
          strokeWidth={3}
          onComplete={onComplete}
          size={theme.spacing(3)}
          colors={[
            [theme.palette.tie.main, 0.33],
            ['#F7B801', 0.33],
            [theme.palette.dealer.main, 0.33],
          ]}
        >
          {Time}
        </CountdownCircleTimer>
      </Box>
    )
  }

  return <Box />
}

export default React.memo(Counting)
