import React, { useRef, useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useChatroom } from './ChatroomProvider'
import { isEmpty } from 'lodash'
import giftList from 'libs/giftList'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    color: 'white',
    width: '50%',
    height: 100,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowY: 'scroll',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    color: 'white',
  },
  gift: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const GiftMessages = ({}) => {
  const classes = useStyles()
  const { messages } = useChatroom()
  const ref = useRef(null)

  useEffect(() => {
    let scrollRef = ref.current
    if (scrollRef) {
      scrollRef.scrollIntoView({ behavior: 'auto' })
      scrollRef.scrollTo(0, scrollRef.scrollHeight)
    }
  }, [messages])

  const giftMessages = useMemo(() => {
    return messages.filter((message) => message.type === 'Message::Gift')
  }, [messages])

  return (
    <Box {...{ ref }} className={classes.root}>
      {giftMessages.map((message, index) => (
        <Box className={classes.container} key={message.id}>
          {message.nickname} 送了{' '}
          <img
            src={giftList.find((gift) => gift.name === message.gift).image}
            className={classes.gift}
          />
        </Box>
      ))}
    </Box>
  )
}

export default React.memo(GiftMessages)
