import React, { useCallback, useState } from 'react'

import { Provider as BlockchainInfoProvider } from 'hooks/useBlockchainInfo'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useTranslation } from 'react-i18next'

import Cards from './Cards'
import Verifier from './Verifier'

export default function BlockchainInfoModal({ onClose }) {
  const { room } = useCurrentRoom()

  const handleClose = useCallback((e) => {
    if (e.currentTarget == e.target) {
      onClose()
    }
  }, [])

  const { t } = useTranslation()

  const [card, setCard] = useState(null)

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-[200]"
      onClick={handleClose}
    >
      <BlockchainInfoProvider room={room}>
        <div className="bg-[#0e426e] fixed inset-3 rounded text-gray-200 flex flex-col h-full md:w-1/2 mx-auto">
          <div className="p-3 bg-[#072036] text-center">
            {t('common.blockchainShoeInfo', { roomName: room.name })}
          </div>
          <div className="my-3 text-center text-xs">
            {t('common.blockchainShoeHint')}
          </div>
          <Cards onCardClick={setCard} />
          {card && <Verifier card={card} key={card.height} />}
        </div>
      </BlockchainInfoProvider>
    </div>
  )
}
