import React, { useCallback, useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { useProfileSettings } from 'hooks/useProfileSettings'
import { isMobile } from 'react-device-detect'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    objectFit: 'cover',
    height: '100%',
  },
}))

function useUnmuteOnTouch(alwaysMute) {
  const { settings } = useProfileSettings()
  const [muted, setMuted] = useState(isMobile ? true : !settings.micOn)

  const updateToRealMuted = useCallback((e) => {
    setMuted(!settings.micOn)
  }, [])

  useEffect(() => {
    document.addEventListener('touchstart', updateToRealMuted, { once: true })
    return () => {
      document.removeEventListener('touchstart', updateToRealMuted)
    }
  }, [])

  if (alwaysMute) {
    return true
  }
  return muted
}

export default function RTCPlayer({ url, alwaysMute = false, style }) {
  const styles = useStyles()
  const ref = useRef(null)
  const muted = useUnmuteOnTouch(alwaysMute)

  useEffect(() => {
    const peer = new RTCPeerConnection(null)

    ;(async function () {
      peer.addTransceiver('audio', { direction: 'recvonly' })
      peer.addTransceiver('video', { direction: 'recvonly' })
      peer.addEventListener('addstream', (e) => {
        ref.current.srcObject = e.stream
        ref.current.play()
      })
      const offerOptions = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      }
      const offer = await peer.createOffer(offerOptions)
      peer.setLocalDescription(offer)
      const request = JSON.stringify({
        version: 'v1.0',
        sessionId: Date.now().toString(),
        localSdp: offer,
      })
      const response = await axios.post(url, request)
      const {
        data: { remoteSdp },
      } = response
      peer.setRemoteDescription(new RTCSessionDescription(remoteSdp))
    })()
  }, [url, ref])

  return (
    <video
      ref={ref}
      playsInline
      muted={muted}
      className={styles.video}
      style={{ ...style }}
    />
  )
}
