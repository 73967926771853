import { useQuery } from '@apollo/client'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CopyBtn from 'components/application/CopyBtn'
import GET_BACCARAT_GAME from 'graphql/application/queries/getBaccaratGame'
import { moneyFormat } from 'helpers/index'
import { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BaccaratGamePanel from './BaccaratGamePanel'
import { withProfiler } from '@sentry/react'

const targets = [
  'player',
  'dealer',
  // 'big',
  // 'small',
  'tie',
  'playerPair',
  'dealerPair',
  // 'super6',
]

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    paddingTop: theme.spacing(1),
  },
  title: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255, 255, 255, 0.5)',
  },
  id: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginBottom: theme.spacing(1),
  },
  table: {
    border: '2px solid #242525',
    textAlign: 'center',
    color: theme.palette.common.white,
    width: '100%',
  },
  tableHeader: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '25%',
  },
  tableTitle: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  noResult: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(20),
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const BaccaratGameResult = ({ betNo }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data, loading } = useQuery(GET_BACCARAT_GAME, {
    variables: { betNo },
  })

  if (loading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    )
  }

  if (data) {
    const { baccaratBetRecord: record, baccaratGame: game } = data

    return (
      <Box className={classes.root}>
        <Box className={classes.list}>
          <Box className={classes.title}>{t('screens.records.gameNo')}</Box>
          <Box className={classes.id}>
            {game.gameNo}
            <CopyBtn text={game.gameNo} />
          </Box>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.title}>{t('screens.records.betNo')}</Box>
          <Box className={classes.id}>
            {record.betNo}
            <CopyBtn text={record.betNo} />
          </Box>
        </Box>
        <Box className={classes.divider} />
        <Box>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableHeader}></th>
                <th className={classes.tableHeader}>
                  {t('screens.records.bet')}
                </th>
                <th className={classes.tableHeader}>
                  {t('screens.records.winOrLoss')}
                </th>
              </tr>
            </thead>
            <tbody>
              {targets.map((target) => (
                <tr key={target}>
                  <td className={classes.tableTitle}>
                    {t(`screens.records.bet${capitalize(target)}`)}
                  </td>
                  <td className={classes.tableCell}>
                    {moneyFormat(record[`${target}Amount`])}
                  </td>
                  <td className={classes.tableCell}>
                    {moneyFormat(record[`${target}DiffAmount`])}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {game.status !== 'closed' && (
            <Box className={classes.noResult}>尚未開牌</Box>
          )}
          {game.status === 'closed' && <BaccaratGamePanel game={game} />}
        </Box>
      </Box>
    )
  }
}

export default withProfiler(React.memo(BaccaratGameResult))
