import React, { useEffect, useState } from 'react'

import AuthScreen from 'components/richman/AuthScreen'
import BackHeader from 'components/richman/BackHeader'
import DesktopLayout from 'components/richman/DesktopLayout'
import Footer from 'components/richman/Footer'
import Header from 'components/richman/Header'
import { AllBaccaratsProvider } from 'hooks/useAllBaccarats'
import useAuth, { AuthProvider } from 'hooks/useAuth'
import useCable from 'hooks/useCable'
import { ConnectingProvider } from 'hooks/useConnecting'
import FullModalProvider from 'hooks/useFullModal'
import { LatencyProvider } from 'hooks/useLatency'
import ProfileSettingsProvider from 'hooks/useProfileSettings'
import useToken from 'hooks/useToken'
import { SnackbarProvider } from 'notistack'
import ApolloClientProvider from 'providers/richman/ApolloClientProvider'
import AppProvider from 'providers/richman/AppProvider'
import LogoutProvider from 'providers/richman/LogoutProvider'
import ProfileProvider, { useProfile } from 'providers/richman/ProfileProvider'
import SiteSettingsProvider from 'providers/richman/SiteSettingsProvider'
import SoundProvider from 'providers/richman/SoundProvider'
import SystemMessageProvider from 'providers/richman/SystemMessageProvider'
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import AllRooms from 'screens/richman/AllRooms'
import BaccaratRoom from 'screens/richman/BaccaratRoom'
// import BaccaratRoom from 'screens/richman/BaccaratRoom'
import BaccaratRooms from 'screens/richman/BaccaratRooms'
import BaccaratRoomsList from 'screens/richman/BaccaratRoomsList'
import GiftRecords from 'screens/richman/GiftRecords'
import Girl from 'screens/richman/Girl'
import Girls from 'screens/richman/Girls'
import Leaderboard from 'screens/richman/Leaderboard'
import Lobby from 'screens/richman/Lobby'
import Login from 'screens/richman/Login'
import Pool from 'screens/richman/Pool'
import Portal from 'screens/richman/Portal'
import Profile from 'screens/richman/Profile'
import BetRecords from 'screens/richman/Profile/BetRecords'
import Ranking from 'screens/richman/Ranking'
import Records from 'screens/richman/Records'
import ScoreBoardTest from 'screens/richman/ScoreBoardTest'
import ShowRoom from 'screens/richman/ShowRoom'
import SignUp from 'screens/richman/SignUp'
import Streamer from 'screens/richman/Streamer'
import Streamers from 'screens/richman/Streamers'
import TongRoom from 'screens/richman/TongRoom'
import TongRoomProvider from 'screens/richman/TongRoom/TongRoomProvider'
import TongRooms from 'screens/richman/TongRooms'
import UpdatePool from 'screens/richman/UpdatePool'
import UpdatePassword from 'screens/richman/UpdatePassword'
import styled from 'styled-components'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider'
import WebRTCProvider from 'providers/richman/WebRTCProvider'

const Container = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
`

const GameContainer = styled(Box)`
  height: 100vh;
  height: 100%;
`

const Background = styled.div`
  flex-grow: 1;
  position: relative;
`

const useStyles = makeStyles((theme) => ({
  game: {
    height: '100%',
  },
  snackbarWarning: {
    backgroundColor: theme.palette.gold.main,
    color: 'black',
  },
  hide: {
    display: 'none',
  },
  snackbar: {
    top: 30,
  },
}))
const PrivateRoute = ({ children, ...rest }) => {
  const [{ isAuthenticated }] = useAuth()
  const { profile } = useProfile()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          profile && (
            <ProfileSettingsProvider>{children}</ProfileSettingsProvider>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const Socket = ({ children }) => {
  const [token] = useToken()
  const [{ isAuthenticated }] = useAuth()
  const [url, setUrl] = useState(null)
  const cable = useCable(isAuthenticated ? `token=${token}` : 'default')

  return <ActionCableProvider cable={cable}>{children}</ActionCableProvider>
}

const MinimalLayout = ({ children }) => {
  return (
    <SoundProvider>
      <FullModalProvider>{children}</FullModalProvider>
    </SoundProvider>
  )
}

const MainLayout = ({
  children,
  hasBack = false,
  title = null,
  withoutFooter = false,
}) => {
  return (
    <FullModalProvider>
      <SoundProvider>
        <Container>
          {hasBack ? <BackHeader title={title} /> : <Header />}
          <Background flexGrow={1} position="relative">
            <Content>{children}</Content>
          </Background>
          {!withoutFooter && <Footer />}
        </Container>
      </SoundProvider>
    </FullModalProvider>
  )
}

const AuthLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <Container>
      <Background flexGrow={1} position="relative">
        <Content>{children}</Content>
      </Background>
    </Container>
  )
}

const ProviderStack = ({ children }) => {
  const classes = useStyles()

  return (
    <AuthProvider>
      <AuthScreen>
        <ApolloClientProvider>
          <SiteSettingsProvider>
            <Socket>
              <SnackbarProvider
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                classes={{
                  root: classes.snackbar,
                  variantWarning: classes.snackbarWarning,
                }}
              >
                <ProfileProvider>
                  <LatencyProvider>
                    <ConnectingProvider>
                      <AppProvider>
                        <SystemMessageProvider>
                          {/* <WebRTCProvider> */}
                          <HashRouter>{children}</HashRouter>
                          {/* </WebRTCProvider> */}
                        </SystemMessageProvider>
                      </AppProvider>
                    </ConnectingProvider>
                  </LatencyProvider>
                </ProfileProvider>
              </SnackbarProvider>
            </Socket>
          </SiteSettingsProvider>
        </ApolloClientProvider>
      </AuthScreen>
    </AuthProvider>
  )
}

const RouterStack = () => {
  let location = useLocation()

  useEffect(() => {
    window.fbq('track', 'PageView', {
      url: window.location.href,
    })
  }, [location])

  return (
    <Switch>
      <PrivateRoute path="/rooms/:id">
        <LogoutProvider>
          <MinimalLayout>
            <BaccaratRoom />
          </MinimalLayout>
        </LogoutProvider>
      </PrivateRoute>
      <PrivateRoute path="/streamers/:id" exact>
        <LogoutProvider>
          <MinimalLayout>
            <AllBaccaratsProvider key="streamer" noRefetch>
              <Streamer />
            </AllBaccaratsProvider>
          </MinimalLayout>
        </LogoutProvider>
      </PrivateRoute>
      <Route path="/portal" exact>
        <DesktopLayout>
          <Portal />
        </DesktopLayout>
      </Route>
      <Route path="/pool" exact>
        <DesktopLayout>
          <Pool />
        </DesktopLayout>
      </Route>
      <Route path="/update_pool" exact>
        <DesktopLayout>
          <UpdatePool />
        </DesktopLayout>
      </Route>
      <Route path="/show_room/:id" exact>
        <DesktopLayout>
          <ShowRoom />
        </DesktopLayout>
      </Route>
      <Route path="/login" exact>
        <AuthLayout>
          <Login />
        </AuthLayout>
      </Route>
      <Route path="/sign_up" exact>
        <AuthLayout>
          <SignUp />
        </AuthLayout>
      </Route>
      <PrivateRoute>
        <LogoutProvider>
          <MainLayout>
            <AllBaccaratsProvider>
              <PrivateRoute path="/" exact>
                <BaccaratRooms />
              </PrivateRoute>
              <PrivateRoute path="/list" exact>
                <BaccaratRoomsList />
              </PrivateRoute>
              <PrivateRoute path="/girls" exact>
                <Girls />
              </PrivateRoute>
              <PrivateRoute path="/girls/:id" exact>
                <Girl />
              </PrivateRoute>
              <PrivateRoute path="/streamers" exact>
                <Streamers />
              </PrivateRoute>
              <PrivateRoute path="/ranking" exact>
                <Ranking />
              </PrivateRoute>
              <PrivateRoute path="/profile" exact>
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/profile/records" exact>
                <Records />
              </PrivateRoute>
              <PrivateRoute path="/profile/bet_records" exact>
                <BetRecords />
              </PrivateRoute>
              <PrivateRoute path="/score_board_test" exact>
                <ScoreBoardTest />
              </PrivateRoute>
              <PrivateRoute path="/profile/gift_records" exact>
                <GiftRecords />
              </PrivateRoute>
              <PrivateRoute path="/update_password" exact>
                <UpdatePassword />
              </PrivateRoute>
            </AllBaccaratsProvider>
          </MainLayout>
        </LogoutProvider>
      </PrivateRoute>
    </Switch>
  )
}

const Router = ({}) => {
  return (
    <ProviderStack>
      <RouterStack />
    </ProviderStack>
  )
}

export default Router
