import React, { useEffect, useState, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { range, isEmpty, compact } from 'lodash'
import { GameResult, PairResult, RoundResult } from 'libs/ScoreBoard/types'
import { useMeasure } from 'react-use'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: 'white',
    height: '100%',
  },
  table: {
    width: '100%',
    height: '100%',
    borderCollapse: 'collapse',
  },
  cell: {
    border: '1px solid #ccc',
    textAlign: 'center',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      marginTop: '100%',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  bgImg: {
    width: '100%',
    height: 'auto',
    verticalAlign: 'top',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    flex: 1,
  },
  rowItem: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  blank: {
    width: '80%',
    height: '80%',
    background: 'transparent',
    color: 'white',
    // fontSize: theme.typography.pxToRem(10),
  },
  dealer: {
    background: '#E40F0F',
    borderRadius: 999,
    color: 'white',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  super6: {
    background: 'transparent',
    color: theme.palette.gold.main,
    fontSize: theme.typography.pxToRem(20),
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  player: {
    background: '#0679EC',
    borderRadius: 999,
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'relative',
  },
  tie: {
    background: '#00B7BA',
    borderRadius: 999,
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'relative',
  },
  dealerPair: {
    position: 'absolute',
    top: '15%',
    left: '-15%',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    background: '#E40F0F',
    border: '1px solid white',
    height: '30%',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
  },
  playerPair: {
    position: 'absolute',
    bottom: '15%',
    right: '-15%',
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '0% 100%',
    background: '#0679EC',
    border: '1px solid white',
    height: '30%',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
  },
}))

const CustomBeadRaod = ({ beadRoad, rows = 6, columns = 12 }) => {
  const classes = useStyles()
  const [ref, { width }] = useMeasure()

  const renderPair = (pairResult) => {
    if (pairResult === 'dealer') {
      return <span className={classes.dealerPair} />
    } else if (pairResult === 'player') {
      return <span className={classes.playerPair} />
    } else if (pairResult === 'both') {
      return (
        <>
          <span className={classes.dealerPair} />
          <span className={classes.playerPair} />
        </>
      )
    }
  }

  const size = useMemo(() => {
    return `${width / columns - 5}px`
  }, [width])

  const fontSize = useMemo(() => {
    return `${(width / columns) * 0.6}px`
  }, [width])

  const diff = useMemo(() => {
    if (beadRoad) {
      return compact(beadRoad.array[0]).length - columns
    }
    return 0
  }, [beadRoad, columns])

  const result = (rowIndex, columnIndex) => {
    let item = null
    if (diff <= 0) {
      item = beadRoad.array[rowIndex][columnIndex]
    } else {
      item = beadRoad.array[rowIndex][columnIndex + diff]
    }

    if (isEmpty(item)) {
      return <Box className={classes.blank}></Box>
    }

    const { game_result, result, pair_result } = item
    if (game_result === 'dealer' && result === 6) {
      return (
        <Box className={classes.dealer}>
          <Box
            className={classes.super6}
            style={{ width: size, height: size, fontSize }}
          >
            6{renderPair(pair_result)}
          </Box>
        </Box>
      )
    } else if (game_result === 'dealer') {
      return (
        <Box
          className={classes.dealer}
          style={{ width: size, height: size, fontSize }}
        >
          莊{renderPair(pair_result)}
        </Box>
      )
    } else if (game_result === 'player') {
      return (
        <Box
          className={classes.player}
          style={{ width: size, height: size, fontSize }}
        >
          閒 {renderPair(pair_result)}
        </Box>
      )
    } else if (game_result === 'tie') {
      return (
        <Box
          className={classes.tie}
          style={{ width: size, height: size, fontSize }}
        >
          和 {renderPair(pair_result)}
        </Box>
      )
    }
  }
  return (
    <Box className={classes.root} {...{ ref }}>
      <table className={classes.table}>
        <tbody>
          {beadRoad &&
            range(rows).map((rowIndex) => (
              <tr key={`rowIndex-${rowIndex}`}>
                {range(columns).map((columnIndex) => (
                  <td
                    className={classes.cell}
                    key={`columnIndex-${rowIndex}-${columnIndex}`}
                    // style={{
                    //   width: `${100 / columns}%`,
                    // }}
                  >
                    <div className={classes.content}>
                      {result(rowIndex, columnIndex)}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  )
}

export default React.memo(CustomBeadRaod)
