import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'

import useWallet from 'hooks/useWallet'
import { useProfile } from 'providers/richman/ProfileProvider'

import BG from 'images/richman/username_bg.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: theme.spacing(1),
  },
  username: {
    flexGrow: 1,
    flexBasis: 0,
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  usernameBg: {
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  usernameText: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    fontSize: theme.typography.pxToRem(18),
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  info: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: '#D6D6D6',
    fontWeight: 'bold',
    letterSpacing: '0.09em',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  value: {
    fontSize: theme.typography.pxToRem(14),
    background:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%)',
    backgroundChip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    textFillColor: 'transparent',
    letterSpacing: '0.09em',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

const ProfileInfo = ({}) => {
  const classes = useStyles()
  const { profile } = useProfile()
  const [wallet] = useWallet()
  const { t } = useTranslation()

  if (!profile) return null
  if (!wallet) return null

  return (
    <Box className={classes.root}>
      <Box className={classes.username}>
        <img src={BG} className={classes.usernameBg} />
        <Box className={classes.usernameText}>
          {profile.nickname || profile.username}
        </Box>
      </Box>
      <Box className={classes.info}>
        <Box className={classes.row}>
          <Box className={classes.label}>{t('screens.profile.deposit')}：</Box>
          <Box className={classes.value}>
            {numeral(wallet.creditBalance).format('0,0.00')}
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box className={classes.label}>
            {t('screens.profile.effectiveAmount')}：
          </Box>
          <Box className={classes.value}>
            {numeral(wallet.totalEffectiveAmount).format('0,0.00')}
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box className={classes.label}>{t('screens.profile.credits')}：</Box>
          <Box className={classes.value}>
            {numeral(wallet.totalEffectiveXimaAmount).format('0,0.00')}
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box className={classes.label}>{t('screens.profile.balance')}：</Box>
          <Box className={classes.value}>
            {numeral(wallet.balance).format('0,0.00')}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(ProfileInfo)
