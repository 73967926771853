import { gql } from '@apollo/client'

export default gql`
  mutation createChatroomMessage($input: CreateChatroomMessageInput!) {
    createChatroomMessage(input: $input) {
      errors {
        message
        attribute
      }
    }
  }
`
