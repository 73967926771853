import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfile } from 'providers/richman/ProfileProvider'
import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    background:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    borderWidth: '0px 1.5px 1px 0px',
    borderStyle: 'solid',
    borderImageSource:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    boxShadow: '2px 2px 4px 0px #1F1F1F9C inset',
    position: 'relative',
    width: '100%',
  },
  inner: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    boxShadow:
      'inset 1px 1px 2px rgba(31, 31, 31, 0.61), inset -1px -1px 2px rgba(198, 198, 198, 0.58)',
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(0.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

const EditNickname = ({}) => {
  const classes = useStyles()
  const {
    profile: { nickname, username },
  } = useProfile()
  const [update, { loading }] = useMutation(UPDATE_PROFILE)
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState(nickname)
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()

  const isZH = ['zhTW', 'zhCN'].includes(i18n.language)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onSubmit = async () => {
    const result = await update({
      variables: {
        input: {
          nickname: name,
        },
      },
    })

    const { errors, profile: newProfile } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    } else {
      enqueueSnackbar(t('actions.updateSuccess'), { variant: 'success' })
      onClose && onClose()
    }
  }

  return (
    <>
      <button onClick={onOpen} className={classes.btn}>
        <Box className={classes.inner}>{t('screens.profile.editNickname')}</Box>
      </button>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            name="nickname"
            variant="outlined"
            label="暱稱"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={onSubmit}
          >
            {t('actions.update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(EditNickname)
