import React from 'react'

import clsx from 'clsx'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  message: {
    background: '#39425A',
    borderRadius: 9.5,
    fontSize: theme.typography.pxToRem(12),
    color: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  win: {
    background: 'rgba(105, 248, 243, 0.58)',
  },
  lose: {
    background: theme.palette.dealer.main,
  },
}))

const Streak = ({ message }) => {
  const classes = useStyles()
  const isWin = message.body.includes('贏')

  return (
    <Typography
      className={clsx(classes.message, {
        [classes.win]: isWin,
        [classes.lose]: !isWin,
      })}
    >
      {message.body}
    </Typography>
  )
}

export default React.memo(Streak)
