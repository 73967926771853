import React, { useEffect, useState } from 'react'

import UPDATE_SETTINGS from 'graphql/application/mutations/updateSettings'
import { useProfileSettings } from 'hooks/useProfileSettings'
import ANIMATE from 'images/richman/music.gif'
import BACKGROUND from 'images/richman/player_background.png'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slider,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    position: 'relative',
    backgroundImage: `url(${BACKGROUND})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxHeight: 700,
  },
  item: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '70%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mute: {
    width: '2.2em',
    height: '2.2em',
  },
  title: {
    whiteSpace: 'nowrap',
    width: '15%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    padding: 0,
    paddingTop: '0 !important',
    maxHeight: 700,
    position: 'relative',
    overflow: 'hidden',
  },
  slider: {
    padding: 10,
  },
  scroll: {
    overflowY: 'scroll',
  },
}))

const Settings = ({ open, onClose }) => {
  const classes = useStyles()
  const { settings } = useProfileSettings()
  const [update] = useMutation(UPDATE_SETTINGS)
  const [volume, setVolume] = useState({
    sound: settings.volume,
    music: settings.musicVolume,
    mic: settings.micVolume,
  })
  const { t } = useTranslation()

  const updateSettings = async (data) => {
    await update({
      variables: {
        input: {
          ...settings,
          ...data,
          tableLimitId: null,
          soundVolume: settings.volume,
        },
      },
    })
  }

  const handleSoundChange = (event: any) => {
    updateSettings({
      soundOn: !settings.soundOn,
    })
  }

  const handleMusicChange = (event: any) => {
    updateSettings({
      musicOn: !settings.musicOn,
    })
  }

  useEffect(() => {
    if (!isEqual(settings, volume)) {
      setVolume({
        sound: settings.volume,
        music: settings.musicVolume,
        mic: settings.micVolume,
      })
    }
  }, [settings])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.root}>
          <Box alignSelf="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon style={{ color: '#FFD700' }} />
            </IconButton>
          </Box>
          <Box className={classes.item}>
            <Box className={classes.title}>{t('components.header.audio')}</Box>
            <Switch checked={settings.soundOn} onChange={handleSoundChange} />
          </Box>
          <Box pl={2} pr={2}>
            <img
              src={ANIMATE}
              style={{
                width: '100%',
                height: 'auto',
                marginTop: 20,
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(Settings)
