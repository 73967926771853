import React, { useCallback } from 'react'

import useAgreeTerms from 'hooks/useAgreeTerms'
import useAuth from 'hooks/useAuth'
import { useLocalStorage } from 'react-use'
import InstallNotice from 'screens/richman/BaccaratRooms/InstallNotice'
import TICRF from 'images/ticrf.png'
import { useTranslation } from 'react-i18next'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    background: 'white',
  },
  text: {
    color: '#1E202C',
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  smallText: {
    color: '#1E202C',
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  buttonText: {},
  content: {
    background: '#fff',
  },
  contentText: {
    color: '#1E202C',
  },
}))

const Terms = ({}) => {
  const classes = useStyles()
  const [, { logout }] = useAuth()
  const { t } = useTranslation()
  const [agree, setAgree, remove] = useAgreeTerms()
  const [hide] = useLocalStorage('installNotice', false)
  const fontSize = useFontSizeByLang()

  const sizeTen = useFontSizeByLang({ zhSize: 13, otherSize: 10 })
  const sizeTwelve = useFontSizeByLang({ otherSize: 12 })

  const onAgree = useCallback(() => {
    setAgree(true)
  }, [])

  const onDisagree = () => {
    logout('/')
  }

  if (agree || hide) return null
  if (agree) return <InstallNotice />

  return (
    <Dialog open={!agree} fullWidth maxWidth="sm">
      <DialogTitle>{t('components.terms.title')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h21')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h211')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h22')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h221')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h23')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h231')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h24')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h241')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h242')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h243')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h244')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h245')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h246')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h247')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h248')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h25')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h251')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h252')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h253')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h254')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h255')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h256')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h257')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h26')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h261')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h262')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h27')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h271')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h28')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h281')}
        </Typography>
        <br />
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h29')}
        </Typography>
        <Typography className={clsx(classes.contentText, fontSize)}>
          {t('components.terms.h291')}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={clsx(sizeTwelve, classes.text)}>
            {t('components.terms.disagree')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onDisagree}
            className={clsx(sizeTwelve, classes.buttonText)}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography className={sizeTen}>
                {t('components.terms.notAdult')}
              </Typography>
              <Typography className={sizeTen}>
                {t('components.terms.leaveGame')}
              </Typography>
            </Box>
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={TICRF}
            style={{
              width: 30,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 16,
            }}
          />
          <Typography className={clsx(classes.smallText, sizeTen)}>
            {t('components.terms.nudeHint')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={clsx(classes.text, sizeTen)}>
            {t('components.terms.agree')}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onAgree}
            className={clsx(classes.buttonText, sizeTen)}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography className={sizeTen}>
                {t('components.terms.adult')}
              </Typography>
              <Typography className={sizeTen}>
                {t('components.terms.enterGame')}
              </Typography>
            </Box>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(Terms)
