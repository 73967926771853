import React, { useMemo } from 'react'
import { Box, Typography, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGame } from 'screens/richman/AllRooms/GameProvider'
import { useMeasure } from 'react-use'
import FirstTwoCards from './FirstTwoCards'
import ThirdCard from './ThirdCard'
import Win from './Win'
import TieWin from './TieWin'
import clsx from 'clsx'
import LOGO from 'images/richman/logo_630x180.png'

interface StyleProps {
  fontSize: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  '@keyframes dealerWinner': {
    '0%': {
      boxShadow: `0 0 10px 5px ${theme.palette.dealer.main}`,
    },
    '100%': {
      boxShadow: `0 0 30px 15px ${theme.palette.dealer.main}`,
    },
  },
  '@keyframes playerWinner': {
    '0%': {
      boxShadow: `0 0 10px 5px ${theme.palette.player.main}`,
    },
    '100%': {
      boxShadow: `0 0 30px 15px ${theme.palette.player.main}`,
    },
  },
  '@keyframes tiePlayerWinner': {
    '0%': {
      boxShadow: `0 0 10px 5px ${theme.palette.tie.main}`,
    },
    '100%': {
      boxShadow: `0 0 30px 15px ${theme.palette.tie.main}`,
    },
  },
  '@keyframes tieDealerWinner': {
    '0%': {
      boxShadow: `0 0 10px 5px ${theme.palette.tie.main}`,
    },
    '100%': {
      boxShadow: `0 0 30px 15px ${theme.palette.tie.main}`,
    },
  },
  dealerWin: {
    animation: `$dealerWinner 0.75s infinite alternate`,
  },
  playerWin: {
    animation: `$playerWinner 0.75s infinite alternate`,
  },
  tieDealerWin: {
    animation: `$tieDealerWinner 0.75s infinite alternate`,
  },
  tiePlayerWin: {
    animation: `$tiePlayerWinner 0.75s infinite alternate`,
  },
  root: {
    background: 'rgba(0, 0, 0, 0.6)',
    flexGrow: 1,
    width: '37%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
  },
  player: {
    width: '100%',
    background:
      'linear-gradient(to right top,rgba(7,23,49,.8),rgba(0,174,255,.8))',
    flex: 1,
    height: '100%',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    border: '.5px solid #00aeff',
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: '5%',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
    '&:before': {
      backgroundImage:
        'linear-gradient(45deg , rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.1) 35%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0) 80%)',
    },
  },
  dealer: {
    width: '100%',
    background:
      'linear-gradient(to right bottom,rgba(255,0,0,.8),rgba(45,15,13,.8))',
    flex: 1,
    height: '100%',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    border: '.5px solid red',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    paddingTop: '5%',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
    '&:before': {
      backgroundImage:
        'linear-gradient(45deg , rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.1) 35%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.1) 65%, rgba(255, 255, 255, 0) 80%)',
    },
  },
  result: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 1% 1% 1%',
    position: 'absolute',
    bottom: 0,
  },
  playerTitle: (props) => ({
    fontSize: props.fontSize,
    color: theme.palette.player.main,
    fontWeight: 'bold',
    lineHeight: 1,
  }),
  dealerTitle: (props) => ({
    fontSize: props.fontSize,
    color: theme.palette.dealer.main,
    fontWeight: 'bold',
    lineHeight: 1,
  }),
  points: (props) => ({
    color: theme.palette.common.white,
    fontSize: props.fontSize,
    lineHeight: 1,
  }),
  cards: {
    width: '100%',
    // maxHeight: '37%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '80%',
    height: 'auto',
  },
  roomName: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: 'white',
  },
}))

const GameResult = ({ roomName }) => {
  const [ref, { height, width }] = useMeasure()

  const fontSize = useMemo(() => {
    return `${width * 0.2}px`
  }, [width])

  const fontSizeProps: StyleProps = { fontSize }
  const classes = useStyles(fontSizeProps)
  const { win, points } = useGame()

  return (
    <Box {...{ ref }} className={classes.root}>
      <Box display="flex" justifyContent="center" alignItems="stretch">
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
        >
          <img src={LOGO} className={classes.logo} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <Typography className={classes.roomName}>{roomName}</Typography>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box
          className={clsx(
            classes.player,
            win.player ? classes.playerWin : null,
            win.tie ? classes.tiePlayerWin : null,
          )}
        >
          <Box className={classes.cards}>
            <FirstTwoCards height={height} target="player" />
            <ThirdCard height={height} target="player" />
            <Win target="player" />
          </Box>
          <Box className={classes.result}>
            <Typography className={classes.playerTitle}>閒</Typography>
            <Typography className={classes.points}>
              {points.player || 0}
            </Typography>
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.dealer,
            win.dealer ? classes.dealerWin : null,
            win.tie ? classes.tieDealerWin : null,
          )}
        >
          <Box className={classes.cards}>
            <FirstTwoCards height={height} target="dealer" />
            <ThirdCard height={height} target="dealer" />
            <Win target="dealer" />
          </Box>
          <Box className={classes.result}>
            <Typography className={classes.dealerTitle}>莊</Typography>
            <Typography className={classes.points}>
              {points.dealer || 0}
            </Typography>
          </Box>
        </Box>
        <TieWin />
      </Box>
    </Box>
  )
}

export default React.memo(GameResult)
