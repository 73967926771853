import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0.5, 0.5),
    background: '#f1eee4',
  },
  player: {
    color: theme.palette.player.main,
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  dealer: {
    color: theme.palette.dealer.main,
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  playerAsk: {
    background: theme.palette.player.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  dealerAsk: {
    background: theme.palette.dealer.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(12),
  },
  bigEyeDealer: {
    border: `2px solid ${theme.palette.dealer.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  bigEyePlayer: {
    border: `2px solid ${theme.palette.player.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: theme.palette.dealer.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: theme.palette.player.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.dealer.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.player.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    color: 'black',
    fontSize: theme.typography.pxToRem(12),
  },
}))

const EmptyHintRoad = ({}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>莊</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.player}>閒</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.tie}>和</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>莊對</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>閒對</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealerAsk}>莊問路</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.playerAsk}>閒問路</Typography>
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(EmptyHintRoad))
