import React, { useCallback, useMemo } from 'react'

import useBetState from 'hooks/useBetState'
import useCommissionFree from 'hooks/useCommissionFree'
import commissionFreeImage from 'images/richman/commission_free.png'
import normalImage from 'images/richman/normal.png'

import { useNotification } from '../NotificationProvider'

const ToggleCommissionFreeButton = () => {
  const [betState] = useBetState()
  const { setNotification } = useNotification()
  const totalConfirmed = useMemo(() => {
    const keys = Object.keys(betState)
    return keys.reduce((acc, key) => {
      if (!betState[key].confirmed) return acc
      return acc + betState[key].confirmed
    }, 0)
  }, [betState])

  const { isCommissionFree, update } = useCommissionFree()
  const onClick = useCallback(async () => {
    if (totalConfirmed > 0) {
      setNotification('同一局是否免佣需為一致')
    } else {
      update(!isCommissionFree)
    }
  }, [update, isCommissionFree, totalConfirmed])

  return isCommissionFree ? (
    <img src={commissionFreeImage} onClick={onClick} />
  ) : (
    <img src={normalImage} onClick={onClick} />
  )
}

export default React.memo(ToggleCommissionFreeButton)
