import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import { useProfile } from 'providers/richman/ProfileProvider'
import SIGN_UP from 'images/richman/signup.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    right: 0,
    bottom: 0,
  },
  img: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  link: {
    display: 'inline-box',
  },
}))

const SignUp = ({}) => {
  const classes = useStyles()
  const {
    profile: { isTrial },
  } = useProfile()

  if (isTrial) {
    return (
      <div className={classes.root}>
        <a href="https://lihi2.com/b9MyG" className={classes.link}>
          <img src={SIGN_UP} className={classes.img} />
        </a>
      </div>
    )
  } else {
    return null
  }
}

export default React.memo(SignUp)
