import React, { useCallback, useState } from 'react'

import clsx from 'clsx'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import { useFullModal } from 'hooks/useFullModal'
import BET_RECORDS from 'images/icons/header/bet_records.png'
import { useTranslation } from 'react-i18next'
import BetRecords from 'screens/richman/Profile/BetRecords'

import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
  icon: {
    width: 18,
    height: 18,
  },
}))

const BetRecordsButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setModal } = useFullModal()

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  const toggleRecords = useCallback(() => {
    onCloseAnchor()
    setModal({
      title: t('components.header.betRecords'),
      modal: <BetRecords />,
    })
  }, [onCloseAnchor, setModal, t])

  return (
    <Button
      color="primary"
      className={buttonClass}
      startIcon={<img src={BET_RECORDS} className={classes.icon} />}
      onClick={toggleRecords}
      fullWidth
    >
      {t('components.header.betRecords')}
    </Button>
  )
}

export default React.memo(BetRecordsButton)
