import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { Box, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2, 3),
    color: 'black',
    fontSize: theme.typography.pxToRem(24),
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.gold.main,
    borderRadius: theme.spacing(0.5),
    zIndex: 1301,
  },
  papper: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  backdrop: {
    background: 'transparent',
  },
  dialogContainer: {
    // width: '100%',
    // height: '100vh',
  },
}))

const NotificationContext = createContext(null)

const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const { t } = useTranslation()

  const setNotification = (text: string) => {
    setTitle(text)
    setOpen(true)
  }

  const statuses = [
    t('status.baccaratRichman.sos'),
    t('status.baccaratRichman.sos_recover'),
    t('status.baccaratRichman.maintain'),
    t('status.baccaratRichman.shuffle'),
  ]

  useEffect(() => {
    if (open) {
      if (statuses.indexOf(title) === -1) {
        setTimeout(() => setOpen(false), 1000)
      }
    }
  }, [open, title])

  const api = useMemo(
    () => ({
      setNotification,
    }),
    [],
  )

  return (
    <NotificationContext.Provider value={api}>
      {children}
      <Title title={title} open={open} />
    </NotificationContext.Provider>
  )
}

const Title = ({ title, open }) => {
  const classes = useStyles()

  if (open) {
    return <Box className={classes.root}>{title}</Box>
  }

  return null
}

export const useNotification = () => useContext(NotificationContext)

export default withProfiler(React.memo(NotificationProvider))
