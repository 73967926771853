import React, { useEffect } from 'react'

import { audios } from 'helpers/richman_assets'
import { useProfileSettings } from 'hooks/useProfileSettings'
import { Howl } from 'howler'
import { useSettings } from 'providers/richman/AppProvider'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { Box, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import { useGameStatus } from '../../../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',
    color: 'white',
  },
  time: {
    fontSize: theme.typography.pxToRem(20),
  },
}))

const Time = ({ remainingTime }) => {
  const { settings } = useProfileSettings()
  const classes = useStyles()
  useEffect(() => {
    if (remainingTime === 6 && settings.soundOn) {
      const sound = new Howl({
        src: [audios.beep],
        html5: true,
        volume: 0.1,
      })
      sound.play()
    }
  }, [remainingTime])
  return <Box className={classes.time}>{remainingTime}</Box>
}

const Counting = ({ left, waitingBetSeconds }) => {
  const classes = useStyles()
  const theme = useTheme()

  const { shuffle, gameNo } = useGameStatus()

  return (
    <Box className={classes.root} width={40} height={40}>
      <CountdownCircleTimer
        key={gameNo}
        isPlaying
        duration={waitingBetSeconds}
        initialRemainingTime={left}
        trailColor="black"
        strokeWidth={5}
        colors={[
          [theme.palette.tie.main, 0.33],
          ['#F7B801', 0.33],
          [theme.palette.dealer.main, 0.33],
        ]}
        size={40}
      >
        {Time}
      </CountdownCircleTimer>
    </Box>
  )
}

export default withProfiler(React.memo(Counting))
