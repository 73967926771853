import React, { useCallback } from 'react'

import { moneyFormatWithoutSymbol } from 'helpers/index'
import giftList from 'libs/giftList'

import { Box, makeStyles, Grid } from '@material-ui/core'

import useGifts from './useGifts'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
    justifyContent: 'space-around',
  },
  gift: {
    appearance: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    width: '4em',
  },
  value: {
    fontSize: theme.typography.pxToRem(14),
    color: 'white',
  },
}))

function Gift({ gift }) {
  const styles = useStyles()
  const [, { send }] = useGifts()

  const handleSend = useCallback(() => {
    send(gift)
  }, [send])

  return (
    <button className={styles.gift} onClick={handleSend}>
      <img src={gift.image} className={styles.icon} />
      <Box className={styles.value}>
        {moneyFormatWithoutSymbol(gift.value, false)}
      </Box>
    </button>
  )
}

export default function Gifts() {
  const styles = useStyles()

  return (
    <Grid container spacing={2}>
      {giftList
        .filter((gift) => gift.game === true)
        .map((gift) => (
          <Grid key={gift.name} item xs={4}>
            <Gift gift={gift} />
          </Grid>
        ))}
    </Grid>
  )
}
