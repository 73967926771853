import React, { useCallback, useState } from 'react'

import useCancelBet from 'hooks/useCancelBet'
import { useFullModal } from 'hooks/useFullModal'
import CANCEL_BTN from 'images/richman/cancel_btn.png'
import { useTranslation } from 'react-i18next'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useNotification } from '../NotificationProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    flexGrow: 1,
    flexBasis: 0,
    margin: '0 1.5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

const RepeatButton = ({}) => {
  const classes = useStyles()
  const notification = useNotification()
  const [cancelBet, { loading }] = useCancelBet({ notification })
  const { setModal, toggleModal } = useFullModal()
  const { t } = useTranslation()

  const onCancel = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      cancelBet()
      toggleModal()
    },
    [cancelBet],
  )

  const onOpen = () => {
    setModal({
      modal: <span />,
      title: t('common.confirmCancel'),
      confirmAction: (
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common.confirm')}
        </Button>
      ),
    })
  }

  return (
    <button className={classes.button} onClick={onOpen}>
      <img src={CANCEL_BTN} className={classes.image} />
    </button>
  )
}

export default React.memo(RepeatButton)
