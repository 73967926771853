import React from 'react'

import useBetState from 'hooks/useBetState'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

const useStyles = makeStyles((theme) => ({
  root: {},
  chips: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateZ(100px)',
  },
  betAmountContainer: {},
  betAmount: {
    position: 'relative',
    color: theme.palette.gold.main,
    margin: 0,
    background: 'black',
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.gold.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  betAmountText: {
    fontSize: theme.typography.pxToRem(24),
    textShadow: '0px 1px 0.05px #333333',
  },
  confirmed: {
    width: 18,
    height: 18,
    position: 'absolute',
    top: -10,
    right: -10,
    background: 'black',
    borderRadius: 999,
  },
}))

const Chips = ({ player }) => {
  const classes = useStyles()
  const [state] = useBetState()

  const targetState = state[player]

  const amount = targetState.confirmed + targetState.additional
  const confirmed = targetState.additional === 0

  return (
    <Box className={classes.chips}>
      {amount !== 0 && (
        <Box className={classes.betAmountContainer}>
          <Box className={classes.betAmount}>
            <Typography className={classes.betAmountText}>{amount}</Typography>
            {confirmed && (
              <CheckCircleOutlinedIcon className={classes.confirmed} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(Chips)
