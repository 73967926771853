import React, { createContext, useContext, useEffect, useState } from 'react'

import GET_BACCARAT_ROOMS from 'graphql/application/queries/getBaccaratRooms'
import { useEvent } from 'react-use'

import { useQuery } from '@apollo/client'

import useOnReconnect from './useOnReconnect'
import { usePageVisibility } from './usePageVisibility'

const AllBaccarats = createContext(null)

const Provider = ({ children, noRefetch = false }) => {
  const [nonce, setNonce] = useState(Math.random())

  const { data, loading, refetch } = useQuery(GET_BACCARAT_ROOMS, {
    fetchPolicy: 'network-only',
  })

  useEvent(
    'refetch',
    () => {
      refetch()
      setNonce(Math.random())
    },
    window,
  )

  const rooms =
    data &&
    data.activeBaccaratRooms.filter((room) => room.isBlockchainRoom === false)

  const xianHong = 0

  // 從背景回來時重新載入
  const isVisible = usePageVisibility()
  useEffect(() => {
    if (noRefetch) {
      return
    }
    if (isVisible && rooms) {
      refetch()
    }
  }, [isVisible])

  useOnReconnect(refetch)

  return (
    <AllBaccarats.Provider value={{ rooms, loading, xianHong, nonce }}>
      {children}
    </AllBaccarats.Provider>
  )
}

export const AllBaccaratsProvider = React.memo(Provider)

export default function useAllBaccarats() {
  return useContext(AllBaccarats)
}
