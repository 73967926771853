import React, { useEffect, useRef, useState } from 'react'

import useAuth from 'hooks/useAuth'
import LOGO from 'images/icons/login_logo.png'
import BG from 'images/richman/auth_bg.png'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import TextInput from 'components/common/TextInput'
import MobileVerify from 'components/richman/MobileVerify'

const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      profile {
        id
        jwtToken
      }
      errors {
        attribute
        message
      }
    }
  }
`

const SEND_VERIFICATION_CODE = gql`
  mutation sendVerificationCode($input: SendVerificationCodeInput!) {
    sendVerificationCode(input: $input) {
      ok
      errors {
        attribute
        message
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    background: 'rgba(0, 0, 0, 0.25)',
  },
  hint: {
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center',
    color: 'gold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bg: {
    width: '100%',
    height: '100%',
    background: `url(${BG})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '60px',
    paddingLeft: 22,
    paddingRight: 22,
  },
  link: {
    color: '#2F80ED',
  },
  label: {
    color: 'gold',
  },
  checkbox: {
    color: 'gold',
  },
  checked: {
    color: 'gold',
  },
  trial: {
    backgroundColor: 'gold',
  },
  btn: {
    minHeight: theme.spacing(6),
    fontSize: theme.typography.pxToRem(24),
  },
}))

const SignUp: React.FC = () => {
  const classes = useStyles()
  let history = useHistory()
  const [countryCode, setCountryCode] = useState('+886')
  const { register, handleSubmit, formState, watch, errors } = useForm({
    mode: 'onChange',
  })
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [signUp, { loading: signUpLoading }] = useMutation(SIGN_UP)
  const [send, { loading: sendLoading }] = useMutation(SEND_VERIFICATION_CODE)
  const [{ isAuthenticated, loading }, { login }] = useAuth()
  const formRef = useRef(null)
  const calling = useRef(false)
  const { t } = useTranslation()

  const watchPassword = watch('password')
  const watchUsername = watch('username')
  const watchNickname = watch('nickname')

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  const onSend = async (data) => {
    const result = await send({
      variables: {
        input: { phone: `${countryCode}${data.username}` },
      },
    })
    const { ok, errors } = result.data.sendVerificationCode
    if (!ok && errors.length > 0) {
      errors.map((error) =>
        enqueueSnackbar(error.message, { variant: 'error' }),
      )
    } else {
      onOpen()
    }
  }

  const onSubmit = async () => {
    if (calling.current) return

    calling.current = true
    const result = await signUp({
      variables: {
        input: {
          username: `${countryCode}${watchUsername}`,
          password: watchPassword,
          nickname: watchNickname,
        },
      },
    })

    const { profile, errors } = result.data.signUp
    if (errors.length > 0) {
      errors.forEach((error) => {
        enqueueSnackbar(`${error.attribute} ${error.message}`, {
          variant: 'error',
        })
      })
    } else {
      enqueueSnackbar(t('screens.signUp.signUpSuccess'), { variant: 'success' })
      login(profile.jwtToken)
    }
    calling.current = false
  }

  const matchPassword = (val: string) => {
    if (val !== watchPassword) {
      return t('screens.signUp.passwordNotMatch')
    }
  }

  const onSuccess = () => {
    onClose()
    onSubmit()
  }

  const onChangeCountryCode = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCountryCode(e.target.value)
  }

  return (
    <Box className={classes.bg}>
      <img src={LOGO} style={{ width: 100, height: 118, marginBottom: 16 }} />
      <form
        className={classes.form}
        noValidate
        ref={formRef}
        onSubmit={handleSubmit(onSend)}
      >
        <Box width="100%" display="flex" alignItems="center">
          <FormGroup>
            <FormControl margin="normal">
              <Select
                variant="outlined"
                value={countryCode}
                onChange={onChangeCountryCode}
              >
                <MenuItem value={'+886'}>🇹🇼 +886</MenuItem>
                <MenuItem value={'+86'}>🇨🇳 +86</MenuItem>
                <MenuItem value={'+62'}>🇮🇩 +62</MenuItem>
                <MenuItem value={'+66'}>🇹🇭 +66</MenuItem>
                <MenuItem value={'+84'}>🇻🇳 +84</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <TextInput
            name="username"
            inputRef={register({
              required: true,
              pattern: {
                value: /^09[0-9]{8}$/,
                message: t('screens.signUp.mobilePatternError'),
              },
            })}
            placeholder="09xxxxxxxx"
            autoCorrect="off"
            autoCapitalize="none"
            label={t('screens.signUp.username')}
            type="tel"
            className={classes.input}
            formGroupProps={{ style: { width: '100%' } }}
          />
        </Box>
        <FormGroup>
          <TextInput
            name="nickname"
            inputRef={register({ required: true })}
            autoCorrect="off"
            autoCapitalize="none"
            label={t('screens.signUp.nickname')}
            className={classes.input}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="password"
            type="password"
            inputRef={register({ required: true, minLength: 8 })}
            label={t('screens.signUp.password')}
            className={classes.input}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="confirmPassword"
            label={t('screens.signUp.confirmPassword')}
            type="password"
            inputRef={register({
              required: true,
              minLength: 8,
              validate: matchPassword,
            })}
            errors={errors}
            className={classes.input}
          />
        </FormGroup>
        <Typography className={classes.hint}>
          {t('screens.signUp.hint')}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={loading || !formState.isValid}
          onClick={handleSubmit(onSend)}
          className={classes.btn}
        >
          {(sendLoading || signUpLoading) && <CircularProgress />}
          {!loading && t('screens.signUp.signUp')}
        </Button>
      </form>
      {open && (
        <MobileVerify
          phone={watchUsername}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      )}
    </Box>
  )
}

export default React.memo(SignUp)
