import React, { useMemo } from 'react'

import { moneyFormatWithoutSymbol } from 'helpers/index'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import { useGameData } from '../../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: 'white',
    justifyContent: 'space-between',
    padding: '0 1%',
  },
  player: {
    color: '#0679EC',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  dealer: {
    color: '#E40F0F',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  value: {
    color: 'black',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
  },
  playerAsk: {
    background: '#0679EC',
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  dealerAsk: {
    background: '#E40F0F',
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(16),
  },
  bigEyeDealer: {
    border: `2px solid #E40F0F`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  bigEyePlayer: {
    border: `2px solid #0679EC`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: '#E40F0F',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: '#0679EC',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid #E40F0F`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid #0679EC`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const HintRoad = ({ data }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const isZH = ['zhCN', 'zhTW'].includes(i18n.language)

  if (isZH) {
    return (
      <Box className={classes.root}>
        <Box className={classes.box}>
          <Typography className={classes.dealer}>
            {t('common.dealer')}
          </Typography>
          <Typography className={classes.value}>{data.dealer_count}</Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.player}>
            {t('common.player')}
          </Typography>
          <Typography className={classes.value}>{data.player_count}</Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.tie}>{t('common.tie')}</Typography>
          <Typography className={classes.value}>{data.tie_count}</Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.dealer}>
            {t('common.dealerPair')}
          </Typography>
          <Typography className={classes.value}>
            {data.dealer_pair_count}
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.player}>
            {t('common.playerPair')}
          </Typography>
          <Typography className={classes.value}>
            {data.player_pair_count}
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Typography className={classes.dealer}>
            {t('common.gamesCount')}
          </Typography>
          <Typography className={classes.value}>{data.total_count}</Typography>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.dealer}>
                {t('common.dealer')}
              </Typography>
              <Typography className={classes.value}>
                {data.dealer_count}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.player}>
                {t('common.player')}
              </Typography>
              <Typography className={classes.value}>
                {data.player_count}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.tie}>{t('common.tie')}</Typography>
              <Typography className={classes.value}>
                {data.tie_count}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.dealer}>
                {t('common.dealerPair')}
              </Typography>
              <Typography className={classes.value}>
                {data.dealer_pair_count}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.player}>
                {t('common.playerPair')}
              </Typography>
              <Typography className={classes.value}>
                {data.player_pair_count}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.box}>
              <Typography className={classes.dealer}>
                {t('common.gamesCount')}
              </Typography>
              <Typography className={classes.value}>
                {data.total_count}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withProfiler(React.memo(HintRoad))
