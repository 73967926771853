import React, { useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  red: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  bold: {
    fontWeight: 'bold',
  },
  h2: {
    fontSize: theme.typography.pxToRem(22),
    color: '#208587',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid white',
    borderRight: 'none',
    borderBottom: 'none',
    width: '100%',
  },
  th: {
    background: theme.palette.gold.main,
    color: 'white',
  },
  td: {
    borderTop: '1px solid white',
    borderRight: '1px solid white',
  },
  tr: {
    borderBottom: '1px solid white',
  },
}))

const Rules = ({}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography>
        百家樂【Baccarat】是撲克遊戲，亦是賭場中常見的遊戲之一。百家樂源於義大利，十五世紀時期傳入法國，及至十九世紀時盛傳於英法等地。時至今日，百家樂是世界各地賭場中受歡迎的遊戲之一。於澳門的賭場中，百家樂賭桌的數目更是全球賭場之中最多。百家樂特別之處在於採用先發牌後下注玩法，玩家可於荷官發牌後，選擇欲下注方位進行投注，遊戲過程中更具公平、公正性。
      </Typography>
      <Typography variant="h2" color="initial" className={classes.h2}>
        百家樂遊戲玩法
      </Typography>
      <Typography>
        每局開始荷官會按百家牌例發牌（牌面朝下），「閒家」「莊家」各先派兩張牌，以「閒家」先發，再發2張牌放在補牌區的位置上，如前兩張未分出勝負需再按「牌例」發第三張的牌，最多每方3張牌，誰最接近9點即為勝方，相同點數即和局。如當局只補一張牌，剩餘的牌將作為下一局閒家第一張使用。以此類推，每新開一局，桌面上保持有6張牌在相對應的位置上。極速百家樂特別之處在於下注時間較短（15秒），並加快收發牌節奏增進遊戲快感，於遊戲過程中更刺激，因此廣受人們歡迎。※
        遊戲左下方可切換單人/多人投注模式 , 帶領玩家體驗不同遊戲介面跟投注方位。
      </Typography>
      <Typography variant="h2" color="initial" className={classes.h2}>
        遊戲規則
      </Typography>
      <Typography>
        發牌員會派出【莊家】和【閒家】兩份牌，總得9點或最接近9點的一家勝出。所有從2到9的牌，其數值就是他們顯示的點數：A當作是1點，K、Q、J、10是0點，而加起來等於10的也當作是0點；當任何一家頭兩張牌的點數總和為8或9，就稱為【天生贏家】。任何一家拿到【天生贏家】，牌局就算結束，不再補牌。9最大，0最小；點數相同則為和。派出兩張牌後，如果需要補牌，將依照補牌規則多發一張牌。標準百家樂遊戲方法遊戲大廳進入真人百家樂默認免水百家樂模式，此時可通過標準百家樂按鈕切換至標準百家樂遊戲；在標準百家樂遊戲也可通過免水百家樂按鈕切換回免水百家樂遊戲。未下注時，可以隨時做切換，選擇標準百家樂或免水百家樂進行投注。有下注時，要取消下注或者下注結算完畢才能進行互相切換。
      </Typography>
      <Typography variant="h2" color="initial" className={classes.h2}>
        補牌規則
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tr}>
            <TableCell className={classes.th}>閒家頭兩張牌 合計點數</TableCell>
            <TableCell className={classes.th}>閒家</TableCell>
            <TableCell className={classes.th}>莊家頭兩張牌 合計點數</TableCell>
            <TableCell className={classes.th}>莊家</TableCell>
            <TableCell className={classes.th}>莊家補牌備註</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>0</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>0</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>1</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>1</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>2</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>2</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>3</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>3</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>
              <Typography>
                若閒家第三張牌補到8，不須補牌，若閒家第三張牌補到0.1.2.3.4.5.6.7.9必須補牌。
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>4</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>4</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>
              <Typography>
                若閒家第三張牌補到0.1.8.9，不須補牌，若閒家第三張牌補到2.3.4.5.6.7必須補牌。
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>5</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>5</TableCell>
            <TableCell className={classes.td}>必须補牌</TableCell>
            <TableCell className={classes.td}>
              <Typography>
                若閒家第三張牌補到0.1.2.3.8.9不須補牌，若閒家第三張牌補到4.5.6.7必須補牌。
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>6</TableCell>
            <TableCell className={classes.td}>不须補牌</TableCell>
            <TableCell className={classes.td}>6</TableCell>
            <TableCell className={classes.td}>閒家不補则不须補牌</TableCell>
            <TableCell className={classes.td}>
              <Typography>
                若閒家第三張牌補到6.7必須補牌，若閒家第三張牌補到0.1.2.3.4.5.8.9不須補牌。
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>7</TableCell>
            <TableCell className={classes.td}>不须補牌</TableCell>
            <TableCell className={classes.td}>7</TableCell>
            <TableCell className={classes.td}>不须補牌</TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>8</TableCell>
            <TableCell className={classes.td}>
              例牌，即定输赢（不须補牌）
            </TableCell>
            <TableCell className={classes.td}>8</TableCell>
            <TableCell className={classes.td}>
              例牌，即定输赢（不须補牌）
            </TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
          <TableRow className={classes.tr}>
            <TableCell className={classes.td}>9</TableCell>
            <TableCell className={classes.td}>
              例牌，即定输赢（不须補牌）
            </TableCell>
            <TableCell className={classes.td}>9</TableCell>
            <TableCell className={classes.td}>
              例牌，即定输赢（不须補牌）
            </TableCell>
            <TableCell className={classes.td}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography className={classes.red}>
        ※若莊閒兩家（頭兩張牌）為6點或7點，即定輸贏（如雙方同為6點或7點，即為和局）。※莊家補牌備註：閑家第三張牌，意指閒家第三張牌牌面，並非三張牌總計。
      </Typography>
      <Typography className={classes.red}>
        ※莊家補牌備註：閒家第三张牌，意指閒家第三张牌牌面，并非三张牌總計。
      </Typography>
      <Typography variant="h2" color="initial" className={classes.h2}>
        標準/免水百家樂賠率表
      </Typography>
      <Typography className={clsx(classes.red, classes.bold)}>
        標準/免水百家樂下注選擇：莊贏、閑贏、和局、莊對、閑對、莊龍寶、閑龍寶、莊例牌、閑例牌、任意一對、完美對子、莊單、莊雙、閑單、閑雙、大、小、Super6、超和
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th className={classes.th}>下注</th>
            <th className={classes.th}>賠率</th>
            <th className={classes.th}>抽水</th>
            <th className={classes.th}>說明</th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.tr}>
            <td className={classes.td}>莊贏（標準）</td>
            <td className={classes.td}>1賠0.95</td>
            <td className={classes.td}>5%</td>
            <td className={classes.td}>
              莊家合計點數大於閑家，開和退回當局注金。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊贏（免水）</td>
            <td className={classes.td}>1賠1</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家合計點數大於閑家，開和退回當局注金。莊家以6點勝出1賠0.5。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑贏</td>
            <td className={classes.td}>1賠1</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              閑家合計點數大於莊家，開和退回當局注金。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>和局</td>
            <td className={classes.td}>1賠8</td>
            <td className={classes.td}></td>
            <td className={classes.td}>兩家合計點數相同。</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊對</td>
            <td className={classes.td}>1賠11</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家前兩張牌發出相同的牌則為莊對 例：紅心8，方塊8。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑對</td>
            <td className={classes.td}>1賠11</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              閑家前兩張牌發出相同的牌則為閑對 例：梅花k，黑桃k。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>大</td>
            <td className={classes.td}>1賠0.54</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              閑/莊兩家牌面（包括增牌）的張數5、6張牌為大；即增牌為大。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>小</td>
            <td className={classes.td}>1賠1.5</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              閑/莊兩家牌面（不包括增牌）的張數4張牌為小；即不增牌為小。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊單</td>
            <td className={classes.td}>1賠0.94</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              每局莊家牌面（包括增牌）之點數相加後為單。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊雙</td>
            <td className={classes.td}>1賠0.94</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              每局莊家牌面（包括增牌）之點數相加後為雙。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑單</td>
            <td className={classes.td}>1賠0.96</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              每局閑家牌面（包括增牌）之點數相加後為單。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑雙</td>
            <td className={classes.td}>1賠0.9</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              每局閑家牌面（包括增牌）之點數相加後為雙。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊例牌</td>
            <td className={classes.td}>1賠4</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家前兩張牌總點數以8或9點勝出為莊例牌。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑例牌</td>
            <td className={classes.td}>1賠4</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              閑家前兩張牌總點數以8或9點勝出為閑例牌。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>任意一對</td>
            <td className={classes.td}>1賠5</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家或閑家前兩張牌點數相同則為任意一對。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>完美對子</td>
            <td className={classes.td}>1賠25</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家或閑家前兩張牌點數和花色相同則為完美對子。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>超和</td>
            <td className={classes.td}>1賠225</td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              牌局為和局，並押中對應點數，最高1賠225。
            </td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>閑龍寶</td>
            <td className={classes.td}>1賠30</td>
            <td className={classes.td}></td>
            <td className={classes.td}>莊閑點數差值，最高1賠30。</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>莊龍寶</td>
            <td className={classes.td}>1賠30</td>
            <td className={classes.td}></td>
            <td className={classes.td}>莊閑點數差值，最高1賠30。</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>Super 6</td>
            <td className={classes.td}>
              1賠12
              <br />
              1賠20
            </td>
            <td className={classes.td}></td>
            <td className={classes.td}>
              莊家以2張牌，6點勝出。
              <br />
              莊家以3張牌，6點勝出。
            </td>
          </tr>
        </tbody>
      </table>
      <Typography className={classes.red}>
        投注《閑/莊例牌》例牌和局時，退還本金 ; 非例牌和局時，則為輸掉本金。●
        投注《任意一對或完美對子》若莊家和閑家開牌結果同時為任意一對或完美對子，只會按照該方位的賠率而派彩一次，不會因為同時間莊/閑都開出，而雙倍派彩。●
        大/小、閑單/閑雙、莊單/莊雙、超和，30局以上（不含30局）押注框將反黑，無法進行投注。●
        大/小、閑單/閑雙、莊單/莊雙上下限與方位閑/莊一樣；閑龍寶/莊龍寶上下限與方位和一樣。
      </Typography>
      <Typography variant="h2" className={classes.h2}>
        免水百家樂規則
      </Typography>
      <Typography>
        免水百家樂的玩法及規則與標準百家樂大致相同，特別之處在於莊家免抽水，即莊贏賠率為1：1(莊家以6點大過閑家，下注莊家贏，賠率1賠0.5)。
      </Typography>
      <Typography variant="h2" className={classes.h2}>
        超和遊戲玩法
      </Typography>
      <Typography>
        押注和局開出的點數，不同點數對應不同賠率(押中和局開出的點數予以派彩)
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tr}>
            <th className={classes.th}>超和點數</th>
            <th className={classes.th}>賠率</th>
            <th className={classes.th}>說明</th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.tr}>
            <td className={classes.td}>0</td>
            <td className={classes.td}>1賠150</td>
            <td className={classes.td}>當牌局為和局，並為0點時，倍數為150倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>1</td>
            <td className={classes.td}>1賠215</td>
            <td className={classes.td}>當牌局為和局，並為1點時，倍數為215倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>2</td>
            <td className={classes.td}>1賠225</td>
            <td className={classes.td}>當牌局為和局，並為2點時，倍數為225倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>3</td>
            <td className={classes.td}>1賠200</td>
            <td className={classes.td}>當牌局為和局，並為3點時，倍數為200倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>4</td>
            <td className={classes.td}>1賠120</td>
            <td className={classes.td}>當牌局為和局，並為4點時，倍數為120倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>5</td>
            <td className={classes.td}>1賠110</td>
            <td className={classes.td}>當牌局為和局，並為5點時，倍數為110倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>6</td>
            <td className={classes.td}>1賠45</td>
            <td className={classes.td}>當牌局為和局，並為6點時，倍數為45倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>7</td>
            <td className={classes.td}>1賠45</td>
            <td className={classes.td}>當牌局為和局，並為7點時，倍數為45倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>8</td>
            <td className={classes.td}>1賠80</td>
            <td className={classes.td}>當牌局為和局，並為8點時，倍數為80倍</td>
          </tr>
          <tr className={classes.tr}>
            <td className={classes.td}>9</td>
            <td className={classes.td}>1賠80</td>
            <td className={classes.td}>當牌局為和局，並為9點時，倍數為80倍</td>
          </tr>
        </tbody>
      </table>
      <Typography variant="h2" className={classes.h2}>
        閑龍寶/莊龍寶規則
      </Typography>
      <Typography>
        在基礎百家樂遊戲發牌的規則下，如玩家於莊龍寶或閑龍寶進行投注，會根據不同的勝出情況再額外進行派彩，且亦可投注莊家或閑家。龍寶會根據所投注一方勝出對方點數的情況，賠率會有所不同，最高彩金為30倍，且所贏得彩金不抽水。若投注其中一方獲勝，並且符合下列前兩種之一即可獲得派彩；
      </Typography>
      <Typography>
        <ul>
          <li>所投注之勝方以非例牌勝出，且超過對方四點以上。</li>
          <li>所投注之勝方兩張牌相加為八點或九點(例牌)，可獲得一倍彩金。</li>
          <li>
            所投注落敗或平手或勝差於三點以下時，即喪失所押注彩金，但若雙方皆為八點或九點(例牌)且雙方平手時，則可取回所投注彩金。
          </li>
        </ul>
      </Typography>
      <Typography className={classes.red}>非例牌:</Typography>
      <Typography>
        <ul>
          <li>頭兩張牌點數總和7點(含7點)以下皆屬非例牌。</li>
          <li>所有三張牌的情況亦屬非例牌。</li>
        </ul>
      </Typography>
      <Typography className={classes.red}>例牌:</Typography>
      <Typography>
        <ul>
          <li>頭兩張牌的點數總和為8或9點。</li>
        </ul>
      </Typography>
      <Typography variant="h2" className={classes.h2}>
        閑龍寶/莊龍寶賠率表閑龍寶/莊龍寶賠率表
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>勝出情況</th>
            <th className={classes.th}>賠率</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏9點</td>
            <td className={classes.td}>1賠30</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏8點</td>
            <td className={classes.td}>1賠10</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏7點</td>
            <td className={classes.td}>1賠5</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏6點</td>
            <td className={classes.td}>1賠3</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏5點</td>
            <td className={classes.td}>1賠2</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【非例牌】贏4點</td>
            <td className={classes.td}>1賠1</td>
          </tr>
          <tr>
            <td className={classes.td}>勝方以【例牌】贏勝出</td>
            <td className={classes.td}>1賠1</td>
          </tr>
          <tr>
            <td className={classes.td}>【例牌】和局</td>
            <td className={classes.td}>退回下注</td>
          </tr>
        </tbody>
      </table>
      <Typography variant="h2" className={classes.h2}>
        遊戲規範
      </Typography>
      <Typography>1.本平台保留一切有爭議事項的修正及最後的決策。</Typography>
      <Typography>
        2.本平台保留隨時修訂、撤銷或中止任何投注的權力而
        無須作事先通知,亦無須作任何解釋。
      </Typography>
      <Typography>
        3.惡意使用外掛程序以不正當方式取得之點數,所有點數 將不予以計算。
      </Typography>
      <Typography>
        4.若玩家於遊戲中有任何套利玩法、無風險投注等違規行為，經審查被判定屬實或有嫌疑者，平台有權退回玩家儲值金且不再提供平台內任何相關服務，並註銷該玩家帳戶持有權益。
      </Typography>
    </div>
  )
}

export default React.memo(Rules)
