import React, { useMemo } from 'react'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useGameContent, useGameStatus } from '../../GameProvider'
import FirstTwoCards from './FirstTwoCards'
import ThirdCard from './ThirdCard'
import TieWin from './TieWin'
import Win from './Win'

const useStyles = makeStyles<Theme>((theme) => ({
  dealerWin: {
    animation: '$resultRed .75s infinite alternate',
    '&::before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(-45deg,hsla(0,0%,100%,0) 20%,hsla(0,0%,100%,.1) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,.1) 65%,hsla(0,0%,100%,0) 80%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '250% 250%',
      backgroundPosition: 0,
      animation: '$resultRedShine 1.5s infinite alternate',
    },
  },
  playerWin: {
    animation: '$resultBlue .75s infinite alternate',
    '&::before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(45deg,hsla(0,0%,100%,0) 20%,hsla(0,0%,100%,.1) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,.1) 65%,hsla(0,0%,100%,0) 80%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '250% 250%',
      backgroundPosition: 0,
      animation: '$resultBlueShine 1.5s infinite alternate',
    },
  },
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    zIndex: 2,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  player: {
    width: '50%',
    height: '100%',
    background:
      'linear-gradient(136.01deg, rgba(103, 146, 255, 0.55) 0%, rgba(0, 22, 140, 0.55) 96.19%)',
    borderWidth: '3px 3px 0px 0px',
    borderStyle: 'solid',
    borderColor: '#49A0FF',
    position: 'relative',
  },
  playerInner: {
    background:
      'linear-gradient(136.01deg, rgba(103, 146, 255, 0.55) 0%, rgba(0, 22, 140, 0.55) 96.19%)',
    boxShadow: '3px 5px 4px rgba(0, 0, 0, 0.46)',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  dealer: {
    width: '50%',
    height: '100%',
    background:
      'linear-gradient(136.01deg, rgba(255, 103, 103, 0.55) 0%, rgba(140, 0, 0, 0.55) 83.28%)',
    borderWidth: '3px 0px 0px 3px',
    borderStyle: 'solid',
    borderColor: '#f85455',
  },
  dealerInner: {
    background:
      'linear-gradient(136.01deg, rgba(255, 103, 103, 0.55) 0%, rgba(140, 0, 0, 0.55) 83.28%)',
    boxShadow: '3px 5px 4px rgba(0, 0, 0, 0.46)',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  result: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 1% 1% 1%',
    position: 'absolute',
    bottom: 0,
  },
  playerTitle: {
    height: '100%',
    color: theme.palette.player.main,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  dealerTitle: {
    height: '100%',
    color: theme.palette.dealer.main,
    fontWeight: 'bold',
    lineHeight: 1,
  },
  points: {
    color: theme.palette.common.white,
    lineHeight: 1,
  },
  cards: {
    width: '100%',
    // maxHeight: '37%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tieWin: {
    animation: `$resultTie .75s infinite alternate`,
    '&::after': {
      content: '" "',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(45deg,hsla(0,0%,100%,0) 20%,hsla(0,0%,100%,.1) 35%,hsla(0,0%,100%,.3) 50%,hsla(0,0%,100%,.1) 65%,hsla(0,0%,100%,0) 80%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '250% 250%',
      backgroundPosition: 0,
      animation: `$resultBlueShine 1.5s infinite alternate`,
    },
  },
  '@keyframes resultTie': {
    '0%': {
      boxShadow: '0 0 10px 5px #39946a',
    },
    to: {
      boxShadow: '0 0 30px 15px #39946a',
    },
  },

  '@keyframes resultBlue': {
    '0%': {
      boxShadow: '0 0 10px 5px #1F93FF',
    },
    '100%': {
      boxShadow: '0 0 30px 15px #1F93FF',
    },
  },

  '@keyframes resultRed': {
    '0%': {
      boxShadow: '0 0 10px 5px #B20000',
    },

    '100%': {
      boxShadow: '0 0 30px 15px #B20000',
    },
  },
  '@keyframes resultBlueShine': {
    '0%': {
      backgroundPosition: '-125% 50%',
    },
    '100%': {
      backgroundPosition: '125% 50%',
    },
  },
  '@keyframes resultRedShine': {
    '0%': {
      backgroundPosition: '125% 50%',
    },
    '100%': {
      backgroundPosition: '-125% 50%',
    },
  },
}))

const Opening = ({ pointsClassName }) => {
  const { status, shuffle } = useGameStatus()
  const { win, points } = useGameContent()
  const [ref, { height, width }] = useMeasure()
  const { t } = useTranslation()

  const classes = useStyles()

  if (status === 'START_BET') return null
  if (shuffle) return null

  return (
    <Box {...{ ref }} className={classes.root}>
      <Box className={clsx(classes.container, win.tie ? classes.tieWin : null)}>
        <Box
          className={clsx(
            classes.player,
            win.player ? classes.playerWin : null,
          )}
        >
          <Box className={classes.playerInner}>
            <div>
              <FirstTwoCards target="player" />
              <ThirdCard target="player" />
              <Win target="player" />
            </div>
            <Box className={classes.result}>
              <div className={clsx(classes.playerTitle, pointsClassName)}>
                {t('common.simplePlayer')}
              </div>
              <div className={clsx(classes.points, pointsClassName)}>
                {points.player || 0}
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.dealer,
            win.dealer ? classes.dealerWin : null,
          )}
        >
          <Box className={classes.dealerInner}>
            <div>
              <FirstTwoCards target="dealer" />
              <ThirdCard target="dealer" />
              <Win target="dealer" />
            </div>
            <Box className={classes.result}>
              <div className={clsx(classes.points, pointsClassName)}>
                {points.dealer || 0}
              </div>
              <div className={clsx(classes.dealerTitle, pointsClassName)}>
                {t('common.simpleDealer')}
              </div>
            </Box>
          </Box>
        </Box>
        <TieWin />
      </Box>
    </Box>
  )
}

export default React.memo(Opening)
