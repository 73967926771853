import React from 'react'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Stream from '../BaccaratRooms/GameRoom/Stream'
import Actions from '../BaccaratRooms/GameRoom/Stream/Actions'
import SelectedPlayer from '../BaccaratRooms/GameRoom/Stream/SelectedPlayer'
import SocketStatus from '../BaccaratRooms/GameRoom/Stream/SocketStatus'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
}))

function CamPlayer() {
  const styles = useStyles()

  return (
    <Box className={styles.root}>
      <Stream>
        <SelectedPlayer />
        <SocketStatus />
        <Actions />
      </Stream>
    </Box>
  )
}

export default CamPlayer
