import React, { useEffect } from 'react'

import { audios } from 'helpers/richman_assets'
import useLeftBetTime from 'hooks/useLeftBetTime'
import { useProfileSettings } from 'hooks/useProfileSettings'
import useStreamer from 'hooks/useStreamer'
import { Howl } from 'howler'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { Box, useTheme } from '@material-ui/core'

import { useGameStatus } from '../BaccaratRooms/GameProvider'

const Time = ({ remainingTime }) => {
  const { settings } = useProfileSettings()

  useEffect(() => {
    if (remainingTime === 6 && settings.soundOn) {
      const sound = new Howl({
        src: [audios.beep],
        html5: true,
        volume: settings.volume,
      })
      sound.play()
    }
  }, [remainingTime])

  return <Box>{remainingTime}</Box>
}

export default function Counting() {
  const [
    {
      boundRoom,
      boundRoom: { waitingBetSeconds },
    },
  ] = useStreamer()
  const left = useLeftBetTime(boundRoom)
  const theme = useTheme()
  const { status, gameNo } = useGameStatus()

  if (status !== 'START_BET') {
    return null
  }

  return (
    <CountdownCircleTimer
      key={gameNo}
      isPlaying
      duration={waitingBetSeconds}
      initialRemainingTime={left}
      strokeWidth={5}
      colors={[
        [theme.palette.tie.main, 0.33],
        ['#F7B801', 0.33],
        [theme.palette.dealer.main, 0.33],
      ]}
      size={50}
    >
      {Time}
    </CountdownCircleTimer>
  )
}
