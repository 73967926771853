import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BetRecords from '../BetRecords'
import CreditChangeRecords from '../CreditChangeRecords'
import GiftRecords from '../GiftRecords'

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
  },
  tab: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    borderRadius: '5px 5px 0px 0px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    color: '#ffffff',
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
  },
  selectedTab: {
    background:
      'linear-gradient(164.31deg, #AB5F00 -177.65%, #FEC47F -109.39%, #FFECE0 -65.66%, #FFF5E1 -47.44%, #FFE6BC -12.52%, #CE7C00 69.84%, #4A2900 118.75%)',
    borderRadius: '5px 5px 0px 0px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    color: '#353535',
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
  },
  divider: {
    background:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%)',
    height: 2,
    transform: 'matrix(1, 0, 0, -1, 0, 0)',
    marginBottom: theme.spacing(2),
  },
}))

const Records = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)

  return (
    <Box className="flex-grow flex flex-col">
      <Box className={classes.tabs}>
        <Box
          className={selected == 0 ? classes.selectedTab : classes.tab}
          onClick={() => setSelected(0)}
        >
          {t('screens.profile.creditRecords')}
        </Box>
        <Box
          className={selected == 1 ? classes.selectedTab : classes.tab}
          onClick={() => setSelected(1)}
        >
          {t('screens.profile.betRecords')}
        </Box>
        <Box
          className={selected == 2 ? classes.selectedTab : classes.tab}
          onClick={() => setSelected(2)}
        >
          {t('screens.profile.giftRecords')}
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box className="flex-grow relative">
        <div className="absolute inset-0 overflow-auto">
          {selected == 0 ? <CreditChangeRecords /> : null}
          {selected == 1 ? <BetRecords /> : null}
          {selected == 2 ? <GiftRecords /> : null}
        </div>
      </Box>
    </Box>
  )
}

export default React.memo(Records)
