export function getRTCHost() {
  const metaTag = document.querySelector('meta[name="rtc-host"]')
  if (metaTag) {
    return (metaTag as HTMLMetaElement).content
  } else {
    return ''
  }
}

export function getRTCTWHost() {
  const metaTag = document.querySelector('meta[name="rtc-tw-host"]')
  if (metaTag) {
    return (metaTag as HTMLMetaElement).content
  } else {
    return ''
  }
}
