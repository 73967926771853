import { useMemo } from 'react'

import { useGameStatus } from 'screens/richman/BaccaratRooms/GameProvider'

import useLatency from './useLatency'

export default function useLeftBetTime(room) {
  const gameStatus = useGameStatus()
  const latency = useLatency()

  return useMemo(() => {
    if (!gameStatus.endAt) return

    const endAt = new Date(gameStatus.endAt)
    const left = (endAt.getTime() - Date.now()) / 1000 + room.latency - latency
    if (left > 0) {
      return left
    }
  }, [gameStatus.endAt, room.latency, latency])
}
