import React from 'react'

import Modal from 'components/richman/Modal'
import useCurrentRoom from 'hooks/useCurrentRoom'
import GameBG from 'images/richman/game_bg.jpeg'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ChatroomProvider from '../ChatroomProvider'
import BetPanel from './BetPanel'
import Buttons from './Buttons'
import ChatAndGifts from './ChatAndGifts'
import ChipSelector from './ChipSelector'
import Header from './Header'
import Notification from './Notification'
import NotificationProvider from './NotificationProvider'
import Opening from './Opening'
import Road from './Road'
import Stream from './Stream'
import Panel from './Stream/Panel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    width: '100%',
    top: 0,
    bottom: 0,
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexGrow: 1,
  },
}))

const GameRoom = () => {
  const { room, close } = useCurrentRoom()

  const classes = useStyles()

  return (
    <NotificationProvider>
      <ChatroomProvider>
        <div className={classes.root}>
          <Header />
          <Stream>
            <Panel />
          </Stream>
          <Box className={classes.panel}>
            <BetPanel />
            <Buttons />
            <Opening pointsClassName="text-[300%]" />
          </Box>
          <ChipSelector />
          <Road />
          {!room.disableChatAndGift && <ChatAndGifts />}
          <Notification />
        </div>
      </ChatroomProvider>
    </NotificationProvider>
  )
}

export default React.memo(GameRoom)
