import React, { useState } from 'react'
import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableLimits from './TableLimits'
import useWallet from 'hooks/useWallet'
import numeral from 'numeral'

const useStyles = makeStyles((theme) => ({
  root: {},
  mainText: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
  },
}))

const TableLimitsAndBalance = ({}) => {
  const classes = useStyles()
  const [{ balance }] = useWallet()

  return (
    <Box display="flex" alignItems="center">
      <TableLimits />
      <Typography className={classes.mainText}>
        {numeral(balance).format('$0,0.00')}
      </Typography>
    </Box>
  )
}

export default React.memo(TableLimitsAndBalance)
