import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom'

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import LOBBY from 'images/icons/footer/lobby.png'
import LOBBY_ACTIVE from 'images/icons/footer/lobby_active.png'
import RANKING from 'images/icons/footer/ranking.png'
import RANKING_ACTIVE from 'images/icons/footer/ranking_active.png'
import PROFILE from 'images/icons/footer/profile.png'
import PROFILE_ACTIVE from 'images/icons/footer/profile_active.png'
import GIRLS from 'images/icons/footer/girls.png'
import GIRLS_ACTIVE from 'images/icons/footer/girls_active.png'
import MULTI_TABLE from 'images/icons/footer/multi_table.png'
import MULTI_TABLE_ACTIVE from 'images/icons/footer/multi_table_active.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    boxShadow: '0px -3px 9px rgba(0, 0, 0, 0.47)',
    paddingBottom: 'env(safe-area-inset-bottom)',
    height: 'auto',
    minHeight: '56px',
  },
  action: {
    color: '#fff',
    fontWeight: 400,
  },
  selected: {
    color: theme.palette.gold.main,
  },
  icon: {
    width: 48,
    height: 48,
  },
}))

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  let location = useLocation()
  let history = useHistory()
  const [value, setValue] = useState(location.pathname.replace('/', ''))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const goProfile = () => {
    handleClose()
    history.push('/profile')
  }
  const goGirls = () => {
    handleClose()
    history.push('/girls')
  }

  const navigation = [
    {
      label: t('components.footer.rooms'),
      value: '',
      to: '/',
      icon: LOBBY,
      active: LOBBY_ACTIVE,
    },
    {
      label: t('components.footer.multiTable'),
      value: 'list',
      to: '/list',
      icon: MULTI_TABLE,
      active: MULTI_TABLE_ACTIVE,
    },
    {
      label: t('components.footer.rank'),
      value: 'ranking',
      to: '/ranking',
      icon: RANKING,
      active: RANKING_ACTIVE,
    },
    {
      label: t('components.footer.girls'),
      value: 'girls',
      to: '/girls',
      icon: GIRLS,
      active: GIRLS_ACTIVE,
    },
    {
      label: t('components.footer.profile'),
      value: 'profile',
      to: '/profile',
      icon: PROFILE,
      active: PROFILE_ACTIVE,
    },
  ]

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      {navigation.map((item) => (
        <BottomNavigationAction
          className={classes.action}
          key={item.value}
          value={item.value}
          label={item.label}
          component={Link}
          to={item.to}
          icon={
            value === item.value ? (
              <img
                src={item.active}
                alt={item.label}
                className={classes.icon}
              />
            ) : (
              <img src={item.icon} alt={item.label} className={classes.icon} />
            )
          }
        />
      ))}
    </BottomNavigation>
  )
}

export default withProfiler(React.memo(Footer))
