import React, { useCallback, useState } from 'react'

import useStreamer from 'hooks/useStreamer'
import { useHistory } from 'react-router-dom'

import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'
import { useTranslation } from 'react-i18next'

function OfflineModal() {
  const history = useHistory()
  const { t } = useTranslation()

  const leave = useCallback(() => {
    history.push('/streamers')
  }, [history])

  return (
    <Dialog open onClose={leave}>
      <DialogContent>{t('screens.streamer.streamerIsOffline')}</DialogContent>
      <DialogActions>
        <Button onClick={leave} variant="contained" color="primary">
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function StreamerChannel({ children }) {
  const [streamer, { update }] = useStreamer()

  return (
    <ActionCableConsumer
      onReceived={update}
      channel={{ channel: 'StreamerChannel', id: streamer.id }}
    >
      {children}
      {!streamer.online && <OfflineModal />}
    </ActionCableConsumer>
  )
}
