import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import UPDATE_SETTINGS from 'graphql/application/mutations/updateSettings'

import { useMutation } from '@apollo/client'

import { useProfileSettings } from './useProfileSettings'

const SelectedChipContext = createContext<[number, any]>(null)

export function SelectedChipProvider({ children }) {
  const { settings } = useProfileSettings()
  const [selectedChip, setSelectedChip] = useState(settings.selectedChip)
  const [update] = useMutation(UPDATE_SETTINGS)

  const select = useCallback(
    (chip: number) => {
      setSelectedChip(chip)
      update({
        variables: {
          input: {
            ...settings,
            soundVolume: settings.volume,
            tableLimitId: null,
            selectedChip: chip,
          },
        },
      })
    },
    [setSelectedChip, update, settings],
  )

  useEffect(() => {
    if (selectedChip == null) {
      setSelectedChip(settings.selectedChip)
    }
  }, [selectedChip, settings.selectedChip])

  return (
    <SelectedChipContext.Provider value={[selectedChip, select]}>
      {children}
    </SelectedChipContext.Provider>
  )
}

export default function useSelectedChip() {
  return useContext(SelectedChipContext)
}
