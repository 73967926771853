import React, { useCallback } from 'react'

import useConfirmBet from 'hooks/useConfirmBet'
import CONFIRM_BET from 'images/richman/confirm_bet.png'
import { useNotification } from 'screens/richman/BaccaratRooms/GameRoom/NotificationProvider'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    opacity: '100%',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
  },
}))

const ConfirmButton = ({ iconWidth }) => {
  const styles = useStyles()
  const notification = useNotification()
  const [confirmBet, { loading }] = useConfirmBet({ notification })

  const onConfirm = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      confirmBet()
    },
    [confirmBet],
  )

  return (
    <button
      className={styles.button}
      style={{ width: iconWidth }}
      onClick={onConfirm}
      disabled={loading}
    >
      <img className={styles.image} src={CONFIRM_BET} />
    </button>
  )
}

export default React.memo(ConfirmButton)
