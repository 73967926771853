import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useGameData } from '../BaccaratRooms/GameProvider'
import SimpleHintRoad from '../BaccaratRooms/GameRoom/Road/SimpleHintRoad'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
  },
  imageContainer: {
    flexGrow: 1,
  },
  image: {
    width: '100%',
    border: 'gray solid 1px',
  },
  askContainer: {
    flexShrink: 0,
  },
}))

function RoadWithHint() {
  const styles = useStyles()
  const { roads, roadsImage } = useGameData()

  const hintFontSize = window.innerWidth / 25

  return (
    <Box className={styles.root}>
      <Box className={styles.imageContainer}>
        <img
          src={`data:image/png;base64,${roadsImage}`}
          className={styles.image}
        />
      </Box>
      <Box className={styles.askContainer} style={{ fontSize: hintFontSize }}>
        <SimpleHintRoad data={roads} />
      </Box>
    </Box>
  )
}

export default RoadWithHint
