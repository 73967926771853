import axios from 'axios'

export default async function verifyToken(token) {
  try {
    const response = await axios.post('/verify', { token })
    return response.data.ok
  } catch (error) {
    const {
      response: {
        data: { reason },
      },
    } = error

    if (reason === 'new_device') {
      throw new Error('您的帳號在其他地方登入過，請重新登入')
    } else {
      throw new Error('你已經登入超過 24 小時，為了帳號安全請重新登入')
    }
  }
}
