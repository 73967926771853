import React, { useEffect, useMemo } from 'react'

import delay from 'helpers/delay'
import usePreloadImages from 'hooks/usePreloadImages'
import { sample } from 'lodash'
import { useSiteSettings } from 'providers/richman/SiteSettingsProvider'

import { Box, Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(${require('images/richman/mobile_bg.png')})`,
    backgroundSize: 'cover',
    zIndex: 1302,
    [theme.breakpoints.up('sm')]: {
      background: `url(${require('images/richman/desktop_bg.png')})`,
      backgroundSize: 'cover',
    },
  },
  root: {
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    paddingTop: '0 !important',
  },
  ad: {
    width: '100%',
    height: 'auto',
  },
  progress: {
    backgroundColor: 'rgba(255,215,0, 0.4)',
    height: 8,
  },
  barColorPrimary: {
    backgroundColor: '#be9148',
  },
  text: {
    color: '#be9148',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
  },
  ok: {
    color: theme.palette.success.main,
  },
  fail: {
    color: theme.palette.error.main,
  },
}))

const disabled = !!document.querySelector('meta[name="disable-loading-delay"]')

const LoadingScreen = ({ onClose }) => {
  const classes = useStyles()
  const { loadingAds } = useSiteSettings()
  const images = usePreloadImages(() => {})

  useEffect(() => {
    const promise = disabled
      ? images.load()
      : Promise.all([images.load(), delay(2000)])
    promise.then(() => {
      onClose()
    })
  }, [])

  const loadingAd = useMemo(() => {
    return sample(loadingAds)
  }, [])

  return (
    <Dialog
      open
      fullScreen
      PaperProps={{ classes: { root: classes.background } }}
    >
      <DialogContent className={classes.root}>
        <img src={loadingAd} alt="Loading" className={classes.ad} />
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          mt={2}
          pl={1}
          pr={1}
        />
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(LoadingScreen)
