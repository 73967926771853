import React, { createContext, useCallback, useContext } from 'react'

import gql from 'graphql-tag'

import { useQuery } from '@apollo/client'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const QUERY = gql`
  query currentShoe($id: ID!) {
    currentShoe(baccaratRoomId: $id) {
      height
      lastBlockHash
      blockHash
      cards {
        height
        lastBlockHash
        blockHash
        card
      }
    }
  }
`

const BlockchainInfoContext = createContext(null)

export function Provider({ room, children }) {
  const { data, updateQuery } = useQuery(QUERY, { variables: { id: room.id } })

  const updateData = useCallback(
    (changes) => {
      updateQuery((data) => {
        switch (changes.action) {
          case 'reveal_card':
            return {
              currentShoe: {
                ...data.currentShoe,
                cards: data.currentShoe.cards.map((originalCard) =>
                  changes.card.height === originalCard.height
                    ? changes.card
                    : originalCard,
                ),
              },
            }
          case 'next_shoe':
            return {
              currentShoe: changes.shoe,
            }
        }
        return data
      })
    },
    [updateQuery],
  )

  return (
    <ActionCableConsumer
      onReceived={updateData}
      channel={{ channel: 'BlockchainInfoChannel', id: room.id }}
    >
      <BlockchainInfoContext.Provider value={data}>
        {children}
      </BlockchainInfoContext.Provider>
    </ActionCableConsumer>
  )
}

export default function useBlockchainInfo() {
  return useContext(BlockchainInfoContext)
}
