import React, { useEffect, useState, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useGameStatus } from './GameProvider'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

const useStyles = makeStyles((theme) => ({
  root: {},
  time: {
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 'bold',
    textStroke: '1px #fff',
  },
  status: {
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 'bold',
    textStroke: '1px #fff',
    color: '#0e426e',
    zIndex: 1,
  },
}))

const Hint = ({}) => {
  const classes = useStyles()
  const [status, setStatus] = useState(null)
  const { status: gameStatus, shuffle } = useGameStatus()
  const { t, i18n } = useTranslation()
  const [ref, { width }] = useMeasure()

  useEffect(() => {
    if (gameStatus === 'SOS') {
      setStatus(t(`status.baccarat.simpleSos`))
    } else if (gameStatus === 'MAINTAIN') {
      setStatus(t(`status.baccarat.${gameStatus}`))
    } else if (shuffle) {
      setStatus(t('status.baccarat.shuffle'))
    } else {
      setStatus(t(`status.baccarat.${gameStatus}`))
    }
  }, [gameStatus, shuffle, i18n.language])

  const fontSize = useMemo(() => {
    if (status?.length < 5) {
      return '30px'
    } else if (status?.length > 5) {
      return '20px'
    }
  }, [width])

  if (status) {
    return (
      <Box className={classes.status} style={{ fontSize }} {...{ ref }}>
        {status}
      </Box>
    )
  }

  return null
}

export default withProfiler(React.memo(Hint))
