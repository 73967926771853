import { gql } from '@apollo/client'

export default gql`
  query creditChangeRecords($types: [String!]!) {
    creditChangeRecords(types: $types) {
      id
      createdAt
      prevCreditLeft
      newCreditLeft
    }
  }
`
