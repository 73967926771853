import React, { createContext, useCallback, useContext, useState } from 'react'

import profileFragments from 'graphql/application/fragments/profileFragments'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import useStreamer from 'hooks/useStreamer'
import useWallet from 'hooks/useWallet'

import { gql, useMutation } from '@apollo/client'

import { useNotification } from '../BaccaratRooms/GameRoom/NotificationProvider'
import { useTranslation } from 'react-i18next'

const GiftsContext = createContext(null)

const SEND_STREAMER_GIFT = gql`
  mutation sendStreamerGift($streamerId: ID!, $gift: String!) {
    sendStreamerGift(input: { streamerId: $streamerId, gift: $gift }) {
      profile {
        id
        ...profileFragments
      }
      errors {
        attribute
        message
      }
    }
  }
  ${profileFragments}
`

export function GiftsProvider({ children }) {
  const [streamer] = useStreamer()
  const [opening, setOpening] = useState(false)
  const { setNotification } = useNotification()
  const { t } = useTranslation()

  const open = useCallback(() => {
    setOpening(true)
  }, [setOpening])

  const close = useCallback(() => {
    setOpening(false)
  }, [setOpening])

  const toggle = useCallback(() => {
    setOpening((opening) => !opening)
  }, [setOpening])

  const [sendGift] = useMutation(SEND_STREAMER_GIFT)
  const [{ balance }] = useWallet()

  const send = useCallback(
    async (gift) => {
      if (balance >= gift.value) {
        const result = await sendGift({
          variables: {
            streamerId: streamer.id,
            gift: gift.name,
          },
        })

        const { errors } = result.data.sendStreamerGift
        if (errors.length > 0) {
          errors.map((error) => setNotification(error.message))
        } else {
          setNotification(t('common.giftSent'))
        }
        close()
      } else {
        setNotification(
          t('common.insufficientBalance', {
            balance: moneyFormatWithoutSymbol(balance),
          }),
        )
      }
    },
    [streamer, balance, sendGift, setNotification, close],
  )

  const state = { opening }

  const methods = { open, close, toggle, send }

  return (
    <GiftsContext.Provider value={[state, methods]}>
      {children}
    </GiftsContext.Provider>
  )
}

export default function useGifts() {
  return useContext(GiftsContext)
}
