import React, { createContext, useContext, useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { makeStyles } from '@material-ui/core/styles'

import { withProfiler } from '@sentry/react'

const SiteConfigContext = createContext(null)

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: '0 auto',
  },
}))

const SiteConfig = ({ children }) => {
  const classes = useStyles()
  const [config, setConfig] = useState({
    site: '',
    adminTitle: '',
    logo: null,
    host: '',
    rtmp: '',
    isRichman: false,
  })

  useEffect(() => {
    const siteTag = document
      .querySelector('meta[name="site"]')
      ?.getAttribute('content')
    setConfig({
      site: 'richman',
      adminTitle: 'SS鉅星管理後台',
      logo: require('images/richman/logo.png'),
      host: 'r1chman.tech',
      rtmp: 'rtmp://rtmp.r1chman.tech',
      isRichman: true,
    })
  }, [])

  return (
    <div className={classes.root}>
      <SiteConfigContext.Provider value={{ ...config }}>
        {children}
      </SiteConfigContext.Provider>
    </div>
  )
}

export const useSiteConfig = () => useContext(SiteConfigContext)

export default withProfiler(React.memo(SiteConfig))
