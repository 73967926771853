import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import { useQuery, gql } from '@apollo/client'
import CountUp from 'react-countup'

const POOL = gql`
  query pool {
    pool
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background:
      '#090017 linear-gradient(136deg, rgba(217, 51, 137, 0.4) 0%, rgba(59, 195, 243, 0.4) 50%, rgba(15, 255, 135, 0.4) 100%)',
    backgroundAttachment: 'fixed',
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
    background: 'rgba(0,0,0,.4)',
    borderRadius: 20,
    boxShadow: '2px 2px 8px rgb(255 255 255 / 10%)',
    border: '1px solid rgba(255,255,255,.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '80%',
    height: '80%',
  },
  title: {
    color: 'white',
    fontSize: theme.typography.pxToRem(100),
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  money: {
    background: 'linear-gradient(90deg, #50d9f6, #bd70d5)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: theme.typography.pxToRem(120),
    fontWeight: 'bold',
  },
}))

const Pool = ({}) => {
  const classes = useStyles()
  const { data } = useQuery(POOL, {
    pollInterval: 10000,
  })
  const [oldAmount, setOldAmount] = useState(0)
  const [newAmount, setNewAmount] = useState(0)

  useEffect(() => {
    if (data) {
      setNewAmount(data.pool)
    }
  }, [data])

  const onEnd = () => {
    setOldAmount(data?.pool)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          累積總彩池
        </Typography>
        <Typography variant="h1" className={classes.money}>
          {data && (
            <CountUp
              onEnd={onEnd}
              start={oldAmount}
              end={newAmount}
              separator=","
              duration={3}
            />
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(Pool)
