import { useCallback, useMemo, useState } from 'react'

export default function useProgress(
  max: number,
): [number, { increment: () => void }] {
  const [current, setCurrent] = useState(0)

  const increment = useCallback(() => {
    setCurrent((n) => Math.min(n + 1, max))
  }, [setCurrent, max])

  const progress = useMemo(() => current / max, [current])

  return [progress, { increment }]
}
