import negotiateConnectionWithClientOffer from './negotiateConnectionWithClientOffer'

export default class WHEPClient {
  private peerConnection: RTCPeerConnection
  private stream: MediaStream
  private isConnectionInitialized: boolean

  constructor(
    private endpoint: string,
    private videoElement?: HTMLVideoElement,
  ) {
    this.stream = new MediaStream()
    this.isConnectionInitialized = false

    this.peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.cloudflare.com:3478',
        },
      ],
      bundlePolicy: 'max-bundle',
    })

    this.peerConnection.addTransceiver('video', {
      direction: 'recvonly',
    })
    this.peerConnection.addTransceiver('audio', {
      direction: 'recvonly',
    })

    this.peerConnection.ontrack = (event) => {
      const track = event.track
      const currentTracks = this.stream.getTracks()
      const streamAlreadyHasVideoTrack = currentTracks.some(
        (track) => track.kind === 'video',
      )
      const streamAlreadyHasAudioTrack = currentTracks.some(
        (track) => track.kind === 'audio',
      )
      switch (track.kind) {
        case 'video':
          if (streamAlreadyHasVideoTrack) {
            break
          }
          this.stream.addTrack(track)
          break
        case 'audio':
          if (streamAlreadyHasAudioTrack) {
            break
          }
          this.stream.addTrack(track)
          break
        default:
          console.log('got unknown track ' + track)
      }
    }

    this.peerConnection.addEventListener('connectionstatechange', (ev) => {
      if (this.peerConnection.connectionState !== 'connected') {
        return
      }
      if (this.videoElement && !this.videoElement.srcObject) {
        this.videoElement.srcObject = this.stream
      }
    })

    this.peerConnection.addEventListener('negotiationneeded', (ev) => {
      if (!this.isConnectionInitialized) {
        negotiateConnectionWithClientOffer(this.peerConnection, this.endpoint)
        console.log('negotiationneeded end')
        this.isConnectionInitialized = true
      }
    })
  }

  initConnection() {
    return negotiateConnectionWithClientOffer(
      this.peerConnection,
      this.endpoint,
    )
  }

  setVideoElement(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement
    if (
      this.peerConnection.connectionState === 'connected' &&
      this.stream &&
      !this.videoElement.srcObject
    ) {
      this.videoElement.srcObject = this.stream
    }
  }
}
