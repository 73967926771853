import React, { useLayoutEffect, useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGame } from 'screens/richman/AllRooms/GameProvider'
import { useParams } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import HintRoad from './HintRoad'
import EmptyHintRoad from 'screens/richman/BaccaratRooms/GameRoom/Road/EmptyHintRoad'
import SimpleHintRoad from './SimpleHintRoad'

const FETCH_ROADS = gql`
  query fetchRoads($id: ID!) {
    roadsAndImage(id: $id)
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    margin: '0 auto',
    position: 'relative',
    // height: '100%',
  },
  img: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
}))

const Road = ({ size }) => {
  const { id } = useParams()
  const classes = useStyles()
  const { roadsImage, roads } = useGame()

  return (
    <Box flex={2} className={classes.root} bgcolor="white">
      <Box width="93%">
        <img
          src={`data:image/png;base64,${roadsImage}`}
          className={classes.img}
        />
        {roads ? <HintRoad /> : <EmptyHintRoad />}
      </Box>
      <Box width="7%">{roads ? <SimpleHintRoad /> : <EmptyHintRoad />}</Box>
    </Box>
  )
}

export default React.memo(Road)
