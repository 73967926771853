import React, { useCallback, useEffect, useState } from 'react'

import verifyToken from 'helpers/verifyToken'
import useAuth from 'hooks/useAuth'
import useToken from 'hooks/useToken'
import AUTH_BG from 'images/richman/auth_bg.png'
import LOADING from 'images/richman/loading.gif'

import { Box, Button, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    maxWidth: 200,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    width: 180,
  },
  logo: {
    width: 200,
    height: 80,
    marginBottom: theme.spacing(1),
  },
  bg: {
    background: `url(${AUTH_BG})`,
    backgroundSize: 'cover',
  },
}))

export default function AuthScreen({ children }) {
  const [token] = useToken()
  const classes = useStyles()
  const [state, { authenticate, setError, logout }] = useAuth()

  useEffect(() => {
    ;(async () => {
      if (token) {
        try {
          if (await verifyToken(token)) {
            authenticate()
          } else {
            setError(
              new Error('你已經登入超過 24 小時，為了帳號安全請重新登入'),
            )
          }
        } catch (error) {
          logout()
          setError(error)
        }
      }
    })()
  }, [token])

  if (state.error) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
        className={classes.bg}
      >
        <img src={LOADING} className={classes.logo} />
        <Dialog open maxWidth="sm" fullWidth>
          <Box className={classes.root}>
            <Box className={classes.title}>{state.error.message}</Box>
            <Button
              className={classes.button}
              onClick={logout}
              variant="contained"
              color="primary"
            >
              確定
            </Button>
          </Box>
        </Dialog>
      </Box>
    )
  } else {
    return children
  }
}
