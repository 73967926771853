import React, { useCallback, useState } from 'react'

import ACTION_ICON from 'images/icons/actions.png'
import { useImmer } from 'use-immer'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ToggleCommissionFree from '../../ChipSelector/ToggleCommissionFree'
import ChangeVideoCamButton from './ChangeVideoCamButton'
import MicButton from './MicButton'
import SelectPlayer from './SelectPlayer'
import ToggleVideoButton from './ToggleVideoButton'
import { ViewBlockchainInfo } from './ViewBlockchainInfo'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    right: '2%',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  img: {
    width: 20,
    height: 20,
  },
  open: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  close: {},
  actions: {},
  button: {
    padding: theme.spacing(0.5),
    backgroundColor: '#D6D6D6',
    borderRadius: theme.spacing(1),
  },
}))

const Actions = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  const onClose = () => setOpen(false)

  return (
    <div className={classes.root}>
      <button className={classes.button} onClick={toggleOpen}>
        <img src={ACTION_ICON} className={classes.img} />
      </button>
      <Box className={open ? classes.open : classes.close}>
        <ViewBlockchainInfo parentOpen={open} onClose={onClose} />
        <SelectPlayer open={open} onClose={onClose} />
        <MicButton open={open} onClose={onClose} />
        <ChangeVideoCamButton open={open} onClose={onClose} />
        <ToggleVideoButton open={open} onClose={onClose} />
      </Box>
    </div>
  )
}

export default React.memo(Actions)
