import 'styles/richman/basic.css'
import 'moment/locale/zh-tw'

import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import disableDevtool from 'disable-devtool'
import i18n from 'helpers/i18n'
import { cacheImages } from 'helpers/index'
import registerServiceWorker from 'libs/registerServiceWorker'
import SiteConfig from 'providers/common/SiteConfig'
import Router from 'providers/richman/Router'
import ThemeProvider from 'providers/richman/ThemeProvider'
import { I18nextProvider } from 'react-i18next'

import MomentUtils from '@date-io/moment'
// import '../libs/wdyr.js'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import 'animate.css'

const images = [require('images/richman/auth_bg.png')]

// new Recorder()

const App: React.FC = () => {
  const setDimentions = useCallback(() => {
    let vh = window.innerHeight * 0.01
    let vw = window.innerWidth * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw', `${vw}px`)
  }, [])

  useEffect(() => {
    setDimentions()
    if (process.env.NODE_ENV === 'production') {
      // disableDevtool()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setDimentions)
    window.addEventListener('orientationchange', setDimentions)
    return () => {
      window.removeEventListener('resize', setDimentions)
      window.removeEventListener('orientationchange', setDimentions)
    }
  }, [])

  useEffect(() => {
    registerServiceWorker()
  }, [])

  useEffect(() => {
    cacheImages(images)
  }, [])

  return (
    <SiteConfig>
      <I18nextProvider i18n={i18n}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="zh-tw">
          <CssBaseline />
          <ThemeProvider>
            <Router />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </I18nextProvider>
    </SiteConfig>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.getElementById('root'))
})
