import React, { useCallback, useState } from 'react'

import clsx from 'clsx'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useEvent } from 'react-use'

import { makeStyles, Box } from '@material-ui/core'
import GradientBox from 'components/common/GradientBox'

const useStyle = makeStyles((theme) => ({
  container: {
    flexGrow: 1.2,
    flexBasis: 0,
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
  },
  inner: {
    background: '#122e66',
    borderRadius: theme.spacing(0.5),
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tie: {
    color: theme.palette.gold.main,
  },
  win: {
    color: theme.palette.tie.main,
  },
  lose: {
    color: theme.palette.dealer.main,
  },
  hidden: {
    visibility: 'hidden',
  },
}))

export default function Jackpot() {
  const styles = useStyle()
  const { room } = useCurrentRoom()
  const [message, setMessage] = useState<string>('P')
  const [winLossAmount, setWinLossAmount] = useState<number | null>(null)

  useEvent(
    'jackpot',
    (e: CustomEvent) => {
      const jackpot = e.detail
      const winLossAmount = parseFloat(jackpot.winLossAmount)
      if (room.name != jackpot.roomName) {
        return
      }
      setMessage(
        `${jackpot.roomName} 輸贏：${winLossAmount} 餘額：${jackpot.balance}`,
      )
      setWinLossAmount(winLossAmount)
    },
    window,
  )

  useEvent('clear-jackpot', (e: CustomEvent) => {
    if (e.detail.name === room.name) {
      setMessage('P')
      setWinLossAmount(null)
    }
  })

  return (
    <GradientBox className={clsx(styles.container)}>
      <Box className={styles.inner}>
        <Box
          className={clsx({
            [styles.hidden]: winLossAmount == null,
            [styles.tie]: winLossAmount === 0,
            [styles.win]: winLossAmount > 0,
            [styles.lose]: winLossAmount < 0,
          })}
        >
          {message}
        </Box>
      </Box>
    </GradientBox>
  )
}
