import React, { useCallback, useState } from 'react'

import useCancelBet from 'hooks/useCancelBet'
import CANCEL_BET from 'images/richman/cancel_bet.png'
import { useNotification } from 'screens/richman/BaccaratRooms/GameRoom/NotificationProvider'

import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    opacity: '100%',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
  },
  cancel: {
    color: 'white',
  },
}))

const CancelButton = ({ iconWidth }) => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  const notification = useNotification()
  const [cancelBet, { loading }] = useCancelBet({ notification })
  const { t } = useTranslation()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onCancel = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      cancelBet()
      onClose()
    },
    [cancelBet, onClose],
  )

  return (
    <>
      <button
        className={styles.button}
        style={{ width: iconWidth }}
        onClick={onOpen}
        disabled={loading}
      >
        <img className={styles.image} src={CANCEL_BET} />
      </button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('common.confirmCancel')}</DialogTitle>
        <DialogActions>
          <Button className={styles.cancel} onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onCancel}>{t('common.confirm')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(CancelButton)
