import React from 'react'

import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import LIKE from '../like.png'

const QUERY = gql`
  query streamers {
    streamers {
      id
      nickname
      avatar
      winRate
      online
      likesCount
    }
  }
`

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#0d426e',
    padding: theme.spacing(1),
    borderRadius: '0 0 0 1rem',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    width: '13rem',
    minHeight: 100,
  },
  streamer: {
    width: '6rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    border: '2px solid #69f8f3',
  },
  likesCount: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.25rem',
  },
}))

function Streamer({ streamer }) {
  const styles = useStyles()
  let history = useHistory()

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/streamers/${streamer.id}`)
  }

  return (
    <Box className={styles.streamer} onClick={onClick}>
      <img src={streamer.avatar} className={styles.avatar} />
      <Box>{streamer.nickname}</Box>
      <Box className={styles.likesCount}>
        <img src={LIKE} width={16} /> {streamer.likesCount}
      </Box>
    </Box>
  )
}

export default function Streamers() {
  const styles = useStyles()
  const { data } = useQuery(QUERY)
  const { id } = useParams()

  if (!data) {
    return <CircularProgress />
  }

  return (
    <Box className={styles.container}>
      {data.streamers
        .filter((streamer) => streamer.online === true)
        .filter((streamer) => streamer.id !== id)
        .map((streamer) => (
          <Streamer key={streamer.id} streamer={streamer} />
        ))}
    </Box>
  )
}
