import React, { createContext, useCallback, useContext, useEffect } from 'react'

import useBetState from 'hooks/useBetState'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useSnackbar } from 'notistack'
import { useImmer } from 'use-immer'

import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const CurrentBetContext = createContext(null)

const CurrentBetProvider = ({ children }) => {
  const { room } = useCurrentRoom()
  const [currentBet, setCurrentBet] = useImmer(room.currentBaccaratBetRecord)
  const [, { start, add, confirm }] = useBetState()
  const { enqueueSnackbar } = useSnackbar()

  const resetCurrentBet = useCallback(() => {
    setCurrentBet(null)
  }, [])

  const onReceived = useCallback(
    (response) => {
      const { currentBet: newBet } = response
      if (newBet) {
        setCurrentBet(newBet)
      }
    },
    [setCurrentBet, enqueueSnackbar],
  )

  useEffect(() => {
    const { currentBaccaratBetRecord } = room
    if (!currentBaccaratBetRecord) {
      return
    }
    const targets = [
      'player',
      'dealer',
      'playerPair',
      'dealerPair',
      'tie',
      'playerDragon',
      'dealerDragon',
      'playerSingle',
      'dealerSingle',
      'playerDouble',
      'dealerDouble',
      'perfectPair',
      'anyPair',
      'playerKing',
      'dealerKing',
      'superTie0',
      'superTie1',
      'superTie2',
      'superTie3',
      'superTie4',
      'superTie5',
      'superTie6',
      'superTie7',
      'superTie8',
      'superTie9',
      'big',
      'small',
      'super6',
    ]
    start()
    for (let target of targets) {
      const amount = parseInt(currentBaccaratBetRecord[`${target}Amount`])
      if (amount > 0) {
        add(target, amount)
      }
    }
    confirm()
  }, [room])

  return (
    <ActionCableConsumer
      channel={{ channel: 'BaccaratBetChannel', roomId: room.id }}
      onReceived={onReceived}
    >
      <CurrentBetContext.Provider value={{ currentBet, resetCurrentBet }}>
        {children}
      </CurrentBetContext.Provider>
    </ActionCableConsumer>
  )
}

export const useBetRecord = () => useContext(CurrentBetContext)

export default React.memo(CurrentBetProvider)
