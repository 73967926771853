import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGameData } from '../GameProvider'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.common.white,
    border: '0.5px solid black',
    position: 'relative',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  roadBg: {
    position: 'absolute',
    top: '10%',
    right: '5%',
    color: 'transparent',
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 8,
    '&::before': {
      content: '" "',
      baxShadow: '1.7982px 1.7982px 1.1988px rgba(0, 0, 0, 0.25)',
      transform: 'matrix(0.99, -0.14, -0.2, 0.98, 0, 0)',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  dealerRoadBg: {
    '&::before': {
      background: 'linear-gradient(136.01deg, #FF6767 0%, #8C0000 83.28%)',
    },
  },
  playerRoadBg: {
    '&::before': {
      background: 'linear-gradient(136.01deg, #6792FF 0%, #00168C 83.28%)',
    },
  },
  roadText: {
    zIndex: 1,
    color: 'white',
    fontWeight: 'bold',
    textShadow: '1.7982px 1.1988px 2.997px rgba(114, 0, 0, 0.76)',
    fontStyle: 'italic',
  },
  firstLetter: {
    fontSize: 24,
    fontFamily: 'Arial',
  },
  otherLetter: {
    fontSize: 16,
    fontFamily:
      '"Microsoft YaHei", "Roboto", "Helvetica", "Arial", "sans-serif"',
    whiteSpace: 'nowrap',
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))

const NiceRoad = ({ road }) => {
  const classes = useStyles()

  // only display long road
  if (road.indexOf('連龍') === -1) return null

  const isDealer = road.split('|')[0] === '莊'
  const firstLetter = road.split('|')[1][0]
  const otherLetters = road.split('|')[1].slice(1)

  if (road.indexOf('連龍') > -1) {
    return (
      <Box
        className={
          isDealer
            ? clsx(
                classes.roadBg,
                classes.dealerRoadBg,
                'animate__animated animate__bounceInRight',
              )
            : clsx(
                classes.roadBg,
                classes.playerRoadBg,
                'animate__animated animate__bounceInRight',
              )
        }
      >
        {road}
        <Box className={classes.inner}>
          <Box className={clsx(classes.roadText, classes.firstLetter)}>
            {firstLetter}
          </Box>
          <Box className={clsx(classes.roadText, classes.otherLetter)}>
            {otherLetters}
          </Box>
        </Box>
      </Box>
    )
  }

  return null
}

const LobbyRoads = ({ ref }) => {
  const classes = useStyles()
  const { roadsImage, roads } = useGameData()

  return useMemo(() => {
    return (
      <Box className={classes.root}>
        <img
          src={`data:image/png;base64,${roadsImage}`}
          className={classes.img}
          ref={ref}
        />
        {roads?.big_road?.nice_road ? (
          <NiceRoad road={roads.big_road.nice_road} />
        ) : null}
      </Box>
    )
  }, [roadsImage, roads])
}

export default React.memo(LobbyRoads)
