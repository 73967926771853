import React from 'react'

import useAuth from 'hooks/useAuth'
import useCable from 'hooks/useCable'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const DesktopLayout = ({ children }) => {
  const classes = useStyles()
  const [{ isAuthenticated }] = useAuth()
  const cable = useCable('default')

  if (isAuthenticated) {
    return <Box className={classes.root}>{children}</Box>
  } else {
    return <ActionCableProvider cable={cable}>{children}</ActionCableProvider>
  }
}

export default React.memo(DesktopLayout)
