import { gql } from '@apollo/client'

export default gql`
  mutation createWelcomeMessage($input: WelcomeMessageInput!) {
    welcomeMessage(input: $input) {
      errors {
        attribute
        message
      }
    }
  }
`
