import React, { createContext, useContext, useState } from 'react'

const SelectedRoomContext = createContext<[number, any]>(null)

export function SelectedRoomProvider({ children }) {
  const [selectedRoom, select] = useState(null)

  return (
    <SelectedRoomContext.Provider value={[selectedRoom, select]}>
      {children}
    </SelectedRoomContext.Provider>
  )
}

export default function useSelectedRoom() {
  return useContext(SelectedRoomContext)
}
