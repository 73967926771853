import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%), linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    border: '1px solid',
    borderImageSource:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%)',
  },
}))

const GradientBox = ({ children, className = {}, ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      {children}
    </Box>
  )
}

export default React.memo(GradientBox)
