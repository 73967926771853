import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { useGameStatus } from '../BaccaratRooms/GameProvider'
import CancelButton from './CancelButton'
import ConfirmButton from './ConfirmButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}))

function Buttons() {
  const styles = useStyles()
  const gameStatus = useGameStatus()

  return (
    <Box
      className={styles.root}
      style={{ opacity: gameStatus.status === 'START_BET' ? '100%' : '50%' }}
    >
      <CancelButton />
      <ConfirmButton />
    </Box>
  )
}

export default Buttons
