import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import { useFullModal } from 'hooks/useFullModal'
import clsx from 'clsx'
import Rules from './Rules'
import RULE from 'images/icons/header/bet_records.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
  icon: {
    width: 18,
    height: 18,
  },
}))

const RuleButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setModal } = useFullModal()

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  const toggleRecords = () => {
    onCloseAnchor()
    setModal({
      modal: <Rules />,
      title: t('components.header.rules'),
    })
  }

  return (
    <Button
      color="primary"
      className={buttonClass}
      startIcon={<img src={RULE} className={classes.icon} />}
      fullWidth
      onClick={toggleRecords}
    >
      {t('components.header.rules')}
    </Button>
  )
}

export default React.memo(RuleButton)
