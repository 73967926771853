import { useState } from 'react'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useEvent } from 'react-use'

export default function IdleTimes() {
  const [times, setTimes] = useState(0)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  useEvent(
    'bet:confirm',
    () => {
      setTimes(0)
    },
    window,
  )

  useEvent(
    'bet:next',
    () => {
      if (times === 3) {
        enqueueSnackbar(t('common.idleTooLongMessage'))
      }
      if (times === 4) {
        window.location.href = '/'
      }
      setTimes(times + 1)
    },
    window,
  )

  return null
}
