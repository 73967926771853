import { findIndex, flatten, reverse, sum, take } from 'lodash'

export const chips = [
  10,
  // 50,
  100,
  500,
  1000,
  5000,
  10000,
  // 20000,
  50000,
  100000,
  // 200000,
  // 1000000,
]

export const calcChips = (chipsAlreadyHave, chip) => {
  // 全部的籌碼總和
  const total = sum(chipsAlreadyHave) + chip
  // 找出總和在陣列中大於等於的 index
  const index = findIndex(chips, (i) => i >= total)
  if (chips[index] === total) {
    // 總和剛好是該籌碼，直接回傳一個籌碼
    return [chips[index]]
  } else {
    // 總和小於該籌碼，繼續計算剩餘籌碼
    let tempChips = []
    let temp = total
    const remainChips = reverse(take(chips, index + 1))
    for (let i = 0; i < remainChips.length; i++) {
      if (temp === 0) {
        break
      }

      const el = remainChips[i]
      const count = Math.floor(temp / el)
      tempChips.push(Array(count).fill(el))
      temp = temp - el * count
    }
    return flatten(tempChips)
  }
}

export const repeatPlayer = (state, player: string) => {
  const confirmedChips = state[player].confirmedChips
  let chips = []
  let currentAmount = 0
  let tempAmount = 0
  if (confirmedChips.length === 0) {
    chips = state.prevBet[player].chips
    currentAmount = state.prevBet[player].amount
    tempAmount = state.prevBet[player].amount
  } else {
    chips = state[player].confirmedChips
    currentAmount = state.prevBet[player].amount + state[player].confirmedAmount
    tempAmount = state.prevBet[player].amount
  }
  return {
    ...state[player],
    cancelled: false,
    confirmed: false,
    currentAmount,
    tempAmount,
    chips,
  }
}

export const cancelPlayer = (state, player: string) => {
  return {
    ...state[player],
    confirmed: true,
    cancelled: false,
    tempAmount: 0,
    currentAmount: 0,
    confirmedAmount: 0,
    chips: [],
  }
}

export const confirmPlayer = (state, player: string) => {
  return {
    ...state[player],
    confirmedAmount: state[player].confirmedAmount + state[player].tempAmount,
    tempAmount: 0,
    currentAmount: state[player].confirmedAmount + state[player].tempAmount,
    confirmed: true,
    confirmedChips: state[player].confirmedChips.concat(state[player].chips),
    chips: [],
  }
}

export const confirmPlayerAmount = (state, amount, player: string) => {
  return {
    ...state[player],
    confirmedAmount: parseInt(amount),
    tempAmount: 0,
    currentAmount: parseInt(amount),
    confirmed: true,
    confirmedChips: state[player].confirmedChips.concat(state[player].chips),
    chips: [],
  }
}
