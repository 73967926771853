import React, { createContext, useCallback, useContext, useState } from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'

const CurrentRoomContext = createContext(null)

function Provider({ room, children }) {
  const match = useRouteMatch({ path: `/rooms/${room.id}` })
  const isOpen = !!match
  const history = useHistory()

  const close = useCallback(() => {
    history.push('/')
  }, [history])

  const open = useCallback(() => {
    history.push(`/rooms/${room.id}`)
  }, [history, room])

  return (
    <CurrentRoomContext.Provider value={{ room, isOpen, open, close }}>
      {children}
    </CurrentRoomContext.Provider>
  )
}

export const CurrentRoomProvider = React.memo(Provider)

export default function useCurrentRoom() {
  return useContext(CurrentRoomContext)
}
