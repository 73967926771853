import React from 'react'

import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'
import { useSnackbar } from 'notistack'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'

import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare'

const useStyles = makeStyles((theme) => ({
  root: {},
  hintText: {
    color: theme.palette.gold.main,
  },
  btn: {
    background:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    borderWidth: '0px 1.5px 1px 0px',
    borderStyle: 'solid',
    borderImageSource:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    boxShadow: '2px 2px 4px 0px #1F1F1F9C inset',
    position: 'relative',
    width: '100%',
  },
  inner: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    boxShadow:
      'inset 1px 1px 2px rgba(31, 31, 31, 0.61), inset -1px -1px 2px rgba(198, 198, 198, 0.58)',
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(0.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

const DragonSetting = ({ forceOpen = false }) => {
  const classes = useStyles()
  const [open, setOpen] = useImmer(forceOpen)
  const {
    profile: { dragonCount, lineNotifyToken, id },
  } = useProfile()
  const { enqueueSnackbar } = useSnackbar()
  const [count, setCount] = useImmer(dragonCount)
  const [update, { loading }] = useMutation(UPDATE_PROFILE)
  const { t, i18n } = useTranslation()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const onSubmit = async () => {
    const result = await update({
      variables: {
        input: {
          dragonCount: count,
        },
      },
    })

    const { errors, profile: newProfile } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    } else {
      enqueueSnackbar('更新成功', { variant: 'success' })
      onClose && onClose()
    }
  }

  const onSubmitToken = async (data) => {
    const result = await update({
      variables: {
        input: {
          ...data,
        },
      },
    })

    const { errors, profile: newProfile } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    } else {
      enqueueSnackbar('更新成功', { variant: 'success' })
      onClose && onClose()
    }
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      window.location.href = `https://notify-bot.line.me/oauth/authorize?client_id=QSst3U9WASIbvItOG2JQbb&client_secret=FfYOe6RW9RnbaoSNHCAnBUtVUqyLqWDReN3S0lsOVCh&response_type=code&redirect_uri=https://r1chman.tech/line/notify&scope=notify&state=User-${id}`
    } else {
      onSubmitToken({
        lineNotifyToken: '',
      })
    }
  }

  const onChangeCount = (event) => {
    setCount(parseInt(event.target.value))
  }

  const isStandalone =
    'standalone' in window.navigator && window.navigator['standalone']

  const openInBrowser = (event) => {
    event.preventDefault()
    event.stopPropagation()
    window.open('https://r1chman.tech', '_blank')
  }

  const isZH = ['zhCN', 'zhTW'].includes(i18n.language)

  return (
    <>
      <button onClick={onOpen} className={classes.btn}>
        <Box className={classes.inner}>
          {t('screens.profile.dragonSettings')}
        </Box>
      </button>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={!!lineNotifyToken}
                  onChange={handleChange}
                  name="checkedA"
                />
              }
              label={t('screens.profile.dragonNotify')}
            />
            {isStandalone && (
              <FormHelperText className={classes.hintText}>
                {t('screens.profile.dragonHint')}
                <IconButton onClick={openInBrowser}>
                  <MobileScreenShareIcon style={{ color: 'gold' }} />
                </IconButton>
              </FormHelperText>
            )}
          </FormGroup>
          {lineNotifyToken && (
            <FormGroup>
              <FormControl>
                <InputLabel id="dragonCount">
                  {t('screens.profile.dragonNotifyCount')}
                </InputLabel>
                <Select
                  labelId="dragonCount"
                  value={count}
                  onChange={onChangeCount}
                  variant="outlined"
                >
                  <MenuItem value={0}>
                    {t('screens.profile.dragonNoNotify')}
                  </MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={onSubmit}
          >
            {t('actions.update')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(DragonSetting)
