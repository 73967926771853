import React, { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import useSearchQuery from 'hooks/useSearchQuery'
import useWallet from 'hooks/useWallet'
import { useSnackbar } from 'notistack'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Actions from './Actions'
import BetRecords from './BetRecords'
import CreditChangeRecords from './CreditChangeRecords'
import DepositButton from './DepositButton'
import GiftRecords from './GiftRecords'
import ProfileInfo from './ProfileInfo'
import Records from './Records'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2.5, 2),
    position: 'relative',
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const Profile = () => {
  const classes = useStyles()
  const { profile } = useProfile()
  const [ref, { width }] = useMeasure()
  const [rootRef, { height }] = useMeasure()
  const [active, setActive] = useState(0)
  const query = useSearchQuery()
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()

  if (!profile) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  useEffect(() => {
    const message = query.get('message')
    if (message === 'success') {
      enqueueSnackbar(t('screens.profile.turnOnNotifySuccessfully'), {
        variant: 'success',
      })
    } else if (message === 'failed') {
      enqueueSnackbar(t('screens.profile.turnOnNotifyFailed'), {
        variant: 'error',
      })
    }
  }, [query])

  return (
    <Box className={classes.root}>
      <ProfileInfo />
      <Actions />
      <Records />
    </Box>
  )
}

export default Profile
