import React, { createContext, useContext, useEffect } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import { useImmer } from 'use-immer'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'
import UPDATE_SETTINGS from 'graphql/application/mutations/updateSettings'
import { isInteger, isEqual } from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { useProfile } from 'providers/richman/ProfileProvider'

const GET_SETTINGS = gql`
  query profile {
    profile {
      id
      settings
    }
  }
`

const SettingsContext = createContext(null)

const Provider = ({ children }) => {
  let history = useHistory()
  let location = useLocation()
  const { data, loading } = useQuery(GET_SETTINGS)
  const [settings, setSettings] = useImmer({
    currentPlay: 0,
    repeatMode: 'all',
    musicVolume: 0.5,
    musicOn: true,
    soundOn: true,
    micOn: true,
    micVolume: 0.5,
    volume: 0.5,
    soundVolume: 0.5,
    tableLimitId: null,
  })
  const [update] = useMutation(UPDATE_SETTINGS)
  const { needUpdatePassword } = useProfile()

  useEffect(() => {
    if (data) {
      setSettings(data.profile.settings)
    }
  }, [data])

  useEffect(() => {
    if (needUpdatePassword && location.pathname !== '/update_password') {
      history.replace('/update_password')
    }
  }, [needUpdatePassword, location, history])

  const onReceived = (response) => {
    const { settings: newSettings } = response
    if (!isEqual(settings, newSettings)) {
      setSettings(newSettings)
    }
  }

  useEffect(() => {
    if (!isInteger(settings.currentPlay)) {
      update({
        variables: {
          input: {
            ...settings,
            currentPlay: 0,
            repeatMode: 'all',
            musicVolume: 0.5,
            musicOn: true,
            soundOn: true,
            micOn: true,
            micVolume: 0.5,
            volume: 0.5,
            soundVolume: 0.5,
            tableLimitId: null,
          },
        },
      })
    }
  }, [settings])

  return (
    <ActionCableConsumer
      channel={{ channel: 'ProfileSettingsChannel' }}
      onReceived={onReceived}
    >
      <SettingsContext.Provider value={{ settings }}>
        {children}
      </SettingsContext.Provider>
    </ActionCableConsumer>
  )
}

export const useProfileSettings = () => useContext(SettingsContext)

export default React.memo(Provider)
