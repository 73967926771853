import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useGameContent } from 'screens/richman/BaccaratRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: '15%',
    background: theme.palette.dealer.main,
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    color: 'white',
  },
}))

const AlertText = ({}) => {
  const classes = useStyles()
  const { alertText } = useGameContent()

  if (!!alertText) {
    return (
      <Box className={classes.root}>
        <Typography>{alertText}</Typography>
      </Box>
    )
  }

  return null
}

export default React.memo(AlertText)
