import React, { useMemo } from 'react'

import clsx from 'clsx'
import { chips } from 'helpers/bet'
import useBetState from 'hooks/useBetState'
import useMobile from 'hooks/useMobile'
import BET_CONFIRMED from 'images/icons/bet_confirmed.png'
import { findIndex, flatten, reverse, sum, take } from 'lodash'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

const useStyles = makeStyles((theme) => ({
  root: {},
  chips: {
    pointerEvents: 'none',
    position: 'absolute',
    left: 10,
    bottom: '25%',
  },
  betAmountText: {
    fontSize: theme.typography.pxToRem(14),
    color: 'white',
  },
  confirmed: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: -11,
    right: -15,
    [theme.breakpoints.up('sm')]: {
      width: 16 * 1.5,
      height: 16 * 1.5,
      top: -16,
      right: -22,
    },
  },
  chipImg: {
    width: 36,
    height: 28,
    [theme.breakpoints.up('sm')]: {
      width: 36 * 1.5,
      height: 28 * 1.5,
    },
  },
  chip: {
    marginTop: -25,
    [theme.breakpoints.up('sm')]: {
      marginTop: -25 * 1.5,
    },
  },
  amount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0D1E4A',
    border: '0.5px solid #FFFFFF',
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    zIndex: 1,
  },
  innerAmount: {
    width: '100%',
    height: '100%',
    fontSize: theme.typography.pxToRem(10),
    color: '#fff',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  chipsAndAmount: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))

export const calcChips = (total) => {
  // 找出總和在陣列中大於等於的 index
  const index = findIndex(chips, (i) => i >= total)
  if (chips[index] === total) {
    // 總和剛好是該籌碼，直接回傳一個籌碼
    return [chips[index]]
  } else {
    // 總和小於該籌碼，繼續計算剩餘籌碼
    let tempChips = []
    let temp = total
    const remainChips = reverse(take(chips, index + 1))
    for (let i = 0; i < remainChips.length; i++) {
      if (temp === 0) {
        break
      }

      const el = remainChips[i]
      const count = Math.floor(temp / el)
      tempChips.push(Array(count).fill(el))
      temp = temp - el * count
    }
    return flatten(tempChips)
  }
}

const Chip = ({ value, index }) => {
  const classes = useStyles()
  const isMobile = useMobile()

  return React.useMemo(() => {
    return (
      <Box className={classes.chip} style={{ zIndex: index }}>
        <img
          src={require(`images/chips/richman/S_${value}.png`)}
          className={classes.chipImg}
        />
      </Box>
    )
  }, [value, isMobile])
}

const Chips = ({ player }) => {
  const classes = useStyles()
  const [state] = useBetState()
  const targetState = state[player]

  const amount = targetState.confirmed + targetState.additional
  const confirmed = targetState.additional === 0

  return React.useMemo(() => {
    return (
      <Box className={classes.chips}>
        {amount !== 0 && (
          <Box className={classes.chipsAndAmount}>
            <div className="flex flex-col-reverse">
              {calcChips(amount).map((chip, index) => (
                <Chip key={index} value={chip} index={index} />
              ))}
            </div>
            <Box className={classes.amount}>
              <Box className={classes.innerAmount}>
                <span className={clsx({ 'confirmed-bet-amount': confirmed })}>
                  {amount}
                </span>
                {confirmed && (
                  <img src={BET_CONFIRMED} className={classes.confirmed} />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }, [targetState])
}

export default React.memo(Chips)
