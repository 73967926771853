import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { range, isEmpty } from 'lodash'
import { GameResult, PairResult, RoundResult } from 'libs/ScoreBoard/types'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    border: `2px solid ${theme.palette.common.black}`,
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  bgImg: {
    width: '100%',
    height: 'auto',
    verticalAlign: 'top',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    flex: 1,
  },
  rowItem: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  blank: {
    width: '80%',
    height: '80%',
    background: 'transparent',
    color: 'white',
    // fontSize: theme.typography.pxToRem(10),
  },
  dealer: {
    width: '90%',
    height: '90%',
    background: theme.palette.dealer.main,
    borderRadius: 999,
    color: 'white',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  super6: {
    background: 'transparent',
    color: theme.palette.primary.main,
    width: '90%',
    height: '90%',
    fontSize: theme.typography.pxToRem(20),
    borderRadius: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  player: {
    width: '90%',
    height: '90%',
    background: theme.palette.player.main,
    borderRadius: 999,
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'relative',
  },
  tie: {
    width: '90%',
    height: '90%',
    background: theme.palette.tie.main,
    borderRadius: 999,
    color: 'white',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'relative',
  },
  dealerPair: {
    position: 'absolute',
    top: '10%',
    left: '-30%',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    background: theme.palette.dealer.main,
    border: '1px solid white',
    height: '30%',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
  },
  playerPair: {
    position: 'absolute',
    bottom: '10%',
    right: '-25%',
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '0% 100%',
    background: theme.palette.player.main,
    border: '1px solid white',
    height: '30%',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
  },
}))

const BeadRoad = ({ beadRoad, rows = 6, columns = 15 }) => {
  const classes = useStyles()

  const renderPair = (pairResult) => {
    if (pairResult === PairResult.BankerPair) {
      return <span className={classes.dealerPair} />
    } else if (pairResult === PairResult.PlayerPair) {
      return <span className={classes.playerPair} />
    } else if (pairResult === PairResult.AllPair) {
      return (
        <>
          <span className={classes.dealerPair} />
          <span className={classes.playerPair} />
        </>
      )
    }
  }

  const result = (item) => {
    if (isEmpty(item)) {
      return <Box className={classes.blank}></Box>
    }

    const { gameResult, result, pairResult } = item
    if (gameResult === GameResult.BankerWin && result === 6) {
      return (
        <Box className={classes.dealer}>
          <Box className={classes.super6}>6{renderPair(pairResult)}</Box>
        </Box>
      )
    } else if (gameResult === GameResult.BankerWin) {
      return <Box className={classes.dealer}>莊{renderPair(pairResult)}</Box>
    } else if (gameResult === GameResult.PlayerWin) {
      return <Box className={classes.player}>閒 {renderPair(pairResult)}</Box>
    } else if (gameResult === GameResult.Tie) {
      return <Box className={classes.tie}>和 {renderPair(pairResult)}</Box>
    }
  }

  const BG = require(`images/grid_6x15.png`)

  const diff = useMemo(() => {
    if (beadRoad) {
      const columnsCount = Math.max(
        ...beadRoad.array.map((item) => item.length),
      )
      return columnsCount - columns
    }
    return 0
  }, [beadRoad, columns])

  return (
    <Box className={classes.root}>
      <img src={BG} className={classes.bgImg} />
      <Box className={classes.container}>
        {beadRoad &&
          range(columns).map((columnIndex) => {
            return (
              <Box
                key={`beadRoad-column-${columnIndex}`}
                className={classes.row}
              >
                {range(6).map((rowIndex) => {
                  return (
                    <Box
                      key={`beadRoad-row-${columnIndex}-${rowIndex}`}
                      className={classes.rowItem}
                    >
                      {result(beadRoad.array[rowIndex][columnIndex + diff])}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(BeadRoad))
