import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomBeadRoad from 'components/richman/NewRoad/CustomBeadRoad'
import { useGame } from 'screens/richman/AllRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const BeadRoad = ({}) => {
  const classes = useStyles()
  const { roads } = useGame()

  return (
    <Box bgcolor="white" flex={1} height="100%" flexGrow={1}>
      <CustomBeadRoad beadRoad={roads?.bead_road} columns={15} />
    </Box>
  )
}

export default React.memo(BeadRoad)
