import React, { useCallback } from 'react'

import { chips } from 'helpers/bet'
import useSelectedChip from 'hooks/useSelectedChip'

import { Box, makeStyles } from '@material-ui/core'

const chipImages: Record<number, any> = chips.reduce(
  (images, chip) => ({
    ...images,
    [chip]: require(`images/chips/richman/${chip}.png`),
  }),
  {},
)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    padding: theme.typography.pxToRem(8),
    overflowY: 'auto',
  },
  chip: {
    opacity: '50%',
  },
  selectedChip: {
    opacity: '100%',
  },
  chipImage: {
    width: 50,
    height: 50,
  },
}))

function Chip({ chip }) {
  const styles = useStyles()
  const [selectedChip, select] = useSelectedChip()

  const selectCurrentChip = useCallback(() => {
    select(chip)
  }, [select, chip])

  return (
    <Box
      className={selectedChip === chip ? styles.selectedChip : styles.chip}
      onClick={selectCurrentChip}
    >
      <img src={chipImages[chip]} className={styles.chipImage} />
    </Box>
  )
}

function ChipSelector() {
  const styles = useStyles()

  return (
    <Box className={styles.root}>
      {chips.map((chip) => (
        <Chip key={chip} chip={chip} />
      ))}
    </Box>
  )
}

export default ChipSelector
