import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import { audios, playList } from 'helpers/richman_assets'
import { useProfileSettings } from 'hooks/useProfileSettings'
import { Howl, Howler } from 'howler'

const SoundContext = createContext(null)

const SoundProvider = ({ children }) => {
  const {
    settings: { soundOn, volume },
  } = useProfileSettings()
  const ref = useRef(null)
  // const [sound, setSound] = useState(null)

  const playSound = (target: string, rate = 1) => {
    const sound = audios[target]
    if (sound && soundOn) {
      Howler.stop()
      Howler.unload()
      let player = new Howl({
        src: [sound],
        html5: true,
        preload: true,
        volume: 0.1,
        rate,
        onloaderror: (id, error) => {
          console.log('onloaderror', error)
          Howler.unload()
        },
        onplayerror: (id, error) => {
          console.log('onplayerror', error)
          Howler.unload()
        },
      })
      player.play()
    }
  }

  const playList = (index, list, rate = 1) => {
    if (soundOn) {
      Howler.stop()
      Howler.unload()
      let sound = new Howl({
        src: [audios[list[index]]],
        html5: true,
        preload: true,
        volume: 0.1,
        rate,
        onend: () => {
          if (index + 1 === list.length) {
            // playList(0, list)
          } else {
            playList(index + 1, list)
          }
        },
        onloaderror: (id, error) => {
          console.log('onloaderror', error)
          Howler.unload()
        },
        onplayerror: (id, error) => {
          console.log('onplayerror', error)
          Howler.unload()
        },
      })
      sound.play()
    }
  }

  useEffect(() => {
    Howler.html5PoolSize = 10
  })

  return (
    <SoundContext.Provider value={{ playSound, playList }}>
      <audio ref={ref} />
      {children}
    </SoundContext.Provider>
  )
}

export const useSound = () => useContext(SoundContext)

export default React.memo(SoundProvider)
