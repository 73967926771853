import React, { useMemo } from 'react'

const pattern = /([a-z]+)(\d+)?\:.+/

export default function CardImage({ card, className = null }) {
  const [_full, type, number, _nonce] = useMemo(
    () => (card.card == null ? [] : card.card.match(pattern)),
    [card],
  )

  const image = useMemo(() => {
    if (type == null) {
      return require(`images/cards/unknown.png`)
    }
    switch (type) {
      case 'spade':
        return require(`images/cards/${parseInt(number)}.png`)
      case 'heart':
        return require(`images/cards/${13 + parseInt(number)}.png`)
      case 'diamond':
        return require(`images/cards/${26 + parseInt(number)}.png`)
      case 'club':
        return require(`images/cards/${39 + parseInt(number)}.png`)
      case 'break':
        return require(`images/cards/break.png`)
    }
  }, [type, number])

  return <img src={image} className={className} />
}
