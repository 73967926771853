import React, { useCallback, useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import PERSON from 'images/richman/streamers/person.png'
import RANKING from 'images/richman/streamers/rank.png'
import Ranking from './SideMenu/Ranking'
import Streamers from './SideMenu/Streamers'
import useStreamer from 'hooks/useStreamer'
import { useTranslation } from 'react-i18next'
import LikeButton from './LikeButton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  menu: {
    backgroundColor: 'rgba(13, 66, 110, 0.6)',
    padding: theme.spacing(1, 0.5),
    borderRadius: 37.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  winRate: {
    color: '#5EFBDF',
    fontSize: theme.typography.pxToRem(14),
  },
}))

export default function SideMenu() {
  const styles = useStyles()
  const { t } = useTranslation()
  const [streamer] = useStreamer()

  const [isGroupOpen, setGroupOpen] = useState(false)
  const [isRankingOpen, setRankingOpen] = useState(false)

  const toggleGroupOpen = useCallback(() => {
    setGroupOpen((value) => !value)
  }, [setGroupOpen])

  const toggleRankingOpen = useCallback(() => {
    setRankingOpen((value) => !value)
  }, [setRankingOpen])

  return (
    <Box className={styles.container}>
      <Box className={styles.menu}>
        <LikeButton />
        <img src={PERSON} onClick={toggleGroupOpen} className={styles.icon} />
        <img
          src={RANKING}
          onClick={toggleRankingOpen}
          className={styles.icon}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box className={styles.winRate}>{t('screens.streamer.winRate')}</Box>
          <Box className={styles.winRate}>
            {Math.floor(streamer.winRate * 100)}%
          </Box>
        </Box>
      </Box>
      {isGroupOpen && <Streamers />}
      {isRankingOpen && <Ranking onClose={toggleRankingOpen} />}
    </Box>
  )
}
