import { useCallback } from 'react'

import BET_BACCARAT from 'graphql/application/mutations/createBaccaratBet'
import { delayAtLeast } from 'helpers/delay'
import { useSettings } from 'providers/richman/AppProvider'

import { MutationResult, useMutation } from '@apollo/client'

import useBetState from './useBetState'
import useCommissionFree from './useCommissionFree'
import useCurrentRoom from './useCurrentRoom'
import useWallet from './useWallet'

export default function useConfirmBet({
  notification,
}): [() => void, MutationResult<any>] {
  const { room } = useCurrentRoom()
  const [, { updateQuery }] = useWallet()
  const [mutate, confirmBetState] = useMutation(BET_BACCARAT)
  const { setNotification } = notification
  const [betState, { confirm, enable, disable }] = useBetState()
  const {
    settings: { deviceInfo },
  } = useSettings()
  const { gameType } = useCommissionFree()

  const loading = confirmBetState.loading || betState.disabled

  const confirmBet = useCallback(async () => {
    if (betState.additional === 0) return
    if (loading) return

    disable()
    await delayAtLeast(async () => {
      const result = await mutate({
        variables: {
          input: {
            baccaratRoomId: room.id,
            playerAmount: betState.player.additional,
            dealerAmount: betState.dealer.additional,
            tieAmount: betState.tie.additional,
            playerPairAmount: betState.playerPair.additional,
            dealerPairAmount: betState.dealerPair.additional,
            bigAmount: betState.big.additional,
            smallAmount: betState.small.additional,
            super6Amount: betState.super6.additional,
            playerDragonAmount: betState.playerDragon.additional,
            dealerDragonAmount: betState.dealerDragon.additional,
            playerSingleAmount: betState.playerSingle.additional,
            dealerSingleAmount: betState.dealerSingle.additional,
            playerDoubleAmount: betState.playerDouble.additional,
            dealerDoubleAmount: betState.dealerDouble.additional,
            perfectPairAmount: betState.perfectPair.additional,
            anyPairAmount: betState.anyPair.additional,
            playerKingAmount: betState.playerKing.additional,
            dealerKingAmount: betState.dealerKing.additional,
            superTie0Amount: betState.superTie0.additional,
            superTie1Amount: betState.superTie1.additional,
            superTie2Amount: betState.superTie2.additional,
            superTie3Amount: betState.superTie3.additional,
            superTie4Amount: betState.superTie4.additional,
            superTie5Amount: betState.superTie5.additional,
            superTie6Amount: betState.superTie6.additional,
            superTie7Amount: betState.superTie7.additional,
            superTie8Amount: betState.superTie8.additional,
            superTie9Amount: betState.superTie9.additional,
            gameType,
            deviceInfo: JSON.stringify(deviceInfo),
          },
        },
      })
      const { errors, wallet } = result.data.createBaccaratBet

      if (errors.length > 0) {
        setNotification(errors[0].message)
      } else {
        updateQuery(() => ({ wallet }))
        setNotification('下注成功')
        confirm()
      }
    }, 100)
    enable()
  }, [betState, loading, mutate, room, deviceInfo, setNotification, confirm])

  return [confirmBet, { ...confirmBetState, loading }]
}
