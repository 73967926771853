import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfileTableLimit } from 'providers/richman/ProfileProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  text: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
  },
}))

const TableLimits = () => {
  const classes = useStyles()
  const { tableLimitMin, tableLimitMax } = useProfileTableLimit()

  return (
    <Box display="flex" alignItems="center" mr={1}>
      <Typography className={classes.text}>{tableLimitMin}</Typography>
      <Typography className={classes.text}> - </Typography>
      <Typography className={classes.text}>{tableLimitMax}</Typography>
    </Box>
  )
}

export default React.memo(TableLimits)
