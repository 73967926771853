import { useState } from 'react'

import GET_BACCARAT_ROOM from 'graphql/application/queries/getBaccaratRoom'
import { useEvent } from 'react-use'

import { useQuery } from '@apollo/client'

export default function useBaccaratRoom(id) {
  const [nonce, setNonce] = useState(Math.random())
  const query = useQuery(GET_BACCARAT_ROOM, {
    fetchPolicy: 'network-only',
    variables: { id },
  })

  useEvent(
    'refetch',
    () => {
      query.refetch()
      setNonce(Math.random())
    },
    window,
  )

  return { ...query, nonce }
}
