import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    overflowX: 'auto',
  },
  selected: {
    background:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%), linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    border: '0.5px solid',
    borderImageSource:
      'linear-gradient(124.47deg, #502000 -10.92%, #AB5F00 1.13%, #FEC47F 30.04%, #FFECE0 41.32%, #FFF5E1 46.53%, #FFE6BC 66.75%, #C77800 94.42%, #4A2900 116.49%)',
    transform: 'matrix(1, 0, -0.1, 1, 0, 0)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    position: 'relative',
    width: '100%',
    marginRight: theme.spacing(1),
  },
  default: {
    background: 'rgba(255, 255, 255, 0.45)',
    border: '1px solid #FFFFFF',
    boxShadow:
      'inset 2px 2px 4px rgba(31, 31, 31, 0.61), inset -2px -2px 4px rgba(198, 198, 198, 0.58)',
    borderRadius: 1,
    transform: 'matrix(1, 0, -0.1, 1, 0, 0)',
    padding: 1,
    position: 'relative',
    width: '100%',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  selectedInner: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.35)',
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  defaultInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    leadingTrim: 'both',
    textEdge: 'cap',
    letterSpacing: '0.73em',
    textShadow: '0px 0px 2px #49A0FF',
    transform: 'matrix(1, 0, -0.1, 1, 0, 0)',
    whiteSpace: 'nowrap',
  },
  selectedText: {
    color: '#ffffff',
  },
  defaultText: {
    color: '#0D1E4A',
  },
}))

const Filters = ({ halls, selected, setSelected }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {halls.map((hall) => (
        <Box
          className={selected === hall ? classes.selected : classes.default}
          key={hall}
          onClick={() => setSelected(hall)}
        >
          <Box
            className={
              selected === hall ? classes.selectedInner : classes.defaultInner
            }
          >
            <Typography
              className={clsx(
                classes.text,
                selected === hall ? classes.selectedText : classes.defaultText,
              )}
            >
              {hall}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default React.memo(Filters)
