import { Avatar, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Gift from 'components/richman/Gift'
import React, { useMemo } from 'react'
import { withProfiler } from '@sentry/react'
import { truncate } from 'lodash'

const useStyles = makeStyles((theme) => ({
  systemMsg: {
    color: 'gold',
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    fontWeight: 500,
    lineHeight: 1.5,
  },
  msgContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '400px',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
  container: {
    // backgroundColor: 'rgba(0, 0, 0, 0.3)',
    // borderRadius: 40,
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  giftContainer: {
    background:
      'linear-gradient(90deg, rgba(251, 188, 95, 0.5) 0%, rgba(242, 207, 88, 0) 100%)',
    width: '100%',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'gold',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  username: {
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    fontWeight: 500,
  },
  body: {
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    fontWeight: 500,
    color: 'white',
  },
}))

const Message = ({ msg }) => {
  const classes = useStyles()

  const isSystem = useMemo(() => {
    return msg.type === 'Message::System'
  }, [msg])

  const isGift = useMemo(() => {
    return msg.type === 'Message::Gift'
  }, [msg])

  const isJackpot = useMemo(() => {
    return msg.type === 'Message::Jackpot'
  }, [msg])

  const isJoin = useMemo(() => {
    return msg.type === 'Message::Join'
  }, [msg])

  if (isJackpot) return null

  return (
    <Box className={classes.msgContainer}>
      <Box className={isGift ? classes.giftContainer : classes.container}>
        {(isSystem || isJoin) && (
          <Box display="flex">
            <Box className={classes.systemMsg}>{msg.body}</Box>
          </Box>
        )}
        {!(isSystem || isJoin) && !isGift && (
          <Box display="flex">
            <Box>
              <Typography className={classes.username}>
                {msg.nickname}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Typography className={classes.body}>
                  {truncate(msg.body, { length: 40, omission: '...' })}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isGift && (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" color="primary.main">
              <Box mr={1}>
                <Gift gift={msg.gift} size={24} />
              </Box>
              <Typography className={classes.body}>{msg.body}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(Message))
