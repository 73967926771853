import React, { useCallback } from 'react'

import SEND from 'graphql/application/mutations/createChatroomMessage'
import useStreamer from 'hooks/useStreamer'
import GiftIcon from 'images/richman/streamers/gift.png'
import { useForm } from 'react-hook-form'

import { useMutation } from '@apollo/client'
import { generateUUIDv4 } from '@bitjourney/uuid-v4'
import { makeStyles } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import SendIcon from '@material-ui/icons/Send'
import { useTranslation } from 'react-i18next'

import useGifts from './useGifts'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    padding: theme.spacing(0.25),
    paddingBottom: 'env(safe-area-inset-bottom)',
    display: 'flex',
    gap: theme.spacing(0.25),
    alignItems: 'center',
  },
  button: {
    margin: 0,
    padding: theme.spacing(0.25),
    border: 0,
    background: 'transparent',
    appearance: 'none',
    '&:disabled': {
      opacity: 50,
    },
  },
  input: {
    flexGrow: 1,
    appearance: 'none',
    border: '1px solid black',
    borderRadius: '1rem',
    padding: theme.spacing(0.5, 1),
  },
  gift: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

export default function ChatBox() {
  const styles = useStyles()
  const [streamer] = useStreamer()
  const { handleSubmit, reset, register } = useForm({
    defaultValues: { body: '' },
  })
  const [send] = useMutation(SEND)
  const [, { toggle }] = useGifts()
  const { t } = useTranslation()

  const sendMessage = useCallback(
    async (values) => {
      if (values.body !== '') {
        const uuid = generateUUIDv4()
        const result = await send({
          variables: {
            input: {
              channelType: 'Streamer',
              channelId: streamer.id,
              body: values.body,
              uuid,
            },
          },
        })
        reset()
      }
    },
    [streamer, send, reset],
  )

  return (
    <form className={styles.root} onSubmit={handleSubmit(sendMessage)}>
      <button type="button" className={styles.button} onClick={toggle}>
        <img src={GiftIcon} className={styles.gift} />
      </button>
      <button type="button" className={styles.button}>
        <ChatIcon />
      </button>
      <input
        className={styles.input}
        name="body"
        placeholder={t('common.inputMessage')}
        ref={register()}
      />
      <button
        type="button"
        onClick={handleSubmit(sendMessage)}
        className={styles.button}
      >
        <SendIcon />
      </button>
    </form>
  )
}
