import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'

import useWallet from 'hooks/useWallet'

const useStyles = makeStyles((theme) => ({
  root: {},
  balance: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 300,
    leadingTrim: 'both',
    textEdge: 'cap',
    letterSpacing: '0.1em',
    color: theme.palette.primary.main,
  },
}))

const UserBalance = ({}) => {
  const classes = useStyles()
  const [{ balance }] = useWallet()

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography className={classes.balance}>
        {numeral(balance).format('$0,0.00')}
      </Typography>
    </Box>
  )
}

export default React.memo(UserBalance)
