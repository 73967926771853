import React, { useCallback } from 'react'

import clsx from 'clsx'
import { chips } from 'helpers/bet'
import useSelectedChip from 'hooks/useSelectedChip'

import { Box, makeStyles } from '@material-ui/core'

import ToggleCommissionFree from './ToggleCommissionFree'

const chipImages: Record<number, any> = chips.reduce(
  (images, chip) => ({
    ...images,
    [chip]: require(`images/chips/richman/${chip}.png`),
  }),
  {},
)

const selectedChipImages: Record<number, any> = chips.reduce(
  (images, chip) => ({
    ...images,
    [chip]: require(`images/chips/richman/A_${chip}.png`),
  }),
  {},
)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(8),
    overflowX: 'auto',
    position: 'relative',
  },
  chip: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5),
    transform: 'translateY(0)',
    transition: 'transform 0.2s',
  },
  chipImage: {
    width: '100%',
    height: 'auto',
  },
  selectedChip: {
    transform: 'translateY(-15%)',
    transition: 'transform 0.2s',
  },
}))

function Chip({ chip }) {
  const styles = useStyles()
  const [selectedChip, select] = useSelectedChip()

  const selectCurrentChip = useCallback(() => {
    select(chip)
  }, [select, chip])

  return (
    <Box className={styles.chip} onClick={selectCurrentChip}>
      <img
        src={
          selectedChip === chip ? selectedChipImages[chip] : chipImages[chip]
        }
        className={clsx(
          styles.chipImage,
          selectedChip === chip ? styles.selectedChip : null,
        )}
      />
    </Box>
  )
}

function ChipSelector() {
  const styles = useStyles()

  return (
    <Box className={styles.root}>
      {chips.map((chip) => (
        <Chip key={chip} chip={chip} />
      ))}
      <Box className={styles.chip}>
        <ToggleCommissionFree />
      </Box>
    </Box>
  )
}

export default ChipSelector
