import React, { useMemo } from 'react'

import useStreamer from 'hooks/useStreamer'
import { getRTCHost } from 'libs/settings'
import { useMeasure } from 'react-use'

import { Box, CircularProgress, makeStyles } from '@material-ui/core'

import RTCPlayer from './RTCPlayer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: 'black',
  },
}))

export default function RoomVideo() {
  const styles = useStyles()
  const [{ boundRoom }] = useStreamer()
  const url = `${getRTCHost()}${boundRoom.streamName}/${
    boundRoom.streamKey
  }.sdp`
  const [ref, { width }] = useMeasure()

  const height = useMemo(() => {
    return (width / 16) * 4.5
  }, [width])

  return (
    <Box className={styles.root} {...{ ref }} style={{ height }}>
      <RTCPlayer
        url={url}
        alwaysMute
        style={{ transform: 'translateY(-40%)', zIndex: 1000, height: 'auto' }}
      />
    </Box>
  )
}
