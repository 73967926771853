import React, { createContext, useCallback, useContext } from 'react'

import baccaratGameFragments from 'graphql/application/fragments/baccaratGameFragments'
import baccaratRoomFragments from 'graphql/application/fragments/baccaratRoomFragments'
import currentBaccaratBetRecordFragments from 'graphql/application/fragments/currentBaccaratBetRecordFragments'
import delay from 'helpers/delay'

import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress } from '@material-ui/core'

import { CurrentRoomProvider } from './useCurrentRoom'

const StreamerContext = createContext(null)

const QUERY = gql`
  query streamer($id: ID!) {
    streamer(id: $id) {
      id
      nickname
      avatar
      winRate
      online
      likesCount
      isLiked
      lineLink
      stream {
        name
        key
      }
      boundRoom {
        id
        ...baccaratRoomFragments
        currentGame {
          id
          ...baccaratGameFragments
        }
        currentBaccaratBetRecord {
          id
          ...currentBaccaratBetRecordFragments
        }
      }
    }
  }
  ${baccaratRoomFragments}
  ${baccaratGameFragments}
  ${currentBaccaratBetRecordFragments}
`

export function StreamerProvider({ id, children }) {
  const { data, updateQuery, refetch } = useQuery(QUERY, { variables: { id } })

  const update = useCallback(
    async (partial) => {
      await delay(2000)
      if (partial.action === 'refetch') {
        refetch()
      } else {
        updateQuery((data) => ({ streamer: { ...data.streamer, ...partial } }))
      }
    },
    [updateQuery],
  )

  if (!data) {
    return <CircularProgress />
  }

  return (
    <StreamerContext.Provider value={[data.streamer, { update }]}>
      <CurrentRoomProvider
        room={data.streamer.boundRoom}
        key={data.streamer.boundRoom.id}
      >
        {children}
      </CurrentRoomProvider>
    </StreamerContext.Provider>
  )
}

export default function useStreamer() {
  return useContext(StreamerContext)
}
