import React from 'react'

import Card from 'components/richman/Card'
import { useProfileSettings } from 'hooks/useProfileSettings'

import { useGameContent, useGameStatus } from '../../GameProvider'

const ThirdCard = ({ target }) => {
  const { open } = useGameStatus()
  const {
    settings: { soundOn },
  } = useProfileSettings()
  const content = useGameContent()
  const cards = content[`${target}Cards`]

  if (cards?.length !== 3) return null

  return (
    <div className="flex justify-center -m-1">
      <div className="w-1/4 rotate-90">
        <Card selected card={cards[2]} on soundOn={soundOn && open} />
      </div>
    </div>
  )
}

export default React.memo(ThirdCard)
