import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { useChatroom } from './ChatroomProvider'

const useStyles = makeStyles((theme) => ({
  container: {
    justifySelf: 'flex-end',
  },
  badges: {
    display: 'flex',
    gap: '0.5rem',
    padding: theme.spacing(1),
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(27, 51, 89, 0.5)',
    borderRadius: '1rem',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: 'white',
  },
}))

export default function Info() {
  const styles = useStyles()
  const { usersCount } = useChatroom()

  return (
    <Box className={styles.container}>
      <Box className={styles.badges}>
        <Box className={styles.badge}>
          <GroupIcon style={{ marginRight: 8 }} /> {usersCount}
        </Box>
      </Box>
    </Box>
  )
}
