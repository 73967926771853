import React from 'react'

import useLeftBetTime from 'hooks/useLeftBetTime'
import { useGameStatus } from 'screens/richman/BaccaratRooms/GameProvider'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useStreamState } from '../index'
import Counting from './Counting'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '20%',
    right: '5%',
  },
}))

const CountingBlock = ({}) => {
  const {
    room,
    room: { waitingBetSeconds },
  } = useStreamState()
  const classes = useStyles()
  const left = useLeftBetTime(room)
  const gameStatus = useGameStatus()

  if (gameStatus.status !== 'START_BET') {
    return null
  }

  return (
    <Box className={classes.root}>
      {left && <Counting left={left} waitingBetSeconds={waitingBetSeconds} />}
    </Box>
  )
}

export default React.memo(CountingBlock)
