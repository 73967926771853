import React, { createContext, useCallback, useContext, useState } from 'react'

import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const Connecting = createContext(null)

const Provider = ({ children }) => {
  const [isConnecting, setConnecting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const online = useCallback(() => {
    setConnecting(true)
  }, [setConnecting])

  const offline = useCallback(() => {
    setConnecting(false)
    enqueueSnackbar(t('common.connectionError'), {
      variant: 'error',
    })
  }, [setConnecting, enqueueSnackbar])

  return (
    <Connecting.Provider value={isConnecting}>
      <ActionCableConsumer
        channel={{ channel: 'PingChannel' }}
        onConnected={online}
        onDisconnected={offline}
      />
      {children}
    </Connecting.Provider>
  )
}

export const ConnectingProvider = React.memo(Provider)

export default function useConnecting() {
  return useContext(Connecting)
}
