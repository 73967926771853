import React, { createContext, useCallback, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    alignItems: 'flex-start',
  },
  icon: {
    color: '#000',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  cancelBtn: {
    backgroundColor: '#D6D6D6',
    color: '#fff',
  },
  title: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'bold',
    color: '#1E202C',
  },
  actions: {
    backgroundColor: '#fff',
  },
}))

interface IModal {
  modal: React.ReactNode
  confirmAction?: () => void
  status?: 'default' | 'success' | 'error' | 'warning'
  size?: 'sm' | 'md' | 'lg' | 'xl' | false
  title?: string
}

const FullModalContext = createContext(null)

const FullModalProvider = ({ children }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useImmer(false)
  const [content, setContent] = useImmer<IModal>({
    modal: null,
    confirmAction: null,
    status: 'default',
    size: 'sm',
    title: '',
  })

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const toggleModal = useCallback(() => {
    setOpen((draft) => !draft)
  }, [])

  const setModal = useCallback(
    ({
      modal,
      status = 'default',
      confirmAction = null,
      size = 'sm',
      title = '',
    }) => {
      setContent((draft) => {
        draft.modal = modal
        draft.status = status
        draft.confirmAction = confirmAction
        draft.size = size
        draft.title = title
      })
      setOpen(true)
    },
    [setContent],
  )

  const value = useMemo(() => {
    return {
      open,
      content,
      toggleModal,
      setModal,
    }
  }, [open, content])

  const titleBar = React.useMemo(
    () => (
      <Box className={classes.titleBar}>
        <Box className={classes.title}>{content.title}</Box>
        <IconButton onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </Box>
    ),
    [onClose, content.title],
  )

  return (
    <FullModalContext.Provider value={value}>
      {children}
      <Dialog fullWidth maxWidth={content.size} open={open} onClose={onClose}>
        <DialogContent className={classes.content}>
          {titleBar}
          <Box width="100%" color="#000">
            {content.modal}
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} className={classes.cancelBtn}>
            {t('common.cancel')}
          </Button>
          {content.confirmAction && content.confirmAction}
        </DialogActions>
      </Dialog>
    </FullModalContext.Provider>
  )
}

export const useFullModal = () => useContext(FullModalContext)

export default React.memo(FullModalProvider)
