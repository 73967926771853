import React from 'react'

import IdleTimes from 'components/richman/IdleTimes'
import useBaccaratRoom from 'hooks/useBaccaratRoom'
import { BetStateProvider } from 'hooks/useBetState'
import { ConnectedRoomsProvider } from 'hooks/useConnectedRooms'
import { CurrentRoomProvider } from 'hooks/useCurrentRoom'
import { SelectedChipProvider } from 'hooks/useSelectedChip'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import CurrentBetProvider from '../BaccaratRooms/BetProvider/CurrentBetProvider'
import GameProvider from '../BaccaratRooms/GameProvider'
import GameRoom from '../BaccaratRooms/GameRoom'

// import LoadingScreen from './LoadingScreen'

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

const BaccaratRooms = () => {
  const params = useParams()
  const { data, nonce } = useBaccaratRoom(params.id)
  const classes = useStyles()

  if (!data) {
    return (
      <Box className={classes.loading}>
        <CircularProgress style={{ color: 'gold' }} />
      </Box>
    )
  }

  const room = data.baccaratRoom

  return (
    <ConnectedRoomsProvider>
      <CurrentRoomProvider room={room} key={nonce}>
        <BetStateProvider>
          <CurrentBetProvider>
            <GameProvider>
              <SelectedChipProvider>
                <IdleTimes />
                <GameRoom />
              </SelectedChipProvider>
            </GameProvider>
          </CurrentBetProvider>
        </BetStateProvider>
      </CurrentRoomProvider>
    </ConnectedRoomsProvider>
  )
}

export default withProfiler(React.memo(BaccaratRooms))
