import React, { useCallback, useEffect, useRef, useState } from 'react'

import Terms from 'components/richman/Terms'
import useAllBaccarats from 'hooks/useAllBaccarats'
import { ConnectedRoomsProvider } from 'hooks/useConnectedRooms'
import { CurrentRoomProvider } from 'hooks/useCurrentRoom'
import { concat, uniq } from 'lodash'
import { useImmer } from 'use-immer'

import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import Filters from './Filters'
import InstallNotice from './InstallNotice'
import LoadingScreen from './LoadingScreen'
import Room from './Room'
import SignUp from './SignUp'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2.5),
    position: 'relative',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

const BaccaratRooms = ({}) => {
  const { rooms, loading, nonce } = useAllBaccarats()
  const [ready, setReady] = useImmer(false)
  const classes = useStyles()
  const ref = useRef(null)
  const [selected, setSelected] = useState('所有廳房')

  const playMusic = () => {
    ref.current.play()
  }

  useEffect(() => {
    document.addEventListener('click', playMusic)
    return () => {
      document.removeEventListener('click', playMusic)
    }
  }, [ref])

  const setLoaded = useCallback(() => setReady(true), [setReady])

  const halls = concat('所有廳房', uniq(rooms?.map((room) => room.hall.name)))

  const filtered =
    selected === '所有廳房'
      ? rooms
      : rooms?.filter((room) => room.hall.name === selected)

  return (
    <ConnectedRoomsProvider>
      {!ready && <LoadingScreen onClose={setLoaded} />}
      {ready && <Terms />}
      {ready && <InstallNotice />}
      <Box className={classes.root} key={nonce}>
        {loading && (
          <Box className={classes.loading}>
            <CircularProgress style={{ color: 'gold' }} />
          </Box>
        )}
        {filtered && (
          <Filters
            halls={halls}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        <div>
          {filtered &&
            filtered.map((room) => (
              <CurrentRoomProvider key={room.id} room={room}>
                <Room />
              </CurrentRoomProvider>
            ))}
        </div>
        <SignUp />
      </Box>
      <audio
        muted
        loop
        autoPlay
        ref={ref}
        src="https://assets.vvip88.net/audios/10-seconds-of-silence.mp3"
      ></audio>
    </ConnectedRoomsProvider>
  )
}

export default withProfiler(React.memo(BaccaratRooms))
