import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGameData } from '../GameProvider'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
  },
  basic: {
    color: '#ffffff',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    lineHeight: 1,
    marginRight: theme.spacing(1),
  },
  dealer: {
    background: 'linear-gradient(136.01deg, #FF6767 0%, #8C0000 83.28%)',
  },
  player: {
    background: 'linear-gradient(136.01deg, #6792FF 0%, #00168C 96.19%)',
  },
  tie: {
    background: 'linear-gradient(136.01deg, #1BB50E 0%, #0A4700 85.22%)',
  },
  total: {
    background:
      'linear-gradient(136.01deg, #DBC44C 11.62%, #AF4A00 37.44%, #6F4200 85.22%)',
  },
  countItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
}))

const RoomHint = ({}) => {
  const classes = useStyles()
  const { roads } = useGameData()
  const { t } = useTranslation()

  return useMemo(() => {
    return (
      <Box
        flex={2}
        display="flex"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box className={classes.countItem}>
          <Box className={clsx(classes.basic, classes.dealer)}>
            {t('common.simpleDealer')}
          </Box>
          <Box className={classes.value}>{roads?.dealer_count || 0}</Box>
        </Box>
        <Box className={classes.countItem}>
          <Box className={clsx(classes.basic, classes.player)}>
            {t('common.simplePlayer')}
          </Box>
          <Box className={classes.value}>{roads?.player_count || 0}</Box>
        </Box>
        <Box className={classes.countItem}>
          <Box className={clsx(classes.basic, classes.tie)}>
            {t('common.simpleTie')}
          </Box>
          <Box className={classes.value}>{roads?.tie_count || 0}</Box>
        </Box>
        <Box className={classes.countItem}>
          <Box className={clsx(classes.basic, classes.total)}>
            {t('common.simpleTotal')}
          </Box>
          <Box className={classes.value}>{roads?.total_count || 0}</Box>
        </Box>
      </Box>
    )
  }, [roads])
}

export default React.memo(RoomHint)
