import React, { useCallback } from 'react'

import clsx from 'clsx'
import useStreamer from 'hooks/useStreamer'

import { gql, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useNotification } from '../BaccaratRooms/GameRoom/NotificationProvider'
import { useTranslation } from 'react-i18next'

import LIKE from 'images/richman/streamers/like.png'
import UNLIKE from 'images/richman/streamers/unlike.png'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 32,
    height: 32,
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    marginLeft: -2,
  },
  inactive: {
    filter: 'grayscale(1)',
  },
}))

const LIKE_STREAMER = gql`
  mutation likeStreamer($streamerId: ID!) {
    likeStreamer(input: { streamerId: $streamerId }) {
      streamer {
        id
        likesCount
        isLiked
      }
    }
  }
`

const UNLIKE_STREAMER = gql`
  mutation unlikeStreamer($streamerId: ID!) {
    unlikeStreamer(input: { streamerId: $streamerId }) {
      streamer {
        id
        likesCount
        isLiked
      }
    }
  }
`

export default function LikeButton() {
  const styles = useStyles()
  const [streamer] = useStreamer()
  const { setNotification } = useNotification()
  const { t } = useTranslation()

  const [likeStreamer] = useMutation(LIKE_STREAMER)
  const [unlikeStreamer] = useMutation(UNLIKE_STREAMER)
  const {
    profile: { isTrial },
  } = useProfile()

  const handleClick = useCallback(async () => {
    if (isTrial) {
      setNotification(t('common.disabledForTrial'))
    } else {
      if (streamer.isLiked) {
        unlikeStreamer({ variables: { streamerId: streamer.id } })
      } else {
        likeStreamer({ variables: { streamerId: streamer.id } })
      }
    }
  }, [streamer.isLiked, streamer.id, likeStreamer, unlikeStreamer, isTrial])

  return (
    <img
      src={streamer.isLiked ? LIKE : UNLIKE}
      className={clsx(styles.icon, { [styles.inactive]: !streamer.isLiked })}
      onClick={handleClick}
    />
  )
}
