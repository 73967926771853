import React, { useState } from 'react'

import { useSiteSettings } from 'providers/richman/SiteSettingsProvider'
import Ticker from 'react-ticker'

import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  ticker: {
    background: 'transparent',
    flexGrow: 1,
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  tickerText: {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
}))

const RoomText = ({}) => {
  const classes = useStyles()
  const { roomText } = useSiteSettings()

  return (
    <Box className={classes.ticker}>
      <Ticker direction="toLeft" speed={1} height={50}>
        {() => (
          <Typography className={classes.tickerText}>{roomText}</Typography>
        )}
      </Ticker>
    </Box>
  )
}

export default React.memo(RoomText)
