import React, { useState } from 'react'

import GET_RECORDS from 'graphql/application/queries/getRecords'
import { moneyFormat } from 'helpers/index'
import useWallet from 'hooks/useWallet'
import { filter } from 'lodash'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'

import { useQuery } from '@apollo/client'
import { Box, Tab, Tabs } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import ListData from './ListData'
import TabPanel from './TabPanel'

const useStyles = makeStyles((theme) => ({
  wallet: {
    fontSize: theme.typography.pxToRem(15),
  },
  balance: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.primary.main,
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    marginBottom: theme.spacing(2),
  },
  tab: {
    minWidth: 0,
    flex: 1,
    color: theme.palette.gold.main,
  },
}))

const Records: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const [page, setPage] = useState(1)

  const { data, loading, error, refetch } = useQuery(GET_RECORDS, {
    variables: {
      page,
      perPage: 50,
      type: null,
    },
  })
  const [{ balance }] = useWallet()

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  return (
    <Box>
      <Box p={2} pb={0}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="white"
        >
          <Box className={classes.wallet}>{t('screens.records.balance')}</Box>
          <Box className={classes.balance}>
            {balance && moneyFormat(balance)}
          </Box>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          scrollButtons="auto"
          // textColor="primary"
          // variant="fullWidth"
        >
          <Tab className={classes.tab} label={t('screens.records.all')} />
          <Tab className={classes.tab} label={t('screens.records.bet')} />
          {/* <Tab className={classes.tab} label={t('screens.records.dealer')} /> */}
          <Tab className={classes.tab} label={t('screens.records.gift')} />
          {/* <Tab className={classes.tab} label={t('screens.records.charge')} /> */}
          {/* <Tab className={classes.tab} label={t('screens.records.return')} /> */}
        </Tabs>
      </Box>
      <Box className={classes.divider} />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {data && <ListData records={data.myRecords.records} />}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {data && (
            <ListData
              records={filter(data.myRecords.records, { __typename: 'Bet' })}
            />
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          {data && (
            <ListData
              records={filter(data.myRecords.records, { __typename: 'Dealer' })}
            />
          )}
        </TabPanel> */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          {data && (
            <ListData
              records={filter(data.myRecords.records, {
                __typename: 'SendGift',
              })}
            />
          )}
        </TabPanel>
        {/* <TabPanel value={value} index={4} dir={theme.direction}>
          {data && (
            <ListData
              records={filter(data.myRecords.records, { __typename: 'Charge' })}
            />
          )}
        </TabPanel> */}
        {/* <TabPanel value={value} index={4} dir={theme.direction}>
          {data && (
            <ListData
              records={filter(data.myRecords.records, { __typename: 'Return' })}
            />
          )}
        </TabPanel> */}
      </SwipeableViews>
    </Box>
  )
}
export default withProfiler(React.memo(Records))
