import React, { useMemo } from 'react'

import Prediction from 'components/richman/Prediction'
import { useMeasure } from 'react-use'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 1%',
    background: 'white',
    flexDirection: 'column',
    height: '100%',
  },
  player: {
    color: '#0679EC',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dealer: {
    color: '#E40F0F',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  playerAsk: {
    color: '#0679EC',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    writingMode: 'vertical-lr',
    marginBottom: theme.spacing(0.5),
  },
  dealerAsk: {
    color: '#E40F0F',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    writingMode: 'vertical-lr',
    marginBottom: theme.spacing(0.5),
  },
  tie: {
    color: '#12E500',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    color: 'black',
  },
}))

const SimpleHintRoad = ({ data }) => {
  const classes = useStyles()
  const [ref, { height }] = useMeasure()
  const { t } = useTranslation()

  const fontSize = useMemo(() => {
    return `${height * 0.07}px`
  }, [height])

  return (
    <Box {...{ ref }} className={classes.root} style={{ fontSize }}>
      <Box className={classes.box}>
        <Box className={classes.dealerAsk}>{t('common.dealerAsk')}</Box>
        <Prediction data={data} side="dealer" road="big_eye" />
        <Prediction data={data} side="dealer" road="small" />
        <Prediction data={data} side="dealer" road="cockroach" />
      </Box>
      <Box className={classes.box}>
        <Box className={classes.playerAsk}>{t('common.playerAsk')}</Box>
        <Prediction data={data} side="player" road="big_eye" />
        <Prediction data={data} side="player" road="small" />
        <Prediction data={data} side="player" road="cockroach" />
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(SimpleHintRoad))
