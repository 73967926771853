import React, { useState, useCallback, useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useSnackbar } from 'notistack'
import LIKE from 'images/icons/like.png'
import LIKED from 'images/icons/liked.png'

const QUERY = gql`
  query getGirl($id: ID!) {
    girl(id: $id) {
      id
      name
      avatar
      bust
      waist
      hips
      height
      weight
      constellation
      hobby
      likesCount
      liked
      workingStartAtInHuman
      workingEndAtInHuman
      hall {
        id
        name
      }
    }
  }
`

const LIKE_MUTATION = gql`
  mutation like($girlId: ID!) {
    likeGirl(input: { girlId: $girlId }) {
      girl {
        id
        likesCount
        liked
      }
    }
  }
`

const UNLIKE_MUTATION = gql`
  mutation unlike($girlId: ID!) {
    unlikeGirl(input: { girlId: $girlId }) {
      girl {
        id
        likesCount
        liked
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
    position: 'relative',
  },
  girl: {},
  likesCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  likesImg: {
    width: 24,
    height: 24,
  },
  likesCountText: {
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.spacing(1),
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(32),
    },
  },
  girlAvatar: {
    border: '1.14721px solid #FFFFFF',
    boxShadow:
      'inset 5px 5px 8px 5px rgba(0, 28, 61, 0.87), inset -4px -4px 15px 5px rgba(255, 238, 149, 0.88)',
    position: 'relative',
  },
  girlAvatarImg: {
    width: '100%',
    height: 'auto',
  },
  likeButton: {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    padding: theme.spacing(1),
  },
  likeButtonIcon: {
    width: 70,
    height: 70,
  },
  girlInfo: {
    marginTop: theme.spacing(0.5),
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    mixBlendMode: 'normal',
    boxShadow: 'inset 6px 8px 9px #07102C, inset -6px -6px 7px #173C87',
    padding: theme.spacing(3.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    flexGrow: 1,
    flexBasis: 0,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexBasis: 0,
  },
  hallAndTime: {
    display: 'flex',
    alignItems: 'flex-end',
    color: '#D6D6D6',
  },
  girlName: {
    fontSize: theme.typography.pxToRem(16),
    color: '#D6D6D6',
    letterSpacing: '1em',
    marginRight: '-1em',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  normal: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: '1em',
    marginRight: '-1em',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  normalWithSmallSpacing: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: '0.11em',
    marginRight: '-0.1em',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  time: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(8),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  measurements: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  constellation: {
    width: '100%',
    textAlign: 'justify',
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(1),
    '&::after': {
      content: '" "',
      display: 'inline-block',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  heightAndWeight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  end: {
    textAlign: 'right',
  },
}))

const Girl = ({}) => {
  const classes = useStyles()
  const { id } = useParams()
  const { data, loading } = useQuery(QUERY, {
    variables: { id },
  })
  const [liked, setLiked] = useState(false)
  const [like] = useMutation(LIKE_MUTATION, {
    variables: { girlId: id },
  })
  const [unlike] = useMutation(UNLIKE_MUTATION, {
    variables: { girlId: id },
  })

  const {
    profile: { isTrial },
  } = useProfile()
  const { enqueueSnackbar } = useSnackbar()

  const handleLike = useCallback(async () => {
    if (isTrial) {
      enqueueSnackbar('試玩玩家無法追蹤荷官', { variant: 'error' })
    } else {
      if (liked) {
        setLiked(false)
        await unlike()
      } else {
        setLiked(true)
        await like()
      }
    }
  }, [liked, setLiked, unlike, like, isTrial])

  useEffect(() => {
    if (data) {
      setLiked(data.girl.liked)
    }
  }, [data])

  return (
    <Box className={classes.root}>
      {loading && <CircularProgress />}
      {data && (
        <Box className={classes.girl}>
          <Box className={classes.likesCount}>
            <Typography className={classes.likesCountText}>
              {data.girl.likesCount}
            </Typography>
            <img src={LIKED} className={classes.likesImg} alt="likes" />
          </Box>
          <Box className={classes.girlAvatar}>
            <img
              src={data.girl.avatar}
              alt={data.girl.name}
              className={classes.girlAvatarImg}
            />
            <IconButton onClick={handleLike} className={classes.likeButton}>
              <img
                src={liked ? LIKED : LIKE}
                className={classes.likeButtonIcon}
                alt="like"
              />
            </IconButton>
          </Box>
          <Box className={classes.girlInfo}>
            <Box className={classes.left}>
              <Typography variant="h1" className={classes.girlName}>
                {data.girl.name}
              </Typography>
              <Box className={classes.hallAndTime}>
                <Typography className={classes.normal}>
                  {data.girl.hall?.name}
                </Typography>
                <span className={classes.time}>
                  {data.girl.workingStartAtInHuman}-
                  {data.girl.workingEndAtInHuman}
                </span>
              </Box>
            </Box>
            <Box className={classes.right}>
              <Box className={classes.measurements} marginBottom={1}>
                <Typography className={classes.normalWithSmallSpacing}>
                  {data.girl.bust}
                </Typography>
                <Typography className={classes.normalWithSmallSpacing}>
                  {data.girl.waist}
                </Typography>
                <Typography
                  className={clsx(classes.normalWithSmallSpacing, classes.end)}
                >
                  {data.girl.hips}
                </Typography>
              </Box>
              <Typography className={classes.constellation}>
                {data.girl.constellation}
              </Typography>
              <Box className={classes.heightAndWeight}>
                <Typography className={classes.normal}>
                  {data.girl.height}
                </Typography>
                <Typography
                  className={clsx(classes.normalWithSmallSpacing, classes.end)}
                >
                  {data.girl.weight}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(Girl)
