import React, { useMemo } from 'react'

import clsx from 'clsx'
import { useSettings } from 'providers/richman/AppProvider'
import { useTranslation } from 'react-i18next'
import { useGameContent } from 'screens/richman/BaccaratRooms/GameProvider'

import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  betName: {
    fontSize: theme.typography.pxToRem(20),
  },
  betRate: {
    fontSize: theme.typography.pxToRem(16),
    color: 'white',
  },
  bet: {
    flex: 1,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.gold.main}`,
    marginLeft: '0.5%',
    marginRight: '0.5%',
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  betWin: {
    '&:after': {
      opacity: 0,
      content: '" "',
      width: '100%',
      height: '100%',
      position: 'absolute',
      borderRadius: 4,
      top: 0,
      left: 0,
      background:
        'radial-gradient(58.64% 122.69% at 57.04% 50.49%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.6) 100%)',
    },
  },
  blueFont: {
    color: '#00a4f5',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
  yellowFont: {
    color: '#fff602',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
  redFont: {
    color: '#d41411',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
}))

const BetBlock = ({ target, rate }) => {
  const { t } = useTranslation()
  const { win } = useGameContent()
  const classes = useStyles()

  const colorClass = useMemo(() => {
    if (target.indexOf('player') !== -1) {
      return classes.blueFont
    } else if (target.indexOf('dealer') !== -1) {
      return classes.redFont
    } else {
      return classes.yellowFont
    }
  }, [target])

  return (
    <Box className={clsx(classes.bet, win[target] ? classes.betWin : null)}>
      <Box className={clsx(colorClass, classes.betName)}>
        {t(`screens.baccaratRoom.${target}`)}
      </Box>
      <Box className={clsx(classes.betRate)}>x{rate}</Box>
    </Box>
  )
}

export default React.memo(BetBlock)
