import React from 'react'
import { Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { timeFormat, moneyFormat } from 'helpers/index'
import CopyBtn from 'components/application/CopyBtn'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  normalBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255, 255, 255, 0.5)',
  },
  bet: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
  },
  channel: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.info.main,
  },
}))

const ChargeRecord = ({ record }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.paper}>
      <Box p={2} color="white">
        <Box className={classes.titleBlock}>
          <Box fontSize={18}>{t('screens.records.charge')}</Box>
          <Box className={classes.bet}>{moneyFormat(record.info.amount)}</Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{timeFormat(record.createdAt)}</Box>
          <Box fontSize={15}>{moneyFormat(record.info.amount)}</Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box className={classes.subTitle}>{t('screens.records.time')}</Box>
          <Box className={classes.subTitle}>{t('screens.records.amount')}</Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{record.info.charge_no}</Box>
          <Box className={classes.channel}>
            {t(`screens.records.gateway.${record.info.channel}`)}
          </Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box display="flex" alignItems="center" className={classes.subTitle}>
            {t('screens.records.trade')}ID
            <CopyBtn text={record.info.bet_no} />
          </Box>
          <Box className={classes.subTitle}>{t('screens.records.channel')}</Box>
        </Box>
      </Box>
    </Paper>
  )
}
export default withProfiler(React.memo(ChargeRecord))
