import React, { useMemo, useRef, useState } from 'react'

import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'
import useNoLoadingScreen from 'hooks/useNoLoadingScreen'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AwsS3 } from 'uppy'

import { useMutation } from '@apollo/client'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Toolbar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import Uppy from '@uppy/core'
import ZH_CN from '@uppy/locales/lib/zh_CN'
import { DashboardModal } from '@uppy/react'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

const useStyles = makeStyles((theme) => ({
  appbar: {
    // background: 'linear-gradient(to right, #061f36, #0d4274, #061f36)',
  },
  disalbed: {
    color: `rgba(${theme.palette.common.white}, 0.6)`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
  cancel: {
    fontSize: theme.typography.pxToRem(16),
    color: '#1f8bf0',
  },
  title: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    color: 'white',
    flexGrow: 1,
  },
  save: {
    fontSize: theme.typography.pxToRem(16),
    // background: 'linear-gradient(to bottom, #0d69bf, #063e72)',
    color: 'white',
    // borderRadius: 30,
    width: 200,
    padding: theme.spacing(1, 2),
  },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  editAvatar: {
    fontSize: theme.typography.pxToRem(13),
    color: '#1f8bf0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  label: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(1),
    // background: '#0c2844',
    // borderRadius: 30,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  hidden: {
    display: 'none',
  },
}))

const EditProfile = ({ open, onClose }) => {
  const fileInput = useRef(null)
  const classes = useStyles()
  const { profile } = useProfile()
  const [avatar, setAvatar] = useState(null)
  const [preview, setPreview] = useState(profile.avatar)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const { handleSubmit, register, errors, setError } = useForm({
    ...profile,
  })

  const [update] = useMutation(UPDATE_PROFILE)

  const uppy = useMemo(() => {
    return Uppy({
      meta: { type: 'avatar' },
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true,
      locale: ZH_CN,
    })
      .use(AwsS3, {
        companionUrl: '/',
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        waitForThumbnailsBeforeUpload: false,
      })
      .on('thumbnail:generated', (file, previewUrl) => {
        setPreview(previewUrl)
      })
      .on('upload-success', (file, response) => {
        const uploadedFileData = {
          id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        }
        handleUploadClose()
        setAvatar(uploadedFileData)
      })
  }, [])

  const onUpdateProfile = async (data) => {
    const result = await update({
      variables: {
        input: {
          ...data,
          username: profile.username,
          nickname: profile.nickname,
          avatar: JSON.stringify(avatar),
        },
      },
    })

    const { errors } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((error) =>
        setError(error.attribute, {
          type: 'manual',
          message: error.message,
        }),
      )
    } else {
      onClose && onClose()
    }
  }

  const onSubmit = async (data) => {
    const { username, oldPassword, newPassword, confirmNewPassword } = data
    if (newPassword !== '' || confirmNewPassword !== '') {
      if (newPassword !== confirmNewPassword) {
        setError('newPassword', {
          type: 'manual',
          message: t('screens.profile.passwordNotTheSame'),
        })
        setError('confirmNewPassword', {
          type: 'manual',
          message: t('screens.profile.passwordNotTheSame'),
        })
      } else {
        await onUpdateProfile(data)
      }
    } else {
      await onUpdateProfile(data)
    }
  }

  const triggerFileUpload = () => {
    fileInput.current.click()
  }

  const openUppy = () => {
    setModalOpen(true)
  }

  const handleUploadClose = () => {
    setModalOpen(false)
  }

  useNoLoadingScreen()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          background: 'linear-gradient(to top, #061e36, #020c15)',
        },
      }}
    >
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Button className={classes.cancel} onClick={onClose}>
            {t('screens.profile.cancel')}
          </Button>
          <Box className={classes.title}>
            {t('screens.profile.editProfile')}
          </Box>
          <Button
            color="primary"
            style={{ visibility: 'hidden' }}
            onClick={handleSubmit(onSubmit)}
          >
            {t('screens.profile.save')}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.root}>
        <Box className={classes.formGroup}>
          <Box className={classes.label}>
            {t('screens.profile.editPassword')}
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.oldPassword')}
            name="oldPassword"
            type="password"
            error={!!errors.oldPassword}
            helperText={errors.oldPassword?.message}
            inputRef={register}
          />
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.newPassword')}
            name="newPassword"
            type="password"
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            inputRef={register}
          />
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.confirmNewPassword')}
            name="confirmNewPassword"
            type="password"
            error={!!errors.confirmNewPassword}
            helperText={errors.confirmNewPassword?.message}
            inputRef={register}
          />
        </Box>
        <Button
          color="secondary"
          variant="contained"
          className={classes.save}
          onClick={handleSubmit(onSubmit)}
        >
          {t('screens.profile.save')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
export default withProfiler(React.memo(EditProfile))
