import { gql } from '@apollo/client'

export default gql`
  mutation cancelBaccaratBet($input: CancelBaccaratBetInput!) {
    cancelBaccaratBet(input: $input) {
      errors {
        attribute
        message
      }
      wallet {
        id
        balance
        creditBalance
        totalEffectiveAmount
        totalEffectiveXimaAmount
      }
    }
  }
`
