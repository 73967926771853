import { useCallback } from 'react'

import gql from 'graphql-tag'

import { useMutation, useQuery } from '@apollo/client'

const QUERY = gql`
  query isCommissionFree {
    profile {
      id
      isCommissionFree
    }
  }
`

const MUTATION = gql`
  mutation updateCommissionFree($value: Boolean!) {
    updateCommissionFree(input: { value: $value }) {
      profile {
        id
        isCommissionFree
      }
    }
  }
`

export default function useCommissionFree() {
  const { data } = useQuery(QUERY)
  const [mutate] = useMutation(MUTATION)

  const isCommissionFree =
    (data && data.profile && data.profile.isCommissionFree) || false

  const gameType = isCommissionFree ? 'commission_free' : 'normal'

  const update = useCallback(
    (isCommissionFree) => {
      mutate({
        variables: { value: isCommissionFree },
      })
    },
    [mutate],
  )

  return {
    isCommissionFree,
    gameType,
    update,
  }
}
