import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  zhFont: {
    fontSize: theme.typography.pxToRem(16),
  },
  otherFont: {
    fontSize: theme.typography.pxToRem(12),
  },
  customSize: (props: { size?: number } = {}) => ({
    fontSize: theme.typography.pxToRem(props.size),
  }),
}))

interface options {
  zhSize?: number
  otherSize?: number
}

const useFontSizeByLang = (options: options = {}) => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const style = useStyles({})

  const zhFontSize = options.zhSize
    ? useStyles({ size: options.zhSize }).customSize
    : style.zhFont
  const otherFontSize = options.otherSize
    ? useStyles({ size: options.otherSize }).customSize
    : style.otherFont

  return ['zhTW', 'zhCN'].includes(i18n.language) ? zhFontSize : otherFontSize
}

export default useFontSizeByLang
