import React, { useEffect } from 'react'

import useBetState from 'hooks/useBetState'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useTranslation } from 'react-i18next'

import { withProfiler } from '@sentry/react'

import { useGameContent, useGameStatus } from '../../GameProvider'
import { useNotification } from '../NotificationProvider'

const Notification = () => {
  const { isOpen } = useCurrentRoom()
  const { setNotification } = useNotification()
  const { t } = useTranslation()
  const { status, shuffle, initial } = useGameStatus()
  const { playerNeedAnother, dealerNeedAnother } = useGameContent()
  const [, { start }] = useBetState()

  useEffect(() => {
    if (status == null || shuffle == null) return

    if (!isOpen) return

    if (shuffle && status === 'MAINTAIN') {
      setNotification(t('status.baccaratRichman.maintain'))
    } else if (status === 'SOS') {
      setNotification(t(`status.baccarat.${status}`))
    } else if (shuffle && status !== 'SOS_RECOVER') {
      setNotification(t('status.baccaratRichman.shuffle'))
      start()
    } else if (shuffle && status === 'SOS_RECOVER') {
      setNotification(t(`status.baccarat.${status}`))
      start()
    } else if (
      ['PLAYER_DRAW_THIRD', 'DEALER_DRAW_THIRD'].indexOf(status) !== -1
    ) {
      return
    } else if (status === 'DRAW_CONFIRMED' && playerNeedAnother) {
      setNotification(
        t('status.baccaratRichman.player_third_opening_confirmed'),
      )
    } else if (status === 'DRAW_CONFIRMED' && dealerNeedAnother) {
      setNotification(
        t('status.baccaratRichman.dealer_third_opening_confirmed'),
      )
    } else if (
      status === 'DEALER_DRAW_THIRD' ||
      status === 'PLAYER_DRAW_THIRD'
    ) {
      return
    } else if (status === 'DRAW_CONFIRMED') {
      return
    } else if (status === 'PLAYER_DRAW_THIRD_CONFIRMED' && dealerNeedAnother) {
      setNotification(
        t('status.baccaratRichman.dealer_third_opening_confirmed'),
      )
    } else if (status.indexOf('CONFIRMED') !== -1) {
      return
    } else {
      setNotification(t(`status.baccarat.${status}`))
    }
  }, [status, shuffle, dealerNeedAnother, playerNeedAnother, isOpen, initial])

  return <></>
}

export default withProfiler(React.memo(Notification))
