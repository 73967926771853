import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMeasure } from 'react-use'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: '#f4ecd0',
    justifyContent: 'space-between',
    padding: '0 1%',
  },
  player: {
    color: theme.palette.player.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  dealer: {
    color: theme.palette.dealer.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  value: {
    color: 'black',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
  },
  playerAsk: {
    background: theme.palette.player.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  dealerAsk: {
    background: theme.palette.dealer.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(16),
  },
  bigEyeDealer: {
    border: `2px solid ${theme.palette.dealer.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  bigEyePlayer: {
    border: `2px solid ${theme.palette.player.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: theme.palette.dealer.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: theme.palette.player.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.dealer.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.player.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const HintRoad = ({ board }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [ref, { width }] = useMeasure()

  const fontSize = useMemo(() => {
    return `${width * 0.033}px`
  }, [width])

  return (
    <Box {...{ ref }} className={classes.root}>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          莊
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.dealerCount}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.player}>
          閒
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.playerCount}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.tie}>
          和
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.tieCount}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          莊對
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.dealerPairCount}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.player}>
          閒對
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.playerPairCount}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          幸運六
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.super6Count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          總數
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {board.totalCount}
        </Typography>
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(HintRoad))
