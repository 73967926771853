import React, { useState, useCallback } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import { useTranslation } from 'react-i18next'
import MUSIC from 'images/icons/header/sound.png'
import Settings from './Settings'

const useStyles = makeStyles((theme) => ({
  root: {},
  musicIcon: {
    width: 18,
    height: 18,
  },
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
}))

const SoundSettingButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  const onOpen = () => {
    onCloseAnchor()
    setOpen(true)
  }
  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <Button
        color="primary"
        className={buttonClass}
        startIcon={<img src={MUSIC} className={classes.musicIcon} />}
        onClick={onOpen}
        fullWidth
      >
        {t('components.header.soundSetting')}
      </Button>
      <Settings open={open} onClose={onClose} />
    </>
  )
}

export default React.memo(SoundSettingButton)
