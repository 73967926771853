import React from 'react'

import useBetTarget from 'hooks/useBetTarget'
import { useNotification } from 'screens/richman/BaccaratRooms/GameRoom/NotificationProvider'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Chips from './Chips'

const useStyles = makeStyles((theme) => ({
  root: {},
  coverBet: {
    height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}))

const BetTarget = ({ target, style = {} }) => {
  const classes = useStyles()
  const notification = useNotification()
  const { disabled, onBet } = useBetTarget({
    target,
    notification,
  })

  return (
    <Box className={classes.coverBet} style={{ ...style }} onClick={onBet}>
      <Chips player={target} />
    </Box>
  )
}

export default React.memo(BetTarget)
