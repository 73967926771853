import React from 'react'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useStreamApi, useStreamState } from '../index'

import VIDEO_ON from 'images/icons/video_on.png'
import VIDEO_OFF from 'images/icons/video_off.png'

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0,
    transform: 'scale(0)',
  },
  openRoot: {
    marginRight: theme.spacing(1),
    background: '#424242',
    opacity: 1,
    transition:
      'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s',
  },
  image: {
    width: 20,
    height: 20,
  },
}))

const ToggleVideoButton = ({ open, onClose }) => {
  const classes = useStyles()
  const { dispatch } = useStreamApi()
  const {
    state: { videoOn },
  } = useStreamState()

  const onClick = () => {
    onClose()
    dispatch({ type: 'TOGGLE_VIDEO' })
  }

  return (
    <IconButton
      onClick={onClick}
      className={open ? classes.openRoot : classes.root}
      style={{ transitionDelay: open ? '0ms' : '30ms' }}
    >
      <img src={videoOn ? VIDEO_ON : VIDEO_OFF} className={classes.image} />
    </IconButton>
  )
}

export default React.memo(ToggleVideoButton)
