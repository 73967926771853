import React from 'react'

import Logo from 'images/richman/logo.png'
import { useRouteMatch } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import MenuList from './MenuList'
import ReloadButton from './ReloadButton'
import UserBalance from './UserBalance'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    height: 56,
  },
  logo: {
    width: '85%',
    height: 'auto',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))

const Header = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const isLobby = path === '/' || path === '/list'

  return (
    <Box
      className={classes.root}
      style={{ justifyContent: isLobby ? 'space-between' : 'center' }}
    >
      {isLobby && <UserBalance />}
      <Box className={classes.logoContainer}>
        <img className={classes.logo} src={Logo} />
      </Box>
      <Box
        flex={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* <ReloadButton /> */}
        <MenuList />
      </Box>
    </Box>
  )
}

export default Header
