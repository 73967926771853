import { useCallback, useMemo } from 'react'

import { audios } from 'helpers/richman_assets'
import { useProfileSettings } from 'hooks/useProfileSettings'
import { Howl } from 'howler'
import { useGameStatus } from 'screens/richman/BaccaratRooms/GameProvider'

import useBetState from './useBetState'
import useSelectedChip from './useSelectedChip'
import useWallet from './useWallet'
import useXianHong from './useXianHong'

export default function useBetTarget({ target, notification }) {
  const [state, { add }] = useBetState()
  const { status, shuffle } = useGameStatus()
  const xianHong = useXianHong()
  const { setNotification } = notification
  const [{ balance }] = useWallet()
  const [chip] = useSelectedChip()
  const {
    settings: { soundOn },
  } = useProfileSettings()

  const targetState = state[target]
  const additional =
    state.big.additional +
    state.small.additional +
    state.dealer.additional +
    state.player.additional +
    state.dealerPair.additional +
    state.playerPair.additional +
    state.tie.additional +
    state.big.additional +
    state.small.additional +
    state.super6.additional +
    state.dealerDragon.additional +
    state.playerDragon.additional +
    state.dealerDouble.additional +
    state.playerDouble.additional +
    state.dealerSingle.additional +
    state.playerSingle.additional +
    state.perfectPair.additional +
    state.anyPair.additional +
    state.dealerKing.additional +
    state.playerKing.additional +
    state.superTie0.additional +
    state.superTie1.additional +
    state.superTie2.additional +
    state.superTie3.additional +
    state.superTie4.additional +
    state.superTie5.additional +
    state.superTie6.additional +
    state.superTie7.additional +
    state.superTie8.additional +
    state.superTie9.additional

  const singleBetLeft = useMemo(() => {
    if (xianHong) {
      return xianHong[target].max - targetState.confirmed
    } else {
      0
    }
  }, [xianHong, target, state])

  const disabled = state.disabled || status !== 'START_BET' || shuffle

  const insufficientBalance = balance < additional + chip

  const onBet = useCallback(() => {
    if (insufficientBalance) {
      setNotification('餘額不足')
      return
    }
    if (singleBetLeft < chip) {
      setNotification(`超出個人限紅 ${xianHong[target].max}`)
      return
    }
    if (disabled) {
      console.warn('bet disabled', {
        'state.disabled': state.disabled,
        status,
        shuffle,
      })
      return
    }
    if (['waiting_for_bet', 'START_BET'].indexOf(status) === -1) {
      setNotification('尚未開始下注')
      return
    }
    console.info('add bet to target', target, chip)
    if (soundOn) {
      let chipSound = new Howl({ src: [audios.chip], autoPlay: true })
      chipSound.play()
    }
    add(target, chip)
  }, [
    insufficientBalance,
    singleBetLeft,
    chip,
    disabled,
    status,
    setNotification,
    add,
    target,
  ])

  return { disabled, onBet }
}
