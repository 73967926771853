import React, { useEffect, useState } from 'react'

import CustomBeadRoad from 'components/richman/NewRoad/CustomBeadRoad'
import useCurrentRoom from 'hooks/useCurrentRoom'
import useMobile from 'hooks/useMobile'
import { useSiteConfig } from 'providers/common/SiteConfig'
import { useSettings } from 'providers/richman/AppProvider'
import { useMeasure } from 'react-use'
import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useImmer } from 'use-immer'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withProfiler } from '@sentry/react'

import { useGameData } from '../../GameProvider'
import Chatroom from './Chatroom'
import EmptyHintRoad from './EmptyHintRoad'
import Gifts from './Gifts'
import HintRoad from './HintRoad'
import SimpleHintRoad from './SimpleHintRoad'

SwiperCore.use([Controller])

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    background: '#236851',
  },
  roadContainer: {
    border: '1px solid black',
    width: '97%',
  },
  container: {
    position: 'relative',
    width: '100%',
    background: 'white',
    display: 'flex',
  },
  leftRoad: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexWrap: 'wrap',
  },
  box: {
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  right: {
    // overflow: 'hidden',
    // overflowX: 'scroll',
  },
  player: {
    background: theme.palette.player.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  dealer: {
    background: theme.palette.dealer.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  tie: {
    background: '#00B7BA',
    borderRadius: 999,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
  absoluteHistory: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  settings: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    position: 'relative',
    height: '100%',
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingButton: {
    // height: 16,
    // padding: theme.spacing(0.5, 1),
    // border: `2px solid ${theme.palette.primary.main}`,
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: '5%',
    padding: 0,
    zIndex: 1,
    transform: 'rotate(0deg)',
    transition: '0.5s',
  },
  iconButtonDown: {
    position: 'absolute',
    top: 0,
    right: '5%',
    padding: 0,
    zIndex: 1,
    transform: 'rotate(180deg)',
    transition: '0.5s',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  icon: {
    fontSize: 50,
  },
}))

const grid = {
  rows: 6,
  columns: 18,
}

const Road = ({ noChatroom = false }) => {
  const {
    room: { roads },
  } = useCurrentRoom()
  const classes = useStyles()
  const { roads: gameRoads, roadsImage } = useGameData()
  const [inner, setInner] = useImmer(roads)
  const [swiper, setSwiper] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const {
    settings: { appWidth },
  } = useSettings()

  // const height = isMobile ? ((window.innerWidth * 0.93) / 348) * 139 :
  const height = ((appWidth * 0.93) / 348) * 139

  useEffect(() => {
    if (gameRoads) {
      setInner(gameRoads)
    }
  }, [gameRoads])

  const onClick = () => {
    if (activePage === 1) {
      swiper.slideTo(0)
      setActivePage(0)
    } else {
      swiper.slideTo(1)
      setActivePage(1)
    }
  }

  return (
    <div className={classes.root}>
      <Box width="100%">
        <Box className={classes.container}>
          <Box
            display="flex"
            alignItems="stretch"
            height={height}
            width="100%"
            position="relative"
          >
            <Swiper
              direction={'vertical'}
              initialSlide={activePage}
              onSwiper={setSwiper}
              resistanceRatio={0}
              height={height}
              className={classes.roadContainer}
            >
              <SwiperSlide>
                <Box height={height} bgcolor="white">
                  <CustomBeadRoad beadRoad={inner?.bead_road} columns={15} />
                </Box>
              </SwiperSlide>
              <SwiperSlide>
                <Box height={height} bgcolor="white">
                  <img
                    src={`data:image/png;base64,${roadsImage}`}
                    className={classes.img}
                  />
                </Box>
              </SwiperSlide>
            </Swiper>
            <Box width="7%">
              {inner ? <SimpleHintRoad data={inner} /> : <EmptyHintRoad />}
            </Box>
            <IconButton
              className={
                activePage === 1 ? classes.iconButton : classes.iconButtonDown
              }
              onClick={onClick}
            >
              <ExpandMoreIcon color="primary" className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        {inner ? <HintRoad data={inner} /> : <EmptyHintRoad />}
      </Box>
      <Gifts />
      {!noChatroom && <Chatroom />}
    </div>
  )
}

export default withProfiler(React.memo(Road))
