import { useEffect, useState } from 'react'

const harmonicTimeout = {
  timeout: null,
  callbacks: [],

  addCallback(callback) {
    this.callbacks.push(callback)
    this.startTimeout()
  },

  removeCallback(callback) {
    this.callbacks = this.callbacks.filter((c) => c != callback)
  },

  startTimeout() {
    if (this.timeout) {
      return
    }
    this.timeout = setTimeout(() => {
      for (const callback of this.callbacks) {
        callback()
      }
      this.callbacks = []
      this.timeout = null
    }, 100)
  },
}

export default function useHarmonicDebounce<T>(value: T) {
  const [state, setState] = useState<T>(value)

  useEffect(() => {
    const callback = () => {
      setState(value)
    }
    harmonicTimeout.addCallback(callback)

    return () => {
      harmonicTimeout.removeCallback(callback)
    }
  }, [value])

  return state
}
