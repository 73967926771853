import React from 'react'
import { Box } from '@material-ui/core'
import { withProfiler } from '@sentry/react'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ height: '1000px' }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
export default withProfiler(React.memo(TabPanel))
