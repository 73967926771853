import React, { useCallback, useState } from 'react'

import useCancelBet from 'hooks/useCancelBet'
import CANCEL_BET from 'images/richman/cancel_bet.png'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useFullModal } from 'hooks/useFullModal'

import { useNotification } from './NotificationProvider'

import CANCEL_BTN from 'images/richman/cancel_btn.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

const CancelButton = ({}) => {
  const classes = useStyles()
  const notification = useNotification()
  const [cancelBet, { loading }] = useCancelBet({ notification })
  const { setModal, toggleModal } = useFullModal()
  const { t } = useTranslation()

  const onCancel = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      cancelBet()
      toggleModal()
    },
    [cancelBet],
  )

  const onOpen = () => {
    setModal({
      modal: <span />,
      title: t('common.confirmCancel'),
      confirmAction: <Button onClick={onCancel}>{t('common.confirm')}</Button>,
    })
  }

  return (
    <button className={classes.button} onClick={onOpen}>
      <img src={CANCEL_BTN} className={classes.image} />
    </button>
  )
}

export default React.memo(CancelButton)
