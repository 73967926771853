import React, { useMemo } from 'react'

import { moneyFormatWithoutSymbol } from 'helpers/index'
import useBetState from 'hooks/useBetState'
import { useTranslation } from 'react-i18next'

import { Box, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(10),
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  player: {
    color: theme.palette.common.white,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    lineHeight: 1,
    marginRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25),
    },
    background: 'linear-gradient(136.01deg, #6792FF 0%, #00168C 96.19%)',
  },
  dealer: {
    color: theme.palette.common.white,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    lineHeight: 1,
    marginRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25),
    },
    background: 'linear-gradient(136.01deg, #FF6767 0%, #8C0000 83.28%)',
  },
  value: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25),
    },
  },
  item: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
  },
}))

function CurrentBet() {
  const styles = useStyles()
  const [state] = useBetState()
  const { t } = useTranslation()

  return (
    <Box className={styles.root}>
      <Box className={styles.item}>
        <Box className={styles.player}>{t('common.player')}</Box>
        <Box className={styles.value}>
          {moneyFormatWithoutSymbol(state.player.confirmed || 0, false)}
        </Box>
      </Box>
      <Box className={styles.item}>
        <Box className={styles.dealer}>{t('common.dealer')}</Box>
        <Box className={styles.value}>
          {moneyFormatWithoutSymbol(state.dealer.confirmed || 0, false)}
        </Box>
      </Box>
    </Box>
  )
}

export default CurrentBet
