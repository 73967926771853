import React, { useCallback, useMemo } from 'react'

import useBetTarget from 'hooks/useBetTarget'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useMeasure } from 'react-use'

import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Chips from 'screens/richman/BaccaratRooms/GameRoom/BetPanel/Chips'
import { useNotification } from './NotificationProvider'
import GradientBox from 'components/common/GradientBox'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'rgba(247, 247, 247, 0.28)',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.76)',
    borderRadius: theme.spacing(0.5),
  },
  text: {
    fontSize: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '150%',
    },
  },
}))

const BetTarget = ({ target, children }) => {
  const classes = useStyles()
  const { room } = useCurrentRoom()

  const notification = useNotification()
  const { disabled, onBet } = useBetTarget({ target, notification })

  const bet = useCallback(() => {
    if (disabled) {
      return
    }
    onBet()
    window.dispatchEvent(new CustomEvent('clear-jackpot', { detail: room }))
  }, [onBet, room])

  return (
    <Box
      className={classes.root}
      style={{ opacity: disabled ? '50%' : '100%' }}
      onClick={bet}
    >
      {children}
      <Chips player={target} />
    </Box>
  )
}

export default React.memo(BetTarget)
