import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import clsx from 'clsx'
import UPDATE_SETTINGS from 'graphql/application/mutations/updateSettings'
import { chips } from 'helpers/bet'
import useSelectedChip from 'hooks/useSelectedChip'
import { useMeasure } from 'react-use'
import styled from 'styled-components'

import { useMutation } from '@apollo/client'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ToggleCommissionFree from './ToggleCommissionFree'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
  },
  fullHeight: {
    height: '100%',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    background: '#0D1E4A',
    // minHeight: 50,
    // [theme.breakpoints.up('sm')]: {
    //   minHeight: 96,
    // },
  },
  chipsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    background: '#236851',
    overflowY: 'scroll',
  },
  chip: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5),
    transform: 'translateY(0)',
    transition: 'transform 0.2s',
  },
  grid: {
    paddingBottom: '0 !important',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  selectedChip: {
    transform: 'translateY(-15%)',
    transition: 'transform 0.2s',
  },
}))

const ChipSelector = () => {
  const classes = useStyles()
  const [selectedChip, select] = useSelectedChip()

  const setChip = (chip) => {
    select(chip)
  }

  return (
    <Box className={classes.root}>
      {chips.map((chip) => (
        <Box className={classes.chip} key={chip} onClick={() => setChip(chip)}>
          <img
            src={
              selectedChip === chip
                ? require(`images/chips/richman/A_${chip}.png`)
                : require(`images/chips/richman/${chip}.png`)
            }
            className={clsx(
              classes.img,
              selectedChip === chip ? classes.selectedChip : null,
            )}
          />
        </Box>
      ))}
      <Box className={classes.chip} key="toggler">
        <ToggleCommissionFree />
      </Box>
    </Box>
  )
}

export default ChipSelector
