import React, { createContext, useContext } from 'react'

import useCurrentRoom from 'hooks/useCurrentRoom'
import useRememberPlayer from 'hooks/useRememberPlayer'
import { useImmerReducer } from 'use-immer'

const StreamApiContext = createContext(null)
const StreamStateContext = createContext(null)
const StreamWidthContext = createContext(null)

function createInitialState(room) {
  const [player] = useRememberPlayer(room)
  return {
    player: player || room.defaultPlayer,
    enlarge: false,
    videoOn: true,
    active: 0,
    room,
  }
}

const reducer = (draft, action) => {
  switch (action.type) {
    case 'TOGGLE_STREAM':
      if (draft.active + 1 === 2) {
        draft.active = 0
      } else {
        draft.active = draft.active + 1
      }
      break
    case 'TOGGLE_VIDEO':
      draft.videoOn = !draft.videoOn
      break
    case 'CHANGE_PLAYER':
      draft.player = action.player
      break
    case 'TOGGLE_ENLARGE':
      draft.enlarge = !draft.enlarge
      break
  }
}

const Stream = ({ children, width = null }) => {
  const { room } = useCurrentRoom()
  const [state, dispatch] = useImmerReducer(reducer, createInitialState(room))

  return (
    <StreamApiContext.Provider value={{ dispatch }}>
      <StreamStateContext.Provider value={{ state, room, width }}>
        <StreamWidthContext.Provider value={{ width }}>
          {children}
        </StreamWidthContext.Provider>
      </StreamStateContext.Provider>
    </StreamApiContext.Provider>
  )
}

export const useStreamApi = () => useContext(StreamApiContext)
export const useStreamState = () => useContext(StreamStateContext)
export const useStreamWidth = () => useContext(StreamWidthContext)

export default React.memo(Stream)
