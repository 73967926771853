import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Ticker from 'react-ticker'
import { useSiteSettings } from 'providers/richman/SiteSettingsProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: '5%',
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    color: 'white',
    height: '10%',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tickerText: {
    fontSize: theme.typography.pxToRem(48),
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: 'black',
  },
}))

const RoomTicker = ({}) => {
  const classes = useStyles()
  const { roomText } = useSiteSettings()

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {roomText && (
          <Ticker direction="toLeft" speed={1}>
            {() => (
              <Typography className={classes.tickerText}>{roomText}</Typography>
            )}
          </Ticker>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(RoomTicker)
