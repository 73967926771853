import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useGameData } from '../../../GameProvider'
import { useSiteSettings } from 'providers/richman/SiteSettingsProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  connected: {
    width: 15,
    height: 15,
    background: theme.palette.success.main,
    borderRadius: 999,
    position: 'absolute',
    top: '50%',
    right: '5%',
  },
  disconnected: {
    width: 15,
    height: 15,
    background: theme.palette.error.main,
    borderRadius: 999,
    position: 'absolute',
    top: '50%',
    right: '5%',
  },
}))

const SocketStatus = ({}) => {
  const classes = useStyles()
  const {
    profile: { beta },
  } = useProfile()
  const { socketConnected } = useGameData()
  const { vConsole } = useSiteSettings()

  const showConsole = () => vConsole.show()

  return useMemo(() => {
    return (
      <Box
        // onClick={showConsole}
        hidden={false}
        className={socketConnected ? classes.connected : classes.disconnected}
      />
    )
  }, [socketConnected, beta])
}

export default React.memo(SocketStatus)
