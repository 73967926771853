import React from 'react'

import useBlockchainInfo from 'hooks/useBlockchainInfo'
import useCurrentRoom from 'hooks/useCurrentRoom'

import { CircularProgress } from '@material-ui/core'

import Card from './Card'

export default function Cards({ onCardClick }) {
  const { room } = useCurrentRoom()
  const info = useBlockchainInfo()

  if (!info) {
    return <CircularProgress />
  }

  return (
    <div className="flex flex-wrap justify-center gap-1 overflow-y-auto flex-1">
      {info.currentShoe.cards.map((card) => (
        <Card card={card} onClick={() => onCardClick(card)} key={card.height} />
      ))}
    </div>
  )
}
