import React, { createContext, useContext, useCallback } from 'react'
import { useImmer } from 'use-immer'

const FullModalContext = createContext(null)

const FullModal = ({ children }) => {
  const [open, setOpen] = useImmer(false)
  const [content, setContent] = useImmer(null)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const toggleModal = useCallback(() => {
    setOpen((draft) => !draft)
  }, [])

  const setModal = useCallback((modal) => {
    setContent(modal)
    setOpen(true)
  }, [])

  return (
    <FullModalContext.Provider value={{ open, content, toggleModal, setModal }}>
      {children}
    </FullModalContext.Provider>
  )
}

export const useFullModal = () => useContext(FullModalContext)

export default React.memo(FullModal)
