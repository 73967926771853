import React, { useCallback, useEffect, useRef, useState } from 'react'

import { request } from 'http'
import { useEvent, useMeasure } from 'react-use'

import { makeStyles } from '@material-ui/core/styles'

const ratio = 430 / 740 // 最佳顯示比例
const fatRatio = 414 / 736 // iPhone 8 Plus 胖手機

// 0.68430657
// 0.5625

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modal: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    width: 480,
  },
}))

export default function Modal({ children, onClose = null }) {
  const styles = useStyles()
  const ref = useRef<HTMLDivElement>()

  const [roomStyle, setRoomStyle] = useState({
    transform: null,
    transformOrigin: null,
  })

  const resizeToFit = useCallback(() => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const screenRatio = windowWidth / windowHeight
    const roomWidth = ref.current.clientWidth
    const roomHeight = ref.current.clientHeight
    const roomRatio = roomWidth / roomHeight

    const scale =
      screenRatio > roomRatio
        ? windowHeight / roomHeight
        : windowWidth / roomWidth
    const transformOrigin = scale < 480 ? 'left top' : 'center top'

    setRoomStyle({
      transform: `scale(${scale})`,
      transformOrigin,
    })
  }, [ref])

  useEffect(() => {
    requestAnimationFrame(resizeToFit)
  }, [ref])
  useEvent('resize', resizeToFit, window)

  return (
    <div className={styles.root}>
      <div className={styles.overlay}></div>
      <div {...{ ref }} className={styles.modal} style={roomStyle}>
        {children}
      </div>
    </div>
  )
}
