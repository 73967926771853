import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import EditNickname from './EditNickname'
import EditPassword from './EditPassword'
import DragonSetting from './DragonSetting'
import LogoutButton from './LogoutButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2.5),
  },
}))

const Actions = ({}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={3}>
        <EditNickname />
      </Grid>
      <Grid item xs={3}>
        <EditPassword />
      </Grid>
      <Grid item xs={3}>
        <DragonSetting />
      </Grid>
      <Grid item xs={3}>
        <LogoutButton />
      </Grid>
    </Grid>
  )
}

export default React.memo(Actions)
