import React, { useMemo } from 'react'

import clsx from 'clsx'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useGameContent } from '../../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  win: {
    position: 'absolute',
    top: '0',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
}))

const Win = ({ target }) => {
  const classes = useStyles()
  const { win } = useGameContent()

  if (!win[target]) return null

  const style = target === 'player' ? { right: '-13%' } : { left: '-20%' }

  return (
    <Box
      className={clsx(
        classes.win,
        'z-10 animate__animated animate__tada animate__infinite',
      )}
      style={style}
    >
      <img src={require('images/richman/win.png')} className={classes.img} />
    </Box>
  )
}

export default React.memo(Win)
