import React, { useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RepeatButton from './RepeatButton'
import CancelButton from './CancelButton'
import ConfirmButton from './ConfirmButton'
import TransferButton from './TransferButton'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1% 2%',
    background: '#0D1E4A',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
}))

const Buttons = ({}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <RepeatButton />
      <CancelButton />
      <ConfirmButton />
      <TransferButton />
    </Box>
  )
}

export default React.memo(Buttons)
