import React, { useCallback, useState } from 'react'

import Terms from 'components/richman/Terms'
import { ConnectedRoomsProvider } from 'hooks/useConnectedRooms'
import { SelectedChipProvider } from 'hooks/useSelectedChip'
import { SelectedRoomProvider } from 'hooks/useSelectedRoom'

import { withProfiler } from '@sentry/react'

import InstallNotice from '../BaccaratRooms/InstallNotice'
import LoadingScreen from '../BaccaratRooms/LoadingScreen'
import ListNotificationProvider from './ListNotificationProvider'
import Rooms from './Rooms'

function BaccaratRoomsList() {
  const [ready, setReady] = useState(false)

  const setLoaded = useCallback(() => setReady(true), [setReady])

  return (
    <ConnectedRoomsProvider>
      <SelectedChipProvider>
        <SelectedRoomProvider>
          <ListNotificationProvider>
            {!ready && <LoadingScreen onClose={setLoaded} />}
            {ready && <Terms />}
            {ready && <InstallNotice />}
            <Rooms />
          </ListNotificationProvider>
        </SelectedRoomProvider>
      </SelectedChipProvider>
    </ConnectedRoomsProvider>
  )
}

export default withProfiler(React.memo(BaccaratRoomsList))
