import React, { useCallback, useState } from 'react'

import clsx from 'clsx'
import SEND_GIFT from 'graphql/application/mutations/sendBaccaratGift'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import useCurrentRoom from 'hooks/useCurrentRoom'
import useWallet from 'hooks/useWallet'
import customImage from 'images/Gifts/custom.png'
import giftList from 'libs/giftList'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'

import { useMutation } from '@apollo/client'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useGameApi, useGameData } from '../../../GameProvider'
import { useNotification } from '../../NotificationProvider'
import CustomGiftModal from './CustomGiftModal'
import useSendCustomGift from './useSendCustomGift'

const useStyles = makeStyles((theme) => ({
  giftValue: {
    fontSize: theme.typography.pxToRem(14),
    color: 'white',
  },
  img: {
    width: '40%',
    height: 'auto',
  },
}))

const Gifts = () => {
  const { room } = useCurrentRoom()
  const classes = useStyles()
  const { giftOpen } = useGameData()
  const { dispatch } = useGameApi()
  const [sendGift] = useMutation(SEND_GIFT)
  const { setNotification } = useNotification()
  const [{ balance }] = useWallet()
  const { t } = useTranslation()

  const [customGiftOpening, setCustomGiftOpening] = useState(false)

  const openCustomGift = () => setCustomGiftOpening(true)
  const closeCustomGift = () => setCustomGiftOpening(false)

  const sendCustomGift = useSendCustomGift()

  const onSend = useCallback(
    async (gift) => {
      if (balance >= gift.value) {
        const result = await sendGift({
          variables: {
            input: {
              baccaratRoomId: room.id,
              gift: gift.name,
            },
          },
        })

        const { errors } = result.data.sendBaccaratGift
        if (errors.length > 0) {
          errors.map((error) => setNotification(error.message))
        } else {
          setNotification(t('common.giftSent'))
          dispatch({ type: 'TOGGLE_GIFT' })
        }
      } else {
        setNotification(
          t('common.insufficientBalance', {
            balance: moneyFormatWithoutSymbol(balance),
          }),
        )
      }
    },
    [balance, sendGift, setNotification, dispatch],
  )

  return (
    <Box
      className={clsx(
        'absolute inset-0 z-10 bg-black bg-opacity-75 flex flex-wrap',
        {
          hidden: !giftOpen,
        },
      )}
    >
      {giftList
        .filter((gift) => gift.game)
        .map((gift) => (
          <Box
            key={gift.name}
            className="basis-1/4 flex flex-col items-center justify-center"
            onClick={() => onSend(gift)}
          >
            <img src={gift.image} className={classes.img} />
            <Box className={classes.giftValue}>
              {moneyFormatWithoutSymbol(gift.value, false)}
            </Box>
          </Box>
        ))}

      <Box
        key="custom"
        className="basis-1/4 flex flex-col items-center justify-center"
        onClick={openCustomGift}
      >
        <img src={customImage} className={classes.img} />
        <Box className={classes.giftValue}>自由打賞</Box>
      </Box>

      {customGiftOpening && (
        <CustomGiftModal onSubmit={sendCustomGift} onClose={closeCustomGift} />
      )}
    </Box>
  )
}

export default React.memo(Gifts)
