import React, { useEffect, useState } from 'react'

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  time: {
    fontSize: theme.typography.pxToRem(16),
  },
}))

const Time = ({ remainingTime }) => {
  const classes = useStyles()

  return <Box className={classes.time}>{remainingTime}</Box>
}

export default function Countdown({ left }) {
  const [remain, setRemain] = useState(left)

  useEffect(() => {
    if (remain == null) {
      setRemain(left)
    }
  }, [remain, setRemain, left])

  return (
    <CountdownCircleTimer
      key={remain}
      isPlaying
      duration={remain || 0}
      trailColor="rgba(0, 0, 0, 0.5)"
      strokeWidth={2}
      colors={[
        ['#01bd7b', 0.33],
        ['#F7B801', 0.33],
        ['#A30000', 0.33],
      ]}
      size={32}
    >
      {Time}
    </CountdownCircleTimer>
  )
}
