import React, { createContext, useCallback, useContext, useEffect } from 'react'

import delay from 'helpers/delay'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'

import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const useStyles = makeStyles((theme) => ({}))

const GameContext = createContext(null)

const translate = (status: string) => {
  switch (status) {
    case 'waiting_for_bet':
      return 'START_BET'
    case 'opening':
      return 'DRAW'
    case 'opening_confirmed':
      return 'DRAW'
    case 'player_third_opening':
      return 'PLAYER_DRAW_THIRD'
    case 'dealer_third_opening':
      return 'DEALER_DRAW_THIRD'
    case 'dealer_third_opening_confirmed':
      return 'DEALER_DRAW_THIRD_CONFIRMED'
    case 'player_third_opening_confirmed':
      return 'PLAYER_DRAW_THIRD_CONFIRMED'
    case 'closed':
      return 'CLOSE'
    case 'maintain':
      return 'MAINTAIN'
    case 'sos':
      return 'SOS'
    case 'sos_recover':
      return 'SOS_RECOVER'
  }
}

const reducer = (draft, action) => {
  switch (action.type) {
    case 'START_BET':
      draft.status = 'START_BET'
      draft.shuffle = false
      draft.gameNo = action.gameNo
      draft.playerCards = null
      draft.dealerCards = null
      draft.points = {
        dealer: null,
        player: null,
      }
      draft.win = {
        player: null,
        dealer: null,
        playerPair: null,
        dealerPair: null,
        tie: null,
      }
      draft.playerNeedAnother = null
      draft.dealerNeedAnother = null
      draft.shuffle = false
      draft.amount = {
        dealer: 0,
        player: 0,
        dealerPair: 0,
        playerPair: 0,
        tie: 0,
      }
      draft.initial = false
      break
    case 'STOP_BET':
      draft.status = 'STOP_BET'
      break
    case 'DRAW':
      draft.status = 'DRAW'
      draft.playerCards = action.playerCards
      draft.dealerCards = action.dealerCards
      draft.points = {
        player: action.playerPoints,
        dealer: action.dealerPoints,
      }
      break
    case 'DRAW_CONFIRMED':
      draft.status = 'DRAW_CONFIRMED'
      draft.playerNeedAnother = action.playerNeedAnother
      draft.dealerNeedAnother = action.dealerNeedAnother
      break
    case 'PLAYER_DRAW_THIRD':
      draft.status = 'PLAYER_DRAW_THIRD'
      draft.playerCards = action.playerCards
      draft.points.player = action.playerPoints
      break
    case 'PLAYER_DRAW_THIRD_CONFIRMED':
      draft.status = 'PLAYER_DRAW_THIRD_CONFIRMED'
      draft.playerNeedAnother = action.playerNeedAnother
      draft.dealerNeedAnother = action.dealerNeedAnother
      break
    case 'DEALER_DRAW_THIRD':
      draft.status = 'DEALER_DRAW_THIRD'
      draft.dealerCards = action.dealerCards
      draft.points.dealer = action.dealerPoints
      break
    case 'DEALER_DRAW_THIRD_CONFIRMED':
      draft.status = 'DEALER_DRAW_THIRD_CONFIRMED'
      draft.playerNeedAnother = action.playerNeedAnother
      draft.dealerNeedAnother = action.dealerNeedAnother
      break
    case 'CLOSE':
      draft.status = 'CLOSE'
      draft.win = {
        player: action.playerWin,
        dealer: action.dealerWin,
        playerPair: action.playerPairWin,
        dealerPair: action.dealerPairWin,
        tie: action.tieWin,
      }
      break
    case 'UPDATE_ROAD_IMAGE':
      draft.roads = action.roads
      draft.roadsImage = action.roadsImage
      break
    case 'UPDATE_AMOUNT':
      draft.amount = {
        player: action.playerAmount,
        dealer: action.dealerAmount,
        playerPair: action.playerPairAmount,
        dealerPair: action.dealerPairAmount,
        tie: action.tieAmount,
      }
      break
    case 'SHUFFLE':
      draft.status = 'SHUFFLE'
      draft.shuffle = true
      draft.roads = action.roads
      draft.roadsImage = action.roadsImage
      break
    case 'SOS':
      draft.status = 'SOS'
      break
    case 'SOS_RECOVER':
      draft.status = 'SOS_RECOVER'
      break
    case 'MAINTAIN':
      draft.status = 'MAINTAIN'
      break
    case 'SET_STATE':
      const { currentGame, roads, currentRoadsImage, alertText } = action
      draft.status = translate(currentGame.status)
      draft.gameNo = currentGame.gameNo
      draft.playerCards = currentGame.playerCards
      draft.dealerCards = currentGame.dealerCards
      draft.playerNeedAnother = currentGame.playerNeedAnother
      draft.dealerNeedAnother = currentGame.dealerNeedAnother
      draft.shuffle = currentGame.shuffle
      draft.roads = roads
      draft.roadsImage = currentRoadsImage
      draft.alertText = alertText
      draft.points = {
        player: currentGame.playerPoints,
        dealer: currentGame.dealerPoints,
      }
      draft.win = {
        player: currentGame.playerWin,
        dealer: currentGame.dealerWin,
        playerPair: currentGame.playerPairWin,
        dealerPair: currentGame.dealerPairWin,
        tie: currentGame.tieWin,
      }
      draft.amount = {
        dealer: currentGame.dealerAmount,
        player: currentGame.playerAmount,
        dealerPair: currentGame.dealerPairAmount,
        playerPair: currentGame.playerPairAmount,
        tie: currentGame.tieAmount,
      }
      break
    case 'ALERT_TEXT':
      draft.alertText = action.alertText
      break
    default:
      console.log('unknown action', action)
  }
}
const GameProvider = ({ room, children, open }) => {
  const classes = useStyles()
  const [state, dispatch] = useImmerReducer(reducer, {
    open,
    initial: true,
    status: null,
    gameNo: null,
    playerCards: [],
    dealerCards: [],
    playerNeedAnother: null,
    dealerNeedAnother: null,
    shuffle: null,
    roads: null,
    roadsImage: null,
    alertText: null,
    points: {
      player: null,
      dealer: null,
    },
    win: {
      player: null,
      dealer: null,
      playerPair: null,
      dealerPair: null,
      tie: null,
    },
    amount: {
      dealer: null,
      player: null,
      dealerPair: null,
      playerPair: null,
      tie: null,
    },
  })

  useEffect(() => {
    dispatch({ type: 'SET_STATE', ...room })
  }, [room])

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const onReceived = useCallback(
    async (response) => {
      await delay(room.latency * 1000)
      const { command } = response
      if (command === 'ABANDON') {
        enqueueSnackbar(t('common.externalError'), { variant: 'error' })
        history.push('/')
      } else {
        dispatch({ type: command, ...response.data })
      }
    },
    [room, history, enqueueSnackbar, t, dispatch],
  )

  return (
    <ActionCableConsumer
      channel={{ channel: 'NewBaccaratGameChannel', roomId: room.id }}
      onReceived={onReceived}
    >
      <GameContext.Provider value={{ ...state }}>
        {children}
      </GameContext.Provider>
    </ActionCableConsumer>
  )
}

export const useGame = () => useContext(GameContext)

export default withProfiler(React.memo(GameProvider))
