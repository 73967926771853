import React, { createContext, useContext, useEffect, useState } from 'react'

import {
  browserName,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  getUA,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect'
import { useOrientation } from 'react-use'

import { withProfiler } from '@sentry/react'

const AppContext = createContext(null)

const ratio = 430 / 740
const fatRatio = 784 / 1180 // iPhone 8 Plus 胖手機

const calcWidth = (width, height) => {
  const screenRatio = width / height
  return screenRatio >= fatRatio ? height * ratio : width
}

const AppProvider = ({ children }) => {
  const { angle } = useOrientation()

  const [settings, setSettings] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    // appWidth: window.innerHeight * ratio,
    appWidth:
      window.innerWidth > 750
        ? calcWidth(window.innerWidth, window.innerHeight)
        : window.innerWidth,
    appHeight: window.innerHeight,
    angle,
    deviceInfo: {
      osVersion,
      osName,
      fullBrowserVersion,
      browserName,
      mobileVendor,
      mobileModel,
      engineName,
      engineVersion,
      deviceType,
      userAgent: getUA,
    },
  })

  useEffect(() => {
    const handleResize = () => {
      const path = window.location.pathname.substring(1)

      const { innerWidth, innerHeight } = window
      const width = Math.min(innerWidth, innerHeight * fatRatio)

      setSettings({
        ...settings,
        screenWidth: innerWidth,
        screenHeight: innerHeight,
        appWidth: width,
        appHeight: innerHeight,
        angle,
      })
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('orientationchange', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <AppContext.Provider
      value={{
        settings,
      }}
    >
      <div
        style={{
          width: settings.appWidth,
          height: settings.appHeight,
          position: 'relative',
          margin: '0 auto',
        }}
      >
        {children}
      </div>
    </AppContext.Provider>
  )
}

export const useSettings = () => useContext(AppContext)

export default withProfiler(React.memo(AppProvider))
