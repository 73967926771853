import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WHEPClient from './WHEPClient'
import { useWebRTC } from 'providers/richman/WebRTCProvider'
import Placeholder from 'images/video_placeholder.jpeg'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
  },
  placeholderContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${Placeholder})`,
    backgroundSize: 'cover',
  },
  loadingIndicator: {
    color: theme.palette.gold.main,
  },
  loadingText: {
    color: theme.palette.gold.main,
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    '&:after': {
      overflow: 'hidden',
      display: 'inline-block',
      verticalAlign: 'bottom',
      animation: `$ellipsis steps(4,end) 900ms infinite`,
      content: "'\\2026'",
      width: 0,
    },
  },
  '@keyframes ellipsis': {
    to: {
      width: '1.25em',
    },
  },
}))

const CFWHEPOutput = ({ url, soundOn = true, videoOn = true, style = {} }) => {
  const classes = useStyles()
  const ref = useRef(null)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  useEffect(() => {
    const video = document.getElementById(`whep-video`) as HTMLVideoElement
    const client = new WHEPClient(url, video)
    client.initConnection()
  }, [url])

  const hidePlaceholder = useCallback(() => {
    setShowPlaceholder(false)
  }, [setShowPlaceholder])

  const touchstartHandler = (e) => {
    if (!isMobile) {
      return
    }
    let video = ref.current
    video.muted = !soundOn
    video.volume = soundOn ? 1 : 0
  }

  useEffect(() => {
    document.addEventListener('touchstart', touchstartHandler)
    return () => {
      document.removeEventListener('touchstart', touchstartHandler)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <video
        className={classes.video}
        id={`whep-video`}
        autoPlay
        muted={isMobile || !soundOn}
        playsInline
        onCanPlay={hidePlaceholder}
        ref={ref}
        style={{ ...style }}
      />
      {showPlaceholder && (
        <Box className={classes.placeholderContainer}>
          <CircularProgress className={classes.loadingIndicator} />
          <Typography variant="body1" className={classes.loadingText}>
            視訊載入中
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(CFWHEPOutput)
