import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMeasure } from 'react-use'
import { withProfiler } from '@sentry/react'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import { useGame } from 'screens/richman/AllRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: 'white',
    justifyContent: 'space-between',
    padding: '0 1%',
  },
  player: {
    color: theme.palette.player.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  dealer: {
    color: theme.palette.dealer.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  value: {
    color: 'black',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
  },
  playerAsk: {
    background: theme.palette.player.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  dealerAsk: {
    background: theme.palette.dealer.main,
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    marginRight: '8%',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(16),
  },
  bigEyeDealer: {
    border: `2px solid ${theme.palette.dealer.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  bigEyePlayer: {
    border: `2px solid ${theme.palette.player.main}`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: theme.palette.dealer.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: theme.palette.player.main,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.dealer.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid ${theme.palette.player.main}`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const HintRoad = ({}) => {
  const classes = useStyles()
  const [ref, { width }] = useMeasure()
  const { roads: data } = useGame()

  const fontSize = useMemo(() => {
    return `${width * 0.033}px`
  }, [width])

  return (
    <Box {...{ ref }} className={classes.root}>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          莊
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.dealer_count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.player}>
          閒
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.player_count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.tie}>
          和
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.tie_count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          莊對
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.dealer_pair_count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.player}>
          閒對
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.player_pair_count}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography style={{ fontSize }} className={classes.dealer}>
          局數
        </Typography>
        <Typography style={{ fontSize }} className={classes.value}>
          {data.total_count}
        </Typography>
      </Box>
      <Amounts fontSize={fontSize} />
    </Box>
  )
}

const Amounts = ({ fontSize }) => {
  const classes = useStyles()
  const { amount } = useGame()

  return useMemo(() => {
    return (
      <>
        <Box className={classes.box}>
          <Typography style={{ fontSize }} className={classes.player}>
            閒
          </Typography>
          <Typography style={{ fontSize }} className={classes.value}>
            {moneyFormatWithoutSymbol(amount.player, false)}
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Typography style={{ fontSize }} className={classes.dealer}>
            莊
          </Typography>
          <Typography style={{ fontSize }} className={classes.value}>
            {moneyFormatWithoutSymbol(amount.dealer, false)}
          </Typography>
        </Box>
      </>
    )
  }, [amount, fontSize])
}

export default withProfiler(React.memo(HintRoad))
