import React, { useCallback, useEffect, useState } from 'react'

import useAuth from 'hooks/useAuth'
import { usePageVisibility } from 'hooks/usePageVisibility'

import LoadingScreen from './LoadingScreen'

const disabled = !!document.querySelector('meta[name="disable-auto-logout"]')

const LogoutProvider = ({ children }) => {
  const [invisibleAt, setInvisibleAt] = useState<number>(-1)
  const [_, { logout }] = useAuth()
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (!isVisible) {
      setInvisibleAt(Date.now())
    }
  }, [isVisible, setInvisibleAt])

  const dispatchRefetch = useCallback(() => {
    if (Date.now() - invisibleAt > 180000 && !disabled) {
      logout()
    } else {
      setInvisibleAt(-1)
      window.dispatchEvent(new CustomEvent('refetch'))
    }
  }, [invisibleAt, setInvisibleAt])

  if (isVisible && invisibleAt !== -1) {
    const noLoadingScreen = !!document.querySelector(
      'meta[name="no-loading-screen"]',
    )

    if (noLoadingScreen) {
      return children
    } else {
      return <LoadingScreen onClose={dispatchRefetch} />
    }
  }
  return children
}

export default React.memo(LogoutProvider)
