import { useCallback } from 'react'

import CANCEL_BACCARAT_BET from 'graphql/application/mutations/cancelBaccaratBet'
import { delayAtLeast } from 'helpers/delay'

import { MutationResult, useMutation } from '@apollo/client'

import useBetState from './useBetState'
import useCurrentRoom from './useCurrentRoom'
import useWallet from './useWallet'

export default function useCancelBet({
  notification,
}): [() => void, MutationResult<any>] {
  const { room } = useCurrentRoom()
  const [, { updateQuery }] = useWallet()
  const [mutate, cancelBetState] = useMutation(CANCEL_BACCARAT_BET)
  const { setNotification } = notification
  const [betState, { cancel, enable, disable }] = useBetState()

  const loading = cancelBetState.loading || betState.disabled

  const cancelBet = useCallback(async () => {
    if (betState.disabled) return
    if (loading) return

    disable()
    await delayAtLeast(async () => {
      const result = await mutate({
        variables: {
          input: { baccaratRoomId: room.id },
        },
      })
      const { errors, wallet } = result.data.cancelBaccaratBet
      if (errors.length > 0) {
        setNotification(errors[0].message)
      } else {
        updateQuery(() => ({ wallet }))
        cancel()
      }
    }, 100)
    enable()
  }, [betState.disabled, loading, mutate, room, setNotification, cancel])

  return [cancelBet, { ...cancelBetState, loading }]
}
