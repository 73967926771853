import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useConfirmBet from 'hooks/useConfirmBet'
import { useNotification } from '../NotificationProvider'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check'
import { grey } from '@material-ui/core/colors'
import GradientBox from 'components/common/GradientBox'

import CONFIRM_BTN from 'images/richman/confirm_btn.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    flexGrow: 1,
    flexBasis: 0,
    margin: '0 1.5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

const ConfirmButton = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const notification = useNotification()
  const [confirmBet, { loading }] = useConfirmBet({ notification })

  const onConfirm = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      confirmBet()
    },
    [confirmBet],
  )

  return (
    <button className={classes.button} onClick={onConfirm}>
      <img src={CONFIRM_BTN} className={classes.image} />
    </button>
  )
}

export default React.memo(ConfirmButton)
