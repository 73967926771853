import React, { useState, createContext, useContext, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useQuery, gql } from '@apollo/client'
import VConsole from 'vconsole'
import { useNetworkState } from 'react-use'

const GET_SETTINGS = gql`
  query getSettings {
    loadingAds
    roomText
    enableStreamer
  }
`

const SiteSettingsContext = createContext(null)

const SiteSettingsProvider = ({ children }) => {
  const [vConsole, setVConsole] = useState(null)
  const { data, refetch } = useQuery(GET_SETTINGS)
  const state = useNetworkState()

  useEffect(() => {
    const vc = new VConsole({ maxLogNumber: 1000 })
    vc.hideSwitch()
    setVConsole(vc)
    return () => {
      vc?.destroy()
    }
  }, [])

  useEffect(() => {
    if (state) {
      console.info('online：', state.online)
      console.info('since：', state.since)
      console.info('downlink：', state.downlink)
      console.info('effectiveType：', state.effectiveType)
      console.info('rtt：', state.rtt)
      console.info('save data：', state.saveData)
      console.info('type：', state.type)
    }
  }, [state])

  return (
    <SiteSettingsContext.Provider
      value={{ vConsole, refetch, netWorkState: state, ...data }}
    >
      {data && children}
    </SiteSettingsContext.Provider>
  )
}

export const useSiteSettings = () => useContext(SiteSettingsContext)

export default React.memo(SiteSettingsProvider)
