import React from 'react'

import useStreamer from 'hooks/useStreamer'

import { gql, useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const QUERY = gql`
  query streamerLastSessionRanking($streamerId: ID!) {
    streamerLastSessionRanking(streamerId: $streamerId) {
      nickname
      amount
    }
  }
`

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& thead': {
      backgroundColor: '#fb21cb',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      paddingTop: '1rem',
    },
  },
}))

export default function LastSessionRanking() {
  const styles = useStyles()
  const [streamer] = useStreamer()
  const { data } = useQuery(QUERY, { variables: { streamerId: streamer.id } })
  const { t } = useTranslation()

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>{t('screens.streamer.rank')}</th>
          <th>{t('screens.streamer.loveRate')}</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.streamerLastSessionRanking.map((rank, index) => (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>{rank.nickname}</td>
              <td>{rank.amount}</td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}
