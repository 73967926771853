import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Typography,
  Button,
  DialogContent,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useTranslation } from 'react-i18next'
import { useMutation, gql } from '@apollo/client'
import { useSnackbar } from 'notistack'

const VERIFY = gql`
  mutation verifyCode($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      ok
      errors {
        attribute
        message
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
  },
  resendBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const MobileVerify = ({ phone, onSuccess, onClose, loading = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [code, setCode] = useState('')
  const [verify, { loading: verifyLoading }] = useMutation(VERIFY)
  const { enqueueSnackbar } = useSnackbar()

  const onCompleted = async (value) => {
    setCode(value)
  }

  const onVerify = async () => {
    const result = await verify({
      variables: {
        input: {
          code,
          phone,
        },
      },
    })

    const { ok, errors } = result.data.verifyCode
    if (ok && errors.length === 0) {
      onSuccess && onSuccess()
    } else {
      enqueueSnackbar(errors[0].message, { variant: 'error' })
    }
  }

  useEffect(() => {
    const regex = /^\d{4}$/
    if (code.match(regex)) {
      onVerify()
      onSuccess && onSuccess()
    }
  }, [code])

  const reSend = () => {}

  const close = () => {
    setOpen(false)
    onClose && onClose()
  }

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.title}>
          {t('screens.signUp.codeSent', { phone })}
        </Typography>
        <ReactInputVerificationCode autoFocus onCompleted={onCompleted} />
        <Button
          className={classes.resendBtn}
          variant="contained"
          color="secondary"
          onClick={reSend}
        >
          {t('screens.signUp.reSend')}
        </Button>
        {(loading || verifyLoading) && <CircularProgress />}
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(MobileVerify)
