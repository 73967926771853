import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from 'components/common/Card'
import React from 'react'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  player: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '50%',
    height: '100%',
    padding: theme.spacing(1, 0, 1, 1.5),
  },
  playerWin: {
    background: 'rgba(21, 71, 250, 0.3)',
  },
  dealerWin: {
    background: 'rgba(192, 0, 0, 0.3)',
  },
  dealer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%',
    height: '100%',
    padding: theme.spacing(1, 1.5, 1, 0),
  },
  playerTitle: {
    color: theme.palette.player.main,
    textStroke: '1px #fff',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(26),
  },
  dealerTitle: {
    color: theme.palette.dealer.main,
    textStroke: '1px #fff',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(26),
  },
  cards: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '4px',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  playerWinCircle: {
    background: theme.palette.player.main,
    fontSize: theme.typography.pxToRem(20),
    borderRadius: 999,
    color: 'white',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    width: '33px',
    height: '33px',
    lineHeight: '33px',
    textAlign: 'center',
  },
  dealerWinCircle: {
    background: theme.palette.dealer.main,
    fontSize: theme.typography.pxToRem(20),
    borderRadius: 999,
    color: 'white',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    width: '33px',
    height: '33px',
    lineHeight: '33px',
    textAlign: 'center',
  },
  tie: {
    background: '#00B7BA',
    fontSize: theme.typography.pxToRem(20),
    borderRadius: 999,
    color: 'white',
    fontWeight: 'bold',
    width: '33px',
    height: '33px',
    lineHeight: '33px',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))

const BaccaratGamePanel = ({ game }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box
          className={clsx(
            classes.player,
            game.playerWin ? classes.playerWin : null,
          )}
        >
          <Box className={classes.title}>
            <Box className={classes.playerTitle}>閒</Box>
            {game.playerWin && (
              <Box className={classes.playerWinCircle}>贏</Box>
            )}
          </Box>
          <Box className={classes.cards}>
            {game.playerCards.map((card, index) => (
              <Card
                width={50}
                selected
                card={card}
                on
                key={`player-${card}-${index}`}
              />
            ))}
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.dealer,
            game.dealerWin ? classes.dealerWin : null,
          )}
        >
          <Box className={classes.title}>
            {game.dealerWin && (
              <Box className={classes.dealerWinCircle}>贏</Box>
            )}
            <Box className={classes.dealerTitle}>莊</Box>
          </Box>
          <Box className={classes.cards}>
            {game.dealerCards.map((card, index) => (
              <Card
                width={50}
                selected
                card={card}
                on
                key={`dealer-${card}-${index}`}
              />
            ))}
          </Box>
        </Box>
        {game.tieWin && <Box className={classes.tie}>和</Box>}
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(BaccaratGamePanel))
