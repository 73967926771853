import React, { useCallback, useState } from 'react'

import clsx from 'clsx'
import { sha512 } from 'js-sha512'
import { useTranslation } from 'react-i18next'

import CardImage from './CardImage'

export default function Verifier({ card }) {
  const { t } = useTranslation()
  const [result, setResult] = useState(null)

  const verify = useCallback(() => {
    const hashData = [card.height, card.lastBlockHash, card.card].join('')
    setResult(sha512(hashData))
  }, [card, setResult])

  return (
    <div>
      <div className="p-3 bg-[#072036] text-center">
        {t('common.blockchainShoeVerifier')}
      </div>
      <div className="p-3 flex flex-col gap-3">
        <div
          className={clsx('bg-gray-500 rounded p-3 flex', {
            'border border-green-400': result === card.blockHash,
            'border border-red-400':
              result != null && result !== card.blockHash,
          })}
        >
          <div className="flex justify-center items-center gap-3 flex-1">
            <CardImage card={card} className="w-16" />
            <div className="text-xl">{card.height}</div>
          </div>
          <div className="flex-1">
            <div>{t('common.blockchainShoeCardHash')}</div>
            <div className="text-cyan-300 break-all text-xs">
              {card.blockHash}
            </div>
          </div>
        </div>
        <div
          className={clsx('bg-gray-500 rounded p-3 flex', {
            'border border-green-400': result === card.blockHash,
            'border border-red-400':
              result != null && result !== card.blockHash,
          })}
        >
          <div className="flex-1">
            <div>{t('common.blockchainShoeCardLastHash')}</div>
            <div className="text-cyan-300 break-all text-xs">
              {card.lastBlockHash}
            </div>
            <div>{t('common.blockchainShoeCardId')}</div>
            <div className="text-cyan-300 break-all text-xs">
              {card.card || t('common.blockchainShoeCardIsNull')}
            </div>
          </div>
          <div className="flex-1">
            <div>{t('common.blockchainShoeCardActualHash')}</div>
            <div className="text-cyan-300 break-all text-xs">{result}</div>
          </div>
        </div>
        <div className="text-center pb-3">
          <button
            onClick={verify}
            disabled={card.card == null}
            className={clsx('border px-3 py-1', {
              'border-yellow-500 rounded': result == null,
              'border-green-500 bg-green-500 rounded':
                result != null && result === card.blockHash,
              'border-red-500 bg-red-500 rounded':
                result != null && result !== card.blockHash,
              'opacity-50': card.card == null,
            })}
          >
            {t('common.blockchainShoeVerify')}
          </button>
        </div>
      </div>
    </div>
  )
}
