import React from 'react'

import { BaccaratRate } from 'hooks/useXianHong'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BetBlock from '../BetBlock'
import BetTarget from '../BetTarget'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1% 1%',
  },
  table: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    position: 'relative',
    flexGrow: 1,
    margin: '1px 0',
  },
  smallRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    position: 'relative',
    margin: '1px 0',
    flexGrow: 1,
  },
  largeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    position: 'relative',
    margin: '1px 0',
    flexGrow: 1,
  },
  title: {
    width: '4%',
    marginRight: 4,
    marginTop: '1.2%',
    marginBottom: '1.2%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#D6D6D6',
    textShadow: '0px 1.08511px 6.51064px rgba(0, 0, 0, 0.76)',
    writingMode: 'vertical-rl',
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: theme.spacing(0.5),
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    writingMode: 'vertical-rl',
  },
}))

const Panel1 = ({}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.table}>
        <Box className={classes.smallRow}>
          <BetBlock target="playerDragon" display="column" />
          <BetBlock target="playerPair" display="column" />
          <BetBlock target="super6" display="column" />
          <BetBlock target="dealerPair" display="column" />
          <BetBlock target="dealerDragon" display="column" />
        </Box>
        <Box className={classes.largeRow}>
          <BetBlock target="player" />
          <BetBlock target="tie" />
          <BetBlock target="dealer" />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Panel1)
