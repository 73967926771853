import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { useGameData } from '../BaccaratRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  image: {
    width: '100%',
  },
}))

export default function Road() {
  const styles = useStyles()
  const { roadsImage } = useGameData()

  return (
    <Box className={styles.root}>
      <img
        src={`data:image/png;base64,${roadsImage}`}
        className={styles.image}
      />
    </Box>
  )
}
