import React, { useState } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { DatePicker } from '@material-ui/pickers'
import SEND_GIFT_RECORDS from 'graphql/application/queries/getSendGiftRecords'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import clsx from 'clsx'
import GiftRecord from '../GiftRecords/GiftRecord'
import { useTranslation } from 'react-i18next'
import SEARCH from 'images/icons/search.png'

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.gold.main}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  scroll: {
    flexGrow: 1,
    // overflowY: 'auto',
    // height: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.gold.main}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  col: {
    flex: 1,
    textAlign: 'center',
  },
  val: {
    flex: 1,
    textAlign: 'center',
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    border: `1px solid ${theme.palette.gold.main}`,
  },
  paginationItem: {
    margin: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0D1E4A',
    border: '0.5px solid #FFFFFF',
    boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.3), -2px -2px 3px rgba(211, 211, 211, 0.3)',
    color: '#fff',
    padding: theme.spacing(0.5, 4),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    }
  },
  startDate: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    width: 85,
    lineHeight: 1,
    '&:focus': {
      outline: 'none',
    },
  },
  endDate: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    width: 85,
    lineHeight: 1,
    '&:focus': {
      outline: 'none',
    },
  },
  searchBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  quick: {
    margin: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0D1E4A',
    border: '0.5px solid #FFFFFF',
    boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.3), -2px -2px 3px rgba(211, 211, 211, 0.3)',
    color: '#fff',
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    }
  },
  selectedQuick: {
    margin: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0D1E4A',
    border: '0.5px solid #FFFFFF',
    boxShadow: '3px 2px 2px rgba(0, 0, 0, 0.3), -2px -2px 3px rgba(211, 211, 211, 0.3)',
    color: '#fff',
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    }
  },
  tableHeadCell: {
    whiteSpace: 'nowrap',
    color: 'white',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    color: '#353535',
    fontSize: theme.typography.pxToRem(12),
  },
  tableHead: {
    background: 'linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    color: '#ffffff',
    border: '1px solid #ffffff',
    letterSpacing: '0.09em',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
  },
  tableBody: {
    color: '#353535',
    background: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
  },
  searchIcon: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: 32,
      height: 32
    }
  }
}))

const GiftRecords = () => {
  const classes = useStyles()
  const [range, setRange] = useState({
    startDate: moment().subtract(1, 'day'),
    endDate: moment(),
  })
  const [open, setOpen] = useState({
    startDate: false,
    endDate: false,
  })
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
  })
  const [selectedQuick, setSelectedQuick] = useState('')
  const { data, loading, refetch } = useQuery(SEND_GIFT_RECORDS, {
    variables: {
      ...pagination,
      ...range,
    },
  })
  const { t } = useTranslation()

  const handlePrev = () => {
    if (pagination.page !== 1) {
      setPagination({
        ...pagination,
        page: pagination.page - 1,
      })
      refetch({
        ...range,
        ...pagination,
      })
    }
  }

  const handleNext = () => {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    })
    refetch({
      ...range,
      ...pagination,
    })
  }

  const quick = (period) => {
    setSelectedQuick(period)
    if (period === 'day') {
      setRange({
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      })
    } else if (period === 'month') {
      setRange({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      })
    } else if (period === 'week') {
      setRange({
        startDate: moment().startOf('isoWeek'),
        endDate: moment().endOf('isoWeek'),
      })
    }
  }

  const onSearch = () => {
    setPagination({
      page: 1,
      perPage: 20,
    })
    refetch({
      ...range,
      ...pagination,
    })
  }

  const onFocus = (event) => {
    event.preventDefault()
  }

  if (loading) return <CircularProgress />

  return (
    <Box className={classes.root}>
      <Box mb={1}>
        <Box display="flex" alignItems="center" mb={1}>
          <Box
            className={
              selectedQuick === 'day' ? classes.selectedQuick : classes.quick
            }
            onClick={() => quick('day')}
          >
            {t('common.today')}
          </Box>
          <Box
            className={
              selectedQuick === 'week' ? classes.selectedQuick : classes.quick
            }
            onClick={() => quick('week')}
          >
            {t('common.thisWeek')}
          </Box>
          <Box
            className={
              selectedQuick === 'month' ? classes.selectedQuick : classes.quick
            }
            onClick={() => quick('month')}
          >
            {t('common.thisMonth')}
          </Box>
          <input
            value={range.startDate.format('YYYY-MM-DD')}
            className={classes.startDate}
            onClick={() => setOpen({ ...open, startDate: true })}
            onFocus={onFocus}
            onChange={() => {}}
          />
          <Box display="none">
            <DatePicker
              open={open.startDate}
              onOpen={() => setOpen({ startDate: true, endDate: false })}
              onClose={() => setOpen({ endDate: false, startDate: false })}
              value={range.startDate}
              inputVariant="outlined"
              disableToolbar
              onChange={(value) => setRange({ ...range, startDate: value })}
            />
          </Box>
          <Box color="#fff"> > </Box>
          <input
            value={range.endDate.format('YYYY-MM-DD')}
            className={classes.endDate}
            onClick={() => setOpen({ ...open, endDate: true })}
            onFocus={onFocus}
            onChange={() => {}}
          />
          <Box display="none">
            <DatePicker
              value={range.endDate}
              open={open.endDate}
              onOpen={() => setOpen({ startDate: false, endDate: true })}
              onClose={() => setOpen({ startDate: false, endDate: false })}
              inputVariant="outlined"
              disableToolbar
              onChange={(value) => setRange({ ...range, endDate: value })}
            />
          </Box>
          <Box className={classes.searchBtn} onClick={onSearch}>
            <img src={SEARCH} className={classes.searchIcon} />
          </Box>
        </Box>
      {data && (
        <Box className={classes.pagination}>
          <Button
            variant="contained"
            color="primary"
            disabled={pagination.page === 1}
            className={clsx(classes.button, classes.paginationItem)}
            onClick={handlePrev}
          >
            {t('common.prevPage')}
          </Button>
          <Typography className={classes.paginationItem}>
            {pagination.page}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={data?.sendGiftRecords?.totalPages === pagination.page}
            className={clsx(classes.button, classes.paginationItem)}
            onClick={handleNext}
          >
            {t('common.nextPage')}
          </Button>
        </Box>
      )}
      </Box>
      <Box className={classes.scroll}>
        <TableContainer>
          <Table size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('components.giftRecords.room')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('components.giftRecords.gift')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('components.giftRecords.amount')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('components.giftRecords.girl')}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {t('components.giftRecords.time')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {data &&
                data.sendGiftRecords.records.map((record) => (
                  <GiftRecord record={record} key={record.id} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default React.memo(GiftRecords)
