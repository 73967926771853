import React from 'react'
import { Box } from '@material-ui/core'
import BetRecord from './BetRecord'
import ChargeRecord from './ChargeRecord'
import GiftRecord from './GiftRecord'
import ReturnRecord from './ReturnRecord'
import DealerRecord from './DealerRecord'
import { withProfiler } from '@sentry/react'

const ListData = ({ records }) => {
  const renderMessage = (record) => {
    switch (record.__typename) {
      case 'Bet':
        return <BetRecord key={record.id} record={record} />
      case 'Dealer':
        return <DealerRecord key={record.id} record={record} />
      case 'Charge':
        return <ChargeRecord key={record.id} record={record} />
      case 'Return':
        return <ReturnRecord key={record.id} record={record} />
      case 'SendGift':
        return <GiftRecord key={record.id} record={record} />
      default:
        break
    }
  }
  return <Box>{records.map((record) => renderMessage(record))}</Box>
}
export default withProfiler(React.memo(ListData))
