import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useCurrentRoom from 'hooks/useCurrentRoom'

import BlockchainInfoModal from '../../BlockchainInfo/BlockchainInfoModal'

import ICON from 'images/icons/blockchain.png'

const useStyles = makeStyles((theme) => ({
  img: {
    width: 20,
    height: 20,
  },
  root: {
    opacity: 0,
    transform: 'scale(0)',
  },
  openRoot: {
    background: '#424242',
    opacity: 1,
    marginRight: theme.spacing(1),
    transition:
      'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s',
  },
}))

export function ViewBlockchainInfo({ parentOpen, onClose }) {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const open = useCallback(() => {
    setShow(true)
  }, [])

  const close = useCallback(() => {
    setShow(false)
  }, [])

  const { room } = useCurrentRoom()

  if (!room.isBlockchainRoom) {
    return null
  }

  const onOpen = () => {
    onClose()
    open()
  }

  return (
    <>
      <IconButton
        onClick={onOpen}
        className={parentOpen ? classes.openRoot : classes.root}
        style={{ transitionDelay: parentOpen ? '0ms' : '150ms' }}
      >
        <img src={ICON} className={classes.img} />
      </IconButton>
      {show &&
        createPortal(<BlockchainInfoModal onClose={close} />, document.body)}
    </>
  )
}
