import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { generateUUIDv4 } from '@bitjourney/uuid-v4'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const LatencyContext = createContext(null)

export const LatencyProvider = ({ children }) => {
  const [latency, setLatency] = useState(0)

  const uuid = useMemo(() => generateUUIDv4(), [])

  const handleReceived = useCallback((data) => {
    const latency = (Date.now() - new Date(data.t).getTime()) / 1000
    console.log('Current latency', latency)
    setLatency(latency)
  }, [])

  return (
    <LatencyContext.Provider value={latency}>
      <ActionCableConsumer
        channel={{ channel: 'LatencyChannel', uuid }}
        onReceived={handleReceived}
      />
      {children}
    </LatencyContext.Provider>
  )
}

export default function useLatency() {
  return useContext(LatencyContext)
}
