import React, { useState, useCallback } from 'react'
import { Box, Menu, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import MENU from 'images/icons/menu.png'

import BetRecordsButton from 'screens/richman/BaccaratRooms/GameRoom/Header/BetRecordsButton'
import SoundSettingButton from 'screens/richman/BaccaratRooms/GameRoom/Header/SoundSettingButton'
import CustomerCenterButton from 'screens/richman/BaccaratRooms/GameRoom/Header/CustomerCenterButton'
import RuleButton from 'screens/richman/BaccaratRooms/GameRoom/Header/RuleButton'
import AdultButton from 'screens/richman/BaccaratRooms/GameRoom/Header/AdultButton'
import LogoutButton from 'screens/richman/BaccaratRooms/GameRoom/Header/LogoutButton'

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    width: 28,
    height: 28,
  },
  btn: {
    padding: theme.spacing(0.5),
  },
  container: {
    background: '#0D1E4A',
    boxShadow: '-3px 7px 12px rgba(0, 0, 0, 0.61)',
    width: 250,
  },
}))

const MenuList = ({}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = useCallback((event) => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <IconButton className={classes.btn} onClick={onOpen}>
        <img src={MENU} className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <Box className={classes.container}>
          <BetRecordsButton onCloseAnchor={onClose} />
          <SoundSettingButton onCloseAnchor={onClose} />
          <RuleButton onCloseAnchor={onClose} />
          <CustomerCenterButton onCloseAnchor={onClose} />
          <AdultButton onCloseAnchor={onClose} />
          <LogoutButton onCloseAnchor={onClose} />
        </Box>
      </Menu>
    </>
  )
}

export default React.memo(MenuList)
