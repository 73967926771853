import React from 'react'

import useStreamer from 'hooks/useStreamer'
import { getRTCTWHost } from 'libs/settings'

import { Box, makeStyles } from '@material-ui/core'

import RTCPlayer from './RTCPlayer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}))

export default function StreamerVideo() {
  const styles = useStyles()
  const [{ stream }] = useStreamer()

  const url = `${getRTCTWHost()}${stream.name}/${stream.key}.sdp`

  // use these lines for testing from streaming room
  // const [{ boundRoom }] = useStreamer()
  // const url = `https://rtc.vvip88.net/${boundRoom.streamName}/${boundRoom.streamKey}.sdp`

  return (
    <Box className={styles.root}>
      <RTCPlayer url={url} />
    </Box>
  )
}
