import React from 'react'

import { moneyFormatWithoutSymbol } from 'helpers/index'
import { useTranslation } from 'react-i18next'

import { Box, makeStyles } from '@material-ui/core'

import { useGameData } from '../BaccaratRooms/GameProvider'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  player: {
    background: 'linear-gradient(136.01deg, #6792FF 0%, #00168C 96.19%)',
  },
  dealer: {
    background: 'linear-gradient(136.01deg, #FF6767 0%, #8C0000 83.28%)',
  },
  tie: {
    background: 'linear-gradient(136.01deg, #1BB50E 0%, #0A4700 85.22%)',
  },
  total: {
    background:
      'linear-gradient(136.01deg, #DBC44C 11.62%, #AF4A00 37.44%, #6F4200 85.22%)',
  },
  flex: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25),
    },
  },
  basicBall: {
    color: theme.palette.common.white,
    borderRadius: '50%',
    lineHeight: 1,
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(10),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25),
    },
  },
}))

function HintRoad() {
  const styles = useStyles()
  const { roads, amount } = useGameData()
  const { t } = useTranslation()

  if (!roads) {
    return null
  }

  return (
    <Box className={styles.row}>
      <div className={styles.flex}>
        <Box className={clsx(styles.basicBall, styles.player)}>
          {t('common.player')}
        </Box>
        <Box className={styles.value}>{roads.player_count}</Box>
      </div>
      <div className={styles.flex}>
        <Box className={clsx(styles.basicBall, styles.player)}>
          {t('common.pair')}
        </Box>
        <Box className={styles.value}>{roads.player_pair_count}</Box>
      </div>
      <div className={styles.flex}>
        <Box className={clsx(styles.basicBall, styles.tie)}>
          {t('common.tie')}
        </Box>
        <Box className={styles.value}>{roads.tie_count}</Box>
      </div>
      <div className={styles.flex}>
        <Box className={clsx(styles.basicBall, styles.dealer)}>
          {t('common.dealer')}
        </Box>
        <Box className={styles.value}>{roads.dealer_count}</Box>
      </div>
      <div className={styles.flex}>
        <Box className={clsx(styles.basicBall, styles.dealer)}>
          {t('common.pair')}
        </Box>
        <Box className={styles.value}>{roads.dealer_pair_count}</Box>
      </div>
    </Box>
  )
}

export default HintRoad
