import React from 'react'

import useCurrentRoom from 'hooks/useCurrentRoom'

import { Box } from '@material-ui/core'

import Actions from './Actions'
import AlertText from './AlertText'
import CountingBlock from './CountingBlock'
import GameNo from './GameNo'
import IncomingMessages from './IncomingMessages'
import SelectedPlayer from './SelectedPlayer'
import SignUp from './SignUp'
import SocketStatus from './SocketStatus'

const Panel = () => {
  const { room } = useCurrentRoom()
  return (
    <Box position="relative" bgcolor="black">
      <AlertText />
      <SelectedPlayer />
      <CountingBlock />
      <GameNo />
      <SocketStatus />
      {!room.disableChatAndGift && <IncomingMessages />}
      <Actions />
      <SignUp />
    </Box>
  )
}

export default React.memo(Panel)
