import { useCallback } from 'react'

import { useLocalStorage } from 'react-use'

export default function useRememberPlayer(room): [string, (string) => void] {
  const [value, setValue] = useLocalStorage('player', {})

  const setPlayer = useCallback(
    (player: string) => {
      setValue({ ...value, [room.id]: player })
    },
    [room.id],
  )

  const player = value[room.id] || null

  return [player, setPlayer]
}
