import React from 'react'

import useCurrentRoom from 'hooks/useCurrentRoom'
import { useFullModal } from 'hooks/useFullModal'
import useRememberPlayer from 'hooks/useRememberPlayer'
import SELECT_PLAYER from 'images/icons/select_player.png'
import { useTranslation } from 'react-i18next'

import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useStreamApi, useStreamState } from '../index'

const useStyles = makeStyles((theme) => ({
  hidden: { opacity: 0, transform: 'scale(0)' },
  openRoot: {
    background: '#424242',
    marginRight: theme.spacing(1),
    opacity: 1,
    transition:
      'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s',
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const SelectModal = ({ roomName, player, onChangePlayer }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box>
      <RadioGroup
        aria-label="gender"
        name="player"
        value={player}
        onChange={onChangePlayer}
      >
        <FormControlLabel
          value="webrtc"
          control={<Radio />}
          label={t('components.stream.line1')}
        />
        <FormControlLabel
          value="llhls"
          control={<Radio />}
          label={t('components.stream.line2')}
        />
        <FormControlLabel value="cf" control={<Radio />} label={'線路3'} />
        <FormControlLabel value="flv" control={<Radio />} label={'線路4'} />
      </RadioGroup>
    </Box>
  )
}

const SelectPlayer = ({ open, onClose }) => {
  const classes = useStyles()
  const { setModal, toggleModal } = useFullModal()
  const { dispatch } = useStreamApi()
  const {
    state: { player },
  } = useStreamState()

  const { room } = useCurrentRoom()
  const [, setPlayer] = useRememberPlayer(room)

  const onChangePlayer = (event) => {
    dispatch({ type: 'CHANGE_PLAYER', player: event.target.value })
    setPlayer(event.target.value)
    toggleModal()
  }

  const onClick = () => {
    onClose()
    setModal({
      modal: (
        <SelectModal
          roomName={room.name}
          player={player}
          onChangePlayer={onChangePlayer}
        />
      ),
      title: '選擇視訊線路',
    })
  }

  return (
    <IconButton
      onClick={onClick}
      className={open ? classes.openRoot : classes.hidden}
      style={{ transitionDelay: open ? '0ms' : '150ms' }}
    >
      <img src={SELECT_PLAYER} className={classes.icon} />
    </IconButton>
  )
}

export default React.memo(SelectPlayer)
