import React, { useState, useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import { ScoreBoard } from 'libs/ScoreBoard'
import NewRoad from 'components/richman/NewRoad'
import BeadRoad from 'components/richman/NewRoad/BeadRoad'
import HintRoad from './HintRoad'
import EmptyHintRoad from 'screens/richman/BaccaratRoom/Road/EmptyHintRoad'
import { useScoreBoardWorker } from 'hooks/useScoreBoardWorker'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  dealer: {
    background: theme.palette.dealer.main,
    flex: 1,
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.dealer.main,
    },
    '&:active': {
      background: theme.palette.dealer.main,
    },
  },
  player: {
    background: theme.palette.player.main,
    flex: 1,
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.player.main,
    },
    '&:active': {
      background: theme.palette.player.main,
    },
  },
  tie: {
    background: theme.palette.tie.main,
    flex: 1,
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.tie.main,
    },
    '&:active': {
      background: theme.palette.tie.main,
    },
  },
  action: {
    flex: 1,
  },
}))

const ScoreBoardTest = ({}) => {
  const classes = useStyles()
  const [records, setRecords] = useState([])
  const { scoreBoard } = useScoreBoardWorker(30, 6, records, true)

  const onClick = (target: string) => {
    if (target === 'dealer') {
      setRecords([...records, 'R5n'])
    } else if (target === 'player') {
      setRecords([...records, 'B5n'])
    } else {
      setRecords([...records, 'G5n'])
    }
  }

  const pop = () => {
    let arr = records.slice()
    arr.pop()
    setRecords(arr)
  }
  const reset = () => setRecords([])

  return (
    <Box>
      <Box bgcolor="white">
        {scoreBoard && <BeadRoad beadRoad={scoreBoard.beadRoad} />}
      </Box>
      {/* <NewRoad board={scoreBoard} /> */}
      {scoreBoard ? <HintRoad board={scoreBoard} /> : <EmptyHintRoad />}
      <Box display="flex" className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.dealer}
          onClick={() => onClick('dealer')}
        >
          莊
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.player}
          onClick={() => onClick('player')}
        >
          閒
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.tie}
          onClick={() => onClick('tie')}
        >
          和
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={pop}
          className={classes.action}
        >
          -1
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={reset}
          className={classes.action}
        >
          重置
        </Button>
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(ScoreBoardTest))
