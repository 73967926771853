import { gql } from '@apollo/client'
import baccaratBetRecordFragments from 'graphql/application/fragments/baccaratBetRecordFragments'

export default gql`
  query liveBaccaratBetRecords(
    $page: Int
    $perPage: Int
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    liveBaccaratBetRecords(
      page: $page
      perPage: $perPage
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        ...baccaratBetRecordFragments
        baccaratRoom {
          id
          name
          gameType
        }
        baccaratGame {
          id
          gameNo
          closedAt
          dealerCards
          playerCards
          dealerPoints
          playerPoints
          playerWin
          dealerWin
          tieWin
          playerPairWin
          dealerPairWin
          super6Win
          totalRounds
          currentRound
        }
      }
      totalPages
      currentPage
    }
  }

  ${baccaratBetRecordFragments}
`
