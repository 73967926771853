import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useStreamer from 'hooks/useStreamer'
import { useTranslation } from 'react-i18next'
import { percentage } from 'helpers/index'
import LIKES_COUNT from 'images/richman/streamers/likesCount.png'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '10%',
    right: 0,
    alignItems: 'center',
  },
  nickname: {
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 12,
    height: 12,
  },
  winRate: {},
}))

const StreamerInfo = ({}) => {
  const styles = useStyles()
  const [streamer] = useStreamer()
  const { t } = useTranslation()

  return (
    <Box className={styles.root}>
      <Box mr={1}>
        <Typography className={styles.nickname}>{streamer.nickname}</Typography>
        <Box display="flex" alignItems="center">
          <img src={LIKES_COUNT} className={styles.icon} />
          <Typography className={styles.nickname}>
            {streamer.likesCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(StreamerInfo)
