import React, { createContext, useContext, useEffect } from 'react'

import clsx from 'clsx'
import { useImmer } from 'use-immer'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useGameStatus } from '../../BaccaratRooms/GameProvider'
import useBetState from 'hooks/useBetState'

import BetBlock from './BetBlock'
import BetTarget from './BetTarget'
import Buttons from './Buttons'

const useStyles = makeStyles((theme) => ({
  bet: {
    flex: 1,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.gold.main}`,
    marginLeft: '0.5%',
    marginRight: '0.5%',
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  root: {
    width: '100%',
    position: 'relative',
  },
  table: {
    width: '100%',
    height: '100%',
    transformStyle: 'preserve-3d',
    transformOrigin: 'center',
    position: 'relative',
    paddingBottom: '1%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    maxHeight: '45%',
    position: 'relative',
    padding: '0 2%',
  },
  coverRow: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    maxHeight: '47%',
    position: 'relative',
  },
  actionBet: {
    flex: 1.5,
    border: 'none',
  },
  cover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  coverBet: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1.5,
    paddingLeft: 4,
    paddingRight: 4,
  },
}))

const LocalBetContext = createContext(null)
const LocalBetApiContext = createContext(null)

const BetPanel = () => {
  const classes = useStyles()
  const [disabled, setDisabled] = useImmer(false)
  const { status, shuffle, initial } = useGameStatus()
  const [, { start }] = useBetState()

  useEffect(() => {
    if (shuffle) {
      start()
    }
  }, [shuffle])

  return (
    <LocalBetContext.Provider value={{ disabled }}>
      <LocalBetApiContext.Provider value={{ setDisabled }}>
        <Box
          className={classes.root}
          style={{
            transform: 'translateX(0)',
          }}
        >
          <Box
            className={classes.table}
            // style={{
            //   transform: `perspective(500px) rotateX(15deg) translateZ(15px) translateY(5px)`,
            // }}
          >
            <Box className={classes.row} mb={0.5}>
              <BetBlock target="playerPair" rate={11} />
              <BetBlock target="tie" rate={8} />
              <BetBlock target="dealerPair" rate={11} />
            </Box>
            <Box className={classes.row}>
              <BetBlock target="player" rate={1} />
              <Box className={clsx(classes.actionBet, classes.bet)}></Box>
              <BetBlock target="dealer" rate={0.95} />
            </Box>
          </Box>
          <Box className={classes.cover}>
            <Box position="relative" height="100%">
              <Box className={classes.coverRow} style={{ height: '50%' }}>
                <BetTarget target="playerPair" />
                <BetTarget target="tie" />
                <BetTarget target="dealerPair" />
              </Box>
              <Box className={classes.coverRow} style={{ height: '50%' }}>
                <BetTarget target="player" style={{ flex: 1 }} />
                <Box className={classes.coverBet}>
                  <Buttons />
                </Box>
                <BetTarget target="dealer" style={{ flex: 1 }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </LocalBetApiContext.Provider>
    </LocalBetContext.Provider>
  )
}

export const useLocalBet = () => useContext(LocalBetContext)
export const useLocalBetApi = () => useContext(LocalBetApiContext)

export default withProfiler(React.memo(BetPanel))
