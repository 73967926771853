import React, { useCallback } from 'react'

import { delayAtLeast } from 'helpers/delay'
import useBetState from 'hooks/useBetState'
import REPEAT_BET from 'images/richman/repeat_bet.png'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    opacity: '100%',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
  },
}))

const RepeatButton = ({ iconWidth }) => {
  const styles = useStyles()
  const [betState, { disable, enable, repeat }] = useBetState()

  const repeatWithDisable = useCallback(async () => {
    disable()
    await delayAtLeast(repeat, 100)
    enable()
  }, [disable, enable, repeat])

  return (
    <button
      className={styles.button}
      style={{ width: iconWidth }}
      onClick={repeatWithDisable}
      disabled={betState.disabled}
    >
      <img className={styles.image} src={REPEAT_BET} />
    </button>
  )
}

export default React.memo(RepeatButton)
