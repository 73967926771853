import React, { useCallback, useEffect, useRef, useState } from 'react'

import SEND from 'graphql/application/mutations/createBaccaratMessage'
import CREATE_WELCOME_MESSAGE from 'graphql/application/mutations/createWelcomeMessage'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/client'
import { generateUUIDv4 } from '@bitjourney/uuid-v4'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import { withProfiler } from '@sentry/react'

import { useChatroom } from '../../ChatroomProvider'
import { useNotification } from '../NotificationProvider'
import { throttle } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    background: 'white',
    border: '1px solid black',
    borderRadius: 30,
    color: 'black',
    minHeight: 20,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(0.5, 1),
    width: '100%',
    appearance: 'none',
    'webkit-appearance': 'none',
    '&:focus': {
      outline: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}))

const Input = () => {
  const { room, isOpen: roomOpen } = useCurrentRoom()

  const classes = useStyles()
  const [text, setText] = useState('')
  const { t } = useTranslation()
  const [welcome] = useMutation(CREATE_WELCOME_MESSAGE)
  const { setNotification } = useNotification()
  const [isSending, setIsSending] = useState(false)
  const [send] = useMutation(SEND)
  const { open, toggleChatroom, setMessages } = useChatroom()
  const {
    profile: { nickname, isTrial },
  } = useProfile()
  const ref = useRef(null)
  const buttonRef = useRef(null)
  const rootRef = useRef(null)

  const sendMessage = useCallback(
    async (message) => {
      if (buttonRef) {
        buttonRef.current.classList.add(
          'animate__animated',
          'animate__bounceIn',
        )
        setTimeout(() => {
          buttonRef.current.classList.remove(
            'animate__animated',
            'animate__bounceIn',
          )
        }, 1000)
      }
      if (text.trim() === '') return

      const uuid = generateUUIDv4()
      const result = await send({
        variables: {
          input: {
            baccaratRoomId: room.id,
            content: message,
            uuid,
          },
        },
      })

      const { errors } = result.data.createBaccaratMessage
      if (errors.length > 0) {
        setNotification(errors[0].message)
      } else {
        setText('')
      }
    },
    [send, room, text, setNotification, setText, setMessages, nickname],
  )

  const sendMessageThrottle = throttle(sendMessage, 3000, {
    leading: true,
    trailing: false,
  })

  const onSend = async () => {
    if (text.trim() !== '' && !isSending) {
      setIsSending(true)
      sendMessageThrottle(text)
    } else if (isSending) {
      setNotification(t('common.sendingMessageThrottle'))
    }
  }

  const onChange = useCallback((event) => {
    if (event.target.value.length > 70) {
      setNotification(t('common.messageTooLong'))
    } else {
      setText(event.target.value)
    }
  }, [])

  const onFocus = useCallback(
    (event) => {
      if (nickname === null || nickname === '') {
        event.preventDefault()
        event.stopPropagation()
        ref.current.blur()
      }
      if (!open) {
        toggleChatroom()
      }
    },
    [nickname, ref, open, toggleChatroom],
  )

  const onBlur = () => {}

  useEffect(() => {
    let timeoutId
    if (isSending) {
      timeoutId = setTimeout(() => {
        setIsSending(false)
      }, 3000)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isSending])

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSend()
    }
  }

  return (
    <Box className={classes.root}>
      <input
        placeholder={t('common.inputMessage')}
        className={classes.input}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        value={text}
        ref={ref}
      />
      <SendIcon ref={buttonRef} className={classes.icon} onClick={onSend} />
    </Box>
  )
}

export default withProfiler(React.memo(Input))
