import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfile } from 'providers/richman/ProfileProvider'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import CUSTOMER_SERVICE from 'images/icons/header/customer_service.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    width: 18,
    height: 18,
  },
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
}))

const CustomerCenterButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const {
    profile: { platformDisableCustomerService },
  } = useProfile()

  const { t } = useTranslation()

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  const goLine = () => {
    window.location.href =
      'https://liff.line.me/1645278921-kWRPP32q/?accountId=596lqmnf'
  }

  if (!platformDisableCustomerService) {
    return (
      <Button
        color="primary"
        className={buttonClass}
        fullWidth
        startIcon={<img src={CUSTOMER_SERVICE} className={classes.icon} />}
        onClick={goLine}
      >
        {t('components.header.customerCenter')}
      </Button>
    )
  }

  return null
}

export default React.memo(CustomerCenterButton)
