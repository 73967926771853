import React, { createContext, useCallback, useContext, useMemo } from 'react'

import useCurrentRoom from 'hooks/useCurrentRoom'
import usePingRoom from 'hooks/usePingRoom'
import { useImmer } from 'use-immer'

import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const ChatroomContext = createContext(null)

function RoomPinger({ room }) {
  usePingRoom(
    useMemo(() => ({ roomType: 'BaccaratRoom', roomId: room.id }), []),
  )

  return null
}

const ChatroomProvider = ({ children }) => {
  const { room, isOpen: modalOpen } = useCurrentRoom()
  const [messages, setMessages] = useImmer([])
  const [open, setOpen] = useImmer(false)

  const toggleChatroom = useCallback(() => {
    setOpen((draft) => !draft)
  }, [])

  const onReceived = useCallback(
    (response) => {
      const { message } = response
      if (message) {
        setMessages((draft) => {
          const found = draft.find((m) => m.uuid === response.message.uuid)
          if (found) {
            Object.assign(found, message)
          } else if (response.message.type !== 'Message::Join') {
            draft.push(response.message)
          }
          if (draft.length > 50) {
            draft.shift()
          }
        })
      }
    },
    [setMessages],
  )

  if (modalOpen) {
    return (
      <ActionCableConsumer
        onReceived={onReceived}
        channel={{
          channel: 'ChatroomChannel',
          roomId: room.id,
          roomType: 'BaccaratRoom',
        }}
      >
        <RoomPinger room={room} />
        <ChatroomContext.Provider
          value={{ messages, setMessages, open, toggleChatroom }}
        >
          {children}
        </ChatroomContext.Provider>
      </ActionCableConsumer>
    )
  } else {
    return (
      <ChatroomContext.Provider
        value={{ messages, setMessages, open, toggleChatroom }}
      >
        {children}
      </ChatroomContext.Provider>
    )
  }
}

export const useChatroom = () => useContext(ChatroomContext)

export default React.memo(ChatroomProvider)
