import React from 'react'

import MenuList from 'components/richman/Header/MenuList'
import ReloadButton from 'components/richman/Header/ReloadButton'
import BACK from 'images/icons/back.png'
import { useHistory } from 'react-router-dom'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import RoomName from './RoomName'
import RoomText from './RoomText'
import TableLimitsAndBalance from './TableLimitsAndBalance'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '5%',
    zIndex: 1,
  },
  nameAndBalance: {},
  icon: {
    width: 10,
    height: 15,
    [theme.breakpoints.up('sm')]: {
      width: 20,
      height: 30,
    },
  },
}))

const BackButton = () => {
  let history = useHistory()
  const classes = useStyles()

  const onBack = () => history.push('/')

  return (
    <IconButton onClick={onBack}>
      <img src={BACK} className={classes.icon} />
    </IconButton>
  )
}

const Header = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <BackButton />
      <Box className={classes.nameAndBalance}>
        <RoomName />
        <TableLimitsAndBalance />
      </Box>
      <RoomText />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {/* <ReloadButton /> */}
        <MenuList />
      </Box>
    </Box>
  )
}

export default Header
