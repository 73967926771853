import React from 'react'

import download from 'images/download/download.png'
import logo from 'images/download/logo.png'
import title from 'images/download/title.png'
import { useSettings } from 'providers/richman/AppProvider'
import { useLocalStorage } from 'react-use'

export default function DownloadAppBanner() {
  const [closed, setClosed] = useLocalStorage('close-download-app')
  const { settings } = useSettings()

  if (closed === 'true') {
    return null
  }

  return (
    <div className="fixed w-full top-0 bg-[#1e658b]">
      <div
        className="flex items-center mx-auto"
        style={{ maxWidth: settings.appWidth }}
      >
        <div className="p-2" onClick={() => setClosed('true')}>
          &#9587;
        </div>
        <div>
          <img src={logo} className="h-[48px]" />
        </div>
        <div className="flex-grow">
          <img src={title} className="h-[37px]" />
        </div>
        <div>
          <a href="https://app.ssppoker.com/" target="_blank">
            <img src={download} className="h-[28px]" />
          </a>
        </div>
      </div>
    </div>
  )
}
