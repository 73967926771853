import React, { useState } from 'react'
import {
  Paper,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { timeFormat, moneyFormat } from 'helpers/index'
import GameResult from './GameResult'
import CopyBtn from 'components/application/CopyBtn'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  dialogTitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(1),
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  normalBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255, 255, 255, 0.5)',
  },
  bet: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
  },
  channel: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.info.main,
  },
  check: {
    marginLeft: theme.spacing(1),
    color: '#2D9CDB',
    fontSize: theme.typography.pxToRem(13),
  },
}))

const DealerRecord = ({ record }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Paper className={classes.paper}>
      <Box p={2} color="white">
        <Box className={classes.titleBlock}>
          <Box display="flex" alignItems="center" fontSize={18}>
            {t('screens.records.dealer')}
            <Box className={classes.check} onClick={onClick}>
              {t('screens.records.check')}
            </Box>
          </Box>
          <Box className={classes.bet}>{moneyFormat(record.info.diff)}</Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{timeFormat(record.createdAt)}</Box>
          <Box fontSize={15}>{moneyFormat(record.info.total)}</Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box className={classes.subTitle}>{t('screens.records.time')}</Box>
          <Box className={classes.subTitle}>
            {t('screens.records.playersTotal')}
          </Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{record.info.bet_no}</Box>
          <Box fontSize={15}>{moneyFormat(record.info.diff)}</Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box display="flex" alignItems="center" className={classes.subTitle}>
            {t('screens.records.trade')}
            ID
            <CopyBtn text={record.info.bet_no} />
          </Box>
          <Box className={classes.subTitle}>{t('screens.records.diff')}</Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {t('screens.records.records')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <GameResult betNo={record.info['bet_no']} />
        </DialogContent>
      </Dialog>
    </Paper>
  )
}
export default withProfiler(React.memo(DealerRecord))
