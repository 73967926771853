import React, { useCallback, useEffect, useMemo } from 'react'

import delay from 'helpers/delay'
import useBetState from 'hooks/useBetState'
import useConnectedRooms from 'hooks/useConnectedRooms'
import useCurrentRoom from 'hooks/useCurrentRoom'
import useHarmonicDebounce from 'hooks/useHarmonicDebounce'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { createContext, useContext } from 'use-context-selector'
import { useImmer, useImmerReducer } from 'use-immer'

import { gql, useLazyQuery } from '@apollo/client'
import { withProfiler } from '@sentry/react'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

import { useBetRecord } from '../BetProvider/CurrentBetProvider'

const FETCH_ROADS = gql`
  query fetchRoads($id: ID!) {
    roadsAndImage(id: $id)
  }
`

const GameStatusContext = createContext(null)
const GameContentContext = createContext(null)
const GameDataContext = createContext(null)
const GameApiContext = createContext(null)

const translate = (status: string) => {
  switch (status) {
    case 'waiting_for_bet':
      return 'START_BET'
    case 'opening':
      return 'DRAW'
    case 'opening_confirmed':
      return 'DRAW'
    case 'player_third_opening':
      return 'PLAYER_DRAW_THIRD'
    case 'dealer_third_opening':
      return 'DEALER_DRAW_THIRD'
    case 'dealer_third_opening_confirmed':
      return 'DEALER_DRAW_THIRD_CONFIRMED'
    case 'player_third_opening_confirmed':
      return 'PLAYER_DRAW_THIRD_CONFIRMED'
    case 'closed':
      return 'CLOSE'
    case 'maintain':
      return 'MAINTAIN'
    case 'maintain_recover':
      return 'MAINTAIN_RECOVER'
    case 'sos':
      return 'SOS'
    case 'sos_recover':
      return 'SOS_RECOVER'
  }
}

const reducer = (draft, action) => {
  if (moment(draft.sendAt) > moment(action.sendAt)) {
    return
  } else {
    switch (action.type) {
      case 'START_BET':
        draft.status = 'START_BET'
        draft.shuffle = false
        draft.gameNo = action.gameNo
        draft.endAt = action.endAt
        draft.playerCards = null
        draft.dealerCards = null
        draft.points = {
          dealer: null,
          player: null,
        }
        draft.win = {
          player: null,
          dealer: null,
          playerPair: null,
          dealerPair: null,
          tie: null,
        }
        draft.playerNeedAnother = null
        draft.dealerNeedAnother = null
        draft.shuffle = false
        draft.amount = {
          dealer: 0,
          player: 0,
          dealerPair: 0,
          playerPair: 0,
          tie: 0,
        }
        draft.targets = {
          dealer: [],
          player: [],
        }
        draft.roadsImage = action.roadsImage
        draft.roads = action.roads
        draft.initial = false
        draft.sendAt = action.sendAt
        break
      case 'STOP_BET':
        draft.status = 'STOP_BET'
        break
      case 'DRAW':
        draft.status = 'DRAW'
        draft.playerCards = action.playerCards
        draft.dealerCards = action.dealerCards
        draft.points = {
          player: action.playerPoints,
          dealer: action.dealerPoints,
        }
        draft.sendAt = action.sendAt
        break
      case 'DRAW_CONFIRMED':
        draft.status = 'DRAW_CONFIRMED'
        draft.playerNeedAnother = action.playerNeedAnother
        draft.dealerNeedAnother = action.dealerNeedAnother
        draft.sendAt = action.sendAt
        break
      case 'PLAYER_DRAW_THIRD':
        draft.status = 'PLAYER_DRAW_THIRD'
        draft.playerCards = action.playerCards
        draft.points.player = action.playerPoints
        draft.sendAt = action.sendAt
        break
      case 'PLAYER_DRAW_THIRD_CONFIRMED':
        draft.status = 'PLAYER_DRAW_THIRD_CONFIRMED'
        draft.playerNeedAnother = action.playerNeedAnother
        draft.dealerNeedAnother = action.dealerNeedAnother
        draft.sendAt = action.sendAt
        break
      case 'DEALER_DRAW_THIRD':
        draft.status = 'DEALER_DRAW_THIRD'
        draft.dealerCards = action.dealerCards
        draft.points.dealer = action.dealerPoints
        draft.sendAt = action.sendAt
        break
      case 'DEALER_DRAW_THIRD_CONFIRMED':
        draft.status = 'DEALER_DRAW_THIRD_CONFIRMED'
        draft.playerNeedAnother = action.playerNeedAnother
        draft.dealerNeedAnother = action.dealerNeedAnother
        draft.sendAt = action.sendAt
        break
      case 'CLOSE':
        draft.status = 'CLOSE'
        draft.win = {
          player: action.playerWin,
          dealer: action.dealerWin,
          playerPair: action.playerPairWin,
          dealerPair: action.dealerPairWin,
          tie: action.tieWin,
        }
        draft.playerCards = action.playerCards
        draft.dealerCards = action.dealerCards
        draft.points = {
          player: action.playerPoints,
          dealer: action.dealerPoints,
        }
        draft.sendAt = action.sendAt
        break
      case 'UPDATE_ROAD_IMAGE':
        draft.roads = action.roads
        draft.roadsImage = action.roadsImage
        break
      case 'UPDATE_AMOUNT':
        if (draft.gameNo !== action.gameNo) {
          return
        } else {
          draft.amount = {
            player: action.player,
            dealer: action.dealer,
            playerPair: action.playerPair,
            dealerPair: action.dealerPair,
            tie: action.tie,
          }
          draft.targets = {
            dealer: action.targets.dealer,
            player: action.targets.player,
          }
          draft.sendAt = action.sendAt
        }
        break
      case 'SHUFFLE':
        draft.status = 'SHUFFLE'
        draft.shuffle = true
        draft.sendAt = action.sendAt
        break
      case 'SOS':
        draft.status = 'SOS'
        draft.sendAt = action.sendAt
        break
      case 'SOS_RECOVER':
        draft.status = 'SOS_RECOVER'
        draft.sendAt = action.sendAt
        break
      case 'MAINTAIN':
        draft.status = 'MAINTAIN'
        draft.sendAt = action.sendAt
        break
      case 'MAINTAIN_RECOVER':
        draft.status = 'MAINTAIN_RECOVER'
        draft.sendAt = action.sendAt
        break
      case 'CHANGE_GIRL':
        draft.girl = action.girl
        draft.sendAt = action.sendAt
        break
      case 'TOGGLE_GIFT':
        draft.giftOpen = !draft.giftOpen
        break
      case 'SET_STATE':
        const { currentGame, roads, currentRoadsImage } = action
        draft.status = translate(currentGame.status)
        draft.gameNo = currentGame.gameNo
        draft.endAt = currentGame.endAt
        draft.playerCards = currentGame.playerCards
        draft.dealerCards = currentGame.dealerCards
        draft.playerNeedAnother = currentGame.playerNeedAnother
        draft.dealerNeedAnother = currentGame.dealerNeedAnother
        draft.shuffle = currentGame.shuffle
        draft.roads = roads
        draft.roadsImage = currentRoadsImage
        draft.points = {
          player: currentGame.playerPoints,
          dealer: currentGame.dealerPoints,
        }
        draft.win = {
          player: currentGame.playerWin,
          dealer: currentGame.dealerWin,
          playerPair: currentGame.playerPairWin,
          dealerPair: currentGame.dealerPairWin,
          tie: currentGame.tieWin,
        }
        draft.amount = {
          dealer: currentGame.dealerAmount,
          player: currentGame.playerAmount,
          dealerPair: currentGame.dealerPairAmount,
          playerPair: currentGame.playerPairAmount,
          tie: currentGame.tieAmount,
        }
        draft.targets = {
          dealer: currentGame.targets.dealer,
          player: currentGame.targets.player,
        }
        draft.sendAt = currentGame.updatedAt
        break
      case 'SET_ROADS':
        draft.roads = action.roads
        draft.currentRoadsImage = action.currentRoadsImage
        break
      case 'ALERT_TEXT':
        draft.alertText = action.alertText
        break
      default:
        console.log('unknown action', action)
    }
  }
}
const GameProvider = ({ children }) => {
  const { room, isOpen: open } = useCurrentRoom()
  const [, connectedRoomsMethods] = useConnectedRooms()
  const [state, dispatch] = useImmerReducer(reducer, {
    open,
    giftOpen: false,
    initial: true,
    status: null,
    gameNo: null,
    playerCards: [],
    dealerCards: [],
    playerNeedAnother: null,
    dealerNeedAnother: null,
    shuffle: null,
    roads: null,
    roadsImage: null,
    girl: room.girl,
    points: {
      player: null,
      dealer: null,
    },
    win: {
      player: null,
      dealer: null,
      playerPair: null,
      dealerPair: null,
      tie: null,
    },
    amount: {
      dealer: null,
      player: null,
      dealerPair: null,
      playerPair: null,
      tie: null,
    },
    targets: {
      dealer: [],
      player: [],
    },
    alertText: room.alertText,
  })
  const [socketConnected, setSocketConnected] = useImmer(false)
  const [fetchRoads, { data }] = useLazyQuery(FETCH_ROADS, {
    variables: { id: room.id },
  })
  const [, { next }] = useBetState()

  useEffect(() => {
    dispatch({ type: 'SET_STATE', ...room })
  }, [room])

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const onReceived = useCallback(
    async (response) => {
      await delay(room.latency * 1000)
      const { command } = response
      if (command === 'ABANDON') {
        enqueueSnackbar(t('common.externalError'), { variant: 'error' })
        history.push('/')
      } else {
        if (command === 'START_BET') {
          next()
        }
        if (command === 'NOTIFY') {
          enqueueSnackbar(response.data.message, { variant: 'success' })
          return
        }
        dispatch({ type: command, ...response.data })
      }
    },
    [room.latency, next, history, enqueueSnackbar, t, dispatch],
  )

  const onInitialized = useCallback(() => {
    console.info(`${room.name} 連線初始化`)
  }, [room.name])

  const onConnected = useCallback(() => {
    console.info(`${room.name} 連線建立成功`)
    connectedRoomsMethods.add(room)
    setSocketConnected(true)
  }, [room.name, room.id, setSocketConnected])

  const onDisconnected = useCallback(() => {
    console.info(`${room.name} 連線失敗`)
    connectedRoomsMethods.remove(room)
    setSocketConnected(false)
  }, [room.name, setSocketConnected])

  const onRejected = useCallback(() => {
    console.info(`${room.name} 連線被拒絕`)
  }, [room.name])

  useEffect(() => {
    if (state.status === 'CLOSE') {
      fetchRoads && fetchRoads()
    }
  }, [state.status])

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'SET_ROADS',
        roads: data.roadsAndImage.roads,
        roadsImage: data.roadsAndImage.currentRoadsImage,
      })
    }
  }, [data])

  const status = useHarmonicDebounce(
    useMemo(() => {
      return {
        open: state.open,
        status: state.status,
        gameNo: state.gameNo,
        endAt: state.endAt,
        shuffle: state.shuffle,
        initial: state.initial,
      }
    }, [state.status, state.shuffle, state.gameNo, state.initial, state.open]),
  )

  const content = useHarmonicDebounce(
    useMemo(() => {
      return {
        points: state.points,
        win: state.win,
        playerNeedAnother: state.playerNeedAnother,
        dealerNeedAnother: state.dealerNeedAnother,
        playerCards: state.playerCards,
        dealerCards: state.dealerCards,
        alertText: state.alertText,
      }
    }, [
      state.points,
      state.win,
      state.playerNeedAnother,
      state.dealerNeedAnother,
      state.playerCards,
      state.dealerCards,
      state.alertText,
    ]),
  )

  const gameData = useHarmonicDebounce(
    useMemo(() => {
      return {
        giftOpen: state.giftOpen,
        roads: state.roads,
        roadsImage: state.roadsImage,
        girl: state.girl,
        amount: state.amount,
        targets: state.targets,
        socketConnected,
      }
    }, [
      state.giftOpen,
      state.roads,
      state.roadsImage,
      state.girl,
      state.amount,
      state.targets,
      socketConnected,
    ]),
  )

  const api = useMemo(
    () => ({
      dispatch,
    }),
    [],
  )

  return useMemo(() => {
    return (
      <ActionCableConsumer
        channel={{ channel: 'NewBaccaratGameChannel', roomId: room.id }}
        onReceived={onReceived}
        onDisconnected={onDisconnected}
        onConnected={onConnected}
        onInitialized={onInitialized}
        onRejected={onRejected}
      >
        <GameApiContext.Provider value={api}>
          <GameStatusContext.Provider value={status}>
            <GameContentContext.Provider value={content}>
              <GameDataContext.Provider value={gameData}>
                {children}
              </GameDataContext.Provider>
            </GameContentContext.Provider>
          </GameStatusContext.Provider>
        </GameApiContext.Provider>
      </ActionCableConsumer>
    )
  }, [api, status, content, gameData])
}

export const useGameStatus = () => useContext(GameStatusContext)
export const useGameContent = () => useContext(GameContentContext)
export const useGameData = () => useContext(GameDataContext)
export const useGameApi = () => useContext(GameApiContext)

export default withProfiler(React.memo(GameProvider))
