import React, { useState } from 'react'

import { Box, IconButton, makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import LastSessionRanking from './LastSessionRanking'
import TotalRanking from './TotalRanking'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    backgroundColor: '#0d426e',
    padding: theme.spacing(1),
    borderRadius: '1rem 1rem 0 0',
    borderTop: '1px solid #fb21cb',
    color: 'white',
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  title: {
    backgroundColor: '#fd24cd',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 9999,
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    paddingBottom: theme.spacing(0.5),
  },
  tab: {
    border: '1px solid #fb21cb',
    borderRadius: theme.spacing(1),
    background: 'transparent',
    color: 'white',
    padding: '0.25rem 0.5rem',
  },
}))

export default function Ranking({ onClose }) {
  const styles = useStyles()
  const [tab, setTab] = useState(1)
  const { t } = useTranslation()

  return (
    <Box className={styles.container}>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <HighlightOffIcon />
      </IconButton>
      <Box className={styles.titleContainer}>
        <Box className={styles.title}>{t('screens.streamer.idiotRank')}</Box>
      </Box>
      <Box className={styles.tabContainer}>
        <button className={styles.tab} onClick={() => setTab(1)}>
          {t('screens.streamer.rankHistory')}
        </button>
        <button className={styles.tab} onClick={() => setTab(2)}>
          {t('screens.streamer.onlineSession')}
        </button>
      </Box>
      {tab === 1 ? <TotalRanking /> : <LastSessionRanking />}
    </Box>
  )
}
