import React, { createContext, useCallback, useContext } from 'react'

import usePingRoom from 'hooks/usePingRoom'
import { useImmer } from 'use-immer'

import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

const ChatroomContext = createContext(null)

function RoomPinger({ channel }) {
  usePingRoom(channel)

  return null
}

const getRandomUserCount = () => Math.floor(Math.random() * (40 - 20 + 1)) + 20

const ChatroomProvider = ({ channel, children }) => {
  const [messages, setMessages] = useImmer([])
  const [usersCount, setUsersCount] = useImmer(getRandomUserCount())
  const [open, setOpen] = useImmer(false)

  const toggleChatroom = useCallback(() => {
    setOpen((draft) => !draft)
  }, [])

  const onReceived = useCallback(
    (response) => {
      const { message, usersCount } = response
      if (message) {
        setMessages((draft) => {
          draft.push(response.message)
          if (draft.length > 50) {
            draft.shift()
          }
        })
      }
      if (usersCount) {
        setUsersCount(Math.max(usersCount, getRandomUserCount()))
      }
    },
    [setMessages, setUsersCount],
  )

  return (
    <ActionCableConsumer
      onReceived={onReceived}
      channel={{
        channel: 'ChatroomChannel',
        ...channel,
      }}
    >
      <RoomPinger channel={channel} />
      <ChatroomContext.Provider
        value={{ messages, usersCount, open, toggleChatroom }}
      >
        {children}
      </ChatroomContext.Provider>
    </ActionCableConsumer>
  )
}

export const useChatroom = () => useContext(ChatroomContext)

export default React.memo(ChatroomProvider)
