import React, { useCallback, useMemo } from 'react'

import useCommissionFree from 'hooks/useCommissionFree'
import commissionFreeImage from 'images/richman/commission_free.png'
import normalImage from 'images/richman/normal.png'

import { useNotification } from './ListNotificationProvider'

const ToggleCommissionFreeButton = () => {
  const { setNotification } = useNotification()

  const getTotalConfirmed = useCallback(() => {
    const spans = document.querySelectorAll('.confirmed-bet-amount')
    return Array.from(spans).reduce(
      (total, span) => total + Number(span.textContent),
      0,
    )
  }, [])

  const { isCommissionFree, update } = useCommissionFree()
  const onClick = useCallback(async () => {
    if (getTotalConfirmed()) {
      setNotification('同一局是否免佣需為一致')
    } else {
      update(!isCommissionFree)
    }
  }, [update, isCommissionFree])

  return isCommissionFree ? (
    <img src={commissionFreeImage} onClick={onClick} />
  ) : (
    <img src={normalImage} onClick={onClick} />
  )
}

export default React.memo(ToggleCommissionFreeButton)
