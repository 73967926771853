import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGame } from 'screens/richman/AllRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  win: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  tieWin: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '20%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const TieWin = () => {
  const classes = useStyles()
  const { win } = useGame()

  if (!win.tie) return null

  return (
    <Box className={classes.tieWin}>
      <img src={require('images/richman/tie.png')} className={classes.img} />
    </Box>
  )
}

export default React.memo(TieWin)
