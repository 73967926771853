import React, { useState, useCallback } from 'react'

import clsx from 'clsx'

import { gql, useQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  makeStyles,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'
import { withProfiler } from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useSnackbar } from 'notistack'

import LIKE from 'images/icons/like.png'
import LIKED from 'images/icons/liked.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2.5),
    position: 'relative',
    paddingBottom: theme.spacing(10),
  },
  girlRoot: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    boxShadow: '2px 2px 5px 1.5px #07102C, -2px -2px 5px 1.5px #173C87',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  girlAvatar: {
    flexGrow: 2.5,
    flexBasis: 0,
    border: '0.723684px solid #FFFFFF',
    boxShadow:
      'inset 3.61842px 3.61842px 4.3421px 0.723684px rgba(0, 28, 61, 0.87), inset -2.89474px -2.89474px 3.61842px 0.723684px rgba(255, 238, 149, 0.88)',
    marginRight: theme.spacing(2),
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  girlInfo: {
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 138,
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    boxShadow:
      'inset 1.9459px 1.29727px 3.24317px rgba(0, 0, 0, 0.55), inset -1.29727px -2.59453px 3.24317px rgba(255, 255, 255, 0.45)',
    padding: theme.spacing(1),
    color: '#D6D6D6',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      minHeight: 176,
    },
  },
  iconButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  icon: {
    width: 30,
    height: 30,
  },
  girlName: {
    fontSize: theme.typography.pxToRem(16),
    color: '#D6D6D6',
    letterSpacing: '1em',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  girlTime: {
    fontSize: theme.typography.pxToRem(12),
    color: '#D6D6D6',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  likesCount: {
    fontSize: theme.typography.pxToRem(12),
    color: '#D6D6D6',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}))

const QUERY = gql`
  query Girls($page: Int, $perPage: Int) {
    girls(page: $page, perPage: $perPage) {
      records {
        id
        name
        avatar
        likesCount
        liked
        workingStartAtInHuman
        workingEndAtInHuman
      }
    }
  }
`

const LIKE_MUTATION = gql`
  mutation like($girlId: ID!) {
    likeGirl(input: { girlId: $girlId }) {
      girl {
        id
        likesCount
        liked
      }
    }
  }
`

const UNLIKE_MUTATION = gql`
  mutation unlike($girlId: ID!) {
    unlikeGirl(input: { girlId: $girlId }) {
      girl {
        id
        likesCount
        liked
      }
    }
  }
`

const Girls = () => {
  const styles = useStyles()
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
  })
  const { data } = useQuery(QUERY)

  if (!data) {
    return (
      <Box className={styles.root}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={styles.root}>
      {data &&
        data.girls.records.map((girl) => <Girl key={girl.id} girl={girl} />)}
    </Box>
  )
}

const Girl = ({ girl }) => {
  let history = useHistory()
  const classes = useStyles()
  const [liked, setLiked] = useState(girl.liked)
  const [like] = useMutation(LIKE_MUTATION, {
    variables: { girlId: girl.id },
  })
  const [unlike] = useMutation(UNLIKE_MUTATION, {
    variables: { girlId: girl.id },
  })
  const {
    profile: { isTrial },
  } = useProfile()
  const { enqueueSnackbar } = useSnackbar()

  const handleLike = useCallback(
    async (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (isTrial) {
        enqueueSnackbar('試玩玩家無法追蹤荷官', { variant: 'error' })
      } else {
        if (liked) {
          setLiked(false)
          await unlike()
        } else {
          setLiked(true)
          await like()
        }
      }
    },
    [liked, setLiked, unlike, like, isTrial],
  )

  const goGirl = (e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/girls/${girl.id}`)
  }

  return (
    <Box className={classes.girlRoot}>
      <Box
        className={classes.girlAvatar}
        style={{ backgroundImage: `url(${girl.avatar})` }}
        onClick={goGirl}
      />
      <Box className={classes.girlInfo}>
        <Box>
          <Typography className={classes.girlName}>{girl.name}</Typography>
          <Typography className={classes.girlTime}>
            {girl.workingStartAtInHuman}-{girl.workingEndAtInHuman}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleLike} className={classes.iconButton}>
            <img
              src={liked ? LIKED : LIKE}
              alt="like"
              className={classes.icon}
            />
          </IconButton>
          <Typography className={classes.likesCount}>
            {girl.likesCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default withProfiler(Girls)
