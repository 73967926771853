import React, { useState } from 'react'
import { Box, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))

const BackHeader = ({ title = null }) => {
  const classes = useStyles()
  let history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <Box className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={goBack}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default withProfiler(React.memo(BackHeader))
