import { useCallback, useEffect, useMemo, useState } from 'react'

import { chips } from 'helpers/bet'

import useProgress from './useProgress'

async function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = resolve
    img.onerror = reject
    img.src = src
  })
}

const gifts = ['bag', 'boat', 'car', 'treasure', 'coin', 'rose', 'diamond']

const giftImages = gifts.map((gift) =>
  require(`images/richman/gifts/${gift}.png`),
)

const chipImages = chips.map((chip) =>
  require(`images/chips/richman/${chip}.png`),
)

const activeChipImages = chips.map((chip) =>
  require(`images/chips/richman/A_${chip}.png`),
)

const betChipImages = chips.map((chip) =>
  require(`images/chips/richman/S_${chip}.png`),
)

const images = [
  ...giftImages,
  ...chipImages,
  ...activeChipImages,
  ...betChipImages,
]

export default function usePreloadImages(onProgress) {
  const [progress, { increment }] = useProgress(images.length)

  const load = useCallback(
    () =>
      Promise.all(
        images.map(async (image) => {
          await loadImage(image)
          increment()
        }),
      ),
    [],
  )

  useEffect(() => {
    onProgress(progress)
  }, [progress])

  return { load, progress }
}
