import React, { useState } from 'react'
import {
  TableRow,
  TableCell,
  Collapse,
  Typography,
  Box,
} from '@material-ui/core'
import Card from 'components/richman/Card'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import { useProfile } from 'providers/richman/ProfileProvider'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    whiteSpace: 'nowrap',
    color: 'black',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  dealer: {
    background: theme.palette.dealer.main,
  },
  player: {
    background: theme.palette.player.main,
  },
  title: {
    color: 'white',
    fontSize: theme.typography.pxToRem(20),
  },
  closedAt: {
    color: 'black',
  },
}))

let targets = [
  'player',
  'dealer',
  'tie',
  'playerPair',
  'dealerPair',
  'playerDragon',
  'dealerDragon',
  'small',
  'big',
  'playerSingle',
  'dealerSingle',
  'playerDouble',
  'dealerDouble',
  'perfectPair',
  'anyPair',
  'dealerKing',
  'playerKing',
  'superTie0',
  'superTie1',
  'superTie2',
  'superTie3',
  'superTie4',
  'superTie5',
  'superTie6',
  'superTie7',
  'superTie8',
  'superTie9',
  'super6',
]

const BetRecord = ({ record }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { profile } = useProfile()

  const renderBet = (record) => {
    let allBet = []
    targets.map((target) => {
      const amount = record[`${target}Amount`]
      if (amount > 0) {
        const name = t(`screens.baccaratRoom.${target}`)
        allBet.push(`${name}${amount}`)
      }
    })
    return allBet.join(', ')
  }

  const renderWin = (record) => {
    const {
      baccaratGame: game,
      baccaratRoom: { gameType },
    } = record
    let allDiff = []
    let all = targets.slice()
    if (gameType !== 'super6') {
      all.pop()
    }
    all.map((target) => {
      const win = game[`${target}Win`]
      if (win) {
        const name = t(`screens.baccaratRoom.${target}`)
        allDiff.push(`${name}贏`)
      }
    })
    return allDiff.join(', ')
  }

  const toggleOpen = () => setOpen(!open)

  return (
    <>
      <TableRow onClick={toggleOpen} className={classes.root}>
        <TableCell align="center" className={classes.tableCell}>
          {moment(record.createdAt).format('MM-DD HH:mm')}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {record.baccaratRoom.name}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {record.baccaratGame.totalRounds}-{record.baccaratGame.currentRound}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {moneyFormatWithoutSymbol(record.winLossAmount)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {moneyFormatWithoutSymbol(record.creditAfterWin)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {renderWin(record)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {moneyFormatWithoutSymbol(record.effectiveAmount)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {moneyFormatWithoutSymbol(record.totalWinAmount)}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {record.betNo}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open}>
            <Typography className={classes.closedAt}>
              {t('screens.profile.winTime')}：
              {moment(record.baccaratGame.closedAt).format('MM-DD HH:mm')}
            </Typography>
            <Typography className={classes.closedAt}>
              {t('screens.profile.betContent')}：{renderBet(record)}
            </Typography>
            {record.baccaratGame.dealerCards?.length >= 2 &&
              record.baccaratGame.playerCards?.length >= 2 && (
                <Box display="flex" alignItems="center" mb={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.dealer}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.title}>
                        {t('common.dealer')}
                      </Typography>
                      <Typography className={classes.title}>
                        {record.baccaratGame.dealerPoints}
                      </Typography>
                    </Box>
                    <Card card={record.baccaratGame.dealerCards[0]} on />
                    <Card card={record.baccaratGame.dealerCards[1]} on />
                    {record.baccaratGame.dealerCards[2] && (
                      <>
                        <Typography className={classes.title}>
                          {t('screens.profile.add')}
                        </Typography>
                        <Card card={record.baccaratGame.dealerCards[2]} on />
                      </>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.player}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.title}>
                        {t('common.player')}
                      </Typography>
                      <Typography className={classes.title}>
                        {record.baccaratGame.playerPoints}
                      </Typography>
                    </Box>
                    <Card card={record.baccaratGame.playerCards[0]} on />
                    <Card card={record.baccaratGame.playerCards[1]} on />
                    {record.baccaratGame.playerCards[2] && (
                      <>
                        <Typography className={classes.title}>
                          {t('screens.profile.add')}
                        </Typography>
                        <Card card={record.baccaratGame.playerCards[2]} on />
                      </>
                    )}
                  </Box>
                </Box>
              )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default React.memo(BetRecord)
