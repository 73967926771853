import React, { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'

import { Box } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 0,
    background: 'transparent',
  },
  autoSize: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
    transformOrigin: 'bottom',
    transition: 'all 1s ease-in-out',
    aspectRatio: '16/9',
  },
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '16/9',
    overflow: 'hidden',
  },
  videoOff: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: theme.spacing(1, 2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.secondary.main,
  },
}))

interface IPlayer {
  applicationName?: string
  streamName?: string
  resolution?: string
  soundOn?: boolean
  videoOn?: boolean
  autoSize?: boolean
  enlarge?: boolean
  volume?: number
  transformOrigin?: string
  defaultScale?: number
}

const Player: React.FC<IPlayer> = ({
  applicationName,
  streamName,
  autoSize = true,
  videoOn = true,
  soundOn = false,
  enlarge = false,
  volume = 0,
  transformOrigin = 'bottom',
  defaultScale = 1.0,
}) => {
  const classes = useStyles()
  const [np, setNp] = useState(null)

  useEffect(() => {
    let player = null
    if (videoOn) {
      // NodePlayer.debug(false)
      player = new NodePlayer()
      // player.useMSE()
      // player.useWorker()
      // player.useWCS()
      player.setView(`video-${applicationName}-${streamName}`)
      player.setBufferTime(1000)
      player.setScaleMode(1)
      player.setVolume(soundOn ? 1.0 : 0.0)
      player.start(
        `https://live.vvip88.net/app/${applicationName}_${streamName}.flv`,
      )
      player.on('error', (err) => {
        console.error('playerError', err)
      })
      setNp(player)
    }
    return () => {
      player?.release()
    }
  }, [streamName, applicationName, videoOn])

  useEffect(() => {
    if (np) {
      np.setVolume(soundOn ? 1.0 : 0.0)
    }
  }, [soundOn])

  useEffect(() => {
    if (!videoOn && np) {
      np.stop()
      np.clearView()
    }
  }, [videoOn])

  const scale = useMemo(() => {
    const scaleValue = enlarge ? (defaultScale || 1.0) * 2 : defaultScale || 1.0
    return `scale(${scaleValue})`
  }, [enlarge])

  return (
    <Box id="canvasContainer" className={clsx(classes.root)}>
      {videoOn && (
        <canvas
          id={`video-${applicationName}-${streamName}`}
          className={clsx(classes.autoSize)}
          style={{ transformOrigin, transform: scale }}
        />
      )}
      {!videoOn && <Box className={classes.videoOff}>視訊已關閉</Box>}
    </Box>
  )
}

export default Player
