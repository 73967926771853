import React, { useLayoutEffect, useMemo } from 'react'

import { chips } from 'helpers/bet'
import { useMeasure } from 'react-use'

import { Box } from '@material-ui/core'

import CancelButton from './CancelButton'
import ConfirmButton from './ConfirmButton'
import RepeatButton from './RepeatButton'

const Buttons = () => {
  const [ref, { width }] = useMeasure()

  useLayoutEffect(() => {
    chips.map((chip) => {
      require(`images/chips/richman/S_${chip}.png`)
    })
  }, [])

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      {...{ ref }}
    >
      <RepeatButton iconWidth={50} />
      <CancelButton iconWidth={50} />
      <ConfirmButton iconWidth={50} />
    </Box>
  )
}

export default React.memo(Buttons)
