import React from 'react'
import { capitalize } from 'lodash'
import { withProfiler } from '@sentry/react'

const Gift = ({ gift, size = 56 }) => {
  return (
    <img src={require(`images/Gifts/${gift}.png`)} width={size} height={size} />
  )
}
export default withProfiler(React.memo(Gift))
