import { gql } from '@apollo/client'
import sendGiftRecordFragments from 'graphql/application/fragments/sendGiftRecordFragments'

export default gql`
  query getSendGiftRecords(
    $page: Int
    $perPage: Int
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    sendGiftRecords(
      page: $page
      perPage: $perPage
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        ...sendGiftRecordFragments
      }
      totalCount
      totalPages
      currentCount
      currentPage
    }
  }

  ${sendGiftRecordFragments}
`
