import React, { useMemo } from 'react'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Join from './Join'
import Normal from './Normal'
import Streak from './Streak'

const useStyles = makeStyles((theme) => ({
  msgContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
}))

const Message = ({ message }) => {
  const classes = useStyles()

  const isSystem = useMemo(() => {
    return message.type === 'Message::System'
  }, [message])

  const isGift = useMemo(() => {
    return message.type === 'Message::Gift'
  }, [message])

  const isJackpot = useMemo(() => {
    return message.type === 'Message::Jackpot'
  }, [message])

  const isJoin = useMemo(() => {
    return message.type === 'Message::Join'
  }, [message])

  const isStreak = message.type === 'Message::Streak'

  const isNormal = !isSystem && !isJoin && !isGift && !isStreak && !isJackpot

  return (
    <Box className={classes.msgContainer}>
      {isJoin && <Join message={message} />}
      {isSystem && <Join message={message} />}
      {isStreak && <Streak message={message} />}
      {isNormal && <Normal message={message} />}
      {isJackpot && <Normal message={message} />}
    </Box>
  )
}

export default Message
