enum BaccaratRateNormal {
  BIG = 0.53,
  SMALL = 1.45,
  DEALER = 0.95,
  PLAYER = 1,
  TIE = 8,
  PLAYER_PAIR = 11,
  DEALER_PAIR = 11,
  SUPER_6 = '12/20',
  DEALER_DRAGON = 30,
  PLAYER_DRAGON = 30,
  DEALER_SINGLE = 0.94,
  PLAYER_SINGLE = 0.96,
  DEALER_DOUBLE = 0.94,
  PLAYER_DOUBLE = 0.9,
  PERFECT_PAIR = 25,
  ANY_PAIR = 5,
  DEALER_KING = 4,
  PLAYER_KING = 4,
  SUPER_TIE_0 = 150,
  SUPER_TIE_1 = 215,
  SUPER_TIE_2 = 225,
  SUPER_TIE_3 = 200,
  SUPER_TIE_4 = 120,
  SUPER_TIE_5 = 110,
  SUPER_TIE_6 = 45,
  SUPER_TIE_7 = 45,
  SUPER_TIE_8 = 80,
  SUPER_TIE_9 = 80,
}

enum BaccaratRateCommissionFree {
  BIG = 0.53,
  SMALL = 1.45,
  DEALER = 1,
  PLAYER = 1,
  TIE = 8,
  PLAYER_PAIR = 11,
  DEALER_PAIR = 11,
  SUPER_6 = '12/20',
  DEALER_DRAGON = 30,
  PLAYER_DRAGON = 30,
  DEALER_SINGLE = 0.94,
  PLAYER_SINGLE = 0.96,
  DEALER_DOUBLE = 0.94,
  PLAYER_DOUBLE = 0.9,
  PERFECT_PAIR = 25,
  ANY_PAIR = 5,
  DEALER_KING = 4,
  PLAYER_KING = 4,
  SUPER_TIE_0 = 150,
  SUPER_TIE_1 = 215,
  SUPER_TIE_2 = 225,
  SUPER_TIE_3 = 200,
  SUPER_TIE_4 = 120,
  SUPER_TIE_5 = 110,
  SUPER_TIE_6 = 45,
  SUPER_TIE_7 = 45,
  SUPER_TIE_8 = 80,
  SUPER_TIE_9 = 80,
}

const targetToKey = {
  big: 'BIG',
  small: 'SMALL',
  dealer: 'DEALER',
  player: 'PLAYER',
  tie: 'TIE',
  playerPair: 'PLAYER_PAIR',
  dealerPair: 'DEALER_PAIR',
  super6: 'SUPER_6',
  super6x2: 'SUPER_6x2',
  super6x3: 'SUPER_6x3',
  dealerDragon: 'DEALER_DRAGON',
  playerDragon: 'PLAYER_DRAGON',
  dealerSingle: 'DEALER_SINGLE',
  playerSingle: 'PLAYER_SINGLE',
  dealerDouble: 'DEALER_DOUBLE',
  playerDouble: 'PLAYER_DOUBLE',
  perfectPair: 'PERFECT_PAIR',
  anyPair: 'ANY_PAIR',
  dealerKing: 'DEALER_KING',
  playerKing: 'PLAYER_KING',
  superTie0: 'SUPER_TIE_0',
  superTie1: 'SUPER_TIE_1',
  superTie2: 'SUPER_TIE_2',
  superTie3: 'SUPER_TIE_3',
  superTie4: 'SUPER_TIE_4',
  superTie5: 'SUPER_TIE_5',
  superTie6: 'SUPER_TIE_6',
  superTie7: 'SUPER_TIE_7',
  superTie8: 'SUPER_TIE_8',
  superTie9: 'SUPER_TIE_9',
}

export default function getTargetRate(target, isCommissionFree = false) {
  const key = targetToKey[target]
  if (isCommissionFree) {
    return BaccaratRateCommissionFree[key]
  }
  return BaccaratRateNormal[key]
}
