import {
  createTheme,
  makeStyles,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles'
import React from 'react'
import { withProfiler } from '@sentry/react'
import { pink, grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    dealer: Palette['primary']
    player: Palette['primary']
    player1: Palette['primary']
    player2: Palette['primary']
    player3: Palette['primary']
    tie: Palette['primary']
    betBackground: Palette['primary']
    gold: Palette['primary']
  }
  interface PaletteOptions {
    dealer: PaletteOptions['primary']
    player: PaletteOptions['primary']
    player1: PaletteOptions['primary']
    player2: PaletteOptions['primary']
    player3: PaletteOptions['primary']
    tie: PaletteOptions['primary']
    gold: PaletteOptions['primary']
  }
}

let theme = createTheme({
  palette: {
    type: 'dark',
    common: { black: '#000', white: '#fff' },
    background: {
      paper: '#081334',
      default: 'rgba(0, 0, 0, 1)',
    },
    primary: {
      light: grey[100],
      main: grey[200],
      dark: grey[300],
    },
    secondary: pink,
    error: {
      light: '#e57373',
      main: '#E40F0F',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#D6D6D6',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    dealer: {
      main: '#FF7A7A',
    },
    player1: {
      main: '#79DFFF',
    },
    player2: {
      main: '#0F8CE9',
    },
    player3: {
      main: '#18B09B',
    },
    player: {
      main: '#79DFFF',
    },
    tie: {
      main: '#12E500',
    },
    betBackground: {
      main: 'rgba(255, 255, 255, 0.5)',
    },
    gold: { main: '#FFD700' },
  },
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiInput: {
      input: {
        '& ::placeholder': {
          color: '#000000',
        },
        border: '1.5px solid',
        borderImageSource:
          'linear-gradient(179.25deg, #502000 -7.89%, #AB5F00 6.07%, #FEC47F 19.21%, #FFF5E1 30.86%, #FFECE0 34.54%, #FFE6BC 62.28%, #C77800 88.9%, #4A2900 103.74%)',
      },
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        borderRadius: '50%', // 設置 Checkbox 的圓角
        color: pink[500],
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'transparent',
          borderImageSource:
            'linear-gradient(179.25deg, #502000 -7.89%, #AB5F00 6.07%, #FEC47F 19.21%, #FFF5E1 30.86%, #FFECE0 34.54%, #FFE6BC 62.28%, #C77800 88.9%, #4A2900 103.74%)',
          border: '1.5px solid',
          background: 'rgba(255, 255, 255, 0.4)',
          borderImageSlice: 1,
        },
        '&$focused $notchedOutline': {
          borderColor: 'transparent',
          borderImageSource:
            'linear-gradient(179.25deg, #502000 -7.89%, #AB5F00 6.07%, #FEC47F 19.21%, #FFF5E1 30.86%, #FFECE0 34.54%, #FFE6BC 62.28%, #C77800 88.9%, #4A2900 103.74%)',
          border: '1.5px solid',
          background: 'rgba(255, 255, 255, 0.4)',
          borderImageSlice: 1,
        },
      },
      notchedOutline: {
        borderColor: 'transparent',
        borderImageSource:
          'linear-gradient(179.25deg, #502000 -7.89%, #AB5F00 6.07%, #FEC47F 19.21%, #FFF5E1 30.86%, #FFECE0 34.54%, #FFE6BC 62.28%, #C77800 88.9%, #4A2900 103.74%)',
        border: '1.5px solid',
        background: 'rgba(255, 255, 255, 0.4)',
        borderImageSlice: 1,
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#1E202C',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#F24B6A', // 設置 secondary color 的 hover 背景色
          '@media (hover: none)': {
            backgroundColor: '#F24B6A',
          },
        },
        backgroundColor: 'transparent',
        background:
          'linear-gradient(164.31deg, #AB5F00 -177.65%, #FEC47F -109.39%, #FFECE0 -65.66%, #FFF5E1 -47.44%, #FFE6BC -12.52%, #CE7C00 69.84%, #4A2900 118.75%)',
        boxShadow:
          '1.91134px 1.91134px 4.77836px #00172C, -1.91134px -1.91134px 4.77836px #5486B5',
        color: '#fff',
        textShadow: '0px 1px 6px rgba(0, 0, 0, 0.76)',
        '&:disabled': {
          background: 'none',
          backgroundColor: '#6B6B6B',
          boxShadow:
            'inset 2px 2px 4px rgba(31, 31, 31, 0.61), inset -2px -2px 4px rgba(198, 198, 198, 0.58)',
        },
      },
    },
  },
})

theme = responsiveFontSizes(theme)

const AppThemeProvider = ({ children }) => {
  const classes = useStyles()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default withProfiler(React.memo(AppThemeProvider))
