import React, { createContext, useCallback, useContext } from 'react'

import { useProfile } from 'providers/richman/ProfileProvider'
import { useImmerReducer } from 'use-immer'

type Outcome =
  | 'player'
  | 'dealer'
  | 'playerPair'
  | 'dealerPair'
  | 'tie'
  | 'playerDragon'
  | 'dealerDragon'
  | 'playerSingle'
  | 'dealerSingle'
  | 'playerDouble'
  | 'dealerDouble'
  | 'perfectPair'
  | 'anyPair'
  | 'playerKing'
  | 'dealerKing'
  | 'superTie0'
  | 'superTie1'
  | 'superTie2'
  | 'superTie3'
  | 'superTie4'
  | 'superTie5'
  | 'superTie6'
  | 'superTie7'
  | 'superTie8'
  | 'superTie9'
  | 'big'
  | 'small'
  | 'super6'

const outcomes = [
  'player',
  'dealer',
  'playerPair',
  'dealerPair',
  'tie',
  'playerDragon',
  'dealerDragon',
  'playerSingle',
  'dealerSingle',
  'playerDouble',
  'dealerDouble',
  'perfectPair',
  'anyPair',
  'playerKing',
  'dealerKing',
  'superTie0',
  'superTie1',
  'superTie2',
  'superTie3',
  'superTie4',
  'superTie5',
  'superTie6',
  'superTie7',
  'superTie8',
  'superTie9',
  'big',
  'small',
  'super6',
]

class BetTarget {
  confirmed: number
  additional: number
  last: number
}

class BetState {
  disabled: boolean
  player: BetTarget
  dealer: BetTarget
  playerPair: BetTarget
  dealerPair: BetTarget
  tie: BetTarget
  big: BetTarget
  small: BetTarget
  super6: BetTarget
  dealerDragon: BetTarget
  playerDragon: BetTarget
  dealerSingle: BetTarget
  playerSingle: BetTarget
  dealerDouble: BetTarget
  playerDouble: BetTarget
  perfectPair: BetTarget
  anyPair: BetTarget
  dealerKing: BetTarget
  playerKing: BetTarget
  superTie0: BetTarget
  superTie1: BetTarget
  superTie2: BetTarget
  superTie3: BetTarget
  superTie4: BetTarget
  superTie5: BetTarget
  superTie6: BetTarget
  superTie7: BetTarget
  superTie8: BetTarget
  superTie9: BetTarget
  additional: number
}

function createBetTarget(last: number = 0): BetTarget {
  return {
    confirmed: 0,
    additional: 0,
    last,
  }
}

function createBetState(): BetState {
  return {
    disabled: false,
    player: createBetTarget(),
    dealer: createBetTarget(),
    playerPair: createBetTarget(),
    dealerPair: createBetTarget(),
    tie: createBetTarget(),
    big: createBetTarget(),
    small: createBetTarget(),
    super6: createBetTarget(),
    dealerDragon: createBetTarget(),
    playerDragon: createBetTarget(),
    dealerDouble: createBetTarget(),
    playerDouble: createBetTarget(),
    dealerSingle: createBetTarget(),
    playerSingle: createBetTarget(),
    perfectPair: createBetTarget(),
    anyPair: createBetTarget(),
    dealerKing: createBetTarget(),
    playerKing: createBetTarget(),
    superTie0: createBetTarget(),
    superTie1: createBetTarget(),
    superTie2: createBetTarget(),
    superTie3: createBetTarget(),
    superTie4: createBetTarget(),
    superTie5: createBetTarget(),
    superTie6: createBetTarget(),
    superTie7: createBetTarget(),
    superTie8: createBetTarget(),
    superTie9: createBetTarget(),
    additional: 0,
  }
}

const BetStateContext = createContext<[BetState, any]>(null)

const initialState: BetState = createBetState()

function reducer(draft: BetState, action) {
  switch (action.type) {
    case 'START': // 開始
      draft.player = createBetTarget(draft.player.last)
      draft.dealer = createBetTarget(draft.dealer.last)
      draft.playerPair = createBetTarget(draft.playerPair.last)
      draft.dealerPair = createBetTarget(draft.playerPair.last)
      draft.tie = createBetTarget(draft.tie.last)
      draft.big = createBetTarget(draft.big.last)
      draft.small = createBetTarget(draft.small.last)
      draft.super6 = createBetTarget(draft.super6.last)
      draft.dealerDragon = createBetTarget(draft.dealerDragon.last)
      draft.playerDragon = createBetTarget(draft.playerDragon.last)
      draft.dealerDouble = createBetTarget(draft.dealerDouble.last)
      draft.playerDouble = createBetTarget(draft.playerDouble.last)
      draft.dealerSingle = createBetTarget(draft.dealerSingle.last)
      draft.playerSingle = createBetTarget(draft.playerSingle.last)
      draft.perfectPair = createBetTarget(draft.perfectPair.last)
      draft.anyPair = createBetTarget(draft.anyPair.last)
      draft.dealerKing = createBetTarget(draft.dealerKing.last)
      draft.playerKing = createBetTarget(draft.playerKing.last)
      draft.superTie0 = createBetTarget(draft.superTie0.last)
      draft.superTie1 = createBetTarget(draft.superTie1.last)
      draft.superTie2 = createBetTarget(draft.superTie2.last)
      draft.superTie3 = createBetTarget(draft.superTie3.last)
      draft.superTie4 = createBetTarget(draft.superTie4.last)
      draft.superTie5 = createBetTarget(draft.superTie5.last)
      draft.superTie6 = createBetTarget(draft.superTie6.last)
      draft.superTie7 = createBetTarget(draft.superTie7.last)
      draft.superTie8 = createBetTarget(draft.superTie8.last)
      draft.superTie9 = createBetTarget(draft.superTie9.last)
      draft.additional = 0
      break
    case 'ADD': // 加碼
      draft[action.outcome].additional += action.amount
      draft.additional += action.amount
      break
    case 'REPEAT': // 重複上一回
      for (let outcome of outcomes) {
        const diff = draft[outcome].last - draft[outcome].additional
        draft[outcome].additional = draft[outcome].last
        draft.additional += diff
      }
      break
    case 'CANCEL': // 取消清零
      for (let outcome of outcomes) {
        draft[outcome].confirmed = 0
        draft[outcome].additional = draft.additional = 0
      }
      break
    case 'CONFIRM': // 確認
      for (let outcome of outcomes) {
        draft[outcome].confirmed += draft[outcome].additional
        draft[outcome].last = draft[outcome].additional
        draft[outcome].additional = 0
        draft.additional = 0
      }
      break
    case 'NEXT': // 前往下一回
      for (let outcome of outcomes) {
        draft[outcome].confirmed = 0
        draft[outcome].additional = 0
        draft.additional = 0
      }
      break
    case 'ENABLE':
      draft.disabled = false
      break
    case 'DISABLE':
      draft.disabled = true
      break
  }
}

export function BetStateProvider({ children }) {
  const [state, dispatch] = useImmerReducer<BetState>(reducer, initialState)
  const {
    profile: { isTrial },
  } = useProfile()

  const start = useCallback(() => {
    dispatch({ type: 'START' })
  }, [dispatch])

  const add = useCallback(
    (outcome: Outcome, amount: number) => {
      dispatch({ type: 'ADD', outcome, amount })
    },
    [dispatch],
  )

  const repeat = useCallback(() => {
    dispatch({ type: 'REPEAT' })
  }, [dispatch])

  const cancel = useCallback(() => {
    dispatch({ type: 'CANCEL' })
  }, [dispatch])

  const confirm = useCallback(() => {
    dispatch({ type: 'CONFIRM' })
    if (isTrial) {
      window.dispatchEvent(new CustomEvent('bet:confirm'))
    }
  }, [dispatch])

  const next = useCallback(() => {
    dispatch({ type: 'NEXT' })
    if (isTrial) {
      window.dispatchEvent(new CustomEvent('bet:next'))
    }
  }, [dispatch])

  const enable = useCallback(() => {
    dispatch({ type: 'ENABLE' })
  }, [dispatch])

  const disable = useCallback(() => {
    dispatch({ type: 'DISABLE' })
  }, [dispatch])

  return (
    <BetStateContext.Provider
      value={[
        state,
        { start, add, repeat, cancel, confirm, next, enable, disable },
      ]}
    >
      {children}
    </BetStateContext.Provider>
  )
}

export default function useBetState() {
  return useContext(BetStateContext)
}
