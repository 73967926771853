import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0.5, 0.5),
    background: 'white',
  },
  player: {
    color: '#0679EC',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  dealer: {
    color: '#E40F0F',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  playerAsk: {
    background: '#0679EC',
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  dealerAsk: {
    background: '#E40F0F',
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    color: 'white',
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(12),
  },
  bigEyeDealer: {
    border: `2px solid #E40F0F`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  bigEyePlayer: {
    border: `2px solid #0679EC`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: '#E40F0F',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: '#0679EC',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid #E40F0F`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid #0679EC`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    color: 'black',
    fontSize: theme.typography.pxToRem(12),
  },
}))

const EmptyHintRoad = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>
          {t('common.simpleDealer')}
        </Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.player}>
          {t('common.simplePlayer')}
        </Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.tie}>{t('common.simpleTie')}</Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>
          {t('common.simpleDealerPair')}
        </Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealer}>
          {t('common.simplePlayerPair')}
        </Typography>
        <Typography className={classes.value}>0</Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.dealerAsk}>
          {t('common.dealerAsk')}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Typography className={classes.playerAsk}>
          {t('common.playerAsk')}
        </Typography>
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(EmptyHintRoad))
