import React, { createContext, useContext } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useImmer } from 'use-immer'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'

const ModalContext = createContext(null)

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  paper: {
    border: '2px gold solid',
    backgroundColor: '#0c395e',
    borderRadius: 20,
  },
  closeIcon: {
    position: 'absolute',
    top: '-5%',
    right: '-5%',
    backgroundColor: '#2b5476',
    borderRadius: 8,
    padding: 6,
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    borderBottom: '1px solid gold',
    width: '52%',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}))

const SystemMessageProvider = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useImmer(false)
  const [modal, setModal] = useImmer(null)
  const { t } = useTranslation()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <ModalContext.Provider value={{ setModal, onOpen, onClose }}>
      {children}
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
        PaperProps={{
          classes: { root: classes.paper },
        }}
      >
        <DialogContent>
          <Box className={classes.root}>
            <Typography className={classes.title}>
              {t('common.systemMessage')}
            </Typography>
            {modal}
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </ModalContext.Provider>
  )
}

export const useSystemMessage = () => useContext(ModalContext)

export default React.memo(SystemMessageProvider)
