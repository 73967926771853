import React from 'react'

import { BaccaratRate } from 'hooks/useXianHong'

import { Box } from '@material-ui/core'

import BetBlock from '../BetBlock'
import BetTarget from '../BetTarget'
import { useStyles } from './Panel1'

const Panel2 = ({}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.table}>
        <Box className={classes.row} mb={0.5}>
          <BetBlock target="anyPair" />
          <BetBlock target="perfectPair" />
        </Box>
        <Box className={classes.row}>
          <BetBlock target="playerKing" />
          <BetBlock target="dealerKing" />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Panel2)
