import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGame } from 'screens/richman/AllRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  win: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '50%',
    height: 'auto',
  },
}))

const Win = ({ target }) => {
  const classes = useStyles()
  const { win } = useGame()

  if (!win[target]) return null

  return (
    <Box className={classes.win}>
      <img src={require('images/richman/win.png')} className={classes.img} />
    </Box>
  )
}

export default React.memo(Win)
