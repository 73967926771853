import { Box, Typography, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AUTH_BG from 'images/richman/auth_bg.png'
import LoadingGif from 'images/richman/loading.gif'
import React from 'react'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 'calc(var(--vh,1vh) * 100)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${AUTH_BG})`,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.common.black,
    zIndex: 9999,
  },
  logo: {
    width: 200,
    height: 80,
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: theme.spacing(2),
    color: 'gold',
  },
}))

const Loading = ({ show = true }) => {
  const classes = useStyles()

  return (
    <Fade in={show}>
      <Box className={classes.root}>
        <img src={LoadingGif} className={classes.logo} />
        <Typography className={classes.text}>信譽第一，品牌保證</Typography>
      </Box>
    </Fade>
  )
}
export default withProfiler(React.memo(Loading))
