import React, { useEffect, useRef } from 'react'

import clsx from 'clsx'
import { BetStateProvider } from 'hooks/useBetState'
import useCurrentRoom from 'hooks/useCurrentRoom'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import CurrentBetProvider from './BetProvider/CurrentBetProvider'
import GameProvider from './GameProvider'
import GirlAndCounting from './GirlAndCounting'
import Hint from './Hint'
import LobbyRoads from './LobbyRoads'
import RoomHint from './RoomHint'

const useStyles = makeStyles((theme) => ({
  root: {},
  room: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    boxShadow: 'inset 6px 8px 9px #07102C, inset -6px -6px 7px #173C87',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  roomContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    gridColumnGap: 0,
    position: 'relative',
  },
  road: {
    background: 'white',
    position: 'relative',
    height: '100%',
  },
  girlName: {
    textAlign: 'center',
    color: '#ffffff',
  },
  hint: {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    gridColumnGap: 0,
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  button: {
    background: '#f7ff0e',
    color: 'black',
    padding: theme.spacing(1, 2),
    borderRadius: 8,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  overlayText: {
    fontSize: theme.typography.pxToRem(32),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(48),
    },
    color: theme.palette.gold.main,
    whiteSpace: 'pre-line',
  },
}))

const Room = () => {
  const { room, open } = useCurrentRoom()
  const classes = useStyles()
  const { t } = useTranslation()

  const onOpen = () => {
    if (room.status === 'active') {
      open()
    }
    return null
  }

  return (
    <div className={clsx(classes.room)} onClick={onOpen}>
      <BetStateProvider>
        <GameProvider>
          <Box className={classes.roomContainer}>
            <GirlAndCounting />
            <Box>
              <div className={classes.road}>
                <LobbyRoads />
              </div>
            </Box>
            <Box className={classes.hint}>
              <Hint />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Typography className={classes.girlName}>{room.name}</Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {room.status === 'active' && <RoomHint />}
            </Box>
          </Box>
        </GameProvider>
      </BetStateProvider>
      {room.status === 'closed_but_show' && <Overlay text={room.waitingText} />}
    </div>
  )
}

const Overlay = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.overlay}>
      <Typography className={classes.overlayText}>{text}</Typography>
    </div>
  )
}

export default withProfiler(React.memo(Room))
