import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
} from 'react'

import NEXT from 'images/icons/next.png'
import PREV from 'images/icons/prev.png'
import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useImmer } from 'use-immer'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

import Panel1 from './Panels/Panel1'
import Panel2 from './Panels/Panel2'
import Panel3 from './Panels/Panel3'
import Panel4 from './Panels/Panel4'

SwiperCore.use([Controller])

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
  },
  slide: {
    height: 'auto',
  },
  icon: {
    width: '50%',
    height: 'auto',
  },
  prevButton: {
    zIndex: 2,
    position: 'absolute',
    padding: 0,
    top: '50%',
    left: -15,
    transform: 'translateY(-50%)',
  },
  nextButton: {
    zIndex: 2,
    position: 'absolute',
    top: '50%',
    padding: 0,
    right: -15,
    transform: 'translateY(-50%)',
  },
}))

const LocalBetContext = createContext(null)
const LocalBetApiContext = createContext(null)

const BetPanel = () => {
  const classes = useStyles()
  const [disabled, setDisabled] = useImmer(false)
  const [swiper, setSwiper] = useImmer(null)

  const onPrev = useCallback(() => swiper?.slidePrev(), [swiper])
  const onNext = useCallback(() => swiper?.slideNext(), [swiper])

  return (
    <LocalBetContext.Provider value={{ disabled }}>
      <LocalBetApiContext.Provider value={{ setDisabled }}>
        <Box className={classes.root}>
          <IconButton className={classes.prevButton} onClick={onPrev}>
            <img src={PREV} className={classes.icon} />
          </IconButton>
          <Swiper
            initialSlide={0}
            loop
            style={{
              width: '100%',
              height: '100%',
            }}
            onSwiper={setSwiper}
          >
            <SwiperSlide className={classes.slide}>
              <Panel1 />
            </SwiperSlide>
            <SwiperSlide className={classes.slide}>
              <Panel2 />
            </SwiperSlide>
            {/* <SwiperSlide className={classes.slide}>
              <Panel3 />
            </SwiperSlide>
            <SwiperSlide className={classes.slide}>
              <Panel4 />
            </SwiperSlide> */}
          </Swiper>
          <IconButton className={classes.nextButton} onClick={onNext}>
            <img src={NEXT} className={classes.icon} />
          </IconButton>
        </Box>
      </LocalBetApiContext.Provider>
    </LocalBetContext.Provider>
  )
}

export const useLocalBet = () => useContext(LocalBetContext)
export const useLocalBetApi = () => useContext(LocalBetApiContext)

export default withProfiler(React.memo(BetPanel))
