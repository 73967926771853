import React, { useMemo } from 'react'

import useCurrentRoom from 'hooks/useCurrentRoom'
import useLeftBetTime from 'hooks/useLeftBetTime'

import { makeStyles } from '@material-ui/core/styles'

import { useGameStatus } from '../GameProvider'
import Counting from './Counting'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const CountingBlock = ({ waitingBetSeconds }) => {
  const classes = useStyles()
  const { room } = useCurrentRoom()
  const left = useLeftBetTime(room)
  const { status, gameNo } = useGameStatus()

  const show = useMemo(() => {
    return ['waiting_for_bet', 'START_BET'].indexOf(status) !== -1 && left
  }, [status, left])

  return useMemo(() => {
    if (show) {
      return (
        <Counting
          left={left}
          key={gameNo}
          waitingBetSeconds={waitingBetSeconds}
        />
      )
    } else {
      return null
    }
  }, [show])
}

export default React.memo(CountingBlock)
