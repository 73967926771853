import React from 'react'

import clsx from 'clsx'
import useCurrentRoom from 'hooks/useCurrentRoom'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CountingBlock from '../CountingBlock'
import { useGameData } from '../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  girl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
  },
}))

const GirlAndCounting = () => {
  const { room } = useCurrentRoom()
  const classes = useStyles()
  const { girl } = useGameData()

  return (
    <Box
      className={clsx(classes.girl)}
      style={{
        backgroundImage: `url(${girl.avatar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
      }}
    >
      <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center leading-7">
        {girl.name}
      </div>
      <CountingBlock waitingBetSeconds={room.waitingBetSeconds} />
    </Box>
  )
}

export default React.memo(GirlAndCounting)
