import React, { createContext, useCallback, useContext } from 'react'

import { useSet } from 'react-use'

const ConnectedRooms = createContext(null)

const Provider = ({ children }) => {
  const [set, methods] = useSet(new Set())

  const add = useCallback(
    (room) => {
      methods.add(room.id)
    },
    [methods],
  )

  const remove = useCallback(
    (room) => {
      methods.remove(room.id)
    },
    [methods],
  )

  const isConnecting = useCallback((room) => methods.has(room.id), [methods])

  return (
    <ConnectedRooms.Provider value={[set, { add, remove, isConnecting }]}>
      {children}
    </ConnectedRooms.Provider>
  )
}

export const ConnectedRoomsProvider = React.memo(Provider)

export default function useConnectedRooms() {
  return useContext(ConnectedRooms)
}
