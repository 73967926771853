import React, { useEffect, useState } from 'react'

import { persistCache } from 'apollo3-cache-persist'
import Loading from 'components/richman/Loading'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import possibleTypes from 'graphql/possibleTypes.json'
import useAuth from 'hooks/useAuth'
import useCable from 'hooks/useCable'
import useToken from 'hooks/useToken'
import { isEmpty } from 'lodash'

import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const httpUri = '/graphql'

const httpLink = new HttpLink({
  uri: httpUri,
})

const cache = new InMemoryCache({
  possibleTypes,
})

const ApolloClientProvider: React.FC = (props) => {
  const { children } = props
  const [apollo, setApollo] = useState({
    client: null,
    cable: null,
  })
  const [token] = useToken()
  const [, { setError }] = useAuth()
  const cable = useCable(`token=${token}`)

  const persist = async () => {
    await persistCache({
      cache,
      storage: window.localStorage,
    })
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === 'Unauthorized error') {
          setError(new Error('你已經登入超過 24 小時，為了帳號安全請重新登入'))
        } else if (message === 'New Device error') {
          setError(new Error('您的帳號在其他地方登入過，請重新登入'))
        }
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  useEffect(() => {
    ;async () => await persist()

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `UserBearer ${token}` : '',
        },
      }
    })

    const hasSubscriptionOperation = ({ query: { definitions } }) => {
      return definitions.some(
        ({ kind, operation }) =>
          kind === 'OperationDefinition' && operation === 'subscription',
      )
    }

    const link = split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable }),
      authLink.concat(httpLink),
    )

    const client = new ApolloClient({
      link: from([errorLink, link]),
      cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    })

    setApollo({ client, cable })
  }, [token])

  if (isEmpty(apollo.client) && isEmpty(apollo.cable)) {
    return <Loading />
  }

  return <ApolloProvider client={apollo.client}>{children}</ApolloProvider>
}

export default ApolloClientProvider
