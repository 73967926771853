import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Message from './Message'
import { useChatroom } from './ChatroomProvider'

const useStyles = makeStyles((theme) => ({
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateX(calc(-100% - 12px))',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes moveOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  root: {
    position: 'absolute',
    bottom: '20%',
    left: 0,
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 8,
    padding: theme.spacing(1),
    width: '30%',
    height: '50%',
  },
  item: {
    marginBottom: theme.spacing(1),
    animation: `$moveIn .8s`,
  },
  hidden: {
    opacity: 0,
    animation: `$moveOut .8s`,
  },
  msg: {
    fontSize: theme.typography.pxToRem(12),
    color: 'white',
  },
}))

const Chatroom = ({}) => {
  const classes = useStyles()
  const [msgs, setMsgs] = useState([])
  const { messages } = useChatroom()

  useEffect(() => {
    let MAX = 3
    const lastTwo = messages
      .filter(
        (item) =>
          ['Message::Jackpot', 'Message::Join'].indexOf(item.type) === -1,
      )
      .slice(-MAX)
    setMsgs(lastTwo)
  }, [messages])

  return (
    <Box className={classes.root}>
      {msgs.map((msg, index) => {
        if (['Message::Jackpot', 'Message::Join'].indexOf(msg.type) === -1) {
          return (
            <Box key={msg.id} className={clsx(classes.item)}>
              <Message msg={msg} />
            </Box>
          )
        }
      })}
    </Box>
  )
}

export default React.memo(Chatroom)
