import React, { useMemo } from 'react'

import Prediction from 'components/richman/Prediction'
import { useMeasure } from 'react-use'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useGame } from 'screens/richman/AllRooms/GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 1%',
    background: 'white',
    flexDirection: 'column',
    height: '100%',
  },
  player: {
    color: theme.palette.player.main,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dealer: {
    color: theme.palette.dealer.main,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  playerAsk: {
    color: theme.palette.player.main,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    writingMode: 'vertical-lr',
    marginBottom: theme.spacing(0.5),
  },
  dealerAsk: {
    color: theme.palette.dealer.main,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    writingMode: 'vertical-lr',
    marginBottom: theme.spacing(0.5),
  },
  tie: {
    color: theme.palette.tie.main,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    color: 'black',
  },
}))

const SimpleHintRoad = () => {
  const classes = useStyles()
  const [ref, { width }] = useMeasure()
  const { roads: data } = useGame()

  const fontSize = useMemo(() => {
    return `${width * 0.48}px`
  }, [width])

  return (
    <Box {...{ ref }} className={classes.root} style={{ fontSize }}>
      <Box className={classes.box}>
        <Box className={classes.dealerAsk}>莊問路</Box>
        <Prediction data={data} side="dealer" road="big_eye" />
        <Prediction data={data} side="dealer" road="small" />
        <Prediction data={data} side="dealer" road="cockroach" />
      </Box>
      <Box className={classes.box}>
        <Box className={classes.playerAsk}>閒問路</Box>
        <Prediction data={data} side="player" road="big_eye" />
        <Prediction data={data} side="player" road="small" />
        <Prediction data={data} side="player" road="cockroach" />
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(SimpleHintRoad))
