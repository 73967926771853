import React, { useCallback, useMemo } from 'react'

import { useHistory } from 'react-router-dom'

import { Box, makeStyles, CardMedia, Typography } from '@material-ui/core'
import HEART from 'images/richman/streamers/heart.png'
import WIN from 'images/richman/streamers/win.png'
import { useMeasure } from 'react-use'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    position: 'relative',
    borderRadius: theme.spacing(1),
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1),
  },
  media: {
    position: 'absolute',
    // position: "absolute",
    left: 0,
    top: 0,
    height: '100%', //auto
    // maxWidth:"100%"
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  streamer: {
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  offlineBackDrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayText: {
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    color: theme.palette.gold.main,
    whiteSpace: 'pre-line',
  },
  avatar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '1rem',
  },
  nickname: {
    position: 'absolute',
    top: '0.25rem',
    left: '0.25rem',
    color: 'white',
  },
  online: {
    display: 'inline-block',
    color: theme.palette.tie.main,
  },
  offline: {
    display: 'inline-block',
    color: theme.palette.dealer.main,
  },
  numbers: {
    position: 'absolute',
    backgroundColor: 'rgba(13,66,110,0.68)',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    padding: theme.spacing(0.25),
    borderRadius: theme.spacing(2),
    color: 'white',
    display: 'flex',
    gap: '0.25rem',
    justifyContent: 'space-around',
  },
  badge: {
    backgroundColor: '#f25268',
    borderRadius: '50%',
    display: 'inline-block',
    width: '1.25rem',
    height: '1.25rem',
    textAlign: 'center',
    marginRight: theme.spacing(0.5),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  offlineStreamer: {
    opacity: 0.5,
  },
}))

export default function Streamer({ streamer }) {
  const styles = useStyles()
  const winRate = Math.floor(streamer.winRate * 100)
  const history = useHistory()
  const [ref, { width }] = useMeasure()

  const goToStreamer = useCallback(() => {
    if (streamer.online) {
      history.push(`/streamers/${streamer.id}`)
    }
  }, [history])

  return (
    <div className={styles.root} ref={ref}>
      <CardMedia
        component={'img'}
        className={styles.media}
        src={streamer.avatar}
      />
      <Box
        className={styles.streamer}
        onClick={goToStreamer}
        style={{ height: width }}
      >
        {!streamer.online && <div className={styles.offlineBackDrop}></div>}
        <Box className={styles.nickname}>
          <Box className={streamer.online ? styles.online : styles.offline}>
            ●
          </Box>
          {streamer.nickname}
        </Box>
        <Box className={styles.numbers}>
          <Box className={styles.row}>
            <img src={HEART} alt="win" className={styles.icon} />
            <Box>{streamer.likesCount}</Box>
          </Box>
          <Box className={styles.row}>
            <img src={WIN} alt="win" className={styles.icon} />
            <Box>{winRate}%</Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
