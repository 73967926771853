import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useProfile } from 'providers/richman/ProfileProvider'
import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'

import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  bg: {
    width: '100%',
    height: '100%',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      padding: 0,
    },
  },
  btn: {
    minHeight: theme.spacing(6),
    fontSize: theme.typography.pxToRem(24),
  },
  input: {},
  title: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
  },
}))

const UpdatePassword = ({}) => {
  const classes = useStyles()
  let history = useHistory()
  const { profile } = useProfile()
  const { register, handleSubmit, formState, setError, errors } = useForm({
    mode: 'onChange',
  })
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [update, { loading }] = useMutation(UPDATE_PROFILE)

  const onSubmit = async (data) => {
    const result = await update({
      variables: {
        input: {
          ...data,
          username: profile.username,
          nickname: profile.nickname,
        },
      },
    })

    const { errors } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((error) =>
        setError(error.attribute, {
          type: 'manual',
          message: error.message,
        }),
      )
    } else {
      enqueueSnackbar('更新成功，3秒後自動跳轉', { variant: 'success' })
      setTimeout(() => {
        history.push('/')
      }, 3000)
    }
  }

  return (
    <Box className={classes.bg}>
      <Box className={classes.title}>您需要先更新密碼才可繼續使用</Box>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.oldPassword')}
            name="oldPassword"
            type="password"
            error={!!errors.oldPassword}
            helperText={errors.oldPassword?.message}
            inputRef={register({ required: true })}
            margin="normal"
          />
        </FormGroup>
        <FormGroup>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.newPassword')}
            name="newPassword"
            type="password"
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            inputRef={register}
            margin="normal"
          />
        </FormGroup>
        <FormGroup>
          <TextField
            className={classes.input}
            variant="outlined"
            placeholder={t('screens.profile.confirmNewPassword')}
            name="confirmNewPassword"
            type="password"
            error={!!errors.confirmNewPassword}
            helperText={errors.confirmNewPassword?.message}
            inputRef={register}
            margin="normal"
          />
        </FormGroup>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={loading || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
          className={classes.btn}
        >
          {loading && <CircularProgress />}
          {!loading && t('actions.update')}
        </Button>
      </form>
    </Box>
  )
}

export default React.memo(UpdatePassword)
