import React, { useCallback } from 'react'

import { gql, useQuery } from '@apollo/client'
import { Box, Grid, CircularProgress, makeStyles } from '@material-ui/core'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'

import Streamer from './Streamer'

const QUERY = gql`
  query streamers {
    streamersImage
    streamers {
      id
      nickname
      avatar
      winRate
      online
      likesCount
      roomText
    }
  }
`

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${require('images/richman/auth_bg.png')})`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  ad: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2),
  },
}))

export default function Streamers() {
  const styles = useStyles()
  const { data, updateQuery } = useQuery(QUERY)

  const update = useCallback(
    (streamer) => {
      updateQuery((data) => {
        const streamers = data.streamers.map((s) =>
          parseInt(s.id) === streamer.id ? { ...s, ...streamer } : s,
        )
        return { streamers }
      })
    },
    [updateQuery],
  )

  if (!data) {
    return <CircularProgress />
  }

  return (
    <ActionCableConsumer
      onReceived={update}
      channel={{ channel: 'StreamersChannel' }}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        padding={1}
        className={styles.root}
      >
        {data && data.streamersImage && (
          <img src={data.streamersImage} className={styles.ad} />
        )}
        <Grid container spacing={2}>
          {data.streamers.map((streamer) => (
            <Grid item xs={6} key={streamer.id}>
              <Streamer streamer={streamer} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ActionCableConsumer>
  )
}
