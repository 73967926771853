import React from 'react'

import UPDATE_SETTINGS from 'graphql/application/mutations/updateSettings'
import { useProfileSettings } from 'hooks/useProfileSettings'

import MIC_OFF from 'images/icons/mic_off.png'
import MIC_ON from 'images/icons/mic_on.png'

import { useMutation } from '@apollo/client'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0,
    transform: 'scale(0)',
  },
  openRoot: {
    background: '#424242',
    opacity: 1,
    marginRight: theme.spacing(1),
    transition:
      'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s',
  },
  video: {
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 20,
    height: 20,
  },
}))

const MicButton = ({ open, onClose }) => {
  const classes = useStyles()
  const { settings } = useProfileSettings()
  const [update] = useMutation(UPDATE_SETTINGS)

  const onClick = async () => {
    onClose()
    await update({
      variables: {
        input: {
          ...settings,
          micOn: !settings.micOn,
          soundVolume: settings.volume,
          tableLimitId: null,
        },
      },
    })
  }

  return (
    <IconButton
      onClick={onClick}
      className={open ? classes.openRoot : classes.root}
      style={{ transitionDelay: open ? '0ms' : '120ms' }}
    >
      <img src={settings.micOn ? MIC_ON : MIC_OFF} className={classes.image} />
    </IconButton>
  )
}

export default React.memo(MicButton)
