import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { isUndefined } from 'lodash'

import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  big_eyeDealer: {
    border: `2px solid #E40F0F`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  big_eyePlayer: {
    border: `2px solid #0679EC`,
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallDealer: {
    background: '#E40F0F',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  smallPlayer: {
    background: '#0679EC',
    borderRadius: 999,
    width: '100%',
    height: '100%',
  },
  cockroachDealer: {
    width: '130%',
    borderTop: `2px solid #E40F0F`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
  cockroachPlayer: {
    width: '130%',
    borderTop: `2px solid #0679EC`,
    position: 'relative',
    transform: 'rotate(-45deg)',
    transformOrigin: 'center',
  },
}))

export default function Prediction({ data, road, side }) {
  const styles = useStyles()
  const prediction = data[`${road}_road`].prediction[side]

  return (
    <Box
      className={styles.root}
      mb={0.25}
      style={{
        width: '1em',
        height: '1em',
      }}
    >
      {!isUndefined(prediction) && (
        <Box
          className={
            prediction ? styles[`${road}Dealer`] : styles[`${road}Player`]
          }
        />
      )}
    </Box>
  )
}
