import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableRow,
  TableCell,
  Collapse,
  Typography,
  Box,
  Avatar,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { moneyFormatWithoutSymbol } from 'helpers/index'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    whiteSpace: 'nowrap',
    color: 'black',
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  dealer: {
    background: theme.palette.dealer.main,
  },
  player: {
    background: theme.palette.player.main,
  },
  title: {
    color: 'white',
    fontSize: theme.typography.pxToRem(20),
  },
  closedAt: {
    color: 'black',
  },
}))

const GiftRecord = ({ record }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableRow className={classes.root}>
      <TableCell align="center" className={classes.tableCell}>
        {record.info.room.name}
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        {t(`gift.${record.info.gift}`)}
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        {moneyFormatWithoutSymbol(record.info.amount)}
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <Box display="flex" alignItems="center">
          {record.info.girl.name}
        </Box>
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        {moment(record.createdAt).format('MM-DD HH:mm')}
      </TableCell>
    </TableRow>
  )
}

export default React.memo(GiftRecord)
