import React from 'react'

import CardImage from './CardImage'

export default function Card({ card, onClick = null }) {
  return (
    <div className="text-center text-xs cursor-pointer" onClick={onClick}>
      {card.height}
      <CardImage card={card} className="w-8" />
    </div>
  )
}
