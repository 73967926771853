import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { Box, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
}))

const NotificationContext = createContext(null)
const NotificationTitleContext = createContext(null)

const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const classes = useStyles()
  const { t } = useTranslation()

  const setNotification = (text: string) => {
    setTitle(text)
    setOpen(true)
  }

  const statuses = [
    t('status.baccaratRichman.sos'),
    t('status.baccaratRichman.sos_recover'),
    t('status.baccaratRichman.maintain'),
    t('status.baccaratRichman.shuffle'),
  ]

  useEffect(() => {
    if (open) {
      if (statuses.indexOf(title) === -1) {
        setTimeout(() => setOpen(false), 3000)
      }
    }
  }, [open, title])

  const api = useMemo(
    () => ({
      setNotification,
    }),
    [],
  )

  return (
    <Box className={classes.container}>
      <NotificationTitleContext.Provider value={{ title }}>
        <NotificationContext.Provider value={api}>
          {children}
        </NotificationContext.Provider>
      </NotificationTitleContext.Provider>
    </Box>
  )
}

export const useNotification = () => useContext(NotificationContext)
export const useNotificationTitle = () => useContext(NotificationTitleContext)

export default withProfiler(React.memo(NotificationProvider))
