import React from 'react'

import { BetStateProvider } from 'hooks/useBetState'
import useCurrentRoom, { CurrentRoomProvider } from 'hooks/useCurrentRoom'
import useSelectedRoom from 'hooks/useSelectedRoom'

import { Box, makeStyles } from '@material-ui/core'

import CurrentBetProvider from '../BaccaratRooms/BetProvider/CurrentBetProvider'
import GameProvider from '../BaccaratRooms/GameProvider'
import CamPlayer from './CamPlayer'
import Notification from './Notification'
import NotificationProvider from './NotificationProvider'
import RoadWithHint from './RoadWithHint'
import RoomGame from './RoomGame'
import RoomHeader from './RoomHeader'
import GradientBox from 'components/common/GradientBox'

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    padding: theme.spacing(1.5),
  },
}))

function RoomContent() {
  const styles = useStyles()
  const { room } = useCurrentRoom()
  const [selectedRoom] = useSelectedRoom()

  const withStream = selectedRoom === room
  const shouldDisplay = withStream || selectedRoom == null
  const display = shouldDisplay ? 'block' : 'none'

  return (
    <NotificationProvider>
      <GradientBox style={{ display, marginBottom: 16 }}>
        <Box className={styles.container}>
          <Notification />
          {withStream && <CamPlayer />}
          <RoomHeader />
          <RoomGame key={room.currentGame.id} />
          {withStream && <RoadWithHint />}
        </Box>
      </GradientBox>
    </NotificationProvider>
  )
}

function Room({ room }) {
  if (room.status === 'closed_but_show') {
    return null
  }

  return (
    <CurrentRoomProvider room={room}>
      <BetStateProvider>
        <CurrentBetProvider>
          <GameProvider>
            <RoomContent />
          </GameProvider>
        </CurrentBetProvider>
      </BetStateProvider>
    </CurrentRoomProvider>
  )
}

export default Room
