import React, { useCallback, useState, useRef } from 'react'

import GiftIcon from 'images/icons/gift.png'
import ChatIcon from 'images/icons/chat.png'

import { useProfile } from 'providers/richman/ProfileProvider'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useChatroom } from '../../ChatroomProvider'
import { useGameApi, useGameData } from '../../GameProvider'
import Input from './Input'
import { useNotification } from '../NotificationProvider'
import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1),
    background: '#0D1E4A',
  },
  button: {
    margin: 0,
    padding: 0,
    border: 0,
    background: 'transparent',
    appearance: 'none',
    '&:disabled': {
      opacity: 50,
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
}))

const ChatAndGifts = () => {
  const classes = useStyles()
  const { toggleChatroom, open } = useChatroom()
  const { giftOpen } = useGameData()
  const { dispatch } = useGameApi()
  const {
    profile,
    profile: { nickname },
  } = useProfile()
  const [nicknameOpen, setNicknameOpen] = useState(false)
  const { setNotification } = useNotification()
  const [update] = useMutation(UPDATE_PROFILE)
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()
  const onClose = () => setNicknameOpen(false)
  const { t } = useTranslation()
  const giftRef = useRef(null)
  const chatRef = useRef(null)

  const onSubmit = async (data) => {
    const result = await update({
      variables: {
        input: {
          ...data,
          username: profile.username,
          oldPassword: null,
          newPassword: null,
          confirmNewPassword: null,
          avatar: null,
        },
      },
    })

    const { errors } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    } else {
      onClose()
    }
  }

  const toggleGift = useCallback(() => {
    if (giftRef) {
      giftRef.current.classList.add('animate__animated', 'animate__bounceIn')
      setTimeout(() => {
        giftRef.current.classList.remove(
          'animate__animated',
          'animate__bounceIn',
        )
      }, 1000)
    }
    if (profile.isTrial) {
      setNotification(t('common.trailNoGift'))
    } else if (nickname === null) {
      setNicknameOpen(true)
    } else {
      if (open) {
        toggleChatroom()
      }
      dispatch({ type: 'TOGGLE_GIFT' })
    }
  }, [open, toggleChatroom, dispatch, nickname, nicknameOpen])

  const toggleChat = useCallback(() => {
    if (chatRef) {
      chatRef.current.classList.add('animate__animated', 'animate__bounceIn')
      setTimeout(() => {
        chatRef.current.classList.remove(
          'animate__animated',
          'animate__bounceIn',
        )
      }, 1000)
    }
    if (giftOpen) {
      dispatch({ type: 'TOGGLE_GIFT' })
    }
    toggleChatroom()
  }, [giftOpen, dispatch, toggleChatroom])

  return (
    <Box className={classes.root}>
      <button ref={giftRef} className={classes.button} onClick={toggleGift}>
        <img src={GiftIcon} className={classes.icon} />
      </button>
      <button ref={chatRef} className={classes.button} onClick={toggleChat}>
        <img src={ChatIcon} className={classes.icon} />
      </button>
      <Input />
      {nickname === null && (
        <Dialog
          open={nicknameOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              onClose()
            }
          }}
        >
          <DialogContent>
            <TextField
              variant="outlined"
              placeholder="設定暱稱"
              name="nickname"
              inputRef={register({
                required: t('common.nicknameIsRequired') as string,
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {t('common.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default React.memo(ChatAndGifts)
