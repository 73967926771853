import React, { useCallback, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import GradientBox from 'components/common/GradientBox'
import THIS_MONTH from 'images/richman/monthly_ranking.png'
import THIS_WEEK from 'images/richman/weekly_ranking.png'
import THIS_YEAR from 'images/richman/yearly_ranking.png'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'

import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Filters from './Filters'

const GET_RANKING = gql`
  query ranking($selected: String!) {
    ranking(selected: $selected)
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2.5),
    position: 'relative',
    paddingBottom: theme.spacing(10),
  },
  banner: {
    width: '100%',
    height: 'auto',
  },
  container: {
    paddingBottom: 80,
    width: '100%',
    height: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    filter:
      'drop-shadow(4.89734px 4.89734px 7.34601px rgba(0, 0, 0, 0.42)) drop-shadow(-3.673px -3.673px 6.12167px rgba(211, 211, 211, 0.42))',
    marginBottom: theme.spacing(1),
  },
  rankTitle: {
    flexGrow: 1,
    flexBasis: 0,
    padding: theme.spacing(1, 2),
    background:
      'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    border: '1px solid',
    borderImageSource:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    color: '#353535',
  },
  nameAndWinLossTitle: {
    flexGrow: 2,
    flexBasis: 0,
    padding: theme.spacing(1, 2),
    background:
      'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    border: '1px solid',
    borderImageSource:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    color: '#353535',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rank: {
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    padding: theme.spacing(1, 2),
    color: '#ffffff',
  },
  nameAndWinLoss: {
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    padding: theme.spacing(1, 2),
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Ranking = ({}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState('thisWeek')
  const { data, loading, refetch } = useQuery(GET_RANKING, {
    variables: { selected },
    notifyOnNetworkStatusChange: true,
  })
  const { t } = useTranslation()
  const ref = useRef(null)

  const onChange = useCallback((string: string) => {
    setSelected(string)
  }, [])

  useEffect(() => {
    if (data && ref.current) {
      const listItems = ref.current?.children

      for (let i = 0; i < listItems.length; i++) {
        listItems[i].classList.add('animate__animated', 'animate__fadeInUp')
        listItems[i].style.animationDelay = `${i * 200}ms`
      }
    }
  }, [data])

  return (
    <Box className={classes.root}>
      <Filters selected={selected} onChange={onChange} />
      {selected === 'thisWeek' ? (
        <img src={THIS_WEEK} className={classes.banner} />
      ) : null}
      {selected === 'thisMonth' ? (
        <img src={THIS_MONTH} className={classes.banner} />
      ) : null}
      <Box className={classes.row}>
        <Box flexGrow={1} flexBasis={0} marginRight={1}>
          <Box className={classes.rankTitle}>{t('screens.ranking.rank')}</Box>
        </Box>
        <Box flexGrow={2} flexBasis={0}>
          <Box className={classes.nameAndWinLossTitle}>
            <Box>{t('screens.ranking.username')}</Box>
            <Box>{t('screens.ranking.winLoss')}</Box>
          </Box>
        </Box>
      </Box>
      <div ref={ref} className={classes.container}>
        {loading && (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        )}
        {data &&
          data.ranking.map((rank, index) => (
            <RankingItem key={rank.username} rank={rank} index={index} />
          ))}
      </div>
    </Box>
  )
}

const RankingItem = ({ rank, index }) => {
  const classes = useStyles()

  return (
    <Box className={classes.row}>
      {index <= 3 ? (
        <Box flexGrow={1} flexBasis={0} marginRight={1}>
          <GradientBox>
            <Box className={classes.rank}>{index + 1}</Box>
          </GradientBox>
        </Box>
      ) : (
        <Box flexGrow={1} flexBasis={0} marginRight={1}>
          <Box className={classes.rank}>{index + 1}</Box>
        </Box>
      )}
      {index <= 3 ? (
        <Box flexGrow={2} flexBasis={0}>
          <GradientBox>
            <Box className={classes.nameAndWinLoss}>
              <Box>{rank.username}</Box>
              <Box>{numeral(rank.total_win_loss).format('$0,0')}</Box>
            </Box>
          </GradientBox>
        </Box>
      ) : (
        <Box flexGrow={2} flexBasis={0}>
          <Box className={classes.nameAndWinLoss}>
            <Box>{rank.username}</Box>
            <Box>{numeral(rank.total_win_loss).format('$0,0')}</Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(Ranking)
