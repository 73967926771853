import { Avatar, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Gift from 'components/richman/Gift'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useProfile } from 'providers/richman/ProfileProvider'
import clsx from 'clsx'
import GradientBox from 'components/common/GradientBox'

const Time = styled.span`
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 1;
`

const useStyles = makeStyles((theme) => ({
  systemMsg: {
    color: 'gold',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  msgContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
  container: {
    backgroundColor: 'transparent',
    maxWidth: '80%',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  giftContainer: {
    background:
      'linear-gradient(90deg, rgba(251, 188, 95, 0.5) 0%, rgba(242, 207, 88, 0) 100%)',
    width: '100%',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'gold',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  username: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  meUsername: {
    color: '#79DFFF',
  },
  body: {
    background: '#D6D6D6',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.common.black,
    border: '1px solid #FFFFFF',
    padding: theme.spacing(1, 1.5),
  },
  giftBody: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  bodyContainer: {
    position: 'relative',
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  meBody: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1, 1.5),
    background: 'linear-gradient(149.28deg, #283D67 2.05%, #081231 94.68%)',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.common.white,
  },
}))

const Message = ({ message }) => {
  const classes = useStyles()
  const {
    profile: { username },
  } = useProfile()

  const isSystem = useMemo(() => {
    return message.type === 'Message::System'
  }, [message])

  const isGift = useMemo(() => {
    return message.type === 'Message::Gift'
  }, [message])

  const isJackpot = useMemo(() => {
    return message.type === 'Message::Jackpot'
  }, [message])

  const isMe = username === message.username

  if (isJackpot) return null

  return (
    <Box className={classes.msgContainer}>
      <Box className={isGift ? classes.giftContainer : classes.container}>
        {isSystem && (
          <Box display="flex">
            <Box>
              <Box display="flex" alignItems="center">
                <Typography className={classes.systemMsg}>房間公告</Typography>
                <Time>{moment(message.createdAt).format('HH:mm')}</Time>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                bgcolor="white"
                className={classes.bodyContainer}
              >
                <Typography className={classes.body}>{message.body}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {!isSystem && !isGift && !isJackpot && (
          <Box>
            <Typography
              className={clsx(
                classes.username,
                isMe ? classes.meUsername : null,
              )}
            >
              {message.nickname}
            </Typography>
            <Box display="flex" alignItems="flex-end">
              {isMe ? (
                <GradientBox>
                  <Typography className={classes.meBody}>
                    {message.body}
                  </Typography>
                </GradientBox>
              ) : (
                <Typography className={classes.body}>{message.body}</Typography>
              )}
              <Time>{moment(message.createdAt).format('HH:mm')}</Time>
            </Box>
          </Box>
        )}
        {isGift && (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" color="primary.main">
              <Box mr={1.5}>
                <Gift gift={message.gift} size={40} />
              </Box>
              <Typography className={classes.giftBody}>
                {message.body}
              </Typography>
              <Box ml="5px">{moment(message.createdAt).format('HH:mm')}</Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Message
