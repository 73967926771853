import React, { useCallback, useState } from 'react'

import Modal from 'components/richman/Modal'
import { BetStateProvider } from 'hooks/useBetState'
import { ConnectedRoomsProvider } from 'hooks/useConnectedRooms'
import { SelectedChipProvider } from 'hooks/useSelectedChip'
import { StreamerProvider } from 'hooks/useStreamer'
import { useHistory, useParams } from 'react-router-dom'
import FullModalProvider from 'screens/richman/BaccaratRooms/GameRoom/FullModalProvider'
import Header from 'screens/richman/BaccaratRooms/GameRoom/Header'

import { Box, makeStyles } from '@material-ui/core'

import CurrentBetProvider from '../BaccaratRooms/BetProvider/CurrentBetProvider'
import GameProvider, { useGameData } from '../BaccaratRooms/GameProvider'
import NotificationProvider from '../BaccaratRooms/GameRoom/NotificationProvider'
import Opening from '../BaccaratRooms/GameRoom/Opening'
import SimpleHintRoad from '../BaccaratRooms/GameRoom/Road/SimpleHintRoad'
import BetPanel from './BetPanel'
import CAMERA from './camera.png'
import ChatBox from './ChatBox'
import Chatroom from './Chatroom'
import ChatroomProvider from './ChatroomProvider'
import ChipSelector from './ChipSelector'
import Counting from './Counting'
import GiftMessages from './GiftMessages'
import Gifts from './Gifts'
import Info from './Info'
import Road from './Road'
import RoomVideo from './RoomVideo'
import SideMenu from './SideMenu'
import StreamerChannel from './StreamerChannel'
import StreamerInfo from './StreamerInfo'
import StreamerVideo from './StreamerVideo'
import useGifts, { GiftsProvider } from './useGifts'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 750,
    margin: '0 auto',
  },
  topShits: {
    flexGrow: 1,
    position: 'relative',
  },
  bottomShits: {
    width: '100%',
  },
  road: {
    display: 'flex',
  },
  hintRoad: {
    flexShrink: 0,
  },
  streamerVideo: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  info: {
    top: 0,
    width: '100%',
    padding: '0.5rem',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  sideMenu: {
    position: 'absolute',
    top: '20%',
    right: 0,
    zIndex: 1,
    marginRight: theme.spacing(1),
  },
  streamerInfo: {
    position: 'absolute',
    top: '5%',
    right: 0,
    zIndex: 1,
  },
  stackingFrontLayer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  chatroom: {
    position: 'relative',
    height: '100%',
  },
  betStatus: {
    position: 'relative',
  },
  gifts: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}))

function Inner() {
  const styles = useStyles()
  const [{ opening }] = useGifts()
  const [isRoomVideoOpen, setRoomVideoOpen] = useState(false)
  const { roads } = useGameData()

  const toggleRoomVideoOpen = useCallback(() => {
    setRoomVideoOpen((value) => !value)
  }, [setRoomVideoOpen])

  const history = useHistory()

  const close = useCallback(() => {
    history.push('/streamers')
  }, [history])

  return (
    <Modal onClose={close}>
      <FullModalProvider>
        <Box className={styles.container}>
          <Header />
          <Box className={styles.topShits}>
            <Box className={styles.streamerVideo}>
              <StreamerVideo />
            </Box>
            {isRoomVideoOpen && <RoomVideo />}
            <Box className={styles.stackingFrontLayer}>
              <Info />
              <Box className={styles.chatroom}>
                <Box
                  display="flex"
                  alignItems="flex-end"
                  padding={1}
                  height="100%"
                >
                  <Box flexGrow={1} display="flex" flexDirection="column">
                    <GiftMessages />
                    <Chatroom />
                  </Box>
                  <Counting />
                </Box>
                {opening && (
                  <Box className={styles.gifts}>
                    <Gifts />
                  </Box>
                )}
              </Box>
              <Box className={styles.betStatus}>
                <BetPanel />
                <ChipSelector />
                <Opening pointsClassName="text-[200%]" />
              </Box>
            </Box>
            <Box className={styles.info}>
              <img src={CAMERA} onClick={toggleRoomVideoOpen} />
            </Box>
            <StreamerInfo />
            <Box className={styles.sideMenu}>
              <SideMenu />
            </Box>
          </Box>
          <Box className={styles.bottomShits}>
            <Box className={styles.road} position="relative">
              <Road />
              {roads && (
                <Box className={styles.hintRoad}>
                  <SimpleHintRoad data={roads} />
                </Box>
              )}
            </Box>
            <ChatBox />
          </Box>
        </Box>
      </FullModalProvider>
    </Modal>
  )
}

export default function Streamer() {
  const { id } = useParams()
  const styles = useStyles()

  return (
    <StreamerProvider id={id}>
      <ConnectedRoomsProvider>
        <BetStateProvider>
          <CurrentBetProvider>
            <GameProvider>
              <SelectedChipProvider>
                <NotificationProvider>
                  <ChatroomProvider
                    channel={{ roomId: id, roomType: 'Streamer' }}
                  >
                    <GiftsProvider>
                      <StreamerChannel>
                        <Inner />
                      </StreamerChannel>
                    </GiftsProvider>
                  </ChatroomProvider>
                </NotificationProvider>
              </SelectedChipProvider>
            </GameProvider>
          </CurrentBetProvider>
        </BetStateProvider>
      </ConnectedRoomsProvider>
    </StreamerProvider>
  )
}
