import React, { useEffect, useMemo, useState } from 'react'

import delay from 'helpers/delay'
import useAllBaccarats from 'hooks/useAllBaccarats'
import useConnectedRooms from 'hooks/useConnectedRooms'
import usePreloadImages from 'hooks/usePreloadImages'
import { sample } from 'lodash'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useSiteSettings } from 'providers/richman/SiteSettingsProvider'
import { createGlobalState } from 'react-use'

import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(${require('images/richman/mobile_bg.png')})`,
    backgroundSize: 'cover',
    zIndex: 1302,
    [theme.breakpoints.up('sm')]: {
      background: `url(${require('images/richman/desktop_bg.png')})`,
      backgroundSize: 'cover',
    },
  },
  root: {
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    paddingTop: '0 !important',
  },
  ad: {
    width: '100%',
    height: 'auto',
  },
  progress: {
    backgroundColor: 'rgba(255,215,0, 0.4)',
    height: 8,
  },
  barColorPrimary: {
    backgroundColor: '#be9148',
  },
  text: {
    color: '#be9148',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
  },
  ok: {
    color: theme.palette.success.main,
  },
  fail: {
    color: theme.palette.error.main,
  },
}))

const useLoaded = createGlobalState(false)

const disabled = !!document.querySelector('meta[name="disable-loading-delay"]')

const LoadingScreen = ({ onClose }) => {
  const classes = useStyles()
  const [progress, setProgress] = useState(0)
  const { loadingAds } = useSiteSettings()
  const {
    profile: { beta },
  } = useProfile()
  const { vConsole } = useSiteSettings()
  const { rooms } = useAllBaccarats()
  const images = usePreloadImages(() => {
    setProgress(40 + 60 * images.progress)
  })
  const [connectedRooms, { isConnecting }] = useConnectedRooms()
  const [loaded, setLoaded] = useLoaded()

  useEffect(() => {
    if (loaded) {
      onClose()
    } else {
      const promise = disabled
        ? images.load()
        : Promise.all([images.load(), delay(2000)])
      promise.then(() => {
        setLoaded(true)
        onClose()
      })
    }
  }, [])

  const showConsole = () => {
    if (beta) {
      vConsole.show()
    }
  }

  useEffect(() => {
    if (
      connectedRooms.size !== 0 &&
      rooms.length !== 0 &&
      connectedRooms.size === rooms.length &&
      progress < 40
    ) {
      setProgress(40)
    }
  }, [connectedRooms.size, rooms, progress])

  const loadingAd = useMemo(() => {
    return sample(loadingAds)
  }, [])

  return (
    <Dialog
      open
      fullScreen
      PaperProps={{ classes: { root: classes.background } }}
    >
      <DialogContent className={classes.root}>
        <img src={loadingAd} alt="Loading" className={classes.ad} />
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          mt={2}
          pl={1}
          pr={1}
        >
          <Box width="100%" mr={1}>
            <LinearProgress
              classes={{
                root: classes.progress,
                barColorPrimary: classes.barColorPrimary,
              }}
              variant="determinate"
              value={progress}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" className={classes.text}>{`${Math.round(
              progress,
            )}%`}</Typography>
          </Box>
        </Box>
        {/* {beta && (
          <Box width="100%" onClick={showConsole}>
            <Typography>
              連線：{connectedRooms.size} / {rooms?.length || 0}
            </Typography>
            {rooms.map((room) => (
              <Box display="flex" alignItems="center" key={room.id}>
                <Typography style={{ marginRight: 8 }}>{room.name}</Typography>
                {isConnecting(room) ? (
                  <CheckCircleIcon className={classes.ok} />
                ) : (
                  <CancelIcon className={classes.fail} />
                )}
              </Box>
            ))}
          </Box>
        )} */}
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(LoadingScreen)
