import React, { useEffect, useState } from 'react'
import { Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocalStorage } from 'react-use'
import { useSystemMessage } from 'providers/richman/SystemMessageProvider'
import { isIOS, isAndroid } from 'react-device-detect'
import IOS_SHARE from 'images/icons/iosShare.png'
import IOS_ADD from 'images/icons/iosAdd.png'
import ANDROID_DOTS from 'images/icons/androidDots.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  img: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(18),
  },
  iconRoot: {
    color: 'white',
  },
}))

const IosNotice = ({}) => {
  const classes = useStyles()
  const [hide, setHide, remove] = useLocalStorage('installNotice', false)
  const { t } = useTranslation()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHide(event.target.checked)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.addIconToHomeScreen')}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.step1')}
        </Typography>
        <img src={IOS_SHARE} className={classes.img} />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.step2')}
        </Typography>
        <img src={IOS_ADD} className={classes.img} />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.step3')}
        </Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            name="hide"
            checked={hide}
            onChange={onChange}
            color="secondary"
            classes={{
              root: classes.iconRoot,
            }}
          />
        }
        label={t('components.installNotice.dontShowAgain')}
      />
    </Box>
  )
}

const AndroidNotice = ({}) => {
  const classes = useStyles()
  const [hide, setHide, remove] = useLocalStorage('installNotice', false)
  const { t } = useTranslation()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHide(event.target.checked)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.googleStep1')}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.googleStep2')}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.googleStep3')}
        </Typography>
        <img src={ANDROID_DOTS} className={classes.img} />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.googleStep4')}
        </Typography>
        <img src={ANDROID_DOTS} className={classes.img} />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.text}>
          {t('components.installNotice.googleStep5')}
        </Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            name="hide"
            checked={hide}
            onChange={onChange}
            color="secondary"
            classes={{
              root: classes.iconRoot,
            }}
          />
        }
        label={t('components.installNotice.dontShowAgain')}
      />
    </Box>
  )
}

const InstallNotice = ({}) => {
  const classes = useStyles()
  const { setModal, onOpen } = useSystemMessage()
  const [hide, setHide, remove] = useLocalStorage('installNotice', false)
  const [agree] = useLocalStorage('agreeTerms', false)

  if (hide) return null

  useEffect(() => {
    if (!hide && agree) {
      const isStandalone =
        'standalone' in window.navigator && (window.navigator as any).standalone
      if (!isStandalone) {
        if (isIOS) {
          setModal(<IosNotice />)
          onOpen()
        } else if (isAndroid) {
          setModal(<AndroidNotice />)
          onOpen()
        }
      }
    }
  }, [hide])

  return <span />
}

export default React.memo(InstallNotice)
