import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  message: {
    fontSize: theme.typography.pxToRem(12),
  },
}))

const Normal = ({ message }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.message}>
      {message.nickname}:{message.body}
    </Typography>
  )
}

export default React.memo(Normal)
