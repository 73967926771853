import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Drawer,
  Typography,
  CircularProgress,
  Badge,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useImmer } from 'use-immer'
import { useParams } from 'react-router-dom'
import { useSettings } from 'providers/richman/AppProvider'
import useAllBaccarats, { AllBaccaratsProvider } from 'hooks/useAllBaccarats'
import Room from 'screens/richman/BaccaratRooms/Room'
import { CurrentRoomProvider } from 'hooks/useCurrentRoom'

import TRANSFER from 'images/richman/transfer_btn.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexBasis: 0,
    margin: '0 1.5%',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  placeholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    color: theme.palette.gold.main,
  },
  roadImage: {
    width: '100%',
    height: 'auto',
    background: 'white',
  },
  drawer: {},
  badge: {
    backgroundColor: '#FF1D1D',
    top: '10%',
    right: '5%',
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    lineHeight: 1,
    padding: theme.spacing(0.5),
  },
}))

const RoomList = ({ width }) => {
  const classes = useStyles()
  const { id } = useParams()
  const { rooms, loading } = useAllBaccarats()

  if (loading) {
    return (
      <Box p={2} width={width * 0.8} className={classes.placeholder}>
        <CircularProgress className={classes.loading} />
      </Box>
    )
  }

  if (rooms) {
    return (
      <Box p={2} width={width * 0.8}>
        {rooms
          .filter((room) => room.id !== id && room.status === 'active')
          .map((room) => (
            <CurrentRoomProvider key={room.id} room={room}>
              <Room />
            </CurrentRoomProvider>
          ))}
      </Box>
    )
  }

  return null
}

const TransferButton = ({}) => {
  const classes = useStyles()
  const [open, setOpen] = useImmer(false)
  const {
    settings: { appWidth },
  } = useSettings()

  const toggleDrawer = () => setOpen((draft) => !draft)

  const onClose = () => setOpen(false)

  return (
    <Box className={classes.root}>
      <Badge
        classes={{ badge: classes.badge }}
        badgeContent={1}
        invisible={false}
      >
        <button className={classes.button} onClick={toggleDrawer}>
          <img src={TRANSFER} className={classes.image} />
        </button>
      </Badge>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={onClose}
        className={classes.drawer}
      >
        {open ? (
          <AllBaccaratsProvider key="baccaratRoom">
            <RoomList width={appWidth} />
          </AllBaccaratsProvider>
        ) : null}
      </Drawer>
    </Box>
  )
}

export default React.memo(TransferButton)
