import React, { useMemo } from 'react'

import { useMeasure } from 'react-use'

import { Box, makeStyles, Theme } from '@material-ui/core'

import { useGameStatus } from '../BaccaratRooms/GameProvider'
import Opening from '../BaccaratRooms/GameRoom/Opening'
import Hint from '../BaccaratRooms/Hint'
import BetPanel from './BetPanel'
import Buttons from './Buttons'
import CurrentRoundBets from './CurrentBet'
import HintRoad from './HintRoad'
import Jackpot from './Jackpot'
import Road from './Road'

interface StyleProps {
  height: string
}

const useStyles = makeStyles((theme) => ({
  root: {},
  game: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  gamePanel: {
    flexGrow: 1.2,
    flexBasis: 0,
    marginRight: theme.spacing(2),
  },
  betPanel: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  openingContainer: {
    position: 'relative',
    width: '100%',
  },
  shuffle: {},
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'items-stretch',
    marginTop: theme.spacing(1),
  },
  road: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function RoomGame() {
  const { status, shuffle } = useGameStatus()
  const styles = useStyles()

  return (
    <Box className={styles.root}>
      <Box className={styles.game}>
        <Box className={styles.gamePanel}>
          <HintRoad />
          <Box className={styles.openingContainer}>
            <Road />
            <Box className={styles.road}>
              {shuffle ? (
                <Hint />
              ) : status !== 'START_BET' ? (
                <Opening pointsClassName="text-[150%]" />
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className={styles.betPanel}>
          <CurrentRoundBets />
          <BetPanel />
        </Box>
      </Box>
      <Box className={styles.footer}>
        <Jackpot />
        <Buttons />
      </Box>
    </Box>
  )
}

export default RoomGame
