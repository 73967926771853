import React, { useMemo } from 'react'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'
import Card from 'components/common/Card'
import Player from 'screens/application/Room/Opening/Player'
import clsx from 'clsx'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 4,
    marginTop: theme.spacing(1),
  },
  dealer: {
    border: '1px solid white',
    borderRadius: '4px',
    padding: '24px 43px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dealerName: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.white,
  },
  result: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
  },
  cardContainer: {
    display: 'flex',
  },
  winner: {
    borderColor: theme.palette.primary.main,
  },
}))

const Dealer = ({ dealer }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getResult = (result) => {
    if (result < 0) return t('screens.room.none')
    if (result === 20) return t('screens.room.odds20')
    if (result === 50) return t('screens.room.odds50')
    if (result) return `牛${result}`
    return null
  }

  const cards = useMemo(() => {
    return compact(
      dealer.bestCards || [
        dealer.card1,
        dealer.card2,
        dealer.card3,
        dealer.card4,
        dealer.card5,
      ],
    )
  }, [dealer])

  return (
    <Box
      className={clsx(classes.dealer, dealer.isWinner ? classes.winner : null)}
    >
      <Box className={classes.dealerName}>{t('screens.room.dealer')}</Box>
      <Box className={classes.cardContainer}>
        {cards.map((card, index) => (
          <Box key={card} mr={index === 1 ? 1 : 0}>
            <Card key={card} card={card} on selected width={28} />
          </Box>
        ))}
      </Box>
      <Box className={classes.result}>{getResult(dealer.result)}</Box>
    </Box>
  )
}

const GamePanel = ({ game }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Dealer dealer={game.dealer} />
      <Box width="100%">
        <Grid container spacing={1}>
          <Grid item xs>
            <Player
              name={`${t('screens.room.player')}1`}
              player="player1"
              game={game}
            />
          </Grid>
          <Grid item xs>
            <Player
              name={`${t('screens.room.player')}2`}
              player="player2"
              game={game}
            />
          </Grid>
          <Grid item xs>
            <Player
              name={`${t('screens.room.player')}3`}
              player="player3"
              game={game}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default withProfiler(React.memo(GamePanel))
