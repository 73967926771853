import React, { useEffect, useRef, useState } from 'react'

import UPDATE_PROFILE from 'graphql/application/mutations/updateProfile'
import { useSnackbar } from 'notistack'
import { useProfile } from 'providers/richman/ProfileProvider'
import { useForm } from 'react-hook-form'

import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useChatroom } from '../../../ChatroomProvider'
import Message from './Message'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  container: {
    background: 'rgba(53, 53, 53, 0.75)',
    boxShadow: 'inset 6px 6px 10px rgba(0, 0, 0, 0.41)',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    overflowY: 'scroll',
    width: '70%',
    height: '100%',
    flexDirection: 'column',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
}))

const Chatroom = ({}) => {
  const classes = useStyles()
  const { open, messages, toggleChatroom } = useChatroom()
  const { profile } = useProfile()
  const [update] = useMutation(UPDATE_PROFILE)
  const [nicknameOpen, setNicknameOpen] = useState(false)
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const ref = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const onClose = () => setNicknameOpen(false)

  const onSubmit = async (data) => {
    const result = await update({
      variables: {
        input: {
          ...data,
          username: profile.username,
          oldPassword: null,
          newPassword: null,
          confirmNewPassword: null,
          avatar: null,
        },
      },
    })

    const { errors } = result.data.updateProfile
    if (errors.length > 0) {
      errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' }))
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (profile.nickname === null && open) {
      setNicknameOpen(true)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const el = document.getElementById('scrollRef')
      if (el) {
        el.scrollIntoView({ behavior: 'auto' })
        el.scrollTo(0, el.scrollHeight)
      }
    }
  }, [messages, open])

  if (!open) return null

  return (
    <Box className={classes.root}>
      <Box className={classes.container} {...{ ref }} id="scrollRef">
        {messages.map((message, index) => (
          <Message key={message.uuid} message={message} />
        ))}
      </Box>
      {profile.nickname === null && open && (
        <Dialog
          open={nicknameOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              onClose()
            }
          }}
        >
          <DialogContent>
            <TextField
              variant="outlined"
              placeholder="設定暱稱"
              name="nickname"
              inputRef={register({ required: '暱稱為必填' })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default React.memo(Chatroom)
