import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGameContent } from '../../GameProvider'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  win: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  tieWin: {
    position: 'absolute',
    top: '22%',
    left: '39%',
    width: '20%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const TieWin = () => {
  const classes = useStyles()
  const { win } = useGameContent()

  if (!win.tie) return null

  return (
    <Box
      className={clsx(
        classes.tieWin,
        'animate__animated animate__tada animate__infinite',
      )}
    >
      <img
        src={require('images/richman/tie_win.png')}
        className={classes.img}
      />
    </Box>
  )
}

export default React.memo(TieWin)
