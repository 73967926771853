import React from 'react'
import { Box } from '@material-ui/core'
import { useGame } from 'screens/richman/AllRooms/GameProvider'
import Card from 'components/richman/Card'

const ThirdCard = ({ height, target }) => {
  const state = useGame()
  const cards = state[`${target}Cards`]

  if (cards?.length !== 3) return null

  return (
    <Box
      style={{
        transform: 'rotate(90deg)',
        width: height / 5,
      }}
    >
      <Card width={height / 5} selected card={cards[2]} on soundOn={false} />
    </Box>
  )
}

export default React.memo(ThirdCard)
