import React from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  message: {
    background: 'rgba(57, 66, 90, 0.6)',
    borderRadius: 9.5,
    fontSize: theme.typography.pxToRem(12),
    color: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}))

const Join = ({ message }) => {
  const classes = useStyles()

  return <Typography className={classes.message}>{message.body}</Typography>
}

export default React.memo(Join)
