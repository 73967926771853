import React, { useEffect, useLayoutEffect, useState } from 'react'

import Player from 'components/richman/Player'
import GET_BACCARAT_ROOM from 'graphql/application/queries/getBaccaratRoom'
import { useParams } from 'react-router-dom'
import { useMeasure } from 'react-use'
import GameProvider from 'screens/richman/AllRooms/GameProvider'

import { useLazyQuery, useQuery } from '@apollo/client'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AlertText from './AlertText'
import BeadRoad from './BeadRoad'
import Chatroom from './Chatroom'
import ChatroomProvider from './ChatroomProvider'
import GameResult from './GameResult'
import Road from './Road'
import RoomTicker from './RoomTicker'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    position: 'relative',
  },
  road: {
    borderColor: 'black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
  beadroad: {
    height: '100%',
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  video: {
    objectFit: 'cover',
    position: 'relative',
  },
}))

const ShowRoom = ({}) => {
  const { id } = useParams()
  const classes = useStyles()
  const [ref, { width, height }] = useMeasure()
  const [size, setSize] = useState({
    width: null,
    height: null,
    containerWidth: null,
    containerHeight: null,
  })
  const { data } = useQuery(GET_BACCARAT_ROOM, {
    variables: { id },
  })

  const resize = () => {
    const clientWidth = document.documentElement.clientWidth // 得到设备宽度
    const clientHeight = document.documentElement.clientHeight // 得到设备高度

    if (clientWidth / clientHeight > 16 / 9) {
      setSize({
        ...size,
        containerWidth: (clientHeight * 16) / 9,
        containerHeight: clientHeight,
      })
    } else {
      setSize({
        ...size,
        containerWidth: clientWidth,
        containerHeight: (clientWidth * 9) / 16,
      })
    }
  }

  useEffect(() => {
    setSize({
      ...size,
      width: (height / 9) * 16,
      height,
    })
  }, [height])

  useEffect(() => {
    resize()
    document.addEventListener('resize', resize)
    return () => {
      document.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      location.reload()
    }, 60 * 30 * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Box className={classes.root}>
      {size.containerWidth && size.containerHeight && (
        <Box width={size.containerWidth} height={size.containerHeight}>
          {data && (
            <GameProvider room={data.baccaratRoom} open={false}>
              <ChatroomProvider id={id}>
                <Box
                  {...{ ref }}
                  display="flex"
                  alignItems="stretch"
                  flexGrow={1}
                  width="100%"
                  position="relative"
                >
                  <Box
                    width="63%"
                    // height={size.height}
                    className={classes.video}
                  >
                    <Player
                      applicationName={data.baccaratRoom.streamName}
                      streamName={data.baccaratRoom.streamKey}
                      transformOrigin={data.baccaratRoom.zoomType}
                      defaultScale={data.baccaratRoom.defaultScale}
                      soundOn
                      videoOn
                    />
                    <AlertText />
                    <RoomTicker />
                    <Chatroom />
                  </Box>
                  <GameResult roomName={data.baccaratRoom.name} />
                </Box>
                <Box width="100%" bgcolor="white">
                  <Grid container spacing={0} alignItems="stretch">
                    <Grid item xs className={classes.beadroad}>
                      <BeadRoad />
                    </Grid>
                    <Grid item xs>
                      <Box className={classes.road}>
                        <Road size={size} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </ChatroomProvider>
            </GameProvider>
          )}
        </Box>
      )}
    </Box>
  )
}

export default React.memo(ShowRoom)
