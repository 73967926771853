import React, { useCallback } from 'react'
import { Typography, Button } from '@material-ui/core'

import { delayAtLeast } from 'helpers/delay'
import useBetState from 'hooks/useBetState'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import REPEAT_BTN from 'images/richman/repeat_btn.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    flexGrow: 1,
    flexBasis: 0,
    margin: '0 1.5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:disabled': {
      opacity: '50%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

const RepeatButton = () => {
  const classes = useStyles()
  const [betState, { disable, enable, repeat }] = useBetState()
  const { t } = useTranslation()

  const repeatWithDisable = useCallback(async () => {
    disable()
    await delayAtLeast(repeat, 100)
    enable()
  }, [disable, enable, repeat])

  return React.useMemo(() => {
    return (
      <button className={classes.button} onClick={repeatWithDisable}>
        <img src={REPEAT_BTN} className={classes.image} />
      </button>
    )
  }, [betState.disabled])
}

export default React.memo(RepeatButton)
