import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import useAuth from 'hooks/useAuth'

const LOGOUT = gql`
  mutation logout($input: LogoutInput!) {
    logout(input: $input) {
      ok
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    background:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    borderWidth: '0px 1.5px 1px 0px',
    borderStyle: 'solid',
    borderImageSource:
      'linear-gradient(132.5deg, #502000 0%, #AB5F00 18.08%, #FEC47F 61.45%, #FFECE0 78.36%, #FFF5E1 86.19%, #FFE6BC 116.51%, #C77800 158.02%, #4A2900 191.12%)',
    boxShadow: '2px 2px 4px 0px #1F1F1F9C inset',
    position: 'relative',
    width: '100%',
  },
  inner: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(171.43deg, #163676 2.68%, #091231 101.43%)',
    boxShadow:
      'inset 1px 1px 2px rgba(31, 31, 31, 0.61), inset -1px -1px 2px rgba(198, 198, 198, 0.58)',
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(0.5, 1.5),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

const LogoutButton = ({}) => {
  const classes = useStyles()
  const [onLogout, { loading }] = useMutation(LOGOUT)
  const [_, { logout }] = useAuth()
  const { t, i18n } = useTranslation()

  const onClick = async () => {
    await onLogout({
      variables: {
        input: {},
      },
    })
    logout()
  }

  const isZH = ['zhTW', 'zhCN'].includes(i18n.language)

  return (
    <button onClick={onClick} className={classes.btn}>
      <Box className={classes.inner}>{t('screens.profile.logout')}</Box>
    </button>
  )
}

export default React.memo(LogoutButton)
