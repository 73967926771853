import React, { useRef, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/client'
import { moneyFormatWithoutSymbol } from 'helpers/index'
import GET_CREDIT_CHANGE_RECORDS from 'graphql/application/queries/getCreditChangeRecords'
import FullLoading from 'components/common/FullLoading'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useProfile } from 'providers/richman/ProfileProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    background: 'linear-gradient(168.38deg, #081233 -172.33%, #143473 138.23%)',
    border: '1px solid #FFFFFF',
    padding: theme.spacing(1, 1.5),
  },
  titleText: {
    flexGrow: 1,
    flexBasis: 0,
    color: '#ffffff',
    letterSpacing: '0.09em',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    background: '#D6D6D6',
    padding: theme.spacing(0.5, 1.5),
  },
  value: {
    flexGrow: 1,
    flexBasis: 0,
    color: '#353535',
    fontSize: theme.typography.pxToRem(12),
  },
  contents: {},
}))

const CreditChangeRecords = () => {
  const classes = useStyles()
  const {
    profile: { role },
  } = useProfile()
  const { data, loading, error } = useQuery(GET_CREDIT_CHANGE_RECORDS, {
    variables: {
      types:
        role === 'Normal'
          ? ['withdraw_usdt', 'deposit_usdt']
          : ['create_user', 'withdraw_user', 'deposit_user'],
    },
  })
  const { t } = useTranslation()
  const ref = useRef(null)

  useEffect(() => {
    if (data && ref.current) {
      const listItems = ref?.current?.children

      for (let i = 0; i < listItems.length; i++) {
        listItems[i].classList.add('animate__animated', 'animate__fadeInUp')
        listItems[i].style.animationDelay = `${i * 200}ms`
      }
    }
  }, [data])

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Typography className={classes.titleText} variant="h6">
          {t('common.time')}
        </Typography>
        <Typography className={classes.titleText} variant="h6">
          {t('common.points')}
        </Typography>
      </Box>
      <div className={classes.contents} ref={ref}>
        {loading && <FullLoading />}
        {data &&
          data.creditChangeRecords.map((record) => (
            <Record key={record.id} record={record} />
          ))}
      </div>
    </Box>
  )
}

const Record = ({ record }) => {
  const classes = useStyles()

  return (
    <Box className={classes.row}>
      <Typography variant="body2" className={classes.value}>
        {moment(record.createdAt).format('YYYY/MM/DD')}
      </Typography>
      <Typography variant="body2" className={classes.value}>
        {moneyFormatWithoutSymbol(
          parseInt(record.newCreditLeft) - parseInt(record.prevCreditLeft),
        )}
      </Typography>
    </Box>
  )
}

export default React.memo(CreditChangeRecords)
