import React, { useEffect, useRef } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { useChatroom } from './ChatroomProvider'
import Message from './Message'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    borderRadius: theme.spacing(1),
    color: 'white',
    width: '40%',
    height: 100,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowY: 'scroll',
  },
}))

export default function Chatroom() {
  const styles = useStyles()
  const { messages } = useChatroom()
  const ref = useRef(null)

  useEffect(() => {
    let scrollRef = ref.current
    if (scrollRef) {
      scrollRef.scrollIntoView({ behavior: 'auto' })
      scrollRef.scrollTo(0, scrollRef.scrollHeight)
    }
  }, [messages])

  return (
    <Box {...{ ref }} className={styles.container}>
      {messages.map((message, index) => (
        <Message key={message.uuid} message={message} />
      ))}
    </Box>
  )
}
