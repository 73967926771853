import React, { useState } from 'react'
import { Box, FormGroup, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
  },
  input: {
    color: theme.palette.text.primary,
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
  },
}))

const TextInput = ({
  name,
  label,
  errors = null,
  formGroupProps = {},
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormGroup {...formGroupProps}>
      <TextField
        className={classes.root}
        margin="normal"
        id={name}
        label={label}
        name={name}
        error={errors && !!errors[name]}
        helperText={errors && !!errors[name] && errors[name]?.message}
        InputProps={{ className: classes.input }}
        variant={props.variant || 'outlined'}
        fullWidth
        {...props}
      />
    </FormGroup>
  )
}

export default withProfiler(React.memo(TextInput))
