import React, { useState, useRef, useEffect } from 'react'
import { Box, TextField, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery, gql } from '@apollo/client'
import CountUp from 'react-countup'

const UPDATE_POOL = gql`
  mutation updatePool($input: UpdatePoolInput!) {
    updatePool(input: $input) {
      value
    }
  }
`
const POOL = gql`
  query pool {
    pool
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    background:
      '#090017 linear-gradient(136deg, rgba(217, 51, 137, 0.4) 0%, rgba(59, 195, 243, 0.4) 50%, rgba(15, 255, 135, 0.4) 100%)',
    backgroundAttachment: 'fixed',
  },
  btn: {
    background:
      'linear-gradient(90deg, rgba(238, 58, 136, 0.8) 0%, rgba(54, 108, 244, 0.8) 100%)',
    color: 'white',
    marginTop: theme.spacing(2),
  },
  money: {
    background: 'linear-gradient(90deg, #50d9f6, #bd70d5)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: theme.typography.pxToRem(80),
    fontWeight: 'bold',
  },
}))

const UpdatePool = ({}) => {
  const classes = useStyles()
  const [update] = useMutation(UPDATE_POOL)
  const { data } = useQuery(POOL, {
    pollInterval: 1000,
  })
  const [oldValue, setOldValue] = useState(0)
  const [newValue, setNewValue] = useState(0)
  const ref = useRef(null)
  const [amount, setAmount] = useState('')

  const onUpdate = async () => {
    const result = await update({
      variables: {
        input: {
          amount,
        },
      },
    })
    const { value } = result.data.updatePool
    setOldValue(newValue)
    setNewValue(value)
    setAmount('')
  }

  useEffect(() => {
    if (data) {
      setNewValue(data.pool)
    }
  }, [data])

  const onEnd = () => {}

  return (
    <Box className={classes.root}>
      <Typography variant="h1" className={classes.money}>
        {data && (
          <CountUp
            end={newValue}
            start={oldValue}
            onEnd={onEnd}
            separator=","
            duration={1}
          />
        )}
      </Typography>
      <TextField
        inputRef={ref}
        type="number"
        label="更新彩池"
        onChange={(e) => setAmount(e.target.value)}
        value={amount}
        fullWidth
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onUpdate}
        className={classes.btn}
      >
        更新
      </Button>
    </Box>
  )
}

export default React.memo(UpdatePool)
