import React, { useEffect, useMemo, useRef, useState } from 'react'

import axios from 'axios'
import useAuth from 'hooks/useAuth'
import LOGO from 'images/icons/login_logo.png'
import SHARE from 'images/icons/share.png'
import { useSnackbar } from 'notistack'
import { useSiteConfig } from 'providers/common/SiteConfig'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { RWebShare } from 'react-web-share'

import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import DownloadAppBanner from './DownloadAppBanner'

const CREATE_TRIAL = gql`
  mutation createTrialUser($input: CreateTrialUserInput!) {
    createTrialUser(input: $input) {
      token
    }
  }
`

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  hint: {
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bg: {
    width: '100%',
    height: '100%',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '15%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      padding: 0,
    },
  },
  link: {
    color: '#2F80ED',
  },
  label: {
    // color: theme.palette.primary.main,
    color: '#fff',
  },
  trial: {
    backgroundColor: theme.palette.primary.main,
    transition: 'background 1s',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(255,103,103,1) 0%, rgba(140,0,0,1) 100%);',
      color: 'white',
    },
  },
  btn: {
    minHeight: theme.spacing(6),
    fontSize: theme.typography.pxToRem(24),
  },
  signUpBtn: {
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(6),
    fontSize: theme.typography.pxToRem(24),
  },
  logoPlaceholder: {
    height: 356,
  },
  shareImg: {
    width: 23.8,
    height: 23.8,
    cursor: 'pointer',
  },
}))

const Login: React.FC = () => {
  const classes = useStyles()
  let history = useHistory()
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  })
  const { enqueueSnackbar } = useSnackbar()
  const [create, { loading: createLoading }] = useMutation(CREATE_TRIAL)
  const [{ isAuthenticated, loading }, { login }] = useAuth()
  const [rememberMe, setRememberMe] = useState(false)
  const [authInfo, setAuthInfo, removeAuthInfo] = useLocalStorage('authInfo', {
    identity: null,
    password: null,
    rememberMe: false,
  })
  const formRef = useRef(null)
  const { t } = useTranslation()
  const { host } = useSiteConfig()

  useEffect(() => {
    const lineUserId = window.localStorage.getItem('lineUserId')
    if (lineUserId) {
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  useEffect(() => {
    setRememberMe(authInfo.rememberMe)
  }, [])

  const onSubmit = async (data) => {
    const lineUserId = window.localStorage.getItem('lineUserId')
    axios
      .post('/login', {
        ...data,
        line_user_id: lineUserId,
      })
      .then((response) => {
        const token = response.data.token
        if (rememberMe) {
          setAuthInfo({
            ...data,
            rememberMe,
          })
        } else {
          setAuthInfo({
            identity: null,
            password: null,
            rememberMe: false,
          })
        }
        login(token)
      })
      .catch((error) => {
        console.log(error)
        const msg = error.response.data?.message
        enqueueSnackbar(msg || t('screens.login.wrongPassword'), {
          variant: 'error',
        })
      })
  }

  const onChangeRememberMe = (event) => {
    setRememberMe(event.target.checked)
  }

  const createTrail = async () => {
    const result = await create({
      variables: {
        input: {},
      },
    })
    const { token } = result.data.createTrialUser
    if (token) {
      login(token)
    }
  }

  const showCaptcha = () => {
    const captcha = new window.TencentCaptcha('1259693855', createTrail)
    captcha.show()
  }

  const state = useLocation()

  const canSignUp = useMemo(() => {
    const host = window.location.host
    return host?.split('.')[0] === 'usdt'
  }, [window.location])

  const goSignUp = () => {
    history.push('/sign_up')
  }

  return (
    <Box className={classes.bg}>
      <DownloadAppBanner />
      <img src={LOGO} style={{ width: 100, height: 118, marginBottom: 16 }} />
      <form
        className={classes.form}
        noValidate
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <InputLabel focused htmlFor="username">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {t('screens.login.username')}
              <RWebShare
                data={{
                  url: `https://${host}`,
                  title: t('common.site'),
                  text: t('common.shareText'),
                }}
                onClick={() => console.log('shared successfully!')}
              >
                <img src={SHARE} className={classes.shareImg} />
              </RWebShare>
            </Box>
          </InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="identity"
            name="identity"
            inputRef={register({ required: true })}
            defaultValue={authInfo.identity}
            autoCorrect="off"
            autoCapitalize="none"
          />
        </FormGroup>
        <FormGroup>
          <InputLabel focused htmlFor="password">
            {t('screens.login.password')}
          </InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            defaultValue={authInfo.password}
            name="password"
            type="password"
            id="password"
            inputRef={register({ required: true })}
            autoComplete="current-password"
          />
        </FormGroup>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormGroup>
            <FormControlLabel
              className={classes.label}
              label={
                <span className={classes.label}>
                  {t('screens.login.rememberMe')}
                </span>
              }
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={onChangeRememberMe}
                  name="rememberMe"
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
            />
          </FormGroup>
          <Button
            variant="contained"
            size="small"
            disabled={createLoading}
            className={classes.trial}
            onClick={showCaptcha}
          >
            {t('screens.login.trialLogin')}
          </Button>
        </Box>
        <Typography className={classes.hint}>
          {t('screens.login.entertainment')}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          disabled={loading || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
          className={classes.btn}
        >
          {loading && <CircularProgress />}
          {!loading && t('screens.login.login')}
        </Button>
        {canSignUp && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={goSignUp}
            className={classes.signUpBtn}
          >
            註冊
          </Button>
        )}
      </form>
    </Box>
  )
}

export default Login
