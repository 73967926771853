import { useEffect } from 'react'

export default function useNoLoadingScreen() {
  useEffect(() => {
    const meta = document.createElement('meta')
    meta.name = 'no-loading-screen'
    meta.content = 'true'
    document.head.appendChild(meta)

    return () => {
      document.head.removeChild(meta)
    }
  }, [])
}
