import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfile } from 'providers/richman/ProfileProvider'
import useFontSizeByLang from 'hooks/useFontSizeByLang'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import ADULT from 'images/icons/header/adult.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    background:
      'radial-gradient(70.33% 72.35% at 28.14% 43.03%, #081334 0%, #193978 100%)',
    marginBottom: 3,
  },
  icon: {
    width: 18,
    height: 18,
  },
}))

const AdultButton = ({ onCloseAnchor }) => {
  const classes = useStyles()
  const {
    profile: { platformDisableAdult },
  } = useProfile()

  const { t } = useTranslation()

  const buttonClass = clsx(
    classes.button,
    useFontSizeByLang({ zhSize: 24, otherSize: 16 }),
  )

  const adultLink = () => (window.location.href = 'https://t.me/richman5888')

  if (!platformDisableAdult) {
    return (
      <Button
        color="primary"
        fullWidth
        className={buttonClass}
        startIcon={<img src={ADULT} className={classes.icon} />}
        onClick={adultLink}
      >
        {t('components.header.adultVideo')}
      </Button>
    )
  }

  return null
}

export default React.memo(AdultButton)
