import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
}))

const NotificationContext = createContext(null)

const NotificationProvider = ({ children }) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (title !== '') {
      const timeout = setTimeout(() => {
        setTitle('')
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [open, title])

  const api = useMemo(
    () => ({
      setNotification: setTitle,
    }),
    [],
  )

  return (
    <NotificationContext.Provider value={api}>
      {children}
      <Title title={title} />
    </NotificationContext.Provider>
  )
}

function Title({ title }) {
  if (title !== '') {
    return (
      <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#ffd700] rounded py-2 px-3 text-lg">
        {title}
      </div>
    )
  }
  return null
}

export const useNotification = () => useContext(NotificationContext)

export default withProfiler(React.memo(NotificationProvider))
