import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Placeholder from 'images/video_placeholder.jpeg'
import { isMobile } from 'react-device-detect'
import OvenPlayer from 'ovenplayer'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    // paddingBottom: '56.25%',
    overflow: 'hidden',
  },
  autoSize: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transformOrigin: 'bottom',
    transition: 'all 1s ease-in-out',
  },
  videoOff: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: theme.spacing(1, 2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.secondary.main,
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

const Player = ({
  name,
  streamKey,
  type,
  soundOn,
  videoOn,
  defaultScale = 1.0,
  enlarge = false,
  transformOrigin = 'bottom',
}) => {
  const classes = useStyles()
  const ref = useRef(null)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const hidePlaceholder = useCallback(() => {
    setShowPlaceholder(false)
  }, [setShowPlaceholder])

  const touchstartHandler = (e) => {
    if (!isMobile) {
      return
    }
    let video = ref.current
    video.muted = !soundOn
    video.volume = soundOn ? 1 : 0
  }

  useEffect(() => {
    document.addEventListener('touchstart', touchstartHandler)
    return () => {
      document.removeEventListener('touchstart', touchstartHandler)
    }
  }, [])

  const scale = useMemo(() => {
    const scaleValue = enlarge ? (defaultScale || 1.0) * 2 : defaultScale || 1.0
    return `scale(${scaleValue})`
  }, [enlarge])

  useEffect(() => {
    if (type === 'webrtc') {
      const player = OvenPlayer.create(
        `ov_player_${name}_${streamKey}_${type}`,
        {
          autoStart: true,
          autoFallback: true,
          mute: false,
          controls: false,
          sources: [
            {
              type: 'webrtc',
              file: `wss://ome.r1chman.tech/app/${name}_${streamKey}`,
            },
          ],
        },
      )
    } else if (type === 'llhls') {
      const player = OvenPlayer.create(
        `ov_player_${name}_${streamKey}_${type}`,
        {
          autoStart: true,
          autoFallback: true,
          mute: false,
          controls: false,
          sources: [
            {
              type: 'll-hls',
              file: `https://ome.r1chman.tech/app/${name}_${streamKey}/llhls.m3u8`,
            },
          ],
        },
      )
    }
  }, [type])

  return (
    <Box className={classes.root}>
      {videoOn && (
        <video
          autoPlay
          playsInline
          muted={isMobile || !soundOn}
          ref={ref}
          // onCanPlay={hidePlaceholder}
          className={classes.autoSize}
          id={`ov_player_${name}_${streamKey}_${type}`}
          style={{ transformOrigin, transform: scale }}
        />
      )}
      {!videoOn && <Box className={classes.videoOff}>視訊已關閉</Box>}
      {/* {showPlaceholder && <img src={Placeholder} className={classes.video} />} */}
    </Box>
  )
}

export default React.memo(Player)
