import React, { useMemo } from 'react'

import RTCPlayer from 'components/common/RTCPlayer'
import Player from 'components/richman/Player'
import { useProfileSettings } from 'hooks/useProfileSettings'
import CFWHEPOutput from 'components/richman/CFWHEPOutput'
import OvenPlayer from 'components/common/OvenPlayer'

import { Box } from '@material-ui/core'

import { useGameStatus } from '../../../GameProvider'
import { useStreamState } from '../index'

const SelectedPlayer = ({}) => {
  const {
    room,
    room: { zoomType, defaultScale, id, name, cfWebrtcPlaybackUrl, transform },
    state: { active, videoOn, player, enlarge },
  } = useStreamState()
  const { settings } = useProfileSettings()
  const { status, shuffle } = useGameStatus()

  const streams = useMemo(() => {
    let arr = []
    arr.push({ key: room.streamKey, name: room.streamName })
    room.streams.forEach((stream) => {
      arr.push({ key: stream.key, name: stream.name })
    })
    return arr
  }, [room])

  const needEnlarge = useMemo(() => {
    return (
      [
        'STOP_BET',
        'DRAW',
        'DRAW_CONFIRMED',
        'PLAYER_DRAW_THIRD',
        'DEALER_DRAW_THIRD',
        'PLAYER_DRAW_THIRD_CONFIRMED',
        'DEALER_DRAW_THIRD_CONFIRMED',
      ].indexOf(status) !== -1 &&
      !shuffle &&
      enlarge
    )
  }, [status, shuffle, enlarge])

  if (!videoOn) {
    return <Box width="100%" paddingBottom="56.25%"></Box>
  }

  if (name.indexOf('巨星') !== -1) {
    if (player === 'cf' && active === 0) {
      return (
        <CFWHEPOutput
          url={cfWebrtcPlaybackUrl}
          videoOn={videoOn}
          soundOn={settings?.micOn}
          style={transform ? { transform } : {}}
        />
      )
    } else if (player === 'webrtc') {
      return (
        <OvenPlayer
          name={streams[active].name}
          streamKey={streams[active].key}
          type="webrtc"
          soundOn={settings?.micOn}
          videoOn={videoOn}
          defaultScale={defaultScale}
        />
      )
    } else if (player === 'llhls') {
      return (
        <OvenPlayer
          name={streams[active].name}
          streamKey={streams[active].key}
          type="llhls"
          soundOn={settings?.micOn}
          videoOn={videoOn}
          defaultScale={defaultScale}
        />
      )
    } else {
      return (
        <Player
          applicationName={streams[active].name}
          streamName={streams[active].key}
          soundOn={settings?.micOn}
          videoOn={videoOn}
          enlarge={needEnlarge && active === 0}
          transformOrigin={zoomType}
          defaultScale={defaultScale}
        />
      )
    }
  } else {
    return (
      <Player
        applicationName={streams[active].name}
        streamName={streams[active].key}
        soundOn={settings?.micOn}
        videoOn={videoOn}
        enlarge={needEnlarge && active === 0}
        transformOrigin={zoomType}
        defaultScale={defaultScale}
      />
    )
  }
}

export default React.memo(SelectedPlayer)
