import React from 'react'

import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useStreamApi, useStreamState } from '../index'

import CHANGE_VIDEO from 'images/icons/change_camera.png'

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0,
    transform: 'scale(0)',
  },
  openRoot: {
    background: '#424242',
    opacity: 1,
    marginRight: theme.spacing(1),
    transition:
      'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s',
  },
  video: {
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 20,
    height: 20,
  },
}))

const ChangeVideoCamButton = ({ open, onClose }) => {
  const classes = useStyles()
  const { room } = useStreamState()
  const { dispatch } = useStreamApi()

  const onClick = () => {
    onClose()
    dispatch({ type: 'TOGGLE_STREAM' })
  }

  if (room.streams?.length > 0) {
    return (
      <IconButton
        onClick={onClick}
        className={open ? classes.openRoot : classes.root}
        style={{ transitionDelay: open ? '0ms' : '30ms' }}
      >
        <img src={CHANGE_VIDEO} className={classes.image} />
      </IconButton>
    )
  }

  return null
}

export default React.memo(ChangeVideoCamButton)
