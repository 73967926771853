import React, { useState } from 'react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, gql } from '@apollo/client'
import sendGiftRecordFragments from 'graphql/application/fragments/sendGiftRecordFragments'
import moment from 'moment'
import { useUpdateEffect } from 'react-use'
import GiftRecord from './GiftRecord'
import { useTranslation } from 'react-i18next'

const GET_GIFTS = gql`
  query getGifts(
    $page: Int!
    $perPage: Int!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    sendGiftRecords(
      page: $page
      perPage: $perPage
      startDate: $startDate
      endDate: $endDate
    ) {
      records {
        id
        ...sendGiftRecordFragments
      }
      totalCount
      totalPages
      currentCount
      currentPage
    }
  }

  ${sendGiftRecordFragments}
`
const useStyles = makeStyles((theme) => ({
  root: {},
  tableHead: {
    fontSize: theme.typography.pxToRem(14),
    color: 'rgba(255, 255, 255, 0.6)',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(11),
      padding: theme.spacing(0.5),
    },
  },
}))

const GiftRecords = ({}) => {
  const classes = useStyles()
  const [search, setSearch] = useState({
    page: 1,
    perPage: 50,
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
  })
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(GET_GIFTS, {
    variables: {
      ...search,
    },
  })

  useUpdateEffect(() => {
    refetch({
      ...search,
    })
  }, [search])

  return (
    <Box className={classes.root}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableHead}>
                {t('common.roomName')}
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                {t('common.giftType')}
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                {t('common.amount')}
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                {t('common.girl')}
              </TableCell>
              <TableCell align="left" className={classes.tableHead}>
                {t('common.time')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.sendGiftRecords.records.map((record) => (
                <GiftRecord record={record} key={record.id} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default React.memo(GiftRecords)
