import React, { useCallback, useMemo } from 'react'

import clsx from 'clsx'
import useCurrentRoom from 'hooks/useCurrentRoom'
import useLeftBetTime from 'hooks/useLeftBetTime'
import useSelectedRoom from 'hooks/useSelectedRoom'

import { Box, makeStyles, Typography } from '@material-ui/core'

import { useGameStatus } from '../BaccaratRooms/GameProvider'
import Countdown from './Countdown'
import { useNotificationTitle } from './NotificationProvider'
import { useTranslation } from 'react-i18next'
import CAMERA_ON from 'images/icons/camera_on.png'
import CAMERA_OFF from 'images/icons/camera_off.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  time: {
    fontSize: theme.typography.pxToRem(16),
  },
  name: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
  },
  round: {
    flexBasis: theme.typography.pxToRem(150),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(14),
  },
  cameraOn: {
    color: theme.palette.tie.main,
  },
  icon: {
    width: 20,
    height: 20,
  },
  notification: {
    background: 'linear-gradient(136.01deg, #FF6767 0%, #8C0000 83.28%)',
    boxShadow:
      '2.25px 1.5px 1.5px rgba(0, 0, 0, 0.3), -1.5px -1.5px 2.25px rgba(211, 211, 211, 0.3)',
    borderRadius: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(0.5, 1.5),
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
  },
  startBet: {
    // background: theme.palette.tie.main,
  },
}))

function RoomHeader() {
  const styles = useStyles()
  const { room } = useCurrentRoom()
  const gameState = useGameStatus()
  const [selectedRoom, select] = useSelectedRoom()
  const { title } = useNotificationTitle()
  const left = useLeftBetTime(room)
  const { t } = useTranslation()

  const toggleThisRoom = useCallback(() => {
    select(selectedRoom === room ? null : room)
  }, [selectedRoom, room, select])

  const showTimer = gameState.status === 'START_BET'

  const gameNo = useMemo(
    () =>
      gameState.gameNo &&
      gameState.gameNo.substring(gameState.gameNo.length - 5),
    [gameState.gameNo],
  )

  return (
    <Box className={styles.root}>
      <Box style={{ visibility: showTimer ? 'visible' : 'hidden' }}>
        <Countdown key={gameNo} left={left} />
      </Box>
      <Box className={styles.name}>
        <Typography>{room.name}</Typography>
        <Typography
          className={clsx(styles.notification, {
            [styles.startBet]: showTimer,
          })}
        >
          {title}
        </Typography>
      </Box>
      <Box className={styles.round}>
        {t('screens.baccaratRoom.gameNo')} {gameNo}
      </Box>
      <Box className={selectedRoom === room ? styles.cameraOn : ''}>
        <img
          src={selectedRoom === room ? CAMERA_OFF : CAMERA_ON}
          className={styles.icon}
          onClick={toggleThisRoom}
        />
      </Box>
    </Box>
  )
}

export default RoomHeader
