import { useCallback } from 'react'

import profileFragments from 'graphql/application/fragments/profileFragments'
import useCurrentRoom from 'hooks/useCurrentRoom'

import { gql, useMutation } from '@apollo/client'

import { useNotification } from '../../NotificationProvider'

const MUTATION = gql`
  mutation sendCustomGift($roomId: ID!, $amount: Int!, $comment: String!) {
    sendCustomGift(
      input: { roomId: $roomId, amount: $amount, comment: $comment }
    ) {
      profile {
        id
        ...profileFragments
      }
      errors {
        message
        attribute
      }
    }
  }

  ${profileFragments}
`

export default function useSendCustomGift() {
  const { room } = useCurrentRoom()
  const [mutate] = useMutation(MUTATION)
  const { setNotification } = useNotification()

  return useCallback(
    async (data) => {
      const response = await mutate({
        variables: {
          roomId: room.id,
          amount: parseInt(data.amount),
          comment: data.comment,
        },
      })

      if (response.data.sendCustomGift.errors.length > 0) {
        setNotification(response.data.sendCustomGift.errors[0].message)
      }
    },
    [mutate, room, setNotification],
  )
}
