import React from 'react'

import Card from 'components/richman/Card'
import { useProfileSettings } from 'hooks/useProfileSettings'

import { Box } from '@material-ui/core'

import { useGameContent, useGameStatus } from '../../GameProvider'

const FirstTwoCards = ({ target }) => {
  const {
    settings: { soundOn },
  } = useProfileSettings()
  const { open } = useGameStatus()
  const content = useGameContent()
  const cards = content[`${target}Cards`]

  if (cards === null) return null

  return (
    <div className="flex justify-center">
      <div className="w-1/4">
        <Card selected card={cards[0]} on soundOn={soundOn && open} />
      </div>
      {cards[1] && (
        <div className="w-1/4">
          <Card selected card={cards[1]} on soundOn={soundOn && open} />
        </div>
      )}
    </div>
  )
}

export default React.memo(FirstTwoCards)
