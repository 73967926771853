import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useProfile } from 'providers/richman/ProfileProvider'
import SIGN_UP from 'images/richman/signup.png'

const useStyles = makeStyles((theme) => ({
  link: {
    position: 'absolute',
    bottom: '16%',
    right: 0,
  },
  img: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))

const SignUp = ({}) => {
  const classes = useStyles()
  const {
    profile: { isTrial },
  } = useProfile()

  if (isTrial) {
    return (
      <a href="https://lihi2.com/b9MyG" className={classes.link}>
        <img src={SIGN_UP} className={classes.img} />
      </a>
    )
  } else {
    return null
  }
}

export default React.memo(SignUp)
