import React from 'react'

import clsx from 'clsx'
import useCommissionFree from 'hooks/useCommissionFree'
import { useTranslation } from 'react-i18next'

import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BetTarget from './BetTarget'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  row: {
    flexGrow: 1.5,
    flexBasis: 0,
    '&:first-child': {
      flexGrow: 1,
      marginBottom: theme.spacing(0.25),
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  item: {
    flexGrow: 1,
    flexBasis: 0,
    margin: theme.spacing(0, 0.25),
  },
  dealer: {
    color: theme.palette.dealer.main,
    fontWeight: 'bold',
  },
  player: {
    color: theme.palette.player.main,
    fontWeight: 'bold',
  },
  tie: {
    color: theme.palette.tie.main,
    fontWeight: 'bold',
  },
  rate: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  img: {
    width: '80%',
    height: 'auto',
  },
  tieImg: {
    width: '40%',
    height: 'auto',
  },
  largeImg: {
    width: '40%',
    height: 'auto',
  },
}))

function BetPanel() {
  const styles = useStyles()
  const { t } = useTranslation()
  const { isCommissionFree } = useCommissionFree()

  return (
    <Box className={styles.root}>
      <Box className={styles.row}>
        <Box className={styles.item}>
          <BetTarget target="playerPair">
            <img
              src={require(`images/betTarget/zhTW/playerPair.png`)}
              className={styles.img}
            />
          </BetTarget>
        </Box>
        <Box className={styles.item}>
          <BetTarget target="tie">
            <img
              src={require(`images/betTarget/zhTW/tie.png`)}
              className={styles.tieImg}
            />
          </BetTarget>
        </Box>
        <Box className={styles.item}>
          <BetTarget target="dealerPair">
            <img
              src={require(`images/betTarget/zhTW/dealerPair.png`)}
              className={styles.img}
            />
          </BetTarget>
        </Box>
      </Box>
      <Box className={styles.row}>
        <Box className={styles.item}>
          <BetTarget target="player">
            <Box display="flex" alignItems="flex-end" justifyContent="center">
              <img
                src={require(`images/betTarget/zhTW/player.png`)}
                className={styles.largeImg}
              />
              <Box className={styles.rate}>1</Box>
            </Box>
          </BetTarget>
        </Box>
        <Box className={styles.item}>
          <BetTarget target="dealer">
            <Box display="flex" alignItems="flex-end" justifyContent="center">
              <img
                src={require(`images/betTarget/zhTW/dealer.png`)}
                className={styles.largeImg}
              />
              <Box className={styles.rate}>
                {isCommissionFree ? '1' : '0.95'}
              </Box>
            </Box>
          </BetTarget>
        </Box>
      </Box>
    </Box>
  )
}

export default BetPanel
