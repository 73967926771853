import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useGameStatus } from '../../GameProvider'

const useStyles = makeStyles((theme) => ({
  root: {},
  currentGame: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(13),
  },
}))

const GameNo = ({}) => {
  const classes = useStyles()
  const { gameNo } = useGameStatus()

  return useMemo(() => {
    return (
      <Box
        position="absolute"
        top="40%"
        right="5%"
        className={classes.currentGame}
      >
        {gameNo?.slice(-5)}
      </Box>
    )
  }, [gameNo])
}

export default React.memo(GameNo)
