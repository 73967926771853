import React, { useEffect, useState } from 'react'

import useAllBaccarats from 'hooks/useAllBaccarats'
import { concat, uniq } from 'lodash'
import Filters from 'screens/richman/BaccaratRooms/Filters'

import { Box, makeStyles, CircularProgress } from '@material-ui/core'

import ChipSelector from './ChipSelector'
import Room from './Room'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  rooms: {
    flexGrow: 1,
    position: 'relative',
  },
  roomsContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflowY: 'auto',
    padding: theme.spacing(3, 1.5),
  },
  chipSelector: {
    flexShrink: 0,
    maxWidth: '100%',
    background: '#0D1E4A',
    padding: theme.spacing(0.5),
    minHeight: '8%',
    [theme.breakpoints.up('sm')]: {
      minHeight: '10%',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

function Rooms() {
  const styles = useStyles()
  const { rooms, nonce, loading } = useAllBaccarats()
  const [selected, setSelected] = useState('所有廳房')

  const halls = concat('所有廳房', uniq(rooms?.map((room) => room.hall.name)))

  const filtered =
    selected === '所有廳房'
      ? rooms
      : rooms?.filter((room) => room.hall.name === selected)

  return (
    <Box className={styles.root}>
      <Box className={styles.rooms}>
        <Box className={styles.roomsContainer} key={nonce}>
          <Filters
            halls={halls}
            selected={selected}
            setSelected={setSelected}
          />
          {loading && (
            <Box className={styles.loadingContainer}>
              <CircularProgress style={{ color: 'gold' }} />
            </Box>
          )}
          <div>
            {filtered &&
              filtered.map((room) => <Room key={room.id} room={room} />)}
          </div>
        </Box>
      </Box>
      <Box className={styles.chipSelector}>
        <ChipSelector />
      </Box>
    </Box>
  )
}

export default Rooms
