import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useCurrentRoom from 'hooks/useCurrentRoom'

const useStyles = makeStyles((theme) => ({
  root: {},
  mainText: {
    color: '#D6D6D6',
    fontSize: theme.typography.pxToRem(12),
  },
}))

const RoomName = ({}) => {
  const classes = useStyles()
  const { room } = useCurrentRoom()

  return <Typography className={classes.mainText}>{room.name}</Typography>
}

export default React.memo(RoomName)
