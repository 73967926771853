import React, { useMemo } from 'react'

import clsx from 'clsx'
import useBetTarget from 'hooks/useBetTarget'
import useCommissionFree from 'hooks/useCommissionFree'
import getTargetRate from 'libs/getTargetRate'
import { useTranslation } from 'react-i18next'

import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useGameContent, useGameData } from '../../GameProvider'
import { useNotification } from '../NotificationProvider'
import Chips from './Chips'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  betName: {
    fontSize: 'calc(6px + var(--vw))',
  },
  betRate: {
    fontSize: 'calc(4px + var(--vh))',
    paddingBottom: '2%',
    color: '#D6D6D6',
  },
  superTieBetRate: {
    fontSize: 'calc(4px + var(--vh))',
    color: '#D6D6D6',
  },
  bet: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    margin: theme.spacing(0, 0.25),
    background: '#1C2B55',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.76)',
    borderRadius: theme.spacing(0.5),
  },
  blueFont: {
    color: '#79DFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
  yellowFont: {
    color: '#12E500',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
  redFont: {
    color: '#FF7A7A',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0px 1px 0.05px #333333',
  },
  inner: {
    background: '#0D1E4A',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1%',
    paddingBottom: '1%',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const AmountLabel = ({ target }) => {
  const gameData = useGameData()
  const targets = gameData.targets[target]

  if (!targets) {
    return null
  }

  const peopleCount = targets.length
  const totalAmount = targets.reduce(
    (acc, cur) => acc + parseInt(cur.amount),
    0,
  )

  return (
    <div className="text-[#D6D6D6] bg-[#353535] text-xs px-2 rounded-md">
      {totalAmount}/{peopleCount}
    </div>
  )
}

const BetBlock = ({ target, display = 'row' }) => {
  const { t, i18n } = useTranslation()
  const { win } = useGameContent()
  const classes = useStyles()
  const notification = useNotification()
  const { disabled, onBet } = useBetTarget({
    target,
    notification,
  })
  const { isCommissionFree } = useCommissionFree()

  const colorClass = useMemo(() => {
    if (target.indexOf('player') !== -1) {
      return classes.blueFont
    } else if (target.indexOf('dealer') !== -1) {
      return classes.redFont
    } else {
      return classes.yellowFont
    }
  }, [target])

  const onClick = () => {
    onBet()
  }

  const isZh = i18n.language === 'zhTW'

  const targetLabel = isZh ? (
    <img
      src={require(`images/betTarget/zhTW/${target}.png`)}
      className="max-h-10"
    />
  ) : (
    <Box className={clsx(colorClass, classes.betName)}>
      {t(`screens.baccaratRoom.${target}`)}
    </Box>
  )
  const rate = getTargetRate(target, isCommissionFree)
  const rateLabel =
    target.indexOf('superTie') !== -1 ? (
      <Box className={clsx(classes.superTieBetRate)}>x{rate}</Box>
    ) : (
      <Box className={clsx(classes.betRate)}>{rate}</Box>
    )

  return (
    <Box onClick={onClick} className={classes.bet}>
      <div className="flex flex-col justify-around items-center h-full">
        <Box
          className={clsx({
            [classes.textContainer]: display === 'row',
            [classes.column]: display !== 'row',
          })}
        >
          {targetLabel}
          {rateLabel}
        </Box>
        <AmountLabel target={target} />
      </div>
      <Chips player={target} />
    </Box>
  )
}

export default React.memo(BetBlock)
