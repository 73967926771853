import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'

import { useForm } from 'react-hook-form'

export default function CustomGiftModal({ onSubmit, onClose }) {
  const { register, handleSubmit } = useForm()
  const submit = useCallback(
    (data) => {
      onClose()
      onSubmit(data)
    },
    [onClose, onSubmit],
  )

  return createPortal(
    <div className="fixed bg-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
      <form onSubmit={handleSubmit(submit)} className="min-w-[375px] p-[20px]">
        <div className="my-2 flex">
          <h1 className="text-2xl flex-grow">
            <span className="font-bold">自由打賞</span>
            <sub className="text-xs text-gray-600">(金額無上限$最低99)</sub>
          </h1>
          <div className="p-2 cursor-pointer" onClick={onClose}>
            ╳
          </div>
        </div>

        <div className="my-2">
          <h2 className="text-lg font-bold">打賞金額</h2>
          <input
            type="number"
            name="amount"
            className="border border-black px-2 py-1 w-full"
            placeholder="請點擊輸入..."
            ref={register}
          />
        </div>

        <div className="my-2 hidden">
          <h2 className="text-lg font-bold">自訂打賞賀詞</h2>
          <input
            type="text"
            name="comment"
            className="border border-black px-2 py-1 w-full"
            placeholder="請點擊輸入..."
            ref={register}
          />
        </div>

        <div className="flex justify-end gap-3">
          <button type="button" className="bg-[#D6D6D6] p-3" onClick={onClose}>
            取消
          </button>
          <button type="submit" className="bg-[#0D1E4A] p-3 text-white">
            確定
          </button>
        </div>
      </form>
    </div>,
    document.body,
  )
}
