import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CopyBtn from 'components/application/CopyBtn'
import { moneyFormat, timeFormat } from 'helpers/index'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import BaccaratGameResult from './BaccaratGameResult'
import GameResult from './GameResult'
import { withProfiler } from '@sentry/react'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  normalBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(13),
    color: 'rgba(255, 255, 255, 0.5)',
  },
  bet: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.primary.main,
  },
  dialog: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  dialogTitle: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  check: {
    marginLeft: theme.spacing(1),
    color: '#2D9CDB',
    fontSize: theme.typography.pxToRem(13),
  },
}))

const BetRecord = ({ record }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Paper className={classes.paper}>
      <Box p={2} color="white">
        <Box className={classes.titleBlock}>
          <Box fontSize={18} display="flex" alignItems="center">
            {t('screens.records.bet')}
            <Box className={classes.check} onClick={onClick}>
              {t('screens.records.check')}
            </Box>
          </Box>
          <Box className={classes.bet}>{moneyFormat(record.info.total)}</Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{timeFormat(record.createdAt)}</Box>
          <Box fontSize={15}>{moneyFormat(record.info.total)}</Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box className={classes.subTitle}>{t('screens.records.time')}</Box>
          <Box className={classes.subTitle}>{t('screens.records.bet')}</Box>
        </Box>
        <Box className={classes.normalBlock}>
          <Box fontSize={15}>{record.info.bet_no}</Box>
          <Box fontSize={15}>{moneyFormat(record.info.diff)}</Box>
        </Box>
        <Box className={classes.titleBlock}>
          <Box display="flex" alignItems="center" className={classes.subTitle}>
            {t('screens.records.bet')}ID
            <CopyBtn text={record.info.bet_no} />
          </Box>
          <Box className={classes.subTitle}>{t('screens.records.diff')}</Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {t('screens.records.records')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {record.info.game_type === 'niuniu' && (
            <GameResult betNo={record.info['bet_no']} />
          )}
          {record.info.game_type !== 'niuniu' && (
            <BaccaratGameResult betNo={record.info['bet_no']} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>關閉</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
export default withProfiler(React.memo(BetRecord))
