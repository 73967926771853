import React from 'react'
import { Box } from '@material-ui/core'
import { useGame } from 'screens/richman/AllRooms/GameProvider'
import Card from 'components/richman/Card'

const FirstTwoCards = ({ target, height }) => {
  const state = useGame()
  const cards = state[`${target}Cards`]

  if (cards === null) return null

  return (
    <Box display="flex" alignItems="center">
      <Card width={height / 5} selected card={cards[0]} on soundOn={false} />
      {cards[1] && (
        <Card width={height / 5} selected card={cards[1]} on soundOn={false} />
      )}
    </Box>
  )
}

export default React.memo(FirstTwoCards)
